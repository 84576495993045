
import { Form } from 'antd';


export const FormItem = ({ children, name, ...props }) => {
  return (
      <Form.Item
        name={name}
        {...props}
      >
        {children}
      </Form.Item>
  );
};

