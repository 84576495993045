import { Menu, Popover } from 'antd';
import { LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ISAMDiaLogoWh } from 'containers/Logos';
import { useWindowSize } from 'hooks';
import { logout } from 'fetcher';
import { NAV_STYLE_DRAWER, NAV_STYLE_FIXED, NAV_STYLE_MINI_SIDEBAR, NAV_STYLE_NO_HEADER_MINI_SIDEBAR, TAB_SIZE, THEME_TYPE_LITE } from 'constant/themeSettings';
import { toggleCollapsedNav } from 'slices/commonSlice';
import { onNavStyleChange } from 'slices/settingSlice';
import { useGetCurrentUserQuery } from 'services';

const SidebarLogo = () => {
  const dispatch = useDispatch();
  const { width } = useWindowSize();

  const themeType = useSelector(({ settings }) => settings.themeType);
  const navCollapsed = useSelector(({ common }) => common.navCollapsed);
  const urlCheck = window.location.origin === process?.env.REACT_APP_TEST_URL;
  let navStyle = useSelector(({ settings }) => settings.navStyle);

  if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
    navStyle = NAV_STYLE_DRAWER;
  }

  const { data: current_user } = useGetCurrentUserQuery();

  const userMenuOptions = (
    <Menu>
      <Menu.Item
        key="kullanici-bilgileri"
        icon={<SettingOutlined />}
      >
        <Link to="/kullanici-bilgileri">Kullanıcı Ayarları</Link>
      </Menu.Item>
      <Menu.Item
        icon={<LogoutOutlined />}
        key="logout"
        onClick={logout}
      >
        Çıkış Yap
      </Menu.Item>
    </Menu>
  );

  return (
    <div
      style={{ zIndex: 99 }}
      className="gx-layout-sider-header sidebarHeaderBorder"
    >
      {navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR ? (
        <div className={`gx-linebar ${urlCheck ? 'gx-pt-2' : ''}`}>
          <i
            className={`gx-icon-btn icon icon-${navStyle === NAV_STYLE_MINI_SIDEBAR ? 'menu-unfold' : 'menu-fold'} ${
              themeType !== THEME_TYPE_LITE ? 'gx-text-white' : ''
            }`}
            onClick={() => {
              if (navStyle === NAV_STYLE_DRAWER) {
                dispatch(toggleCollapsedNav(!navCollapsed));
              } else if (navStyle === NAV_STYLE_FIXED) {
                dispatch(onNavStyleChange(NAV_STYLE_MINI_SIDEBAR));
              } else if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
                dispatch(toggleCollapsedNav(!navCollapsed));
              } else {
                dispatch(onNavStyleChange(NAV_STYLE_FIXED));
              }
            }}
          />
        </div>
      ) : null}

      <Popover
        className="gx-p-0 gx-d-md-none userInfo "
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click"
      >
        <span className="gx-avatar-name gx-text-center">
          <UserOutlined style={{ marginRight: 10 }} />
          {current_user?.fullName ? current_user?.fullName : ''}
          <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
        </span>
      </Popover>

      <Link
        to="/anasayfa"
        className="gx-site-logo"
        style={{ position: 'relative' }}
      >
        <ISAMDiaLogoWh
          className={`gx-d-none gx-d-md-block ${urlCheck ? 'logoPosition' : ''}`}
          width={150}
          height={75}
        />
      </Link>
    </div>
  );
};

export default SidebarLogo;
