import { configureStore } from '@reduxjs/toolkit';

import keyboardSlice from '../slices/keyboardSlice';
import commonSlice from '../slices/commonSlice';
import settingSlice from '../slices/settingSlice';
import { apiInstance } from '../services';

const store = configureStore({
  reducer: {
    keyboard: keyboardSlice,
    common: commonSlice,
    settings: settingSlice,
    [apiInstance.reducerPath]: apiInstance.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiInstance.middleware),
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;
