import Refund from 'components/ArticleOffer/offer/ArticleStatus/Refund';

const EncyclopiaUnitRequestOpinion = ({ status, step }) => {
  return (
    <Refund
      committee="eu_control"
      step={step}
      status={status}
    />
  );
};

export default EncyclopiaUnitRequestOpinion;
