import apiInstance from './api';

const versionApi = apiInstance.injectEndpoints({
  endpoints: (build) => ({
    getVersion: build.query({
      query: () => '/fversion',
      providesTags: ['Version'],
    }),
  }),
  overrideExisting: true,
});

export default versionApi;
