import { useEffect, useMemo } from 'react';

import { useGetAsignmentFieldsQuery } from 'services';
import { Form, Select } from 'antd';

import AsignItem from '../ContentAsignment/AsignItem';

const RedactionDecision = ({ offerID, type }) => {
  const { data: assignments } = useGetAsignmentFieldsQuery({
    type,
    articleOffer: offerID,
  });
  const form = Form.useFormInstance();
  const redactionStartUnit = Form.useWatch('redactionCommitee', form);

  const redactionUnit = useMemo(() => {
    return assignments?.filter((step) => step.status >= redactionStartUnit);
  }, [assignments, redactionStartUnit]);

  useEffect(() => {
    form.setFieldsValue({
      assignments: redactionUnit?.reduce((acc, item, index) => {
        acc[index] = {
          field: item.id,
          user: item.assignments[0]?.user?.id,
        };
        return acc;
      }, {}),
    });
  }, [redactionUnit, redactionStartUnit, form]);

  return (
    <>
      <Form.Item
        rules={[
          {
            required: true,
            message: 'Lütfen karar verecek redaksiyon aşaması seçin',
          },
        ]}
        name="redactionCommitee"
      >
        <Select
          placeholder="Redaksiyon aşamalarını seçiniz"
          style={{ width: '100%' }}
          allowClear
          options={
            assignments?.map((step) => ({
              label: step.roleText,
              value: step.status,
            })) || []
          }
        />
      </Form.Item>
      {redactionUnit?.map((step, index) => (
        <div
          key={step.id}
          className="gx-d-flex gx-align-items-center gx-mt-4 gx-w-100"
        >
          <AsignItem
            field={step.id}
            label={step.roleText}
            status={step.status}
            index={index}
            role={step.role}
            isRedaction
          />
        </div>
      ))}
    </>
  );
};

export default RedactionDecision;
