import DisposalContractGeneralSettings from 'module/Form/DisposalContract/DisposalContractGeneralSettings';

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Button, Card, Col, Form, Input, Modal, Row, Select, Steps } from 'antd';
import { CircularProgress } from 'components/CircularProgress';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  useCalculateDisposalContactPriceMutation,
  useCreateDisposalContractMutation,
  useGenareteDisposalContractMutation,
  useGetDisposalContractsQuery,
  useGetStiplatedTimeQuery,
  useUpdateDisposalContractMutation,
} from 'services';
import DisposedContract from 'components/DisposedContract';
import { fileDownload } from 'fetcher';
import { currencyFormatter, downloadBlob, openNotification } from 'utils';
import { useDispatch } from 'react-redux';
import { fetchError } from 'slices/commonSlice';

const formLabelText = {
  receivedLength: 'Gelen Hacim',
  approvedLength: 'Onaylanan Hacim (Telif)',
  approvedRevisionLength: 'Onaylanan Hacim (Tashih/Redaksiyon)',
};

const SavingContract = ({ articleLength, articleLengthCustom, offerID, authors, ...other }) => {
  const timeOutRef = useRef(null);
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { data: times, isLoading } = useGetStiplatedTimeQuery();
  const [currentStep, setCurrentStep] = useState(0);
  const { data, isLoading: contractLoading } = useGetDisposalContractsQuery(offerID, {
    skip: !other.open,
  });
  const [calculateDisposalContractPrice, { data: price }] = useCalculateDisposalContactPriceMutation();
  const [createDisposalContract] = useCreateDisposalContractMutation();
  const [updateDisposalContract] = useUpdateDisposalContractMutation();
  const [generateDisposalContract] = useGenareteDisposalContractMutation();
  const stipulatedTime = times?.find((time) => time?.lengthKey === articleLength);
  const [form] = Form.useForm();
  const author = Form.useWatch('author', form);

  // sisteme bilgisi girilmiş olan yazarı bulmak için kullanılanılır
  const authorContractData = useMemo(
    () => ({
      authorPaymentInfo: data?.contributors?.find((item) => item.author === author),
    }),
    [data, author]
  );
  const handleDownload = async (fileUrl, fileName) => {
    const { data } = await fileDownload(fileUrl);
    downloadBlob(data, fileName);
  };

  const handleGenerateContract = async () => {
    const { error } = await generateDisposalContract({
      id: disposalID,
    });

    if (error) {
      return dispatch(
        fetchError(
          formatMessage(
            {
              id: 'article.form.fetchError',
            },
            { error: error.status }
          )
        )
      );
    }
    openNotification({
      message: formatMessage({
        id: 'appModule.actionSuccessfully',
      }),
    });
  };

  /**
   * bir sözleşme oluşup oluşmadığının kontrolü
   */
  const isContractExist = Boolean(data?.isContractGenerated);

  const authorPrice = Form.useWatch('price', form);
  const approvedLength = Form.useWatch('approvedLength', form);
  const approvedRevisionLength = Form.useWatch('approvedRevisionLength', form);
  const disposalID = Form.useWatch('disposalID', form);

  const inputValidationRules = [
    {
      required: true,
      validator: (item, value) => {
        if (!value) {
          return Promise.reject(`${formLabelText[item.field]} alanı zorunludur`);
        }

        if (item.field === 'receivedLength' && Number(value) === 0) {
          return Promise.reject(`${formLabelText[item.field]} sıfırdan büyük olmalıdır`);
        }

        if (Number(approvedLength) === 0 && Number(approvedRevisionLength) === 0) {
          return Promise.reject('Onaylanan Hacim (Telif) veya Onaylanan Hacim (Tashih/Redaksiyon) alanlarının ikisi birden sıfır olamaz');
        }

        if (Number(value) < 0) {
          return Promise.reject(`${formLabelText[item.field]} negatif değer olamaz`);
        }
        if (Number(value) % 1 !== 0) {
          return Promise.reject(`${formLabelText[item.field]} tam sayı olmalıdır`);
        }

        return Promise.resolve();
      },
    },
  ];

  // initial time da gelen verileri set etmek için kullanılan useEffect
  const handleInitialValues = useCallback(() => {
    if (data) {
      form.setFieldsValue({
        disposalID: data.id,
        disposalContract: data['@id'],
        wordPrice: data.wordPrice,
        revisionPrice: data.revisionPrice,
        pricePercent: data.pricePercent,
        boardDecision: data.boardDecision,
      });
    }
  }, [data, form]);

  useEffect(() => {
    handleInitialValues();
  }, [handleInitialValues]);

  // kullannıcının girdiği değerlere göre anlık olarak fiyatı hesaplamak için kullanılan fonksiyon
  const handleCalculate = useCallback(() => {
    clearTimeout(timeOutRef.current);
    timeOutRef.current = setTimeout(() => {
      // bu alanların hepsi zorunlu alanlar ancak bu istek form submit eventiyle çalışmadığı için bu şekilde bir kontrol yapmak zorunda kaldım
      if (approvedLength && approvedRevisionLength && disposalID && !authorPrice) {
        calculateDisposalContractPrice({
          id: disposalID,
          body: {
            approvedLength: Number(approvedLength),
            approvedRevisionLength: Number(approvedRevisionLength),
          },
        });
      }
    }, 1500);
  }, [calculateDisposalContractPrice, approvedLength, approvedRevisionLength, disposalID, authorPrice]);

  useEffect(() => {
    handleCalculate();
    return () => clearTimeout(timeOutRef.current);
  }, [handleCalculate, approvedLength, approvedRevisionLength]);

  // eğer henüz sözleşme oluşturulmamış ise telif ve redaksiyon fiyatlarını her seferinde yapabilmesi için ilgili step e yönlendirme yapıldı
  useEffect(() => {
    if (isContractExist) {
      setCurrentStep(1);
    } else {
      setCurrentStep(0);
    }
  }, [isContractExist]);

  // hesaplanan fiyatı formun price alanına set etmek için kullanılan useEffect
  useEffect(() => {
    if (price?.price) {
      form.setFieldValue('price', currencyFormatter(price.price, price.currency));
    }
  }, [price, form]);
  // selectbox ile seçilen yazarın girilmiş haci bilgilerini set etmek için kullanılan useEffect
  useEffect(() => {
    if (authorContractData.authorPaymentInfo) {
      form.setFieldsValue({
        ...authorContractData.authorPaymentInfo,
        price: currencyFormatter(authorContractData.authorPaymentInfo.price, authorContractData.authorPaymentInfo.currency),
      });
    }
  }, [authorContractData.authorPaymentInfo, form]);
  if (isLoading || contractLoading) return <CircularProgress />;

  const gridCells = [
    {
      label: <FormattedMessage id="article.detail.articleLength" />,
      content: articleLengthCustom ? articleLengthCustom : stipulatedTime?.lengthDisplay,
      disabled: true,
      colSpan: 8,
    },
    {
      label: 'Gelen Hacim',
      name: 'receivedLength',
      type: 'number',
      disabled: isContractExist,
      rules: inputValidationRules,
      colSpan: 8,
    },
    {
      label: 'Onaylanan Hacim (Telif)',
      name: 'approvedLength',
      disabled: isContractExist,
      rules: inputValidationRules,
      dependencies: ['approvedRevisionLength'],
      colSpan: 6,
    },
    {
      label: 'Onaylanan Hacim (Tashih/Redaksiyon) ',
      name: 'approvedRevisionLength',
      disabled: isContractExist,
      dependencies: ['approvedLength'],
      rules: inputValidationRules,
      colSpan: 6,
    },
    {
      label: 'Kelime Ücreti (Telif)',
      name: 'wordPrice',
      type: 'number',
      disabled: true,
      rules: [
        {
          required: true,
          message: 'Bu alan zorunludur',
        },
      ],
      colSpan: 6,
    },
    {
      label: 'Kelime Ücreti (Tashih/Redaksiyon)',
      name: 'revisionPrice',
      type: 'number',
      disabled: true,
      rules: [
        {
          required: true,
          message: 'Bu alan zorunludur',
        },
      ],
      colSpan: 6,
    },
    {
      label: 'Yönetim Kurulu Kararı',
      name: 'boardDecision',
      isHidden: Boolean(!data?.boardDecision),
      disabled: true,
    },

    {
      label: 'Telif Yüzdesi',
      name: 'pricePercent',
      defaultValue: 100,
      disabled: true,
      type: 'number',
      rules: [
        {
          required: true,
          message: 'Bu alan zorunludur',
        },
      ],
      colSpan: 12,
      withPercentage: true,
    },
    {
      label: 'Toplam Ücret',
      name: 'price',
      disabled: true,
      colSpan: 12,
    },
  ];

  const handleFinish = async (values) => {
    const body = {
      disposalContract: values.disposalContract,
      author: values.author,
      receivedLength: Number(values.receivedLength),
      approvedLength: Number(values.approvedLength),
      approvedRevisionLength: Number(values.approvedRevisionLength),
    };

    const { error } = authorContractData.authorPaymentInfo
      ? await updateDisposalContract({
          id: authorContractData.authorPaymentInfo.id,
          body,
        })
      : await createDisposalContract({
          body,
        });

    if (error) {
      return dispatch(
        fetchError(
          formatMessage(
            {
              id: 'article.form.fetchError',
            },
            { error: error.status }
          )
        )
      );
    }
    openNotification({
      message: formatMessage({
        id: 'appModule.actionSuccessfully',
      }),
    });
  };

  const items = [
    {
      title: 'Genel',
      content: (
        <>
          <DisposalContractGeneralSettings
            data={data}
            handleNextStep={setCurrentStep}
          />
        </>
      ),
    },
    {
      title: 'Yazar',
      content: (
        <>
          <Form
            layout="vertical"
            form={form}
            onFieldsChange={(changedFields) => {
              changedFields.forEach((field) => {
                if (field.name[0] === 'author') {
                  return form.resetFields(['receivedLength', 'approvedLength', 'approvedRevisionLength', 'price']);
                }
                if (field.name[0] === 'approvedLength' || field.name[0] === 'approvedRevisionLength') {
                  return form.resetFields(['price']);
                }
              });
            }}
            onFinish={handleFinish}
            className="gx-mt-5"
          >
            <Form.Item
              name="disposalContract"
              hidden
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="disposalID"
              hidden
            >
              <Input />
            </Form.Item>
            <Row
              style={{
                flexDirection: 'row',
              }}
              gutter={24}
            >
              <Col
                sm={24}
                md={8}
              >
                <Card>
                  <Form.Item
                    name="author"
                    label="Yazar Seçiniz"
                    initialValue={authors.length === 1 || isContractExist ? authors[0].author['@id'] : undefined}
                  >
                    <Select
                      options={authors.map((item) => ({
                        key: item.author['@id'],
                        value: item.author['@id'],
                        label: item.author.user.fullName,
                      }))}
                      disabled={authors.length === 1}
                    />
                  </Form.Item>
                </Card>
              </Col>

              {gridCells.map((cell) => (
                <Col
                  sm={24}
                  className={cell.isHidden && 'gx-display-none'}
                  md={cell.colSpan || 24}
                  key={cell.label}
                >
                  <Card>
                    <Form.Item
                      label={cell.label}
                      name={cell.name}
                      {...(cell.dependencies && {
                        dependencies: cell.dependencies,
                      })}
                      rules={cell.rules}
                    >
                      <Input
                        type={cell.type || 'text'}
                        readOnly={cell.readOnly || false}
                        disabled={cell.disabled || false}
                        defaultValue={cell.defaultValue}
                        value={cell.content}
                        addonAfter={cell.withPercentage && '%'}
                      />
                    </Form.Item>
                  </Card>
                </Col>
              ))}
            </Row>
            <div className="gx-d-flex gx-align-items-center">
              {!isContractExist && (
                <Button
                  block
                  className="gx-mb-0"
                  onClick={() => {
                    form.resetFields(['price']);
                    setCurrentStep(0);
                  }}
                >
                  Geri
                </Button>
              )}
              <Button
                block
                className="gx-mb-0"
                disabled={isContractExist}
                type="primary"
                htmlType="submit"
              >
                Kaydet
              </Button>
            </div>
          </Form>
          {!isContractExist && (
            <Button
              className="gx-mt-3"
              block
              onClick={handleGenerateContract}
            >
              Kontrat Oluştur
            </Button>
          )}

          {isContractExist && (
            <DisposedContract
              handleDownload={handleDownload}
              contractFileName={data?.contract?.name}
              signedContractFileName={data?.signedContract?.name}
              contractID={data?.contract?.id}
              signedContractID={data?.signedContract?.id}
              disposalID={data?.id}
              onCancel={() => other.onCancel()}
            />
          )}
        </>
      ),
    },
  ];

  return (
    <Modal
      {...other}
      width={'70%'}
      style={{
        top: 0,
      }}
      bodyStyle={{
        overflowY: 'auto',
        maxHeight: '700px',
      }}
      centered={false}
      title="Tasarruf Sözleşmesi"
      destroyOnClose
      footer={null}
    >
      <Steps
        current={currentStep}
        items={items}
        labelPlacement="vertical"
      />
      {items[currentStep].content}
    </Modal>
  );
};

export default SavingContract;
