import { setKeyboardInput, setKeyboardInputName } from 'slices/keyboardSlice';
import { useDispatch, useSelector } from 'react-redux';

export const useKeyboard = () => {
  const dispatch = useDispatch();

  const value = useSelector(({ keyboard }) => keyboard.value);
  const inputName = useSelector(({ keyboard }) => keyboard.inputName);

  const setInputName = (name) => {
    dispatch(setKeyboardInputName(name));
  };

  const setValue = (value) => {
    dispatch(setKeyboardInput(value));
  };

  return {
    inputName,
    setInputName,
    value,
    setValue,
  };
};
