import { Row, Form, Col, Input, Select, Checkbox, Button } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { regexPass } from 'utils';
import { useGetRolesQuery } from 'services';
import { CircularProgress } from 'components';

const FormItem = Form.Item;
const UserForm = ({
  vertical = false,
  userEdit = false,
  showPassCange,
  editProfile = false,
  isSuperAdmin = false,
  hasPassChangePermission = true,
  handlePassChange,
  changePassword,
}) => {
  const { data: roles, isLoading } = useGetRolesQuery();
  const { formatMessage } = useIntl();

  /**
   * @param {boolean} vertical
   * form içi elamanların düzenini belirler
   */
  const mdSpan = vertical ? 24 : 12;
  if (isLoading) return <CircularProgress />;

  return (
    <Row
      style={{
        flexDirection: 'row',
      }}
      gutter={24}
    >
      <Col
        sm={24}
        md={mdSpan}
      >
        <FormItem
          rules={[
            {
              required: true,
              message: <FormattedMessage id="userAdd.nameError" />,
            },
          ]}
          name="firstName"
          label={<FormattedMessage id="appModule.name" />}
          className="gx-mb-2"
        >
          <Input />
        </FormItem>
      </Col>
      <Col
        sm={24}
        md={mdSpan}
      >
        <FormItem
          rules={[
            {
              required: true,
              message: <FormattedMessage id="userAdd.surnameError" />,
            },
          ]}
          name="lastName"
          label={<FormattedMessage id="appModule.surname" />}
          className="gx-mb-2"
        >
          <Input />
        </FormItem>
      </Col>
      <Col
        sm={24}
        md={mdSpan}
      >
        <FormItem
          name="email"
          label={<FormattedMessage id="appModule.email" />}
          className="gx-mb-2"
          rules={[
            {
              required: true,
              type: 'email',
              message: <FormattedMessage id="app.userAuth.EmailErrorCheck" />,
            },
          ]}
        >
          <Input />
        </FormItem>
      </Col>
      {userEdit && (
        <Col sm={24}>
          <Form.Item
            name="isActive"
            valuePropName="checked"
            className="gx-my-3"
          >
            <Checkbox>
              <FormattedMessage id="userEdit.userIsActive" />
            </Checkbox>
          </Form.Item>
        </Col>
      )}

      {!userEdit && !editProfile && (
        <Col
          sm={24}
          md={mdSpan}
        >
          <FormItem
            label="Parola"
            name="plainPassword"
            className="gx-mb-2"
            rules={[
              {
                required: true,
                message: <FormattedMessage id="app.userAuth.PassError" />,
              },
              {
                pattern: regexPass,
                message: <FormattedMessage id="app.userAuth.PassResetInfoMessageType" />,
              },
              {
                min: 8,
                message: <FormattedMessage id="app.userAuth.PassResetInfoMessageCharacter" />,
              },
            ]}
          >
            <Input.Password />
          </FormItem>
        </Col>
      )}
      {!editProfile && (
        <Col sm={24}>
          <FormItem
            name="roles"
            label={<FormattedMessage id="userAdd.userRole" />}
            className="gx-mb-2"
          >
            <Select
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              mode="multiple"
              placeholder={<FormattedMessage id="userAdd.userRole" />}
              style={{ width: '100%' }}
              showSearch={false}
              notFoundContent={
                <div className="gx-text-center gx-h1-lg gx-my-2">
                  <FormattedMessage id="userAdd.userRoleNotFound" />{' '}
                </div>
              }
              options={roles?.map((item) => ({
                key: item.roleType,
                label: item.roleText,
                value: item.roleType,
                disabled: item.isAssignable === false,
              }))}
            />
          </FormItem>
        </Col>
      )}
      {(userEdit || editProfile) && (
        <Col sm={24}>
          {((!showPassCange && hasPassChangePermission) || isSuperAdmin) && (
            <Button
              className="gx-mt-2"
              onClick={() => handlePassChange(!showPassCange)}
            >
              <FormattedMessage id="userEdit.userPassChange" />
            </Button>
          )}
          {showPassCange && (
            <div>
              <hr />
              <h4>
                <FormattedMessage id="userEdit.userPassActionTitle" />
              </h4>
              {editProfile && (
                <FormItem
                  label={<FormattedMessage id="userEdit.userPassOld" />}
                  name="plainPassword"
                  className="gx-mb-2"
                  rules={[
                    {
                      required: true,
                      message: formatMessage({
                        id: 'appModule.requiredMessage',
                      }),
                    },
                  ]}
                  loading={true}
                >
                  <Input.Password type="password" />
                </FormItem>
              )}
              <FormItem
                label={<FormattedMessage id="userEdit.userPassNew" />}
                name="newPassword"
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id="app.userAuth.PassError" />,
                  },
                  {
                    pattern: regexPass,
                    message: <FormattedMessage id="app.userAuth.PassResetInfoMessageType" />,
                  },
                  {
                    min: 8,
                    message: <FormattedMessage id="app.userAuth.PassResetInfoMessageCharacter" />,
                  },
                ]}
              >
                <Input.Password />
              </FormItem>
              <FormItem
                label={<FormattedMessage id="userEdit.userPassAgain" />}
                name="passwordCheck"
                dependencies={['newPassword']}
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id="app.userAuth.PassAgainError" />,
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('newPassword') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          formatMessage({
                            id: 'app.userAuth.PassNotMatch',
                          })
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </FormItem>

              <div className="gx-mt-2 gx-d-flex">
                <Button onClick={() => handlePassChange(!showPassCange)}>
                  <FormattedMessage id="appModule.cancel" />
                </Button>
                <FormItem shouldUpdate>
                  {({ getFieldError, isFieldTouched, resetFields }) => (
                    <FormItem
                      shouldUpdate
                      name="passChangeButton"
                    >
                      <Button
                        onClick={() => {
                          changePassword();
                          resetFields();
                        }}
                        type={showPassCange ? 'primary' : 'default'}
                        disabled={getFieldError('passwordCheck').length !== 0 || !isFieldTouched('passwordCheck') || getFieldError('newPassword').length !== 0}
                      >
                        <FormattedMessage id="appModule.change" />
                      </Button>
                    </FormItem>
                  )}
                </FormItem>
              </div>
              <hr />
            </div>
          )}
        </Col>
      )}
    </Row>
  );
};

export default UserForm;
