import {
  MailOutlined,
  BookOutlined,
  CalendarOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import { Widget } from 'components';

export const UserAbout = ({ email, groups, lastLoginAt }) => {
  return (
    <Widget
      title={<FormattedMessage id="userDetail.title" />}
      styleName="gx-card-tabs gx-card-profile"
    >
      <div className="ant-card-body gx-mt-4  gx-align-items-center gx-d-flex gx-justify-content-between">
        <div className="gx-d-flex ">
          <div className="gx-media gx-align-items-center ">
            <div className="gx-mr-4">
              <MailOutlined className="gx-fs-xxl gx-text-grey" />
            </div>
          </div>

          <div className=" gx">
            <span className="gx-mb-0 gx-text-grey gx-fs-md">
              <FormattedMessage id="appModule.email" />
            </span>
            <p className="gx-mb-0">{email}</p>
          </div>
        </div>

        {groups && (
          <div className="gx-d-flex ">
            <div className="gx-media gx-align-items-center ">
              <div className="gx-mr-4">
                <BookOutlined className="gx-fs-xxl gx-text-grey" />
              </div>
            </div>
            <div className=" gx">
              <span className="gx-mb-0 gx-text-grey gx-fs-md">
                <FormattedMessage id="committees.Title" />
              </span>
              <p className="gx-mb-0">{groups}</p>
            </div>
          </div>
        )}

        <div className="gx-d-flex ">
          <div className="gx-media gx-align-items-center ">
            <div className="gx-mr-4">
              <CalendarOutlined className="gx-fs-xxl gx-text-grey" />
            </div>
          </div>

          <div>
            <span className="gx-mb-0 gx-text-grey gx-fs-md">
              <FormattedMessage id="userDetail.lastLoginAt" />
            </span>

            <p className="gx-mb-0">
              {dayjs(lastLoginAt).format('DD MMMM YYYY HH:mm:ss')}
            </p>
          </div>
        </div>
      </div>
    </Widget>
  );
};
