import { useEffect, useState } from 'react';

import { Input as AntInput } from 'antd';
import { useKeyboard } from 'hooks';

export const Search = ({ onFocus, onChange, onSearch, name, ...props }) => {
  const { inputName, setInputName, value, setValue } = useKeyboard();

  const [search, setSearch] = useState('');

  useEffect(() => {
    if (inputName === name) {
      if (value !== search) {
        if (onChange) {
          onChange({ target: { value } });
        }

        if (onSearch) {
          onSearch(value);
        }
      }

      setSearch(value);
    }
  }, [name, inputName, value, search, onSearch, onChange]);

  const onFocusChange = (event) => {
    const { value } = event.target;

    setInputName(name);
    setValue(value || '');

    if (onFocus) {
      onFocus(event);
    }
  };

  const onInputChange = (event) => {
    const { value } = event.target;
    setValue(value || '');
  };

  return (
    <AntInput.Search
      onFocus={onFocusChange}
      onChange={onInputChange}
      value={search}
      {...props}
    />
  );
};
