import { Form } from 'antd';

export const FormList = ({ children, name, ...props }) => {
  return (
    <Form.List
      name={name}
      {...props}
    >
      {children}
    </Form.List>
  );
};
