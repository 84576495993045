import CommiteeDecisionForm from 'components/ArticleOrder/status/CommitteeDecisionForm';

const ApprovedCommiteeDecision = ({ offerID, step, status }) => {
  return (
    <CommiteeDecisionForm
      offerID={offerID}
      step={step}
      status={status}
    />
  );
};

export default ApprovedCommiteeDecision;
