const { default: apiInstance } = require('./api');

const userApi = apiInstance.injectEndpoints({
  endpoints: (build) => ({
    getUserList: build.query({
      query: (params) => ({
        url: `/users`,
        params,
      }),
      providesTags: ['UserList'],
    }),
    getFilteredUserList: build.query({
      query: (params) => ({
        url: `/users/auto_complete`,
        params,
      }),
      transformResponse: (response) => response['hydra:member'],
      providesTags: ['UserAutoComplete'],
    }),
  }),
});

export default userApi;
