import { CircularProgress } from 'components';
import { UserAbout, UserProfileHeader } from 'components/UserProfile';
import { useUserInfo } from 'providers/UserInfoProvider';

export default () => {
  const { user, isLoading } = useUserInfo();

  if (isLoading) return <CircularProgress />;

  return (
    <>
      <UserProfileHeader {...user} />
      <UserAbout {...user} />
    </>
  );
};
