import useSWR from 'swr';
import { ROLES_DEF } from 'utils';

const useUsers = ({ id, pagination = true, page = 1, pageSize = 20, search, isActive, roles, memberships }) => {
  const url = id ? `/users/${id}` : `/users`;

  const params = new URLSearchParams({
    pagination,
    page,
    itemsPerPage: pageSize,
    ...(search && { searchKey: search }),
    ...(isActive && { isActive }),
  });

  roles?.forEach((role) => params.append('roles[]', role));
  memberships?.forEach((membership) => params.append('memberships.committee.shortName[]', membership));

  const { data, error, mutate } = useSWR(`${url}?${params.toString()}`, {
    revalidateOnFocus: false,
  });

  let userRoles = [];
  if (id && data) {
    userRoles = data.roles.map((role) => {
      return { roleType: role, text: ROLES_DEF[role] };
    });
  }

  return {
    url,
    users: data,
    isLoading: !error && !data,
    isError: error,
    userRoles,
    mutate,
  };
};

export default useUsers;
