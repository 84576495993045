import apiInstance from './api';

export const authorApi = apiInstance.injectEndpoints({
  endpoints: (builder) => ({
    getAuthors: builder.query({
      query: (params) => ({
        url: '/authors',
        params,
      }),

      providesTags: ['Authors'],
    }),
    getAuthor: builder.query({
      query: (id) => `/authors/${id}`,

      providesTags: ['Author'],
    }),
    editAuthor: builder.mutation({
      query: ({ id, body }) => ({
        url: `/authors/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Author'],
    }),
    addAuthor: builder.mutation({
      query: (body) => ({
        url: '/authors',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Author'],
    }),
  }),
  overrideExisting: true,
});
