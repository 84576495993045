import useSWR from 'swr';

const useTimeLine = (id) => {
  const { data, error } = useSWR(
    `/activities?entity=App\\Entity\\ArticleOffer&entityId=${id}&pagination=false`
  );
  return {
    timeScale: data,
    isLoading: !error && !data,
    isError: error,
  };
};
export default useTimeLine;
