import { Link } from 'react-router-dom';
import { Card } from 'antd';
import { FormattedMessage } from 'react-intl';
import { InfoView } from 'components';

import { ISAMDiaLogo, ISAMLogo } from './Logos';
import 'styles/customForm/customFilter.less';

export const Login = ({ children }) => {
  return (
    <div
      className="gx-d-flex gx-justify-content-center gx-align-items-center"
      style={{
        height: '100vh',
        width: '100vw',
      }}
    >
      <Card className="gx-p-2 login-card">
        <div
          style={{ margin: '24px 48px 24px 48px' }}
          className="gx-text-center"
        >
          <Link to={'/'}>
            <ISAMDiaLogo
              width={200}
              height={100}
            />
          </Link>
        </div>
        <h4 className="gx-mx-auto gx-w-75 gx-text-center gx-font-weight-semi-bold gx-p-1  gx-text-grey">
          <FormattedMessage id="app.userAuth.Header" />
        </h4>

        {children}

        <div className="gx-p-2 gx-d-flex gx-align-items-center gx-justify-content-center">
          <ISAMLogo
            width={100}
            height={50}
          />
        </div>

        <InfoView />
      </Card>
    </div>
  );
};
