import React, { useState } from 'react';

import { Button } from 'antd';
import { useIntl } from 'react-intl';

import GiveCommitteOpinionModal from './GiveCommitteOpinionModal';
/**
 * Görüş Bildir Butonu
 */
const GiveCommitteeOpinionAction = ({ committes }) => {
  const { formatMessage } = useIntl();
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        className="modalBtnStyle"
        block
        onClick={() => setOpen(true)}
      >
        {formatMessage({
          id: 'article.committeeDecisionGiveOpinion',
        })}
      </Button>
      <GiveCommitteOpinionModal
        open={open}
        onCancel={() => setOpen(false)}
        committes={committes}
      />
    </>
  );
};

export default GiveCommitteeOpinionAction;
