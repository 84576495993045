import { useState } from 'react';

import { Button, Radio } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { sendPullbackDecision } from 'fetcher';
import { openNotification } from 'utils';
import { Form, Input } from 'components';
import { fetchError } from 'slices/commonSlice';

const { TextArea } = Input;

export default ({ request, id, committee }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const { formatMessage } = useIntl();

  const [loading, setLoading] = useState(false);

  const initialValues = {
    decision: null,
    description: '',
  };

  const onFinish = async (decision) => {
    setLoading(true);

    try {
      await sendPullbackDecision(id, committee, decision);

      openNotification({
        message: formatMessage({ id: 'appModule.actionSuccessfully' }),
      });

      navigate('/madde-teklifleri/tum-maddeler');
    } catch (error) {
      dispatch(fetchError(formatMessage({ id: 'article.form.fetchError' }, { error: `${error}` })));
    }

    setLoading(false);
  };

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <Form.Item shouldUpdate>
        {({ getFieldValue }) => (
          <>
            <Form.Item name="decision">
              <Radio.Group className="pullback-request-form">
                <Radio.Button
                  value="approve"
                  className={`gx-mr-3 ${getFieldValue('decision') === 'approve' ? 'success-filled' : 'success'}`}
                >
                  <FormattedMessage id="appModule.accept" />
                </Radio.Button>

                <Radio.Button
                  value={'rejected'}
                  className={`${getFieldValue('decision') === 'rejected' ? 'danger-filled' : 'danger'}`}
                >
                  <FormattedMessage id="appModule.reject" />
                </Radio.Button>
              </Radio.Group>
            </Form.Item>

            {getFieldValue('decision') === 'rejected' && (
              <Form.Item
                name="description"
                label={<FormattedMessage id="article.decision.reasonTitle" />}
                className="gx-mb-4"
                rules={[
                  {
                    required: true,
                    message: formatMessage(
                      { id: 'appModule.requiredFieldMessage' },
                      {
                        field: formatMessage({
                          id: 'article.decision.reasonTitle',
                        }),
                      }
                    ),
                  },
                  {
                    min: 100,
                    message: formatMessage({
                      id: 'article.reasonLengthMessage',
                    }),
                  },
                ]}
              >
                <TextArea
                  placeholder={formatMessage({
                    id: 'article.decision.reasonPlaceholder',
                  })}
                  rows={5}
                  showCount
                />
              </Form.Item>
            )}

            <div className={`gx-d-flex gx-justify-content-between gx-align-items-center`}>
              <div className="gx-d-flex gx-align-items-center">
                <ClockCircleOutlined style={{ fontSize: 16 }} />
                <span className="gx-ml-1">
                  <FormattedMessage
                    id="article.status.pullback.requestTime"
                    values={{
                      time: dayjs(request.createdAt).format('DD MMMM YYYY - HH:mm'),
                    }}
                  />
                </span>
              </div>

              <Button
                type="primary"
                className="gx-mb-0 gx-px-4"
                htmlType="submit"
                disabled={!getFieldValue('decision') || (getFieldValue('decision') === 'rejected' && !getFieldValue('description'))}
                loading={loading}
              >
                <FormattedMessage id="form.send" />
              </Button>
            </div>
          </>
        )}
      </Form.Item>
    </Form>
  );
};
