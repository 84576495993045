import { useEffect } from 'react';

import { Card, Form, Col, Row, Select, Switch, Input } from 'antd';
import { useGetUserListQuery } from 'services';

const { TextArea } = Input;

const AsignItem = ({ status, index, field, label, disabled, handleIsEditable, isRedaction = false, isRequired = true, role }) => {
  const { data, isLoading } = useGetUserListQuery({
    'roles[]': role,
  });

  useEffect(() => {
    if (status && !isRedaction) {
      handleIsEditable(status);
    }
  }, [status, isRedaction, handleIsEditable]);
  const form = Form.useFormInstance();

  const addNote = Form.useWatch(status, form);

  return (
    <Card className="gx-w-100">
      <Row
        align="middle"
        gutter={24}
      >
        <Col span={18}>{label}</Col>
        <Col span={6}>
          {!isRedaction && (
            <Form.Item
              name={['assignments', index, 'id']}
              hidden
            >
              <Input />
            </Form.Item>
          )}
          <Form.Item
            name={['assignments', index, 'field']}
            initialValue={field}
            hidden
          >
            <Input value={field} />
          </Form.Item>

          <Form.Item
            rules={[
              {
                required: isRequired,
                message: 'Lütfen bir kullanıcı seçiniz!',
              },
            ]}
            name={['assignments', index, 'user']}
          >
            <Select
              disabled={disabled}
              options={data?.['hydra:member']?.map((item) => ({
                label: item.fullName,
                value: item.id,
              }))}
              loading={isLoading}
              className="gx-w-100"
            />
          </Form.Item>
        </Col>
        {isRedaction && (
          <Col span={24}>
            <label className="gx-mt-3 gx-mb-1">Not Ekle</label>
            <Form.Item
              name={status}
              valuePropName="checked"
              normalize={(value) => (!value ? undefined : value)}
            >
              <Switch size="small" />
            </Form.Item>
            {addNote && (
              <div className="gx-m-2">
                <label>Notunuz</label>
                <Form.Item name={['assignments', index, 'note']}>
                  <TextArea
                    rows={4}
                    showCount
                  />
                </Form.Item>
              </div>
            )}
          </Col>
        )}
      </Row>
    </Card>
  );
};

export default AsignItem;
