import { Route, Routes } from 'react-router-dom';
import { SignIn, ResetPassword, TokenExpired } from 'containers';
import { NotFound } from 'routes/404';

import { AppRedirect } from './AppRedirect';
import { AppContent } from './AppContent';

export const AppRoutes = () => (
  <Routes>
    <Route
      index
      element={<AppRedirect />}
    />
    <Route
      path="/giris"
      element={<SignIn />}
    />
    <Route
      exact
      path="/parola-sifirlama-hata"
      element={<TokenExpired />}
    />
    <Route
      path="/parola-sifirlama/:token"
      strict
      element={<ResetPassword />}
    />
    <Route
      exact
      path="/parola-sifirlama"
      element={<ResetPassword noToken={true} />}
    />
    <Route
      path="*"
      element={<AppContent />}
    />
    <Route
      path="*"
      element={<NotFound />}
    />
  </Routes>
);
