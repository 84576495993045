import { DownloadOutlined, NodeExpandOutlined } from '@ant-design/icons';
import { Card, Col, Divider, Row } from 'antd';
import { CircularProgress } from 'components';
import { fileDownload } from 'fetcher';
import { FormattedMessage } from 'react-intl';
import { useGetArticleOfferGuidesQuery } from 'services';
import { downloadBlob } from 'utils';

import ContentTemplate from '../ContentTemplate';

const GuideItem = ({ mediaName, handleDownload }) => {
  return (
    <Card className="gx-mb-0">
      <div className="gx-d-flex gx-align-items-center gx-justify-content-between">
        <div className="gx-d-flex gx-align-items-center">
          <NodeExpandOutlined className="gx-fs-xxl" />
          <span className="gx-ml-3">{mediaName}</span>
        </div>

        <a
          className="gx-ml-2"
          onClick={handleDownload}
        >
          <DownloadOutlined className="gx-fs-xl" />
        </a>
      </div>
    </Card>
  );
};

/**
 *@params {bool} isEncylopediaUnitControl
 * bu parametre sadece ansiklopedi birimi kontrol asamasinda klavuz dosyalarinin gizlenmesi icin kullaniliyor
 */
export const GuideFiles = ({ offerID, articleTypeID, isEncylopediaUnitControl = false }) => {
  const { data, isLoading } = useGetArticleOfferGuidesQuery(offerID);

  const currentArticleType = data?.filter((item) => item.articleType?.id === articleTypeID);

  async function download(file) {
    const { name, fileFullUrl } = file;
    const downloaded = await fileDownload(fileFullUrl);
    downloadBlob(downloaded.data, name);
  }

  if (isLoading) return <CircularProgress />;

  return (
    <div>
      <h2 className="gx-mb-4">
        <FormattedMessage id="article.detail.guides" />
        <span className="gx-fs-md"> ({data?.length}) </span>
      </h2>

      {currentArticleType.length > 0 && (
        <>
          <Row gutter={[24, 16]}>
            {currentArticleType.map((guide, i) => (
              <Col
                key={i}
                sm={24}
                md={12}
              >
                <GuideItem
                  mediaName={guide.media.name}
                  handleDownload={() => download(guide.media)}
                />
              </Col>
            ))}
          </Row>
          <Divider />
        </>
      )}
      <Row gutter={[24, 16]}>
        {data
          ?.filter((item) => item.articleType?.id !== articleTypeID)
          .map((guide, i) => (
            <Col
              key={i}
              sm={24}
              md={12}
            >
              <GuideItem
                mediaName={guide.media.name}
                handleDownload={() => download(guide.media)}
              />
            </Col>
          ))}
      </Row>
      {!isEncylopediaUnitControl && (
        <ContentTemplate
          id={offerID}
          articleTypeID={articleTypeID}
        />
      )}
    </div>
  );
};
