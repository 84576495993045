import CommitteeOpinionExtras from 'module/Form/CommitteeOpinionExtras';

import { Button, Form, Input } from 'antd';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSendCommitteeControlDecisionMutation } from 'services';
import { fetchError } from 'slices/commonSlice';
import Refund from 'components/ArticleOffer/offer/ArticleStatus/Refund';
import { committeeDecisions, refundObjectParser } from 'utils';

const { TextArea } = Input;

const CommiteeDecisionForm = ({ offerID, status, step, children }) => {
  const [sendCommitteeControlDecision] = useSendCommitteeControlDecisionMutation();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const handleFinish = async (values) => {
    delete values.itemFile;
    delete values.idCard;
    delete values.uvkaFile;
    let answers = {
      ...values,
    };
    refundObjectParser(answers);
    let newValues = {};
    if (status === committeeDecisions.rejected || status === committeeDecisions.revert) {
      newValues = {
        status,
        answers,
        mediaOfferDescription: values.description,
      };
    } else {
      newValues = {
        status,
        ...values,
      };
    }

    const { error } = await sendCommitteeControlDecision({ id: offerID, body: newValues });

    if (error) return dispatch(fetchError(formatMessage({ id: 'article.form.fetchError' }, { error: error.status })));

    navigate('/madde-teklifleri/tum-maddeler');
  };

  return (
    <Form
      layout="vertical"
      onFinish={handleFinish}
      form={form}
    >
      {children}

      <CommitteeOpinionExtras offerID={offerID} />
      {[committeeDecisions.rejected, committeeDecisions.revert].includes(status) ? (
        <Form.Item>
          <Refund
            status={status}
            committee="committee_control"
            step={step}
          />
        </Form.Item>
      ) : (
        <Form.Item
          name="description"
          className="gx-mb-4"
          rules={[
            {
              min: 50,
              message: formatMessage({
                id: 'article.reasonLengthMessageShort',
              }),
            },
          ]}
          normalize={(value) => (value.trim() === '' ? undefined : value)}
        >
          <TextArea
            rows={8}
            placeholder="Notunuz varsa girebilirsiniz"
            showCount
          />
        </Form.Item>
      )}
      <Button
        block
        htmlType="submit"
      >
        Gönder
      </Button>
    </Form>
  );
};

export default CommiteeDecisionForm;
