import UserForm from 'module/Form/UserForm';

import { useState } from 'react';

import { Button, Drawer, Form } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useGetCurrentUserQuery, useUpdateCurrentUserMutation } from 'services';
import { CircularProgress } from 'components/CircularProgress';
import { removeCookies, userRenewPassword } from 'fetcher';
import { openNotification } from 'utils';
import { useDispatch } from 'react-redux';
import { fetchError, fetchStart, fetchSuccess } from 'slices/commonSlice';

const EditProfileDrawer = ({ ...other }) => {
  const [loading, setLoading] = useState(false);
  const [showPassCange, setShowPassCange] = useState(false);
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { data: current_user, isLoading } = useGetCurrentUserQuery();
  const [updateCurrentUser] = useUpdateCurrentUserMutation();

  const [form] = Form.useForm();
  const oldPass = Form.useWatch('plainPassword', form);
  const newPass = Form.useWatch('newPassword', form);
  const newAgainPass = Form.useWatch('passwordCheck', form);

  if (isLoading) return <CircularProgress />;

  const onFinish = async (values) => {
    dispatch(fetchStart());
    setLoading(true);

    const { error } = await updateCurrentUser({ id: current_user.id, body: values });
    if (error) {
      dispatch(fetchError(error.message));
      setLoading(false);
      return;
    }
    setLoading(false);
    dispatch(fetchSuccess());
    openNotification({
      message: formatMessage({ id: 'appModule.actionSuccessfully' }),
    });
    if (values.email !== current_user.email) {
      removeCookies();
    }
  };

  const changePassword = async () => {
    setLoading(true);

    if (newPass === newAgainPass) {
      const pass = {
        oldPassword: oldPass,
        newPassword: newPass,
      };

      const res = await userRenewPassword(current_user.id, pass);
      if (res) {
        dispatch(fetchSuccess());

        setShowPassCange(!showPassCange);
        openNotification({
          message: formatMessage({ id: 'appModule.actionSuccessfully' }),
        });
        setLoading(false);
      } else {
        setLoading(false);
        dispatch(fetchError('Bilgilerinizi Kontrol Ediniz.'));
      }
    }
  };

  return (
    <Drawer
      style={{
        overflow: 'hidden',
      }}
      headerStyle={{ padding: 0 }}
      footerStyle={{
        padding: 0,
      }}
      width={800}
      bodyStyle={{ paddingTop: '1rem', paddingLeft: 0, paddingRight: 0, overflow: 'hidden' }}
      title={<FormattedMessage id="user.info.editProfile" />}
      footer={
        <Button
          form="editProfileForm"
          block
          loading={loading}
          type="primary"
          htmlType="submit"
        >
          <FormattedMessage id="appModule.save" />
        </Button>
      }
      destroyOnClose
      {...other}
    >
      <Form
        id="editProfileForm"
        form={form}
        initialValues={current_user}
        onFinish={onFinish}
        layout="vertical"
      >
        <UserForm
          vertical
          editProfile
          changePassword={changePassword}
          showPassCange={showPassCange}
          handlePassChange={() => setShowPassCange(!showPassCange)}
        />
      </Form>
    </Drawer>
  );
};

export default EditProfileDrawer;
