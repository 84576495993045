import AfterCopyrightContentControl from 'module/AfterCopyright/AfterCopyrightContentControl';

const PublishManagementControl = ({ offer }) => {
  return (
    <AfterCopyrightContentControl
      offer={offer}
      type={1}
      committee="pub_control"
    />
  );
};

export default PublishManagementControl;
