import { useMemo, useState } from 'react';

import { useAfterCopyrightUnitGeneralControlMutation, useGetAfterCopyRightAssignmentQuery, useGetCurrentUserQuery } from 'services';
import { Button, Card, Col, Form, Input, Row } from 'antd';
import { openNotification, refundObjectParser } from 'utils';
import { useDispatch } from 'react-redux';
import { fetchError } from 'slices/commonSlice';
import { useIntl } from 'react-intl';
import { article_order_status } from 'constant/static';
import { useUserHasPermission } from 'hooks';
import { OrderDetailGrid } from 'components/ArticleOrder';
import { OrderDetailDescriptions } from 'components/ArticleOrder/OrderDetailDescriptions';
import MediaField from 'components/AfterCopyright/MediaField';
import ArticleOrderMedia from 'components/ArticleOrder/ArticleOrderMedia';
import RevertAnswerItem from 'components/ArticleOffer/offer/ArticleStatus/RevertAnswerItem';
import GiveOpinionForEncylopediaUnit from 'components/AfterCopyright/EncyclopediaUnit/GiveOpinionForEncylopediaUnit';
import RedactionDecision from 'components/AfterCopyright/EncyclopediaUnit/RedactionDecision';
import AcceptDescision from 'components/AfterCopyright/EncyclopediaUnit/AcceptDescision';
import AssigmentNotes from 'components/AfterCopyright/EncyclopediaUnit/AssigmentNotes';
import EncyclopiaUnitRequestOpinion from 'components/AfterCopyright/EncyclopediaUnit/EncyclopiaUnitReqeuestOpinion';
import EncylopeiaManagementUnitRevert from 'components/AfterCopyright/EncylopediaManagementUnit/EncylopeiaManagementUnitRevert';
import EncylopediaManagementUnitReview from 'components/AfterCopyright/EncylopediaManagementUnit/EncylopediaManagementUnitReview';
import EncylopediaManagementUnitReject from 'components/AfterCopyright/EncylopediaManagementUnit/EncylopediaManagementUnitReject';
import StatusBar from 'components/StatusBar';

const unitDecisions = {
  review: 1,
  accept: 2,
  reject: 5,
  revert: 3,
  requestOpinion: 10,
  redaction: 12,
};

const { TextArea } = Input;

const AfterCopyrightContentControl = ({ offer, committee, type, encyclopediaUnit = false }) => {
  const [form] = Form.useForm();
  const { data: assigments } = useGetAfterCopyRightAssignmentQuery(
    {
      articleOffer: offer && offer.id,
      type: type,
    },
    {
      skip: !type,
    }
  );
  const { data: current_user } = useGetCurrentUserQuery();
  const { handleUserIsOnCommittee, handlePermission } = useUserHasPermission();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [afterCopyrightGeneralControl] = useAfterCopyrightUnitGeneralControlMutation();

  const [unitDecision, setUnitDecision] = useState(null);
  const [opinion, setOpinion] = useState(false);
  const [modalProps, setModalProps] = useState({
    visible: false,
    data: null,
  });
  const handleModalProps = (visible, data) => {
    setModalProps({ visible, data });
  };

  const euUnitDecisonVisible = useMemo(() => {
    return offer?.ownerCommitteeOpinion && committee === 'eu_control' && (current_user.euManager || handlePermission(offer && offer.ownerCommittee.id));
  }, [offer, handlePermission, committee, current_user]);

  const isShowdecisionOptions = useMemo(() => {
    return (
      (article_order_status['Ansiklopedi Birimi Kontrol'] === offer.status && (current_user.euManager || current_user.isAdmin)) ||
      (article_order_status['Yayin Mudurlugu Genel Kontrol'] === offer.status && (current_user.pubManager || current_user.isAdmin))
    );
  }, [current_user, offer.status]);

  const handleFinish = async (values) => {
    let answers;
    if (unitDecision === unitDecisions.requestOpinion || unitDecision === unitDecisions.revert || unitDecision === unitDecisions.reject) {
      answers = { ...values };
      refundObjectParser(answers);
    }
    if (values.redactionCommitee) {
      delete values.redactionCommitee;
    }

    const data = {
      status: unitDecision,
      ...(values.to ? { to: values.to } : {}),
      ...(values.description ? { description: values.description } : {}),
      ...(values.assignments ? { assignments: values.assignments } : {}),
      ...(answers ? { answers } : {}),
    };
    const { error } = await afterCopyrightGeneralControl({ data, committee, offerID: offer.id });
    if (error) {
      return dispatch(
        fetchError(
          formatMessage(
            {
              id: 'article.form.fetchError',
            },
            { error: error.status }
          )
        )
      );
    }
    openNotification({
      message: formatMessage({
        id: 'appModule.actionSuccessfully',
      }),
    });
    form.resetFields();
    setUnitDecision(null);
    setOpinion(false);
  };

  // sadece gorus girilmis heyetleri filtreler
  const unitDescriptions = useMemo(() => assigments?.filter((item) => item.description !== null), [assigments]);

  return (
    <>
      <Card>
        <StatusBar status={offer && offer.status} />
        <OrderDetailGrid offer={offer} />
        <OrderDetailDescriptions
          offer={offer}
          isEncyclopediaUnitControl
        />
      </Card>
      <MediaField
        offerID={offer && offer.id}
        isMediaEditable={false}
      />
      {unitDescriptions?.length > 0 && <AssigmentNotes descriptions={unitDescriptions} />}
      <ArticleOrderMedia
        offerID={offer && offer.id}
        modalProps={modalProps}
        handleModalProps={handleModalProps}
        isEncylopediaUnitControl
        isContentSend
      />
      {[article_order_status['Ansiklopedi Birimi Görüş İstedi'], article_order_status['Ansiklopedi Birimi Kontrol']].includes(offer.status) &&
        offer?.revertReasons?.eu_control?.length > 0 && (
          <Card>
            <h3>Ansiklopedi birimi aşağıdaki bilgiler doğrultusunda görüşünüzü talep etti.</h3>
            <Card>
              {offer?.revertReasons?.eu_control.map((item, index) => (
                <RevertAnswerItem
                  key={index}
                  title={item.revertReason.title}
                  answer={item.answer}
                />
              ))}
            </Card>
          </Card>
        )}
      {euUnitDecisonVisible && (
        <Card>
          <h3>Heyet Görüşü</h3>
          <span>{offer.ownerCommitteeOpinion}</span>
        </Card>
      )}
      {isShowdecisionOptions && (
        <Row gutter={24}>
          <Col
            xs={24}
            // parametre olarak butun alanlardan kaldirmamak adina bu sekilde bir kosul yapildi gorus isteme yapildiginda eski haline direk deger degistirilerek alinabilir
            md={encyclopediaUnit ? 12 : 12}
          >
            <Button
              onClick={() => {
                setUnitDecision(unitDecisions.accept);
              }}
              type={unitDecision === unitDecisions.accept ? 'primary' : 'default'}
              block
            >
              KABUL
            </Button>
          </Col>
          {/* {encyclopediaUnit && (
            <Col
              xs={24}
              md={encyclopediaUnit ? 8 : 12}
            >
              <Button
                onClick={() => {
                  setUnitDecision(unitDecisions.requestOpinion);
                }}
                type={unitDecision === unitDecisions.requestOpinion ? 'primary' : 'default'}
                block
              >
                GÖRÜŞ İSTE
              </Button>
            </Col>
          )} */}
          <Col
            xs={24}
            // parametre olarak butun alanlardan kaldirmamak adina bu sekilde bir kosul yapildi gorus isteme yapildiginda eski haline direk deger degistirilerek alinabilir
            md={encyclopediaUnit ? 12 : 12}
          >
            <Button
              type={unitDecision === unitDecisions.redaction ? 'primary' : 'default'}
              onClick={() => setUnitDecision(unitDecisions.redaction)}
              block
            >
              REDAKSİYON
            </Button>
          </Col>
        </Row>
      )}
      {article_order_status['Ansiklopedi Yonetim Kurulu Kontrol'] === offer.status && (
        <Row gutter={24}>
          <Col
            xs={24}
            md={6}
          >
            <Button
              type={unitDecision === unitDecisions.accept ? 'primary' : 'default'}
              onClick={() => {
                setUnitDecision(unitDecisions.accept);
              }}
              block
            >
              KABUL
            </Button>
          </Col>
          <Col
            xs={24}
            md={6}
          >
            <Button
              type={unitDecision === unitDecisions.revert ? 'primary' : 'default'}
              onClick={() => {
                setUnitDecision(unitDecisions.revert);
              }}
              block
            >
              İADE
            </Button>
          </Col>
          <Col
            xs={24}
            md={6}
          >
            <Button
              type={unitDecision === unitDecisions.requestOpinion ? 'primary' : 'default'}
              onClick={() => {
                setUnitDecision(unitDecisions.review);
              }}
              block
            >
              İNCELE
            </Button>
          </Col>
          <Col
            xs={24}
            md={6}
          >
            <Button
              type={unitDecision === unitDecisions.reject ? 'primary' : 'default'}
              onClick={() => {
                setUnitDecision(unitDecisions.reject);
              }}
              block
            >
              REDDET
            </Button>
          </Col>
        </Row>
      )}
      {(handleUserIsOnCommittee(offer && offer.ownerCommittee.id) || current_user.isAdmin) &&
        article_order_status['Ansiklopedi Birimi Görüş İstedi'] === offer.status && (
          <Button
            onClick={() => setOpinion(true)}
            block
          >
            Görüş Bildir
          </Button>
        )}
      {opinion && (
        <>
          <GiveOpinionForEncylopediaUnit
            offerID={offer && offer.id}
            handleOpinion={setOpinion}
          />
          <Button
            onClick={() => {
              setOpinion(false);
            }}
            block
            type="primary"
          >
            Vazgeç
          </Button>
        </>
      )}
      <div className="gx-mt-4 gx-mb-4">
        <Form
          onFinish={handleFinish}
          layout={unitDecisions.redaction ? 'horizontal' : 'vertical'}
          form={form}
        >
          {unitDecision === unitDecisions.accept && <AcceptDescision commitee={committee} />}
          {unitDecision === unitDecisions.redaction && (
            <>
              <RedactionDecision
                offerID={offer && offer.id}
                type={type}
              />
              <Form.Item
                rules={[
                  {
                    min: 50,
                    message: 'Lütfen en az 50 karakter giriniz',
                  },
                ]}
                className="gx-mt-2"
                name="description"
              >
                <TextArea
                  placeholder="Genel Notunuz varsa girebilirsiniz"
                  rows={4}
                  showCount
                />
              </Form.Item>
            </>
          )}
          {unitDecision === unitDecisions.revert && (
            <EncylopeiaManagementUnitRevert
              status={unitDecision}
              step={offer && offer.step}
              commitee={committee}
            />
          )}
          {unitDecision === unitDecisions.reject && (
            <EncylopediaManagementUnitReject
              status={unitDecision}
              step={offer && offer.step}
              commitee={committee}
            />
          )}
          {unitDecision === unitDecisions.review && <EncylopediaManagementUnitReview />}
          {unitDecision === unitDecisions.requestOpinion && (
            <EncyclopiaUnitRequestOpinion
              status={unitDecision}
              step={offer && offer.step}
            />
          )}
          {unitDecision && (
            <>
              <Button
                className="gx-mt-4 gx-mb-4"
                block
                htmlType="submit"
              >
                Gönder
              </Button>
              <Button
                onClick={() => {
                  setUnitDecision(null);
                  form.resetFields();
                }}
                block
                type="primary"
              >
                Vazgeç
              </Button>
            </>
          )}
        </Form>
      </div>
    </>
  );
};

export default AfterCopyrightContentControl;
