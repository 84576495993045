import AfterCopyrightProcessContent from 'module/AfterCopyright/AfterCopyrightProcessContent';

const AfterCopyright = ({ offer }) => {
  return (
    <AfterCopyrightProcessContent
      offer={offer}
      type={0}
      disabledStatus={27}
      committee="eu"
    />
  );
};

export default AfterCopyright;
