import useSWR from 'swr';

const useSources = ({ type, title, id, page = 1, pageSize = 50, sourceType = '' }) => {
  const params = new URLSearchParams({ searchKey: title, itemsPerPage: pageSize, page });
  const queries = {
    all: `/resources?${params.toString()}${sourceType}`,
    single: `/resources/${id}`,
  };
  const { data, error } = useSWR(queries[type]);
  return {
    url: queries.single,
    sources: data,
    isLoading: !error && !data,
    isError: error,
  };
};
export default useSources;
