import apiInstance from './api';

const contractApi = apiInstance.injectEndpoints({
  endpoints: (build) => ({
    getContract: build.query({
      query: (id) => `contracts/contract/${id}/content`,
      providesTags: ['Contract'],
    }),
  }),
  overrideExisting: true,
});

export default contractApi;
