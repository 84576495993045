import React from 'react';

import { Card } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { METADATA, STEP_DEF } from 'utils';

import TimeLineTag from './TimeLineTag';

const ignoredTags = ['requester', 'status'];

const TimeCardGrid = ({ title, type, idx, user, metadata = {} }) => {
  const { formatMessage } = useIntl();

  const { step, requester, description, decisionDescription, requesterCommittee, requestedCommittee, status } = metadata;

  const metaKeys = Object.keys(metadata);
  const showNum = step ? metaKeys.length - 1 > 1 : metaKeys.length > 1;

  function getGridTitle() {
    switch (title) {
      case 'committeeStatusCommittee':
        return (
          <span>
            {' '}
            <FormattedMessage id="article.detail.ownerCommittee" />
            <br /> ({metadata[title][1]}){' '}
          </span>
        );
      case 'reason':
        return (
          <FormattedMessage
            id="article.detail.timeLine.authorReason"
            values={{ author: metadata?.author?.filter((item) => item !== null).map((item) => item.user.fullName) }}
          />
        );
      case 'opinion':
        return (
          <FormattedMessage
            id="article.detail.timeLine.opinion"
            values={{ committee: metadata.committee }}
          />
        );
      case 'description':
      case 'decisionDescription':
        return <FormattedMessage id={requester || decisionDescription ? 'appModule.reason' : 'article.detail.timeLine.opinionDescription'} />;
      case 'requester':
        return (
          <FormattedMessage
            id={`article.detail.timeLine.${description ? 'pullbackRequest' : 'pullback'}`}
            values={{
              requesterCommittee,
              requestedCommittee,
              step: formatMessage({
                id: `article.process.${STEP_DEF[step]}`,
              }),
            }}
          />
        );
      case 'status': {
        const [, newStatus] = status;
        const decision = newStatus === 1 ? 'pullbackApproved' : 'pullbackRejected';

        return (
          <FormattedMessage
            id={`article.detail.timeLine.${decision}`}
            values={{
              requestedCommittee,
              step: formatMessage({
                id: `article.process.${STEP_DEF[step]}`,
              }),
            }}
          />
        );
      }
      case 'committee':
        return type !== 'entity_add' && <FormattedMessage id="article.detail.timeLine.removeContributorCommittee" />;
      default:
        return METADATA[title];
    }
  }

  if (user)
    return (
      <Card.Grid
        key={user.fullName + idx}
        className="gx-w-100 gx-px-3 gx-py-1"
        style={{ textAlign: idx % 2 ? 'right' : 'left' }}
      >
        <h6
          title={formatMessage({
            id: 'article.detail.timeLine.WhoMadeChangeTitle',
          })}
          className="gx-d-inline-block gx-mt-2"
        >
          <div className="gx-d-flex gx-align-items-center">
            <UserOutlined
              className="gx-fs-lg gx-mr-2"
              style={{ textAlign: idx % 2 ? 'right' : 'left' }}
            />
            <span style={{ color: '#ad6800' }}>{user.fullName}</span>
          </div>
        </h6>
      </Card.Grid>
    );

  return (
    <div
      key={title + idx}
      className="gx-d-flex gx-align-items-stretch"
      style={{
        backgroundColor: idx % 2 ? '#FBFBFB' : '#fff',
      }}
    >
      {showNum && (
        <Card.Grid
          style={{
            width: '10%',
            borderStyle: '0',
          }}
          hoverable={false}
        >
          {idx + 1}
        </Card.Grid>
      )}

      <Card.Grid
        style={{
          width: showNum ? '90%' : '100%',
          textAlign: 'left',
        }}
      >
        <div key={title + idx}>
          {metaKeys.length !== 0 && (
            <div className="gx-w-100">
              <span className="gx-d-block gx-font-weight-semi-bold">{getGridTitle()}</span>

              {!ignoredTags.includes(title) && (
                <div className="gx-d-flex gx-flex-column-reverse">
                  {metadata[title]?.map((item, idx) => (
                    <TimeLineTag
                      item={item}
                      key={idx}
                      idx={idx}
                      type={title}
                    />
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </Card.Grid>
    </div>
  );
};

export default TimeCardGrid;
