import { useEffect } from 'react';

export const useAlertLeavingPage = () => {
  useEffect(() => {
    window.onbeforeunload = () => true;

    return () => {
      window.onbeforeunload = null;
    };
  }, []);
};
