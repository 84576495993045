import { Collapse } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { fileDownload } from 'fetcher';
import { downloadBlob } from 'utils';
import { CircularProgress } from 'components/CircularProgress';
import { useGetContractQuery } from 'services';

const { Panel } = Collapse;

export const OrderAgreement = ({ offer }) => {
  const { data: content, isError } = useGetContractQuery(offer.id, {
    skip: !offer.id,
  });

  const download = async () => {
    const file = await fileDownload(`/contracts/contract/${offer?.id}`);
    downloadBlob(file.data, 'Taahhut_Sozlesmesi.pdf');
  };

  const extraIcons = () => {
    return (
      <DownloadOutlined
        style={{
          fontSize: '20px',
        }}
        className="gx-mr-2"
        onClick={download}
      />
    );
  };

  if (!isError && !content) return <CircularProgress />;
  return (
    <Collapse className="gx-mb-5 commitment-agreement ">
      <Panel
        header={
          <h2>
            <FormattedMessage id="article.order.agreementTitle" />
          </h2>
        }
        extra={extraIcons()}
      >
        <iframe
          className="gx-w-100 gx-border-0"
          srcDoc={content?.html}
          height={250}
        />
      </Panel>
    </Collapse>
  );
};
