import format from 'dayjs';

export const useInitialValueMaker = (search) => {
  const params = search.split('&');
  let pageCount = 1;
  let tmpTitle;
  let tmpOtherCommitteesURL = '';
  let tmpOwnerCommitteeURL = '';
  let tmpOwner = '';
  let tmpCommitteeContributionsURL = '';
  let tmpOwnerURL = '';
  let tmpDateRange = '';
  let tmpDateRangeURL = '';
  let pageSize = 20;

  let otherCommittees = [];
  let ownerCommittee = [];
  let committeeContributions = [];

  let dateRange = [];
  let tmpStep = null;

  let tmpStatusURL = '';
  let tmpStatus = [];
  let tmpCommitteeStatusURL = '';
  let tmpCommitteeStatus = [];
  let tmpDahStatusURL = '';
  let tmpDahStatus = [];
  let tmpDahHistory = false;
  let tmpDahHistoryURL = '';
  let tmpAykStatusURL = '';
  let tmpAykStatus = [];
  let tmpAykHistory = false;
  let tmpAykHistoryURL = '';
  let tmpStepURL = '';

  let tmpOtherCommittees = [];
  let tmpOwnerCommittee = [];
  let tmpCommitteeContributions = [];

  let oldArticles = [];
  let tmpOldTitle = '';
  let tmpOldTitleType = '';
  let tmpOldArticles = [];
  let tmpOldArticlesURL = '';

  let committeeContributionsType = [];
  let tmpCommitteeContributionsType = [];
  let tmpCommitteeContributionsTypeURL = '';

  let detectReason;

  let tmpArticleGroupURL = '';
  let tmpArticleTypeURL = '';
  let tmpDetectReasonURL = '';
  let tmpOldTitleURL = '';
  let tmpOldArticleWorkTypeURL = '';
  let tmpRewriteandUpdate = '';
  let tmpIsOfferURL = '';

  let tmpOldArticleWorkType = [];

  let tmpFirstEditionURL = '';
  let tmpFirstEdition = '';
  let tmpFirstEditionTermURL = '';
  let tmpFirstEditionTerm = '';

  params.forEach((param) => {
    if (param.includes('page=')) {
      pageCount = +param.replaceAll('page=', '');
    }
    if (param.includes('pageSize=')) {
      pageSize = +param.replaceAll('pageSize=', '');
    }
    if (param.includes('title=')) {
      tmpTitle = param.replaceAll('title=', '');
    }
    if (param.includes('ownerCommittee.id[]=')) {
      ownerCommittee = [...ownerCommittee, param];
      tmpOwnerCommittee = ownerCommittee.map((item) => '&' + item);
      tmpOwnerCommitteeURL = tmpOwnerCommittee.join('');
    }
    if (param.includes('committeeContributions.committee[]=')) {
      committeeContributions = [...committeeContributions, param];
      tmpCommitteeContributions = committeeContributions.map((item) => '&' + item);
      tmpCommitteeContributionsURL = tmpCommitteeContributions.join('');
    }
    if (param.includes('otherCommittees.id[]=')) {
      otherCommittees = [...otherCommittees, param];
      tmpOtherCommittees = otherCommittees.map((item) => '&' + item);
      tmpOtherCommitteesURL = tmpOtherCommittees.join('');
    }
    if (param.includes('owner=')) {
      tmpOwner = decodeURIComponent(param.replaceAll('owner=', ''));
      tmpOwnerURL = param.replaceAll('owner=', '&owner=');
    }
    if (param.includes('targetOffer.status=')) {
      tmpStatusURL = param.replaceAll('targetOffer.status=', '&targetOffer.status=');
    }
    if (param.includes('targetOffer.committeeStatus=')) {
      tmpCommitteeStatusURL = param.replaceAll('targetOffer.committeeStatus=', '&targetOffer.committeeStatus=');
    }
    if (param.includes('targetOffer.dahStatus=')) {
      tmpDahStatusURL = param.replaceAll('targetOffer.dahStatus=', '&targetOffer.dahStatus=');
    }
    if (param.includes('historyDahStatus=')) {
      tmpDahHistoryURL = param.replaceAll('historyDahStatus=', '&historyDahStatus=');
      tmpDahHistory = true;
    }
    if (param.includes('targetOffer.aykStatus=')) {
      tmpAykStatusURL = param.replaceAll('targetOffer.aykStatus=', '&targetOffer.aykStatus=');
    }
    if (param.includes('historyAykStatus=')) {
      tmpAykHistoryURL = param.replaceAll('historyAykStatus=', '&historyAykStatus=');
      tmpAykHistory = true;
    }
    if (param.includes('createdAt[after]=') || param.includes('createdAt[before]=')) {
      dateRange = [...dateRange, param];
      tmpDateRange = dateRange.map((item) => format(decodeURIComponent(item.replaceAll('createdAt[after]=', '').replaceAll('createdAt[before]=', ''))));
      tmpDateRangeURL = dateRange
        .map((item) => item.replaceAll('createdAt[after]=', '&createdAt[after]=').replaceAll('createdAt[before]=', '&createdAt[before]='))
        .join('');
    }
    if (param.includes('targetOffer.step=')) {
      tmpStepURL = param.replaceAll('targetOffer.step=', '&targetOffer.step=');
    }
    if (param.includes('detectReason=')) {
      detectReason = param.replaceAll('detectReason=', '');
      tmpDetectReasonURL = param.replaceAll('detectReason=', '&detectReason=');
    }
    if (param.includes('articleGroup=')) {
      tmpArticleGroupURL = param.replaceAll('articleGroup=', '&articleGroup=');
    }
    if (param.includes('articleType=')) {
      tmpArticleTypeURL = param.replaceAll('articleType=', '&articleType=');
    }
    if (param.includes('oldTitleType=')) {
      tmpOldTitleType = param.replaceAll('oldTitleType=', '&oldTitleType=');
    }
    if (param.includes('oldTitle[]=')) {
      tmpOldTitleURL = param.replaceAll('oldTitle[]=', '&oldTitle[]=');
    }
    if (param.includes('oldArticleWorkType[]=')) {
      tmpRewriteandUpdate = param.split('=')[1] === '0' ? 'Yeniden Yazım' : 'Güncelleme';
      tmpOldArticleWorkType = [...tmpOldArticleWorkType, param];
    }
    if (param.includes('oldArticles[]=')) {
      oldArticles = [...oldArticles, param];
      tmpOldArticles = oldArticles.map((item) => '&' + item);
      tmpOldArticlesURL = tmpOldArticles.join('');
    }
    if (param.includes('committeeContributions.type[]=')) {
      committeeContributionsType = [...committeeContributionsType, param];
      tmpCommitteeContributionsType = committeeContributionsType.map((item) => '&' + item);
      tmpCommitteeContributionsTypeURL = tmpCommitteeContributionsType.join('');
    }
    if (param.includes('isOffer=')) {
      tmpArticleTypeURL = param.replaceAll('isOffer=', '&isOffer=');
    }
    if (param.includes('exists[oldTitleType]')) {
      tmpFirstEditionURL = param.replaceAll('exists[oldTitleType]', '&exists[oldTitleType]');
      tmpFirstEdition = param.includes('true') ? 'Evet' : 'Hayır';
    }
    if (param.includes('jsonNullFilter[oldArticles]')) {
      tmpFirstEditionTermURL = param.replaceAll('jsonNullFilter[oldArticles]', '&jsonNullFilter[oldArticles]');
      tmpFirstEditionTerm = param.includes('true') ? 'Evet' : 'Hayır';
    }
  });

  if (tmpOldArticleWorkType.length > 0) {
    tmpOldArticleWorkTypeURL = tmpOldArticleWorkType.map((item) => item.replaceAll('oldArticleWorkType[]=', '&oldArticleWorkType[]=')).join('');
    tmpOldArticleWorkType = tmpOldArticleWorkType.map((item) => item.replaceAll('oldArticleWorkType[]=', ''));
  }

  const filterModalURL =
    tmpOtherCommitteesURL +
    tmpOwnerCommitteeURL +
    tmpCommitteeContributionsURL +
    tmpOwnerURL +
    tmpStepURL +
    tmpDateRangeURL +
    tmpDetectReasonURL +
    tmpArticleGroupURL +
    tmpArticleTypeURL +
    tmpOldTitleType +
    tmpOldTitleURL +
    tmpOldArticlesURL +
    tmpOldArticleWorkTypeURL +
    tmpCommitteeContributionsTypeURL +
    tmpIsOfferURL +
    tmpFirstEditionURL +
    tmpFirstEditionTermURL;

  const filterStatusURL = tmpCommitteeStatusURL + tmpStatusURL + tmpDahStatusURL + tmpAykStatusURL + tmpDahHistoryURL + tmpAykHistoryURL;

  const filterModal = JSON.parse(localStorage.getItem('filterModalValues'));
  localStorage.removeItem('filterModalValues');

  if (tmpOldTitleType) {
    tmpOldTitleType = tmpOldTitleType.split('=')[1];
    tmpFirstEdition = 'Evet';
  }

  if (tmpOldTitleURL) {
    tmpOldTitle = tmpOldTitleURL.split('=')[1];
  }

  if (tmpStepURL) {
    tmpStep = tmpStepURL.split('=')[1];
  }

  return {
    page: pageCount,
    pageSize,
    title: tmpTitle,
    filterModal: {
      ...filterModal,
      otherCommittees: tmpOtherCommittees,
      ownerCommittee: tmpOwnerCommittee,
      committeeContributions: tmpCommitteeContributions,
      owner: tmpOwner,
      step: tmpStep,
      dateRange: tmpDateRange,
      articleType: tmpArticleTypeURL,
      articleGroup: tmpArticleGroupURL,
      ihStatus: tmpCommitteeStatusURL,
      aykStatus: tmpAykStatusURL,
      dahStatus: tmpDahStatusURL,
      status: tmpStatusURL,
      detectReason: detectReason,
      oldArticles: tmpOldArticles,
      oldTitle: tmpOldTitle,
      atifTam: tmpOldTitleType,
      dahIncludeHistory: tmpDahHistory,
      aykIncludeHistory: tmpAykHistory,
      birinciEdisyondaYerAldi: tmpFirstEdition,
      yakinIfadeZikrediliyorMu: tmpFirstEditionTerm,
      rewriteandUpdate: tmpRewriteandUpdate,
      oldArticleWorkType: tmpOldArticleWorkType,
    },
    filterModalURL,
    filterStatusURL,

    filterStatusObjects: {
      status: tmpStatus,
      committeeStatus: tmpCommitteeStatus,
      dahStatus: tmpDahStatus,
      aykStatus: tmpAykStatus,
    },
  };
};
