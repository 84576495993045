import React, { useState } from 'react';

import { Button, Card, Col, Row, Form, Input, Select, Checkbox } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { userEdit, userRenewPassword } from 'fetcher';
import { useUsers } from 'useSWR';
import { openNotification, regexPass } from 'utils';
import { CircularProgress, Title } from 'components';
import { useUserInfo } from 'providers/UserInfoProvider';
import { fetchError } from 'slices/commonSlice';
import { useGetRolesQuery } from 'services';

const FormItem = Form.Item;

const EditUser = () => {
  const { id } = useParams();
  const { formatMessage } = useIntl();
  const { isLoading, isSuperAdmin } = useUserInfo();
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const { users: user, isLoading: usersLoading, mutate, userRoles } = useUsers({ id });
  const { data: roles } = useGetRolesQuery();

  const navigate = useNavigate();
  const [formLoading, setFormLoading] = useState(false);
  const [showPassCange, setShowPassCange] = useState(false);

  const onSubmit = async (values) => {
    setFormLoading(true);
    try {
      await userEdit(id, values);
      openNotification({
        message: formatMessage({ id: 'appModule.actionSuccessfully' }),
      });
      mutate();
      setTimeout(() => {
        setFormLoading(false);
        navigate('/uye-islemleri/uye-listesi');
      }, 1500);
    } catch (error) {
      dispatch(
        fetchError(
          formatMessage(
            {
              id: 'article.form.fetchError',
            },
            { error: `${error}` }
          )
        )
      );
    } finally {
      setFormLoading(false);
    }
  };

  const changePassword = async () => {
    const pass = {
      newPassword: form.getFieldValue('newPassword'),
    };
    setFormLoading(false);

    try {
      await userRenewPassword(id, pass);
      openNotification({
        message: formatMessage({ id: 'appModule.actionSuccessfully' }),
      });
      setTimeout(() => {
        setFormLoading(false);
        setShowPassCange(!showPassCange);
      }, 1500);
    } catch (error) {
      dispatch(
        fetchError(
          formatMessage(
            {
              id: 'article.form.fetchError',
            },
            { error: `${error}` }
          )
        )
      );
    } finally {
      setFormLoading(false);
    }
  };

  if (usersLoading || isLoading) return <CircularProgress />;

  return (
    <>
      <Title>{formatMessage({ id: 'userList.userEdit' })}</Title>
      <Row>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={16}
          xl={14}
          xxl={12}
        >
          {' '}
          <h1 className="gx-h1-lg">{formatMessage({ id: 'userList.userEdit' })}</h1>
          <Link to="/uye-islemleri/uye-listesi">
            <Button
              style={{ float: 'right' }}
              icon={<UserOutlined />}
            >
              <FormattedMessage id="userAdd.backUserList" />
            </Button>
          </Link>
        </Col>
      </Row>
      <Row>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={16}
          xl={14}
          xxl={12}
        >
          <Card>
            <Form
              layout={'vertical'}
              onFinish={onSubmit}
              initialValues={user}
              form={form}
            >
              <FormItem
                label={<FormattedMessage id="appModule.name" />}
                name="firstName"
                required
              >
                <Input />
              </FormItem>
              <FormItem
                label={<FormattedMessage id="appModule.surname" />}
                name="lastName"
                required
              >
                <Input />
              </FormItem>
              <FormItem
                label={<FormattedMessage id="appModule.email" />}
                name="email"
                required
                rules={[
                  {
                    type: 'email',
                    message: <FormattedMessage id="app.userAuth.EmailErrorCheck" />,
                  },
                ]}
              >
                <Input />
              </FormItem>
              <Form.Item
                name="isActive"
                valuePropName="checked"
                className="gx-my-3"
              >
                <Checkbox>
                  <FormattedMessage id="userEdit.userIsActive" />
                </Checkbox>
              </Form.Item>
              <Form.Item
                name="roles"
                label={<FormattedMessage id="userAdd.userRole" />}
              >
                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  mode="multiple"
                  placeholder="Kullanıcının rolünü seçiniz"
                  defaultValue={userRoles?.map((role) => role.roleType)}
                  style={{ width: '100%' }}
                  showSearch={false}
                  notFoundContent={
                    <div className="gx-text-center gx-h1-lg gx-my-2">
                      <FormattedMessage id="userAdd.userRoleNotFound" />{' '}
                    </div>
                  }
                >
                  {Array.isArray(roles) &&
                    roles.map((item) => (
                      <Select.Option
                        key={item.roleType}
                        disabled={(item.roleType === 'ROLE_SUPER_ADMIN' && user?.roles?.includes('ROLE_SUPER_ADMIN')) || item.isAssignable === false}
                        value={item.roleType}
                      >
                        {item.roleText}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              {((!showPassCange && !user?.roles?.includes('ROLE_SUPER_ADMIN')) || isSuperAdmin) && (
                <Button
                  className="gx-mt-2"
                  onClick={() => setShowPassCange(!showPassCange)}
                >
                  <FormattedMessage id="userEdit.userPassChange" />
                </Button>
              )}
              {showPassCange && (
                <div>
                  <hr />
                  <h4>
                    {' '}
                    <FormattedMessage id="userEdit.userPassActionTitle" />
                  </h4>

                  <FormItem
                    label={<FormattedMessage id="userEdit.userPassNew" />}
                    name="newPassword"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="app.userAuth.PassError" />,
                      },
                      {
                        pattern: regexPass,
                        message: <FormattedMessage id="app.userAuth.PassResetInfoMessageType" />,
                      },
                      {
                        min: 8,
                        message: <FormattedMessage id="app.userAuth.PassResetInfoMessageCharacter" />,
                      },
                    ]}
                  >
                    <Input.Password />
                  </FormItem>
                  <FormItem
                    label={<FormattedMessage id="userEdit.userPassAgain" />}
                    name="passwordCheck"
                    dependencies={['newPassword']}
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="app.userAuth.PassAgainError" />,
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('newPassword') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              formatMessage({
                                id: 'app.userAuth.PassNotMatch',
                              })
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </FormItem>

                  <div className="gx-mt-2 gx-d-flex">
                    <Button
                      loading={formLoading}
                      onClick={() => setShowPassCange(!showPassCange)}
                    >
                      <FormattedMessage id="appModule.cancel" />
                    </Button>
                    <FormItem shouldUpdate>
                      {({ getFieldError, isFieldTouched, resetFields }) => (
                        <FormItem
                          shouldUpdate
                          name="passChangeButton"
                        >
                          <Button
                            onClick={() => {
                              changePassword();
                              resetFields();
                            }}
                            type={showPassCange ? 'primary' : ''}
                            disabled={
                              getFieldError('passwordCheck').length !== 0 || !isFieldTouched('passwordCheck') || getFieldError('newPassword').length !== 0
                            }
                            loading={formLoading}
                          >
                            <FormattedMessage id="appModule.change" />
                          </Button>
                        </FormItem>
                      )}
                    </FormItem>
                  </div>
                  <hr />
                </div>
              )}
              <br />
              <Button
                type="primary"
                className="gx-mb-0 gx-mt-4"
                htmlType="submit"
                disabled={formLoading || showPassCange}
              >
                <FormattedMessage id="appModule.save" />
              </Button>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default EditUser;
