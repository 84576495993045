import { useRef, useState } from 'react';

import { Select, Spin } from 'antd';

const SearchBox = ({ fetcher, placeholder, onChange, value }) => {
  const debounce = useRef();
  const [loading, setLoading] = useState(false);
  const [currentOptions, setCurrenOptions] = useState([]);

  const handleSearch = async (searchValue) => {
    setLoading(false);
    if (debounce.current) {
      clearTimeout(debounce.current);
    }
    if (searchValue && searchValue.trim !== '') {
      setLoading(true);
      setCurrenOptions([]);
      debounce.current = setTimeout(async () => {
        const { data } = await fetcher({ fullName: searchValue });
        setCurrenOptions(data?.['hydra:member']);
        setLoading(false);
      }, 1500);
    }
  };
  return (
    <Select
      showSearch
      placeholder={placeholder}
      showArrow={currentOptions.length > 0}
      allowClear
      loading={loading}
      value={value}
      onChange={onChange}
      onSearch={handleSearch}
      filterOption={false}
      notFoundContent={loading ? <Spin size="small" /> : null}
      options={currentOptions?.map((item) => ({ label: item.user.fullName, value: item.id }))}
    />
  );
};

export default SearchBox;
