import AfterCopyrightContentControl from 'module/AfterCopyright/AfterCopyrightContentControl';

const EncyclopediaUnitControl = ({ offer }) => {
  return (
    <AfterCopyrightContentControl
      offer={offer}
      type={0}
      committee="eu_control"
      encyclopediaUnit
    />
  );
};

export default EncyclopediaUnitControl;
