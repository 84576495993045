import { useState } from 'react';

import { Button, Col, Form, Modal, Row } from 'antd';
import Refund from 'components/ArticleOffer/offer/ArticleStatus/Refund';
import { openNotification, refundObjectParser } from 'utils';
import { useDispatch } from 'react-redux';
import { fetchError } from 'slices/commonSlice';
import { useIntl } from 'react-intl';
import { useSendDecisionMutation } from 'services';

const ReorderProcessCancel = ({ offerID, committee }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();

  const [sendDecision] = useSendDecisionMutation();

  const dispatch = useDispatch();

  const onFinish = async (values) => {
    let answers;
    answers = { ...values };
    refundObjectParser(answers);

    const body = {
      status: 15,
      answers,
      ...(values.other && { description: values.other }),
    };

    const { error } = await sendDecision({ body, committee, id: offerID });
    if (error) {
      return dispatch(
        fetchError(
          formatMessage(
            {
              id: 'article.form.fetchError',
            },
            { error: error.status }
          )
        )
      );
    }
    openNotification({
      message: formatMessage({
        id: 'appModule.actionSuccessfully',
      }),
    });
  };

  return (
    <>
      <Button
        onClick={() => setModalVisible(true)}
        block
      >
        İPTAL ET
      </Button>
      <Modal
        open={modalVisible}
        width={800}
        title="İptal Gerekçesi Seçiniz"
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        <Form
          onFinish={onFinish}
          layout="vertical"
          form={form}
        >
          <Refund
            committee="ou"
            status={15}
            step={3}
          />
          <Row
            style={{
              flexDirection: 'row',
            }}
            gutter={24}
          >
            <Col
              sm={24}
              md={12}
            >
              <Button
                onClick={() => setModalVisible(false)}
                block
              >
                İptal Et
              </Button>
            </Col>
            <Col
              sm={24}
              md={12}
            >
              <Button
                block
                htmlType="submit"
              >
                Gönder
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ReorderProcessCancel;
