import { useState } from 'react';

import { Button, Col, Form, Modal, Row } from 'antd';
import { DatePicker } from 'components';
import { FormattedMessage, useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { useOrderUnitDecisionMutation } from 'services';
import { committeeDecisions, datePickerFormat, openNotification, refundObjectParser } from 'utils';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchError } from 'slices/commonSlice';
import Refund from 'components/ArticleOffer/offer/ArticleStatus/Refund';

const RefundToAuthor = ({ offerId, ihControlStatus, step }) => {
  const { formatMessage } = useIntl();
  const [sendToCommittee] = useOrderUnitDecisionMutation();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const onFinish = async (values) => {
    const formatDate = dayjs(values.finishDate).format('YYYY-MM-DD');
    delete values.finishDate;
    let answers = {
      ...values,
    };
    refundObjectParser(answers);
    const { error } = await sendToCommittee({
      id: offerId,
      body: {
        status: 3,
        finishDate: formatDate,
        description: values.other,
        answers,
      },
    });
    if (error) return dispatch(fetchError(formatMessage({ id: 'article.form.fetchError' }, { error: error.status })));

    openNotification({
      message: formatMessage({ id: 'appModule.actionSuccessfully' }),
    });

    navigate('/madde-teklifleri/tum-maddeler');
  };

  return (
    <>
      <Button
        block
        onClick={() => setOpen(true)}
        className="modalBtnStyle"
      >
        <FormattedMessage id="article.order.to.author.refund" />
      </Button>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        width={800}
        footer={null}
      >
        <h1
          style={{
            textAlign: 'center',
            textTransform: 'uppercase',
          }}
        >
          {formatMessage({ id: 'article.order.to.author.refund' })}
        </h1>
        <Form
          layout="vertical"
          form={form}
          id="articleOrderRefundToAuthorForm"
          labelCol={{ span: 24 }}
          onFinish={onFinish}
        >
          <Row gutter={[24, 6]}>
            <Col span={24}>
              <Form.Item
                label="Son teslim tarihi seçin"
                name="finishDate"
                rules={[
                  {
                    required: true,
                    message: 'Lütfen son teslim tarihini seçin',
                  },
                ]}
              >
                <DatePicker
                  className="gx-w-100"
                  disabledDate={(current) => current && current < dayjs().startOf('day')}
                  format={datePickerFormat}
                />
              </Form.Item>
            </Col>
            {ihControlStatus !== committeeDecisions.revert && (
              <Col span={24}>
                <Form.Item>
                  <Refund
                    committee="ou_control"
                    step={step}
                    status={3}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
        </Form>
        <Row
          gutter={24}
          align="middle"
          className="gx-mt-4"
        >
          <Col span={12}>
            <Button
              block
              type="primary"
              form="articleOrderRefundToAuthorForm"
              htmlType="submit"
            >
              {formatMessage({ id: 'appModule.send' })}
            </Button>
          </Col>
          <Col span={12}>
            <Button
              block
              onClick={() => setOpen(false)}
            >
              {formatMessage({ id: 'appModule.cancel' })}
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default RefundToAuthor;
