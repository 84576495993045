import useSWR from 'swr';

const useAuthors = ({ id, pagination = true, page = 1, pageSize = 20, search, roles, memberships, status }) => {
  const url = id ? `/authors/${id}` : `/authors`;

  const params = new URLSearchParams({
    pagination,
    page,
    itemsPerPage: pageSize,
    ...(search && { searchKey: search }),
    ...(status && { status }),
  });

  roles?.forEach((role) => params.append('user.roles[]', role));
  memberships?.forEach((membership) => params.append('user.memberships.committee.shortName[]', membership));

  const { data, error } = useSWR(`${url}?${params.toString()}`, {
    revalidateOnFocus: true,
  });

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
};

export default useAuthors;
