import useSWR from 'swr';

const usePullbackRequests = ({ id, step }) => {
  const { data, error } = useSWR(
    `pullback_requests?articleOffer=${id}&step=${step}`
  );

  return {
    pullbackRequests: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export default usePullbackRequests;
