import React from 'react';

import { Card } from 'antd';

export const Widget = ({
  title,
  children,
  styleName,
  cover,
  extra,
  actions,
}) => {
  return (
    <Card
      title={title}
      actions={actions}
      cover={cover}
      className={`gx-card-widget ${styleName}`}
      extra={extra}
    >
      {children}
    </Card>
  );
};
