import apiInstance from './api';

const committeeOpinionsApi = apiInstance.injectEndpoints({
  endpoints: (build) => ({
    getCommitteeOpinions: build.query({
      query: (id) => `/committee_opinions/${id}`,
      providesTags: ['CommitteeOpinions'],
    }),
  }),
  overrideExisting: true,
});

export default committeeOpinionsApi;
