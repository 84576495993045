import { useState } from 'react';

import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

const committeeDecisions = {
  approved: 2,
  rejected: 5,
  revert: 3,
  rewrite: 4,
  opinion: 1,
};

const DecisionLink = ({ onClick, children }) => (
  <Button
    className="modalBtnStyle"
    onClick={onClick}
  >
    {children}
  </Button>
);

const decisionItems = (offerId, commiteeID, authorID) => {
  const decisions = [
    {
      id: committeeDecisions.approved,
      label: <FormattedMessage id="article.table.decisionAccept" />,
      path: `/heyet-karar/${authorID}/${offerId}/${committeeDecisions.approved}`,
    },
    {
      id: committeeDecisions.opinion,
      label: 'Görüş iste',
      path: `/heyet-karar/${authorID}/${offerId}/${commiteeID}/${committeeDecisions.opinion}`,
    },
    {
      id: committeeDecisions.revert,
      label: <FormattedMessage id="article.table.decisionRevent" />,
      path: `/heyet-karar/${authorID}/${offerId}/${committeeDecisions.revert}`,
    },
    {
      id: committeeDecisions.rewrite,
      label: 'Yeniden Telif',
      path: `/heyet-karar/${authorID}/${offerId}/${committeeDecisions.rewrite}`,
    },
    {
      id: committeeDecisions.rejected,
      label: 'Ret',
      path: `/heyet-karar/${authorID}/${offerId}/${committeeDecisions.rejected}`,
    },
  ];
  return decisions;
};
/**
 * IH Heyet Kararları
 */
const CommiteeDecisionActions = ({ offerId, commiteeID, step }) => {
  const navigate = useNavigate();
  const decisions = decisionItems(offerId, commiteeID, step);

  const [showButton, setShowButton] = useState(false);

  return (
    <>
      {showButton && (
        <div className="modalBtnGrp">
          {decisions.map((decision) => (
            <DecisionLink
              key={decision.id}
              onClick={() => navigate(decision.path)}
            >
              {decision.label}
            </DecisionLink>
          ))}
        </div>
      )}
      <Button
        className={showButton ? 'gx-my-4' : ''}
        type={showButton ? 'primary' : 'default'}
        onClick={() => setShowButton(!showButton)}
        block
      >
        {showButton ? 'Vazgeç' : 'Karar Ver'}
      </Button>
    </>
  );
};

export default CommiteeDecisionActions;
