import axios from 'axios';

import { getCookies, setCookies, removeCookies } from './tokenHandler';

export const baseURL = `${process.env.REACT_APP_PUBLIC_BASE_URL}/api`;

const instance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

let isRefreshing = false;
let refreshSubscribers = [];

const onRefreshed = (token) => {
  refreshSubscribers.forEach((cb) => cb(token));
};

const addRefreshSubscriber = (callback) => {
  refreshSubscribers.push(callback);
};

instance.interceptors.request.use(
  (config) => {
    const { token, xSwitchUser } = getCookies();

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    if (xSwitchUser) {
      config.headers['x-switch-user'] = xSwitchUser;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (res) => res,
  async (err) => {
    const originalConfig = err.config;

    if (err.response && window.location.pathname !== '/giris') {
      if (err.response.status === 401 && !originalConfig._retry && !originalConfig.url.includes('/login')) {
        originalConfig._retry = true;

        if (isRefreshing) {
          return new Promise((resolve) => {
            addRefreshSubscriber((token) => {
              originalConfig.headers['Authorization'] = `Bearer ${token}`;
              resolve(instance(originalConfig));
            });
          });
        }
                isRefreshing = true;

        try {
          const { refreshToken } = getCookies();

          const rs = await axios.post(`${baseURL}/refresh_token`, {
            refreshToken,
          });

          const { token, refreshToken: newRs } = await rs.data;

          setCookies(token, newRs);
          instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          originalConfig.headers['Authorization'] = `Bearer ${token}`;
          isRefreshing = false;
          onRefreshed(token);
          return instance(originalConfig);
        } catch (_error) {
          isRefreshing = false;
          refreshSubscribers = [];
          if (err.response.status === 401 && originalConfig.url.includes('/current_user') && originalConfig._retry) {
            removeCookies();
            instance.defaults.headers.common['Authorization'] = '';
            window.location.href = '/giris';
          }

          if (_error.response && _error.response.data) {
            return Promise.reject(_error.response.data);
          }

          return Promise.reject(_error);
        }
      } else if (err.response.data) {
        const description = err.response.data['hydra:description'];
        return Promise.reject(description || err.response.status);
      }
    }

    return Promise.reject(err);
  }
);

export default instance;
