import React, { useEffect } from 'react';

import { Card, Tabs, Form } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomUpload, Input } from 'components';

import DetectionInfo from './DetectionInfo';
import DetectionDetail from './DetectionDetail';
import DetectionResponsible from './DetectionResponsible';
import AddAuthor from './AddAuthor';
import ArticleFormAction from './ArticleFormAction';

const { TextArea } = Input;

const ArticleDetection = ({ fileList, activeTab, handleActiveTab, setFileList, buttonLoading, isDetailPage = false, isMediaPreview = false }) => {
  const { formatMessage } = useIntl();

  const activeTabHandler = (key) => {
    handleActiveTab(key);
  };

  useEffect(() => {
    if (activeTab === '2') {
      document.getElementById('topOfScreen').scrollIntoView(true);
    }
  }, [activeTab]);

  return (
    <div id="topOfScreen">
      <Tabs
        defaultActiveKey="1"
        type="card"
        activeKey={activeTab}
        onChange={(key) => activeTabHandler(key)}
        items={[
          {
            key: '1',
            label: <FormattedMessage id="article.form.detectionFormStep" />,
            children: (
              <>
                {isDetailPage && (
                  <Card
                    style={{ marginBottom: 12, border: 0 }}
                    hoverable
                  >
                    <Form.Item
                      name="formNumber"
                      label={<FormattedMessage id="article.detail.FormNumber" />}
                    >
                      <Input />
                    </Form.Item>
                  </Card>
                )}
                <DetectionInfo isDetailPage={isDetailPage} />
                <DetectionDetail isDisabled={isDetailPage} />
                <DetectionResponsible isDetailPage={isDetailPage} />
              </>
            ),
            forceRender: true,
          },
          {
            key: '2',
            label: <FormattedMessage id="article.process.content.authorStep" />,
            children: (
              <>
                <AddAuthor />
                <Card className="gx-mb-3">
                  <Form.Item
                    label={formatMessage({
                      id: 'article.process.author.contentDescription',
                    })}
                    name="contentDescription"
                    rules={[
                      {
                        min: 100,
                        message: formatMessage({ id: 'article.reasonLengthMessage' }),
                      },
                    ]}
                  >
                    <TextArea
                      rows={5}
                      showCount
                    />
                  </Form.Item>
                </Card>
                <Card
                  style={{ marginBottom: 12, border: 0, paddingBottom: 25 }}
                  hoverable
                >
                  <p>
                    <FormattedMessage id="article.detail.media" />
                  </p>
                  <CustomUpload
                    isMediaPreview={isMediaPreview}
                    disabled={isDetailPage}
                    fileList={fileList}
                    setFileList={setFileList}
                  />
                </Card>
              </>
            ),
            forceRender: true,
          },
        ]}
      />
      <ArticleFormAction
        activeTab={activeTab}
        handleActiveTab={activeTabHandler}
        buttonLoading={buttonLoading}
      />
    </div>
  );
};

export default ArticleDetection;
