import UserForm from 'module/Form/UserForm';

import React, { useState } from 'react';

import { Button, Row, Form, Col, Card } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import { useIntl, FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { adminUserAdd } from 'fetcher';
import { openNotification } from 'utils';
import { Title } from 'components';
import { fetchError } from 'slices/commonSlice';

const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const { formatMessage } = useIntl();

  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      await adminUserAdd(values);
      openNotification({
        message: formatMessage({ id: 'appModule.actionSuccessfully' }),
      });
      setTimeout(() => {
        navigate('/uye-islemleri/uye-listesi');
      }, 1500);
    } catch (error) {
      dispatch(
        fetchError(
          formatMessage(
            {
              id: 'article.form.fetchError',
            },
            { error: `${error}` }
          )
        )
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Title>{formatMessage({ id: 'userAdd.Title' })}</Title>
      <Row justify="center">
        <Col
          sm={24}
          md={24}
        >
          <h1 className="gx-h1-lg">
            {' '}
            <FormattedMessage id="userAdd.Title" />
          </h1>
          <Link to="/uye-islemleri/uye-listesi">
            <Button
              style={{ float: 'right' }}
              icon={<UserOutlined />}
            >
              <FormattedMessage id="userAdd.backUserList" />
            </Button>
          </Link>
        </Col>
      </Row>
      <Row>
        <Col
          sm={24}
          md={24}
          lg={12}
        >
          <Card>
            <Form
              form={form}
              initialValues={{ remember: false }}
              name="basic"
              onFinish={onFinish}
              layout="vertical"
            >
              <UserForm />
              <Form.Item>
                <Button
                  type="primary"
                  block
                  className="gx-mb-0 gx-mt-4"
                  htmlType="submit"
                  loading={loading}
                >
                  <FormattedMessage id="userAdd.userAdd" />
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default SignUp;
