import { useEffect } from 'react';

import { Layout, notification } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';
import dayjs from 'dayjs';
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  NAV_STYLE_MINI_SIDEBAR,
} from 'constant/themeSettings';
import App from 'routes/index';
import Sidebar from 'containers/Sidebar';
import Topbar from 'containers/Topbar';
import { Loading, InfoView } from 'components';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCookies } from 'fetcher';
import { useGetCurrentUserQuery, useGetVersionQuery } from 'services';

const { Content, Footer } = Layout;

export const AppContent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { token } = getCookies();

  // const { isLoading, isAdmin, isE2User } = useGetUserInfo();
  const { data: current_user, isFetching } = useGetCurrentUserQuery();

  const { data: updatedAt, isLoading: updatedAtLoading } = useGetVersionQuery();

  const navStyle = useSelector(({ settings }) => settings.navStyle);

  const { data } = useSWR('system/message', {
    revalidateOnFocus: false,
  });

  useEffect(() => {
    if (data?.enabled) {
      notification.warning({
        description: data?.message,
        duration: 30000,
        closeIcon: <span>x</span>,
      });
    }
  }, [data]);

  useEffect(() => {
    if (current_user) {
      if (!token || (!current_user.isE2User && !current_user.isAdmin)) {
        const { pathname } = location;

        sessionStorage.setItem('returnURL', pathname);
        navigate('/giris');
      } else {
        const returnURL = sessionStorage.getItem('returnURL');
        if (returnURL) {
          sessionStorage.removeItem('returnURL');
          navigate(returnURL);
        }
      }
    }
  }, [isFetching, token, location, navigate, current_user]);

  const getContainerClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DARK_HORIZONTAL:
      case NAV_STYLE_DEFAULT_HORIZONTAL:
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
      case NAV_STYLE_BELOW_HEADER:
      case NAV_STYLE_ABOVE_HEADER:
        return 'gx-container-wrap';
      default:
        return '';
    }
  };

  const getNavStyles = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_FIXED:
      case NAV_STYLE_DRAWER:
      case NAV_STYLE_MINI_SIDEBAR:
        return <Topbar />;

      default:
        return null;
    }
  };

  if (isFetching) return <Loading />;

  return (
    <>
      {window.location.origin === process?.env.REACT_APP_TEST_URL && <div className="testEnvironmentHeader">Test ortamında çalışıyorsunuz.</div>}

      <Layout className="gx-app-layout">
        <Sidebar />

        <Layout>
          {getNavStyles(navStyle)}

          <Content className={`gx-layout-content main-app-content ${getContainerClass(navStyle)} `}>
            <App />
            <InfoView />

            <Footer className="gx-layout-footer-content gx-d-sm-flex gx-justify-content-between">
              <div className="gx-mb-2 gx-mb-sm-0">
                <span className="gx-d-none gx-d-sm-inline-block">
                  <FormattedMessage id="allRightsReserved" />
                  &nbsp;
                </span>
                <FormattedMessage
                  id="app.footer"
                  values={{ year: new Date().getFullYear() }}
                />
              </div>

              {current_user?.isAdmin && !updatedAtLoading && (
                <div>
                  <FormattedMessage
                    id="app.systemUpdatedAt"
                    values={{
                      time: dayjs(updatedAt * 1000).format('DD/MM/YYYY HH:mm'),
                    }}
                  />
                </div>
              )}
            </Footer>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};
