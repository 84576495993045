import apiInstance from './api';

const hashApi = apiInstance.injectEndpoints({
  endpoints: (build) => ({
    getHash: build.query({
      query: () => '/hash',
      providesTags: ['Hash'],
    }),
  }),
});

export default hashApi;
