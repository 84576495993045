import { useState } from 'react';

import { Select } from 'antd';
import useSWR from 'swr';
import { CircularProgress, EditorComponent, Title } from 'components';

const { Option } = Select;

const EditStaticPage = () => {
  const [page, setPage] = useState();
  const { data, isValidating } = useSWR(page ? `/static_pages/${page}` : null,{
    revalidateOnFocus:false
  });

  function getUrl(suffix) {
    if (process.env.REACT_APP_SENTRY_ENV === 'test') {
      return `https://test.ekaynaklar.islamansiklopedisi.org.tr/${suffix}`;
    }
    return `https://ekaynaklar.islamansiklopedisi.org.tr/${suffix}`;
  }

  return (
    <>
      <Title>Sabit Sayfa Editörü</Title>
      <h1 className="gx-h1-lg">Sabit Sayfa Editörü</h1>

      <div>
        <Select
          placeholder="Düzenlemek istediğiniz sabit sayfayı seçiniz"
          className="gx-mb-3 ant-select-xs-max"
          onChange={(e) => setPage(e)}
        >
          <Option value="instructions">Yönergeler</Option>
          <Option value="about">Hakkında</Option>
        </Select>

        {data && (
          <>
            <br />
            <a href={getUrl(data?.url)} target="_blank" rel="noreferrer">
              Düzenlenmek istenen sayfaya gitmek için tıklayın. (
              {getUrl(data?.url)})
            </a>
            <hr />
          </>
        )}
      </div>

      {isValidating && <CircularProgress />}

      {data && (
        <EditorComponent
          content={data?.content}
          name={data?.name}
          title={data?.title}
          isLoading={isValidating}
        />
      )}
    </>
  );
};

export default EditStaticPage;
