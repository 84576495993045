import React from 'react';

import { Tag } from 'antd';
import { useIntl } from 'react-intl';
import { articleLength, opinionTypes } from 'utils';

const titleType = [
  'dahDescription',
  'aykDescription',
  'statusDescription',
  'offerReason',
  'offerTitle',
  'offerGroup',
  'offerType',
  'offerSecondEditionSuitability',
  'offerCommitteePriority',
  'offerCommitteeDetectAndChange',
  'offerCommitteeContributionChange',
  'offerReasonSufficiency',
  'description',
  'decisionDescription',
];

const revertTitles = [
  'offerContentWrongAuthorInfo',
  'offerContentAuthorInfoNotEnough',
  'offerContentArticleLengthReasonNotEnough',
  'offerContentAdditionalInfoNotEnough',
  'offerContentMediaNotEnough',
];

const TimeLineTag = ({ item, idx: dis, type }) => {
  const { formatMessage } = useIntl();

  const dataType = {
    0: {
      color: '#ff4d4f',
      title: formatMessage({
        id: 'article.detail.timeLine.tagOld',
      }),
    },
    1: {
      color: '#87d068',
      title: formatMessage({
        id: 'article.detail.timeLine.tagNew',
      }),
    },
  };

  if (item === null || item === '' || !item || item === 0) {
    return <></>;
  }

  let newItem = [];

  if (typeof item === 'string') {
    newItem = item.split();
  }

  if (Array.isArray(item)) {
    newItem = item.map((title) => {
      if (!title) return null;

      if (title.title) {
        return title.title;
      } else if (title.name) {
        return title.name;
      }

      return title;
    });
  }

  if (!Array.isArray(item) && typeof item === 'object') {
    newItem = item.title ? item.title?.split() : item.name?.split();
    if (item.firstName) newItem = [`${item?.firstName} ${item?.lastName}`];
  }

  if (Number.isInteger(item)) {
    newItem = [articleLength[item]];
  }

  if (revertTitles.includes(type)) {
    newItem = [];
  }

  if (type === 'author') {
    newItem = [item?.user?.fullName];
  }

  if (type === 'media') {
    newItem = [item?.originalFilename];
  }

  if (type === 'opinion') {
    newItem = [opinionTypes[item]];
  }

  if (!newItem) return <div />;

  return newItem?.map((arrayItem, idx) => (
    <div key={idx}>
      <Tag
        key={dataType[dis].text + idx}
        style={{
          margin: '4px',
          whiteSpace: 'inherit',
          borderRadius: 0,
          fontSize: '13px',
          padding: '2px 5px',
          border: '2px',
          borderStyle: dis === 0 ? 'dashed' : 'solid',
          borderColor: titleType.includes(type) ? '' : dataType[dis].color,
        }}
        title={dataType[dis].title}
      >
        {titleType.includes(type) ? (
          <q>{dis === 0 ? <del>{arrayItem}</del> : arrayItem}</q>
        ) : dis === 0 ? (
          <del>{arrayItem}</del>
        ) : (
          arrayItem
        )}
      </Tag>
      <br />
    </div>
  ));
};

export default TimeLineTag;
