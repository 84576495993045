import apiInstance from './api';

const activityApi = apiInstance.injectEndpoints({
  endpoints: (builder) => ({
    getActivities: builder.query({
      query: ({ page, pageSize }) => ({
        url: '/activities',
        method: 'GET',
        params: { page, itemsPerPage: pageSize },
      }),
      providesTags: ['Activities'],
    }),
    getActivitiesList: builder.query({
      query: (params) => ({
        url: '/activities/lists/committee_control',
        method: 'GET',
        params,
      }),
      providesTags: ['ActivitiesList'],
    }),
  }),
  overrideExisting: true,
});

export default activityApi;
