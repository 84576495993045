import apiInstance from './api';

const authorSendContentApi = apiInstance.injectEndpoints({
  endpoints: (build) => ({
    getArticleContent: build.query({
      query: ({ offerID }) => `/article_offer_author_contents/by/${offerID}`,
      providesTags: ['ArticleContentSend'],
    }),
    sendArticleContent: build.mutation({
      query: ({ offerID, body }) => ({
        url: `article_offer_author_content_media/${offerID}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ArticleOffers', 'ArticleOfferDetail', 'ArticleContentSend'],
    }),
    updateArticleContent: build.mutation({
      query: ({ mediaID, body }) => ({
        url: `article_offer_author_content_media/${mediaID}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['ArticleOffers', 'ArticleOfferDetail', 'ArticleContentSend'],
    }),
    deleteArticleContentMedia: build.mutation({
      query: ({ offerID, mediaID }) => ({
        url: `article_offer_author_content_media/${offerID}/${mediaID}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ArticleOffers', 'ArticleOfferDetail', 'ArticleContentSend'],
    }),
    deleteFormContentMedia: build.mutation({
      query: (id) => ({
        url: `media/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ArticleOffers', 'ArticleOfferDetail', 'ArticleContentSend'],
    }),
  }),
  overrideExisting: true,
});

export default authorSendContentApi;
