export const isamAddress = 'Altunizade, İcadiye Bağlarbaşı Cd. No:38, 34672 Üsküdar/İstanbul';

export const orderDepartmentInfo = {
  supervisor: 'İsmail Atmaca',
  email: 'ismail.atmaca@isam.org.tr',
  phone: '(0216) 474 08 50',
  internal: '1053',
  shippingPhone: '0850 255 00 66',
  shippingCode: '1F743A',
};
