import apiInstance from './api';

const orderMeetApi = apiInstance.injectEndpoints({
  endpoints: (build) => ({
    getArticleOrderMeet: build.query({
      query: (id) => `/order_meets/offer/${id}`,
      providesTags: ['ArticleOrderMeet'],
      transformResponse: (response) => response['hydra:member'],
    }),
    sendArticleOrderMeet: build.mutation({
      query: (data) => ({
        url: '/order_meets',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['ArticleOffers', 'ArticleOfferDetail', 'AuthorArticles', 'ArticleOrderMeet'],
    }),
  }),
  overrideExisting: true,
});
export default orderMeetApi;
