import useSWR from 'swr';

const TYPES = {
  all: '/committees',
  categoryOne: '/committees?category.id=1',
  categoryTwo: '/committees?category.id=2',
};

const useCommittees = (type = '', fetchData = true) => {
  const query =
    TYPES[type] !== undefined ? TYPES[type] : `${TYPES['all']}/${type}`;
  const { data, error } = useSWR(() => (fetchData ? query : null), {
    revalidateOnFocus: false,
  });
  let head, secretary;
  if (data) {
    if (data.members) {
      const { members } = data;
      head = members.filter((item) => {
        return item.type === 0;
      })[0];
      secretary = members.filter((item) => {
        return item.type === 1;
      })[0];
    }
  }

  return {
    url: query,
    committees: data,
    head: head,
    secretary: secretary,
    isLoading: !error && !data,
    isError: error,
    condition: fetchData,
  };
};

export default useCommittees;
