import apiInstance from './api';

const authorWorksApi = apiInstance.injectEndpoints({
  endpoints: (build) => ({
    getAuthorWorks: build.query({
      query: (params) => ({
        url: '/works',
        method: 'GET',
        params,
      }),
      transformResponse: (response) => response['hydra:member'],
      providesTags: ['AuthorWorks'],
    }),
  }),
  overrideExisting: true,
});

export default authorWorksApi;
