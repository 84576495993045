import { Card, Col, Row, Spin } from 'antd';
import {
  CodeOutlined,
  DashboardOutlined,
  FileWordOutlined,
  HourglassOutlined,
  PartitionOutlined,
  ReadOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import { useGetStiplatedTimeQuery } from 'services';

export const OrderDetailGrid = ({ offer }) => {
  const { data: times, isLoading } = useGetStiplatedTimeQuery();

  const stipulatedTime = times?.find((time) => time?.lengthKey === offer?.articleLength);

  const cells = [
    {
      icon: <CodeOutlined />,
      label: <FormattedMessage id="article.detail.articleCode" />,
      content: offer?.articleCodeFormatted,
    },
    {
      icon: <TeamOutlined />,
      label: <FormattedMessage id="committee" />,
      content: offer?.ownerCommittee?.name,
    },
    {
      icon: <PartitionOutlined />,
      label: <FormattedMessage id="article.detail.articleGroup" />,
      content: offer?.articleGroup?.name,
    },
    {
      icon: <ReadOutlined />,
      label: <FormattedMessage id="article.detail.articleType" />,
      content: offer?.articleType?.name,
    },
    {
      icon: <FileWordOutlined />,
      label: <FormattedMessage id="article.detail.articleLength" />,
      content: offer && offer.articleLengthCustom ? offer.articleLengthCustom : stipulatedTime?.lengthDisplay,
      loading: isLoading,
    },
    {
      icon: <UserOutlined />,
      label: <FormattedMessage id="author.name" />,
      content: offer?.authors?.map(({ author }, index) => (
        <div key={index}>
          {author?.user?.fullName}
          {index + 1 < offer.authors.length && ','}
        </div>
      )),
    },
    {
      icon: <DashboardOutlined />,
      label: <FormattedMessage id="article.order.orderDate" />,
      content: dayjs(offer?.ouDate?.orderDate).format('DD/MM/YYYY'),
    },
    {
      icon: <HourglassOutlined />,
      label: <FormattedMessage id="article.order.deliveryDate" />,
      content: dayjs(offer?.ouDate?.deliveryDate).format('DD/MM/YYYY'),
      loading: isLoading,
    },
  ];

  return (
    <Row
      className="gx-mb-4"
      justify="center"
      align="center"
      gutter={1}
    >
      {cells.map((cell, i) => (
        <Col
          key={i}
          xs={24}
          xl={6}
          xxl={3}
        >
          <Card.Grid
            className="gx-d-flex gx-flex-column gx-justify-content-center gx-align-items-center gx-w-100 gx-h-100 gx-py-5"
            hoverable={false}
          >
            {!offer || cell.loading ? (
              <Spin
                size="large"
                className="gx-m-0"
              />
            ) : (
              <>
                <span className="gx-fs-xxxl">{cell.icon}</span>
                <p className="gx-fs-lg gx-mt-3 gx-mb-2 gx-text-secondary">{cell.label}</p>
                <span>{cell.content}</span>
              </>
            )}
          </Card.Grid>
        </Col>
      ))}
    </Row>
  );
};
