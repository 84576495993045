// Customizer const
export const THEME_TYPE_LITE = 'THEME_TYPE_LITE';
export const THEME_TYPE_SEMI_DARK = 'THEME_TYPE_SEMI_DARK';

export const LAYOUT_TYPE_FRAMED = 'LAYOUT_TYPE_FRAMED';
export const LAYOUT_TYPE_BOXED = 'LAYOUT_TYPE_BOXED';
export const LAYOUT_TYPE_FULL = 'LAYOUT_TYPE_FULL';

export const NAV_STYLE_FIXED = 'NAV_STYLE_FIXED';
export const NAV_STYLE_MINI_SIDEBAR = 'NAV_STYLE_MINI_SIDEBAR';
export const NAV_STYLE_DRAWER = 'NAV_STYLE_DRAWER';
export const NAV_STYLE_NO_HEADER_MINI_SIDEBAR = 'NAV_STYLE_NO_HEADER_MINI_SIDEBAR';
export const NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR = 'NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR';

export const NAV_STYLE_DEFAULT_HORIZONTAL = 'NAV_STYLE_DEFAULT_HORIZONTAL';
export const NAV_STYLE_DARK_HORIZONTAL = 'NAV_STYLE_DARK_HORIZONTAL';
export const NAV_STYLE_INSIDE_HEADER_HORIZONTAL = 'NAV_STYLE_INSIDE_HEADER_HORIZONTAL';
export const NAV_STYLE_BELOW_HEADER = 'NAV_STYLE_BELOW_HEADER';
export const NAV_STYLE_ABOVE_HEADER = 'NAV_STYLE_ABOVE_HEADER';

export const TAB_SIZE = 992;
