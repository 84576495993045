import { useState } from 'react';

import { Button, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { exportTable } from 'utils';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { useWindowSize } from 'hooks';
import { CircularProgress, Title } from 'components';
import { useGetCommitteesByCategoryQuery, useGetCurrentUserQuery } from 'services';
import AddCommiteeModal from 'components/Modal/AddCommitee';

const columns = [
  {
    title: <FormattedMessage id="committees.committeeId" />,
    dataIndex: 'id',
    width: 150,
    sorter: (a, b) => a.id - b.id,
  },
  {
    title: <FormattedMessage id="committees.committeeCategory" />,
    dataIndex: ['category', 'name'],
    width: 200,
    sorter: (a, b) => a.category.name.localeCompare(b.category.name),
  },
  {
    title: <FormattedMessage id="committees.committeeName" />,
    dataIndex: 'name',
    width: 300,
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: <FormattedMessage id="committees.committeeShortName" />,
    dataIndex: 'shortName',
    width: 120,
    sorter: (a, b) => a.shortName.localeCompare(b.shortName),
  },
  {
    title: <FormattedMessage id="committees.committeeHead" />,
    dataIndex: 'head',
    width: 300,
    sorter: (a, b) => a?.head?.fullName.localeCompare(b?.head.fullName),
    render: (head) => <span>{head?.fullName} </span>,
  },
  {
    title: <FormattedMessage id="committees.committeeSecretary" />,
    dataIndex: 'secretary',
    width: 300,
    sorter: (a, b) => a?.secretary?.fullName.localeCompare(b?.secretary.fullName),
    render: (secretary) => <span>{secretary?.fullName} </span>,
  },
  {
    title: <FormattedMessage id="appModule.email" />,
    dataIndex: 'email',
    width: 300,
    sorter: (a, b) => a?.email?.localeCompare(b?.email),
  },
];

const Committees = () => {
  const { formatMessage } = useIntl();
  const [tableProps, setTableProps] = useState({
    page: 1,
    itemsPerPage: 20,
  });

  const { data: committees, isLoading } = useGetCommitteesByCategoryQuery({
    head: false,
    page: tableProps.page,
    itemsPerPage: tableProps.itemsPerPage,
  });
  const { data: current_user } = useGetCurrentUserQuery();

  let navigate = useNavigate();
  const { height } = useWindowSize();
  const pagination = {
    hideOnSinglePage: true,
    current: tableProps.page || 1,
    pageSize: tableProps.itemsPerPage || 20,
    onChange: (page, itemsPerPage) => {
      setTableProps({ ...tableProps, page, itemsPerPage });
    },
    total: committees?.['hydra:totalItems'],
    showTotal: (total) => `${formatMessage({ id: 'table.totalItem' }, { type: 'Heyet' })} ${total}`,
    showSizeChanger: true,
  };

  const [wait, setWait] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const handleExportTable = async () => {
    setWait(true);
    await exportTable('/committees/export?', 'İlim Heyetleri');

    setWait(false);
  };

  if (isLoading) return <CircularProgress />;

  return (
    <>
      <div className="gx-mb-2">
        <Title>{formatMessage({ id: 'committees.Title' })}</Title>
        <div className="offers-table-columns gx-d-flex gx-justify-content-end">
          {current_user.isAdmin && (
            <Button
              type="primary"
              onClick={() => setModalOpen(true)}
            >
              Yeni Heyet Ekle
            </Button>
          )}
          <Button
            loading={wait}
            disabled={!(committees?.length > 0)}
            onClick={handleExportTable}
            icon={<CloudDownloadOutlined />}
          >
            <FormattedMessage id="app.export" />
          </Button>
        </div>
        <h1 className="gx-h1-lg">
          <FormattedMessage id="committees.Title" />
        </h1>
        <Table
          bordered
          columns={columns}
          dataSource={committees?.['hydra:member']}
          pagination={pagination}
          rowKey={(record) => record.id}
          onRow={(record) => {
            return {
              onClick: () => navigate(`/heyetler/heyet-detay/${record.id}`),
            };
          }}
          rowClassName={() => 'clickable'}
          scroll={{ x: 990, y: height - 300 }}
        />
      </div>

      <AddCommiteeModal
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
      />
    </>
  );
};

export default Committees;
