import React from 'react';

const RevertAnswerItem = ({ title, answer }) => {
  return (
    <li className="gx-mb-1">
      <div className="gx-font-weight-semi-bold gx-mb-1">{title}</div>
      {answer && <q>{answer}</q>}
    </li>
  );
};

export default RevertAnswerItem;
