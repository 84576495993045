import React from 'react';

import StatusBar from 'components/StatusBar';

export const TableAlert = ({ karar }) => (
  <StatusBar
    status={karar}
    className="table-alert"
  />
);
