import apiInstance from './api';

const projectApi = apiInstance.injectEndpoints({
  endpoints: (build) => ({
    getAllProject: build.query({
      query: () => '/projects',
      transformResponse: (response) => response['hydra:member'],
      providesTags: ['Projects'],
    }),
  }),
  overrideExisting: true,
});
export default projectApi;
