import React from 'react';

import { Alert, Button, Card, Col, Radio, Row } from 'antd';
import { Form, Input } from 'components';
import { FormattedMessage, useIntl } from 'react-intl';
import { PlusOutlined } from '@ant-design/icons';
import { RemoveButton } from 'components';
import { AuthorSelect } from 'components/Select';

const { TextArea } = Input;

const AddAuthor = () => {
  const { formatMessage } = useIntl();
  const form = Form.useFormInstance();

  const authors = Form.useWatch('authors', form);

  return (
    <Form.List name={'authors'}>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ name, ...restField }) => {
            return (
              <Card
                className="gx-mb-3"
                key={name}
              >
                <Row
                  justify="space-between"
                  className="gx-flex-row"
                >
                  <Col>
                    <Form.Item shouldUpdate>
                      {() => (
                        <Form.Item
                          name={[name, 'isCommitteeMember']}
                          label={<FormattedMessage id="article.process.content.isAuthorMember" />}
                          {...restField}
                        >
                          <Radio.Group
                            buttonStyle="solid"
                            onChange={({ target }) => {
                              const newValues = {
                                isCommitteeMember: target.value,
                                authorSelection: 'list',
                                author: null,
                              };
                              authors[name] = { ...authors[name], ...newValues };

                              return form.setFieldsValue({
                                authors,
                              });
                            }}
                          >
                            <Radio.Button value={'yes'}>
                              <FormattedMessage id="appModule.yes" />
                            </Radio.Button>
                            <Radio.Button value={'no'}>
                              <FormattedMessage id="appModule.no" />
                            </Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      )}
                    </Form.Item>
                  </Col>
                  <Col>
                    <RemoveButton onClick={() => remove(name)} />
                  </Col>
                </Row>
                <Form.Item shouldUpdate>
                  {() => {
                    if (authors?.[name]?.isCommitteeMember !== undefined) {
                      return (
                        <>
                          <Form.Item
                            name={[name, 'author']}
                            label={formatMessage({
                              id: 'article.process.content.selectAuthor',
                            })}
                            rules={[
                              {
                                required: true,
                                message: formatMessage({
                                  id: 'appModule.requiredMessage',
                                }),
                              },
                            ]}
                            className="gx-mb-3"
                            required
                          >
                            <AuthorSelect isCommitteeMember={authors?.[name]?.isCommitteeMember === 'yes' ? 'committee' : 'authors'} />
                          </Form.Item>
                          <Alert
                            type="info"
                            message={
                              <span>
                                Eklemek istediğiniz yazar adayı sistemde tanımlı değilse{' '}
                                <a
                                  href="/yazar-islemleri/yazar-ekle"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  buraya tıklayarak
                                </a>{' '}
                                sisteme tanımlayabilir ve sonrasında bu sayfaya dönerek seçiminizi yapabilirsiniz.
                              </span>
                            }
                          />
                          <Form.Item
                            label={formatMessage({
                              id: 'article.process.content.authorReason',
                            })}
                            rules={[
                              {
                                min: 100,
                                message: formatMessage({
                                  id: 'article.reasonLengthMessage',
                                }),
                              },
                            ]}
                            normalize={(value) => (value.trim() === '' ? undefined : value)}
                            name={[name, 'reason']}
                          >
                            <TextArea
                              rows={4}
                              showCount
                            />
                          </Form.Item>
                        </>
                      );
                    }
                  }}
                </Form.Item>
              </Card>
            );
          })}
          <Card className="gx-mb-3">
            <Button
              type="dashed"
              onClick={() => add()}
              icon={<PlusOutlined />}
              className="gx-m-0"
              block
            >
              {formatMessage({ id: 'author.addAuthor' })}
            </Button>
          </Card>
        </>
      )}
    </Form.List>
  );
};

export default AddAuthor;
