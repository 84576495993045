import { useState } from 'react';

import { Button, Modal, Select } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useUserList } from 'useSWR';
import { openNotification, showErrorMessage } from 'utils';
import { Form, TextArea } from 'components';
import { useGetAllOrderRevertReasonQuery, useSendDecisionMutation } from 'services';

const { Option } = Select;

export const OrderCancelModal = ({ offer, open, close }) => {
  const { title, targetOffer } = offer;

  const { formatMessage } = useIntl();
  const [form] = Form.useForm();

  const { data: reasons, isLoading: reasonsLoading } = useGetAllOrderRevertReasonQuery();
  const [sendDecision] = useSendDecisionMutation();
  const { users, isLoading: usersLoading } = useUserList({
    pagination: false,
    'memberships.committee.shortName': 'AYK',
  });

  const [busy, setBusy] = useState(false);

  async function onFinish(values) {
    setBusy(true);

    const reason = {
      status: 3,
      ...values,
    };

    if (values.ouRevertReasonId === 'other') {
      reason.ouRevertReasonId = null;
    } else {
      reason.description = null;
    }

    const { error } = await sendDecision({
      id: targetOffer?.id,
      committee: 'ou',
      body: reason,
    });

    if (error) return showErrorMessage(error.data.message);
    openNotification({
      message: formatMessage({ id: 'appModule.actionSuccessfully' }),
    });

    close();

    setBusy(false);
  }

  return (
    <Modal
      open={open}
      onCancel={close}
      footer={null}
      width="40%"
      maskClosable={false}
      centered
      destroyOnClose
    >
      <h1 className="gx-text-center">{title}</h1>
      <p className="gx-text-center gx-my-4">
        <FormattedMessage id="article.order.cancelModalDescription" />
      </p>

      <Form
        form={form}
        onFinish={onFinish}
        className="gx-px-3"
      >
        <Form.Item
          className="gx-mb-3"
          name="ouRevertUserId"
          rules={[
            {
              required: true,
              message: formatMessage({ id: 'appModule.requiredMessage' }),
            },
          ]}
        >
          <Select
            placeholder={<FormattedMessage id="appModule.selectPerson" />}
            loading={usersLoading}
          >
            {users?.['hydra:member']?.map((user) => (
              <Option
                key={user?.id}
                value={user?.id}
              >
                {user?.fullName}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="ouRevertReasonId"
          rules={[
            {
              required: true,
              message: formatMessage({ id: 'appModule.requiredMessage' }),
            },
          ]}
        >
          <Select
            placeholder={<FormattedMessage id="article.order.cancelReasonPlaceholder" />}
            loading={reasonsLoading}
          >
            {reasons?.map((reason) => (
              <Option
                key={reason?.id}
                value={reason?.id}
              >
                {reason?.name}
              </Option>
            ))}
            <Option value="other">
              <FormattedMessage id="appModule.other" />
            </Option>
          </Select>
        </Form.Item>

        <Form.Item shouldUpdate>
          {({ getFieldValue }) =>
            getFieldValue('ouRevertReasonId') === 'other' && (
              <Form.Item
                name="description"
                className="gx-mt-3"
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id="appModule.requiredMessage" />,
                  },
                  {
                    min: 100,
                    message: formatMessage({
                      id: 'article.reasonLengthMessage',
                    }),
                  },
                ]}
              >
                <TextArea
                  placeholder={formatMessage({ id: 'appModule.reason' })}
                  rows={4}
                  showCount
                />
              </Form.Item>
            )
          }
        </Form.Item>

        <div className="gx-d-flex gx-mt-4">
          <Button
            onClick={close}
            block
          >
            <FormattedMessage id="appModule.cancel" />
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            loading={busy}
            block
          >
            <FormattedMessage id="appModule.cancelAction" />
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
