import React from 'react';

import { Radio, Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import { article_detection_decisions } from 'constant/static';

const MakeDecision = ({ isDraft, formData, situation, value, onChange }) => {
  return (
    <Radio.Group
      buttonStyle="solid"
      value={value}
      onChange={onChange}
    >
      <Radio.Button value={article_detection_decisions.kabul}>
        <FormattedMessage id="article.committeeDecisionAccept" />
      </Radio.Button>
      {(situation === 'dah' || situation === 'ayk') && (
        <Radio.Button value={article_detection_decisions.incele}>
          <FormattedMessage id="article.committeeDecisionAcceptReview" />
        </Radio.Button>
      )}
      {situation !== 'committee' && (
        <Radio.Button value={article_detection_decisions.iade}>
          <FormattedMessage id="article.committeeDecisionRevent" />
        </Radio.Button>
      )}
      {situation === 'ayk' && (
        <Radio.Button value={article_detection_decisions.red}>
          <FormattedMessage id="article.committeeDecisionReject" />
        </Radio.Button>
      )}
      {situation === 'committee' && (
        <Tooltip title={isDraft && 'Taslak formlar için "Beklet" kararı verilememektedir.'}>
          <Radio.Button
            value={article_detection_decisions.incele}
            disabled={isDraft}
          >
            <FormattedMessage id="article.committeeDecisionWait" />
          </Radio.Button>
        </Tooltip>
      )}

      {situation === 'committee' &&
        [article_detection_decisions.iade, article_detection_decisions.kabul, article_detection_decisions.incele].includes(formData.dah.status) && (
          <Tooltip title={formData.ayk.status === article_detection_decisions.iade && "AYK tarafından iade edilmiş bir form tekrar AYK'ya havale edilemez"}>
            <Radio.Button
              disabled={formData.ayk.status === article_detection_decisions.iade}
              value={article_detection_decisions.havale}
            >
              <FormattedMessage id="article.committeeDecisionSendToAyk" />
            </Radio.Button>
          </Tooltip>
        )}
    </Radio.Group>
  );
};
export default MakeDecision;
