import useSWR from 'swr';

const useContracts = ({ id, author } = {}) => {
  const url = `/contracts/offer${id ? `/${id}` : ''}${author ? `/${author}` : ''}`;
  const { data, error, mutate } = useSWR(url, { revalidateOnFocus: false });

  return {
    contracts: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useContracts;
