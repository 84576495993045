import apiInstance from './api';

const authorOpinionApi = apiInstance.injectEndpoints({
  endpoints: (build) => ({
    sendAuthorOpinion: build.mutation({
      query: ({ id, body }) => ({
        url: `/give_author_opinion/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['ArticleOffers', 'ArticleOfferDetail', 'ArticleOffersProcess', 'AuthorArticles'],
    }),
  }),
});

export default authorOpinionApi;
