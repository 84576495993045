import { Card } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import { pullbackStatus } from 'utils';

import PullbackForm from './PullbackForm';

export function PullbackResponse({ id, committee, pullbackRequests }) {
  const { formatMessage } = useIntl();

  const requests = pullbackRequests?.filter(
    (req) => req.requested === committee && req.status === 0
  );

  if (!requests || requests.length === 0) return null;

  return (
    <>
      <Card
        title={
          <h2 className="gx-h2-lg gx-mb-0">
            <FormattedMessage
              id="article.status.pullback.response.title"
              values={{
                committee: formatMessage({
                  id: committee === 'dah' ? 'committee' : 'dah',
                }),
              }}
            />
          </h2>
        }
      >
        {requests.map((request) => (
          <Card key={request.id} className="pullback-request-card">
            <p>
              <FormattedHTMLMessage
                id="article.status.pullback.response.description"
                values={{
                  committee: formatMessage({ id: request.requester }),
                }}
              />
            </p>

            <Card className="opinion-card-inner gx-word-break-word">{`"${request?.description}"`}</Card>

            {request.status === 0 ? (
              <PullbackForm request={request} id={id} committee={committee} />
            ) : (
              <div
                className={`gx-d-inline-block opinion-button ${
                  pullbackStatus[request.status]
                }`}
              >
                {request.status === 1 ? <CheckOutlined /> : <CloseOutlined />}
                <span className="gx-ml-2">
                  <FormattedMessage
                    id={`article.status.${pullbackStatus[request.status]}`}
                  />
                </span>
              </div>
            )}
          </Card>
        ))}
      </Card>
    </>
  );
}
