import React from 'react';

import { Checkbox, Tooltip, Form, Input } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { CircularProgress } from 'components';
import { useGetCommitteeRevertReasonsQuery } from 'services';

const { TextArea } = Input;
const renderErrorMessage = (status) => {
  switch (status) {
    case 3:
      return <FormattedMessage id="article.status.reventReasonsErrorMessage" />;
    case 5:
      return <FormattedMessage id="article.status.cancelReasonsErrorMessage" />;
    case 6:
      return <FormattedMessage id="article.status.transferErrorMessage" />;
    case 15:
      return 'İptal sebebi seçmelisiniz';
  }
};

const renderStatusHeader = (status) => {
  switch (status) {
    case 3:
      return <FormattedMessage id="article.status.reventReasons" />;
    case 5:
      return <FormattedMessage id="article.status.cancelReasons" />;
    case 6:
      return <FormattedMessage id="article.status.transferReasons" />;
    case 15:
      return 'İptal Sebebi';
  }
};

const renderPlaceholder = (status, formatMessage) => {
  switch (status) {
    case 3:
      return formatMessage({ id: 'article.status.cancelReasonsPlaceholder' });
    case 5:
      return formatMessage({ id: 'article.status.reventReasonsPlaceholder' });
    case 15:
      return 'İptal sebebi';
  }
};
const Refund = ({ committee, step, status, toCommittee }) => {
  const { formatMessage } = useIntl();
  const { data, error } = useGetCommitteeRevertReasonsQuery({ step: step, status: status, committee: committee, toCommittee: toCommittee });

  if (!error && !data) return <CircularProgress />;

  return (
    <>
      <Form.Item
        name="checkbox"
        tooltip={<FormattedMessage id={`article.status.${status}.TooltipMessage`} />}
        label={renderStatusHeader(status)}
        rules={[
          {
            required: true,
            message: renderErrorMessage(status),
          },
        ]}
      >
        <Checkbox.Group
          style={{ width: '600px' }}
          className="gx-px-2"
        >
          {data?.map((item) => (
            <>
              <Form.Item
                key={item.id}
                name={item.input + 'checkbox'}
                valuePropName="checked"
                className="gx-mb-2"
              >
                <Checkbox value={item.input}>
                  {item.title}
                  {item?.info?.length ? (
                    <Tooltip title={item?.info}>
                      <QuestionCircleOutlined />{' '}
                    </Tooltip>
                  ) : (
                    ''
                  )}
                </Checkbox>
              </Form.Item>
              <Form.Item dependencies={[item.input + 'checkbox']}>
                {({ getFieldValue }) =>
                  getFieldValue(item.input + 'checkbox') && (
                    <Form.Item
                      rules={[
                        {
                          min: 50,
                          message: <FormattedMessage id="article.reasonLengthMessageShort" />,
                          whitespace: true,
                        },
                      ]}
                      normalize={(value) => (value.trim() === '' ? null : value)}
                      name={item.input}
                      className="gx-mb-3"
                    >
                      <TextArea
                        rows={4}
                        placeholder={renderPlaceholder(status, formatMessage)}
                        showCount
                      />
                    </Form.Item>
                  )
                }
              </Form.Item>
            </>
          ))}
          <Form.Item className="gx-mb-1">
            <Form.Item
              name="othercheckbox"
              valuePropName="checked"
              className="gx-mb-2"
            >
              <Checkbox value="other">
                <FormattedMessage id="article.detail.articleTypeOther" />
              </Checkbox>
            </Form.Item>
            <Form.Item dependencies={['othercheckbox']}>
              {({ getFieldValue }) =>
                getFieldValue('othercheckbox') && (
                  <Form.Item
                    name={'other'}
                    rules={[
                      {
                        required: true,
                        message: renderErrorMessage(status),
                      },
                      {
                        min: 50,
                        message: <FormattedMessage id="article.reasonLengthMessageShort" />,
                      },
                    ]}
                    className="gx-mb-3"
                  >
                    <TextArea
                      rows={4}
                      placeholder={formatMessage({
                        id: 'article.status.DecisionReasonPlaceHolder',
                      })}
                      showCount
                    />
                  </Form.Item>
                )
              }
            </Form.Item>
          </Form.Item>
        </Checkbox.Group>
      </Form.Item>
    </>
  );
};

export default Refund;
