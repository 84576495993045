import { Form, Input } from 'antd';

const { TextArea } = Input;

const AcceptDescision = ({ commitee }) => {
  return (
    <div className="gx-mt-5 gx-mb-5">
      <div className="gx-text-center gx-mb-4">
        <span className="gx-mt-4 gx-mb-2">
          {commitee === 'eu_control' ? (
            <>
              Gönder butonuna bastığınızda madde teknik redaksiyon için
              <strong> Yayın Müdürlüğü'ne </strong>
              gönderilecektir. Onaylıyor musunuz?
            </>
          ) : commitee === 'ayk_control' ? (
            <>
              Gönder butonuna bastığınızda madde <strong>Yayın aşaması'na </strong>
              geçecektir. Onaylıyor musunuz?
            </>
          ) : (
            <>
              Gönder butonuna bastığınızda madde karar aşaması için
              <strong> AYK'ya</strong> gönderilecektir. Onaylıyor musunuz
            </>
          )}
        </span>
      </div>
      <Form.Item name="description">
        <TextArea
          rows={7}
          placeholder="Notunuz varsa girebilirsiniz"
          showCount
        />
      </Form.Item>
    </div>
  );
};

export default AcceptDescision;
