import { useEffect } from 'react';

import { Button, Col, Form, Input, Modal, Row } from 'antd';
import { DatePicker } from 'components';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useChangeDeadlineMutation } from 'services';
import { fetchError } from 'slices/commonSlice';
import { datePickerFormat, openNotification } from 'utils';

const { TextArea } = Input;

const ArticleOrderDateEdit = ({ id, title, authors, deliveryDate, orderDate, ...other }) => {
  const [form] = Form.useForm();
  const ouDeliveryDate = Form.useWatch('deliveryDate', form);
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [changeDeadline] = useChangeDeadlineMutation();
  useEffect(() => {
    if (other.open) {
      form.setFieldsValue({
        orderDate: dayjs(orderDate),
        deliveryDate: dayjs(deliveryDate),
      });
    }
  }, [other.open, form, orderDate, deliveryDate]);

  const onFinish = async (values) => {
    const deliveryDate = dayjs(values.deliveryDate).format('YYYY-MM-DD');
    const { error } = await changeDeadline({ id, body: { deliveryDate, description: values.description } });

    if (error) {
      return dispatch(
        fetchError(
          formatMessage(
            {
              id: 'article.form.fetchError',
            },
            { error: error.status }
          )
        )
      );
    }
    openNotification({
      message: formatMessage({ id: 'appModule.actionSuccessfully' }),
    });

    form.resetFields();
    other.onCancel();
  };

  return (
    <Modal
      footer={null}
      {...other}
      bodyStyle={{
        padding: '2rem 5rem',
      }}
      width={900}
    >
      <div className="articleOrderEdit">
        <div>
          <h1>Teslim Tarihi Düzenle</h1>
          <div className="orderDetail">
            Madde: {title} - Yazar:
            {authors.map((item, index) => (
              <span key={index}> {item.user.fullName}</span>
            ))}
          </div>
        </div>
        <div className="gx-text-center">Maddenin yazılış süresi: {dayjs(ouDeliveryDate).diff(orderDate, 'day')} gün</div>
        <div>
          <Form
            id="articleOrderDateChangeForm"
            style={{ width: '100%' }}
            layout="vertical"
            form={form}
            onFinish={onFinish}
          >
            <Row
              gutter={[24, 24]}
              style={{
                flexDirection: 'row',
              }}
            >
              <Col span={12}>
                <Form.Item
                  name="orderDate"
                  label={<div className="calenderFormLabel">Sipariş Tarihi</div>}
                >
                  <DatePicker
                    disabled
                    format={datePickerFormat}
                    className="gx-w-100"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="deliveryDate"
                  label={<div className="calenderFormLabel">Teslim Tarihi</div>}
                >
                  <DatePicker
                    disabledDate={(current) => {
                      return current && current < orderDate;
                    }}
                    format={datePickerFormat}
                    className="gx-w-100"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="description"
                  label="Açıklama"
                  rules={[
                    {
                      required: true,
                      message: 'Açıklama giriniz',
                    },
                    {
                      min: 100,
                      message: 'Açıklama en az 100 karakter olmalıdır',
                    },
                  ]}
                >
                  <TextArea
                    placeholder="Açıklama giriniz"
                    showCount
                    rows={4}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
        <Button
          type="primary"
          className="gx-mt-4"
          htmlType="submit"
          form="articleOrderDateChangeForm"
        >
          {formatMessage({
            id: 'appModule.save',
          })}
        </Button>
      </div>
    </Modal>
  );
};

export default ArticleOrderDateEdit;
