import apiInstance from './api';
const mediaApi = apiInstance.injectEndpoints({
  endpoints: (build) => ({
    getMedias: build.query({
      query: (params) => ({
        url: 'media',
        params,
      }),
      providesTags: ['Medias'],
    }),
    deleteMedia: build.mutation({
      query: (id) => ({
        method: 'DELETE',
        url: `media/${id}`,
      }),
      invalidatesTags: ['Medias', 'Guides'],
    }),
  }),
  overrideExisting: true,
});

export default mediaApi;
