import { Input as AntInput, Form } from 'antd';
import { useKeyboard } from 'hooks';

export const TextArea = ({ onFocus, ...props }) => {
  const form = Form.useFormInstance();

  const { setInputName, setValue } = useKeyboard();

  const onFocusChange = (event) => {
    if (form.getFieldValue) {
      const inputName = props.id;
      setInputName(inputName);
      setValue(form.getFieldValue(inputName));
    }

    if (onFocus) {
      onFocus(event);
    }
  };

  return (
    <AntInput.TextArea
      onFocus={onFocusChange}
      {...props}
    />
  );
};
