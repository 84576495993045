import { createSlice } from '@reduxjs/toolkit';

const { NAV_STYLE_FIXED, LAYOUT_TYPE_FULL, THEME_TYPE_SEMI_DARK } = require('constant');

const initialState = {
  navStyle: NAV_STYLE_FIXED,
  layoutType: LAYOUT_TYPE_FULL,
  themeType: THEME_TYPE_SEMI_DARK,

  isDirectionRTL: false,
  locale: {
    languageId: 'turkish',
    locale: 'tr',
    name: 'Türkçe',
    icon: 'tr',
  },
};

const settingSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    switchLanguage: (state, action) => {
      state.locale = action.payload;
    },
    setThemeType: (state, action) => {
      state.themeType = action.payload;
    },
    setThemeColor: (state, action) => {
      state.themeColor = action.payload;
    },
    onNavStyleChange: (state, action) => {
      state.navStyle = action.payload;
    },

    onLayoutTypeChange: (state, action) => {
      state.layoutType = action.payload;
    },
  },
});

export const { switchLanguage, setThemeType, setThemeColor, onNavStyleChange, onLayoutTypeChange } = settingSlice.actions;

export default settingSlice.reducer;
