import React from 'react'

import { Result, Button } from 'antd'

const FallBack = () => {
  return (
    <Result
    status="warning"
    title="Bir problemle karşılaşıldı."
    extra={
      <Button
        onClick={() => window.location.reload(true)}
        type="primary"
        key="console"
      >
        Sayfayı Yenile
      </Button>
    }
  />
  )
}

export default FallBack
