import { useState } from 'react';

import { Button, Col, Form, Modal, Row } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useUpdateOrderDateMutation } from 'services';
import dayjs from 'dayjs';
import { DatePicker } from 'components';
import { datePickerFormat, openNotification } from 'utils';
import { useDispatch } from 'react-redux';
import { fetchError } from 'slices/commonSlice';

const AdditionalTime = ({ title, offerId, authorsId }) => {
  const [updateOrderDate] = useUpdateOrderDateMutation();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);

  const onFinish = async (values) => {
    const newValues = {
      date: dayjs(values.date).format('YYYY-MM-DD'),
      author: authorsId,
    };

    const { error } = await updateOrderDate({ id: offerId, body: newValues });

    if (error) {
      setOpen(false);
      return dispatch(
        fetchError(
          formatMessage(
            {
              id: 'article.form.fetchError',
            },
            { error }
          )
        )
      );
    }
    openNotification({
      message: formatMessage({
        id: 'appModule.actionSuccessfully',
      }),
    });

    setOpen(false);
  };

  return (
    <>
      <Button
        block
        className="modalBtnStyle"
        onClick={() => setOpen(true)}
      >
        <FormattedMessage id="additional.time.btn" />
      </Button>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
        destroyOnClose
        bodyStyle={{
          padding: '2rem 5rem',
          height: '600px',
        }}
      >
        <div className="articleOrderEdit">
          <div>
            <h1>Ek Süre Tanimla</h1>
            <div className="subtitle">Aşağıdaki alanda gerekçesini belirterek yazara ek süre verebilirsiniz.</div>
            <div className="orderDetail">Madde: {title}</div>
          </div>
          <div>
            <Form
              id="additionalTimeForm"
              style={{ width: '100%' }}
              layout="vertical"
              form={form}
              onFinish={onFinish}
            >
              <Row
                gutter={[24, 24]}
                style={{
                  flexDirection: 'row',
                }}
              >
                <Col span={24}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: formatMessage({
                          id: 'appModule.requiredMessage',
                        }),
                      },
                    ]}
                    name="date"
                  >
                    <DatePicker
                      disabledDate={(current) => current && current < dayjs().startOf('day')}
                      className="gx-w-100"
                      format={datePickerFormat}
                    />
                  </Form.Item>
                </Col>
                {/* //bu alan henüz belli değil o yüzden şimdilik yorum satırı olarak yapıldı  */}
                {/* <Col span={24}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: formatMessage({
                          id: 'appModule.requiredMessage',
                        }),
                      },
                    ]}
                    name="endDate"
                  >
                    <TextArea
                      placeholder={formatMessage({
                        id: 'additional.time.reason',
                      })}
                      rows={6}
                    />
                  </Form.Item>
                </Col> */}
              </Row>
            </Form>
          </div>
          <Button
            type="primary"
            htmlType="submit"
            form="additionalTimeForm"
          >
            {formatMessage({
              id: 'additional.time.form',
            })}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default AdditionalTime;
