import React from 'react';

import { Button, Form, Input, Modal, Select } from 'antd';
import { useIntl } from 'react-intl';
import { useCreateCommitteeMutation, useGetAllCommitteeCategoriesQuery } from 'services';
import { useDispatch } from 'react-redux';
import { fetchError } from 'slices/commonSlice';
import { openNotification } from 'utils';

const AddCommiteeModal = ({ ...other }) => {
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [createCommittee] = useCreateCommitteeMutation();
  const { data } = useGetAllCommitteeCategoriesQuery();

  const onFinish = async (values) => {
    const { error } = await createCommittee(values);
    if (error) {
      return dispatch(
        fetchError(
          formatMessage(
            {
              id: 'article.form.fetchError',
            },
            { error: error.status }
          )
        )
      );
    }
    openNotification({
      message: formatMessage({ id: 'appModule.actionSuccessfully' }),
    });

    other.onCancel();
  };

  return (
    <Modal
      {...other}
      destroyOnClose
      footer={null}
    >
      <Form
        onFinish={onFinish}
        form={form}
        layout="vertical"
      >
        <Form.Item
          label="Heyet Grubu"
          name="category"
          initialValue={data?.find((item) => item.name === 'Heyet')?.['@id']}
          rules={[
            {
              required: true,
              message: formatMessage({ id: 'appModule.requiredMessage' }),
            },
          ]}
        >
          <Select
            options={data?.map((item) => ({
              key: item.id,
              label: item.name,
              value: item['@id'],
            }))}
          />
        </Form.Item>
        <Form.Item
          className="gx-mb-3 gx-mt-3"
          label={formatMessage({ id: 'committees.committeeName' })}
          rules={[
            {
              required: true,
              message: formatMessage({ id: 'appModule.requiredMessage' }),
            },
          ]}
          name="name"
        >
          <Input />
        </Form.Item>
        <Form.Item
          className="gx-mb-3"
          label={formatMessage({ id: 'committees.committeeShortName' })}
          rules={[
            {
              required: true,
              message: formatMessage({ id: 'appModule.requiredMessage' }),
            },
          ]}
          name="shortName"
        >
          <Input />
        </Form.Item>
        <Form.Item
          className="gx-mb-3"
          label="Email"
          name="email"
          rules={[
            {
              type: 'email',
              message: formatMessage({ id: 'appModule.requiredMessage' }),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button
            block
            type="primary"
            htmlType="submit"
          >
            {formatMessage({ id: 'appModule.save' })}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddCommiteeModal;
