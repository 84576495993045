import React, { useState } from 'react';

import { Form, Select, Spin } from 'antd';
import { useIntl } from 'react-intl';
import { fetchSearchE1 } from 'fetcher';

let timeCheck;
const ArticleSearchEdisonOne = ({ type, onChange, value, disabled }) => {
  const { formatMessage } = useIntl();
  const form = Form.useFormInstance();

  // bu alanlar formun verilerindeki değişiklikleri anlık olarak izlemek için kullanılıyor
  const atifTam = Form.useWatch('atifTam', form);

  const [searchResult, setSearchResult] = useState([]);
  const [fetching, setFetching] = useState(false);

  const handleSearch = async (e, type = null) => {
    setFetching(true);
    clearTimeout(timeCheck);
    timeCheck = setTimeout(async () => {
      if (e.length >= 2) {
        try {
          const result = await fetchSearchE1(e, type ? (type === 'Tam' ? true : false) : '');
          setFetching(true);
          setSearchResult(result['hydra:member']);
          setFetching(false);
        } catch (e) {
          console.log(e);
        }
      } else {
        setFetching(false);
      }
    }, 500);
  };

  return (
    <Select
      getPopupContainer={(triggerNode) => triggerNode.parentElement}
      labelInValue="hangiMadde"
      disabled={disabled}
      onChange={onChange}
      value={value}
      mode={type ? 'multiple' : 'default'}
      placeholder={formatMessage({
        id: 'article.form.SearchEdisionOnePlaceholder',
      })}
      loading={fetching}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      filterOption={false}
      onSearch={(e) => handleSearch(e, type ? type : atifTam)}
      style={{ display: 'block' }}
      allowClear={!type}
      showSearch
      dropdownStyle={{ position: 'relative' }}
      onClick={() => setSearchResult([])}
      options={searchResult.map((d) => ({
        key: d.id,
        value: d.id,
        label: `${d.title} (${d.slug})`,
      }))}
    />
  );
};

export default ArticleSearchEdisonOne;
