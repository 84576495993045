import { Card, Divider } from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  FieldTimeOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { pullbackStatus } from 'utils';
import dayjs from 'dayjs';

export function PullbackRequests({ pullbackRequests, committee }) {
  const requests = pullbackRequests?.filter(
    (req) => req.requester === committee
  );

  const RequestStatusIcon = ({ status }) => {
    if (status === 1) {
      return <CheckOutlined />;
    } else if (status === 2) {
      return <CloseOutlined />;
    } else {
      return <FieldTimeOutlined />;
    }
  };

  if (!requests || requests?.length === 0) return null;

  return (
    <Card>
      {requests?.map((request) => (
        <Card
          key={request.id}
          className="pullback-request-card"
          bodyStyle={{ padding: '24px 0 0 0' }}
          title={
            <div className="gx-d-flex gx-justify-content-between gx-align-items-center gx-px-3">
              <h2 className="gx-h2-lg gx-my-0">
                <FormattedMessage id="article.status.pullback.requestsTitle" />
              </h2>
              <p
                className={`pullback-status ${pullbackStatus[request?.status]}`}
              >
                <RequestStatusIcon
                  status={request?.status}
                  style={{ fontSize: 18 }}
                />

                <span className="gx-ml-2">
                  <FormattedMessage
                    id={`article.status.${pullbackStatus[request?.status]}`}
                  />
                </span>
              </p>
            </div>
          }
        >
          <Card className="opinion-card-inner gx-mx-3 gx-mb-0">
            <span className="gx-font-weight-semi-bold">
              <FormattedMessage id="article.status.pullback.reason" />
            </span>
            <span className="gx-word-break-word">
              {`"${request?.description}"`}
            </span>
          </Card>

          <div className="gx-my-3">
            <div className="pullback-committee-row">
              <div>
                <UserOutlined />
                <span className="gx-ml-2">
                  <FormattedMessage id={request?.requester} />
                </span>
                <span className="gx-ml-1 gx-font-italic gx-text-light">
                  <FormattedMessage id="article.status.pullback.requested" />
                </span>
              </div>
              <span>
                {dayjs(request?.createdAt).format('DD MMMM YYYY - HH:mm')}
              </span>
            </div>

            {request?.status !== 0 && (
              <>
                <Divider className="gx-my-3" />

                {request?.status === 2 && (
                  <Card className="opinion-card-inner gx-mx-3 gx-mb-3">
                    <span className="gx-font-weight-semi-bold">
                      <FormattedMessage id="article.status.pullback.reason" />
                    </span>
                    <span className="gx-word-break-word">
                      {`"${request?.decisionDescription}"`}
                    </span>
                  </Card>
                )}

                <div className="pullback-committee-row">
                  <div>
                    <UserOutlined />
                    <span className="gx-ml-2">
                      <FormattedMessage id={request?.requested} />
                    </span>
                    <span className="gx-ml-1 gx-font-italic gx-text-light">
                      <FormattedMessage
                        id={`article.status.pullback.${
                          pullbackStatus[request?.status]
                        }`}
                      />
                    </span>
                  </div>
                  <span>
                    {dayjs(request?.updatedAt).format('DD MMMM YYYY - HH:mm')}
                  </span>
                </div>
              </>
            )}
          </div>
        </Card>
      ))}
    </Card>
  );
}
