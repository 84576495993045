import apiInstance from './api';

const authApi = apiInstance.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation({
      query: (body) => ({
        url: '/login',
        method: 'POST',
        body,
      }),
      invalidatesTags: (_, error) => (error ? [] : ['CurrentUser']),
    }),
  }),
  overrideExisting: true,
});

export default authApi;
