import { useState } from 'react';

import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';
import { NOTE_TYPES, openNotification } from 'utils';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { fetchError } from 'slices/commonSlice';
import { useSendArticleOrderMeetMutation } from 'services';

const { TextArea } = Input;

export const ArticleInterviewNote = ({ id }) => {
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const [sendArticleOrderMeet] = useSendArticleOrderMeetMutation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const [type, setType] = useState();

  const handleSelectType = (value) => {
    form.setFieldsValue({ interviewNote: value });
    const noteType = form.getFieldValue(`interviewNote`);
    setType(noteType);
  };

  const onFinish = async (values) => {
    const note = {
      articleOffer: id,
      type: parseInt(values.noteType),
      subject: type === '2' ? values.emailTitle : null,
      content: values.noteArea,
    };

    const { error } = await sendArticleOrderMeet(note);
    if (error) {
      setOpen(false);
      return dispatch(fetchError(formatMessage({ id: 'article.form.fetchError' }, { error: error.status })));
    }
    openNotification({
      message: formatMessage({ id: 'appModule.actionSuccessfully' }),
    });
    setOpen(false);
  };

  return (
    <>
      <Button
        block
        className="modalBtnStyle"
        onClick={() => setOpen(true)}
      >
        <FormattedMessage id="interview.note.btn" />
      </Button>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        width={900}
        footer={null}
      >
        <div className="interviewNote">
          <h1 className="h1">GÖRÜŞME NOTU</h1>

          <Form
            form={form}
            onFinish={onFinish}
            id="articleInterviewNoteForm"
          >
            <Form.Item
              className="gx-mb-4"
              name="noteType"
            >
              <Select
                placeholder="İletişim Türü Seçiniz"
                showSearch
                dropdownMatchSelectWidth={false}
                onChange={handleSelectType}
              >
                {NOTE_TYPES.map(([value, text]) => (
                  <Select.Option key={value}>{text}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            {type === '2' && (
              <Form.Item
                className="gx-mb-4"
                name="emailTitle"
              >
                <Input placeholder="Lütfen mail başlığı giriniz." />
              </Form.Item>
            )}
            <Form.Item
              className="gx-mb-4"
              name="noteArea"
            >
              <TextArea
                className="noteArea"
                placeholder="Lütfen yazar ile yapılan görüşmede aldığınız notları giriniz."
              />
            </Form.Item>
            <Row gutter={[24, 6]}>
              <Col
                xs={24}
                md={12}
              >
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  form="articleInterviewNoteForm"
                >
                  BİLDİRİM OLUŞTUR
                </Button>
              </Col>
              <Col
                xs={24}
                md={12}
              >
                <Button
                  onClick={() => setOpen(false)}
                  block
                >
                  VAZGEÇ
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
    </>
  );
};
