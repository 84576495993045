import Cookies from 'js-cookie';

export const getCookies = () => {
  const token = Cookies.get('token', {
    domain: process.env.REACT_APP_COKKIE_URL,
    path: '/',
  });

  const refreshToken = Cookies.get('refresh_token', {
    domain: process.env.REACT_APP_COKKIE_URL,
    path: '/',
  });

  const xSwitchUser = Cookies.get('xSwitchUser', {
    domain: process.env.REACT_APP_COKKIE_URL,
    path: '/',
  });

  return { token, refreshToken, xSwitchUser };
};

export const setCookies = (token, refreshToken) => {
  Cookies.set('token', token, {
    expires: 1,
    domain: process.env.REACT_APP_COKKIE_URL,
    path: '/',
  });

  Cookies.set('refresh_token', refreshToken, {
    expires: 1,
    domain: process.env.REACT_APP_COKKIE_URL,
    path: '/',
  });
};

export const removeCookies = () => {
  Cookies.remove('token', {
    domain: process.env.REACT_APP_COKKIE_URL,
    path: '/',
  });
  Cookies.remove('refresh_token', {
    domain: process.env.REACT_APP_COKKIE_URL,
    path: '/',
  });
};

export const switchUser = () => {
  Cookies.remove('xSwitchUser', {
    domain: process.env.REACT_APP_COKKIE_URL,
    path: '/',
  });
};
