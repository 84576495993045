import useSWR from 'swr';
import { getFilteredURL } from 'utils';

const useUserList = ({ pagination = true, page = 1, pageSize = 20, ...filters }) => {
  const url = getFilteredURL('/users', {
    pagination,
    page,
    pageSize,
    ...filters,
  });

  const { data, error, mutate } = useSWR(url, { revalidateOnFocus: false });

  return {
    users: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useUserList;
