import { Radio } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { educationTypes } from 'utils';
import { Form, Input, RemoveButton } from 'components';

export default ({ name, editable, remove }) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <div className="gx-d-flex gx-justify-content-between">
        <Form.Item
          className="gx-my-2"
          name={[name, 'type']}
          label={<FormattedMessage id="author.education.type" />}
          rules={[
            {
              required: true,
              message: (
                <FormattedMessage
                  id="appModule.requiredFieldMessage"
                  values={{
                    field: formatMessage({ id: 'author.education.type' }),
                  }}
                />
              ),
            },
          ]}
        >
          <Radio.Group buttonStyle="solid">
            {educationTypes.map((type, i) => (
              <Radio.Button
                value={`${i}`}
                key={i}
              >
                {type}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Form.Item>

        <RemoveButton
          disabled={!editable}
          onClick={remove}
        />
      </div>

      <Form.Item shouldUpdate>
        {({ getFieldValue }) => {
          const edu = getFieldValue('authorEducation')[name];
          return (
            edu?.type && (
              <>
                <Form.Item
                  className="gx-mb-2"
                  name={[name, 'graduationYear']}
                  label={
                    <FormattedMessage
                      id="author.education.year"
                      values={{ type: educationTypes[edu.type] }}
                    />
                  }
                  rules={[
                    {
                      required: true,
                      message: (
                        <FormattedMessage
                          id="appModule.requiredFieldMessage"
                          values={{
                            field: formatMessage({ id: 'author.education.year' }, { type: educationTypes[edu.type] }),
                          }}
                        />
                      ),
                    },
                  ]}
                >
                  <Input type="number" />
                </Form.Item>

                <Form.Item
                  className="gx-mb-2"
                  name={[name, 'institution']}
                  label={
                    <FormattedMessage
                      id="author.education.university"
                      values={{ type: educationTypes[edu.type] }}
                    />
                  }
                  rules={[
                    {
                      required: true,
                      message: (
                        <FormattedMessage
                          id="appModule.requiredFieldMessage"
                          values={{
                            field: formatMessage({ id: 'author.education.university' }, { type: educationTypes[edu.type] }),
                          }}
                        />
                      ),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name={[name, 'institute']}
                  label={
                    <FormattedMessage
                      id="author.education.institute"
                      values={{ type: educationTypes[edu.type] }}
                    />
                  }
                  className="gx-mb-2"
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name={[name, 'branch']}
                  label={
                    <FormattedMessage
                      id={`author.education.${edu.type === '0' ? 'department' : 'branch'}`}
                      values={{ type: educationTypes[edu.type] }}
                    />
                  }
                  className="gx-mb-2"
                >
                  <Input />
                </Form.Item>

                {edu.type !== '0' && (
                  <Form.Item
                    name={[name, 'thesisTitle']}
                    label={
                      <FormattedMessage
                        id="author.education.thesisTitle"
                        values={{ type: educationTypes[edu.type] }}
                      />
                    }
                    className="gx-mb-2"
                  >
                    <Input />
                  </Form.Item>
                )}
              </>
            )
          );
        }}
      </Form.Item>
    </>
  );
};
