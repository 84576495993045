import apiInstance from './api';

const afterCopyrightApi = apiInstance.injectEndpoints({
  endpoints: (build) => ({
    getAfterCopyRightAssignment: build.query({
      query: (params) => ({
        url: '/assignments',
        method: 'GET',
        params,
      }),
      transformResponse: (response) => response['hydra:member'],
      providesTags: ['AfterCopyRightAssignment'],
    }),
    getAsignmentFields: build.query({
      query: (params) => ({
        url: '/assignment_fields',
        method: 'GET',
        params,
      }),
      transformResponse: (response) => response['hydra:member'],
      providesTags: ['AfterCopyRightAssignmentFields'],
    }),
    startAfterCopyrightDecideProcess: build.mutation({
      query: ({ offerID, committee, data }) => ({
        url: `/article_offers/decision/${committee}/${offerID} `,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['AfterCopyRightAssignment', 'AfterCopyRightAssignmentFields', 'ArticleOfferDetail'],
    }),
    afterCopyrightUnitGeneralControl: build.mutation({
      query: ({ data, committee, offerID }) => ({
        url: `/article_offers/decision/${committee}/${offerID}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['AfterCopyRightAssignment', 'AfterCopyRightAssignmentFields', 'ArticleOfferDetail'],
    }),
    makeAssigmentDecision: build.mutation({
      query: ({ data, offerID }) => ({
        url: `/assignments/${offerID}/decide`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['AfterCopyRightAssignment', 'AfterCopyRightAssignmentFields', 'ArticleOfferDetail'],
    }),
    giveDecisionForEncylopediaUnit: build.mutation({
      query: ({ data, offerID }) => ({
        url: `article_offers/decision/owner_committee/${offerID}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['AfterCopyRightAssignment', 'ArticleOfferDetail'],
    }),
    sendNoteToAuthorMediaOffer: build.mutation({
      query: ({ data, mediaID }) => ({
        url: `article_offer_author_content_media/${mediaID}/add_note`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['AfterCopyRightAssignment', 'ArticleOfferDetail', 'ArticleContentSend'],
    }),
    postAssignment: build.mutation({
      query: ({ data }) => ({
        url: '/assignments',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['AfterCopyRightAssignment', 'AfterCopyRightAssignmentFields'],
    }),
    updateAssigment: build.mutation({
      query: ({ data, assigmentID }) => ({
        url: `/assignments/${assigmentID}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['AfterCopyRightAssignment', 'AfterCopyRightAssignmentFields'],
    }),
  }),
  overrideExisting: true,
});
export default afterCopyrightApi;
