import { useState } from 'react';

import { Button, Checkbox, Col, Form, Input, Modal, Row } from 'antd';
import { DatePicker } from 'components';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { datePickerFormat, openNotification } from 'utils';
import { useSendDecisionMutation } from 'services';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { fetchError } from 'slices/commonSlice';

const { TextArea } = Input;

export const StartOrderProcess = ({ offer }) => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const formDeliveryDate = Form.useWatch('deliveryDate', form);

  const [busy, setBusy] = useState(false);
  const [editDate, setEditDate] = useState(false);
  const [sendDecision] = useSendDecisionMutation();
  const [modalVisible, setModalVisible] = useState(false);

  const onFinish = async (values) => {
    setBusy(true);

    const { error } = await sendDecision({
      id: offer?.id,
      committee: 'ou',
      body: {
        status: 2,
        ...(values.deliveryDate && { deliveryDate: dayjs(values.deliveryDate).format('YYYY-MM-DD') }),
        ...(values.deliveryDateDescription && { deliveryDateDescription: values.deliveryDateDescription }),
      },
    });

    if (error)
      return dispatch(
        fetchError(
          formatMessage(
            {
              id: 'article.form.fetchError',
            },
            { error: error.status }
          )
        )
      );

    openNotification({
      message: formatMessage({ id: 'appModule.actionSuccessfully' }),
    });

    navigate('/madde-teklifleri/tum-maddeler');

    setBusy(false);
  };

  return (
    <>
      <Button
        block
        type="primary"
        className="gx-text-uppercase"
        onClick={() => setModalVisible(true)}
      >
        <FormattedMessage id="article.order.startProcess" />
      </Button>
      <Modal
        footer={null}
        width={'60%'}
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        maskClosable={false}
        centered
      >
        <h1 className="gx-text-center gx-text-uppercase">
          <FormattedMessage id="article.order.startProcess" />
        </h1>

        <p className="gx-text-center gx-my-4">
          <FormattedMessage id="article.order.startProcessDescription" />
        </p>

        <p className="gx-text-center gx-my-4 gx-font-weight-medium">
          <FormattedMessage
            id="article.order.startProcessInfo"
            values={{ title: offer?.title, author: offer?.authors?.map(({ author }) => author?.user?.fullName).join(', ') }}
          />
        </p>
        <Form
          id="articleOrderDateChangeForm"
          style={{ width: '100%' }}
          layout="vertical"
          form={form}
          initialValues={{
            orderDate: dayjs(offer.ouDate.orderDate),
            deliveryDate: dayjs(offer.ouDate.deliveryDate),
          }}
          onFinish={onFinish}
        >
          <div className="gx-mb-4 gx-mt-4 gx-d-flex gx-justify-content-center">
            <Checkbox onChange={(e) => setEditDate(e.target.checked)}>Teslim Tarihi Düzenle</Checkbox>
          </div>
          {editDate && (
            <div className="articleOrderEdit gx-mb-4">
              <div className="gx-text-center gx-mb-4">Maddenin yazılış süresi: {dayjs(formDeliveryDate).diff(offer?.ouDate?.orderDate, 'day')} gün</div>
              <div>
                <Row
                  gutter={[24, 24]}
                  style={{
                    flexDirection: 'row',
                  }}
                >
                  <Col span={12}>
                    <Form.Item
                      name="orderDate"
                      label={<div className="calenderFormLabel">Sipariş Tarihi</div>}
                    >
                      <DatePicker
                        disabled
                        disabledDate={(current) => {
                          return current && current > dayjs().subtract(1, 'day');
                        }}
                        format={datePickerFormat}
                        className="gx-w-100"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="deliveryDate"
                      label={<div className="calenderFormLabel">Teslim Tarihi</div>}
                    >
                      <DatePicker
                        disabledDate={(current) => {
                          return current && current < dayjs(offer.ouDate.orderDate);
                        }}
                        format={datePickerFormat}
                        className="gx-w-100"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="deliveryDateDescription"
                      label="Açıklama"
                      rules={[
                        {
                          required: true,
                          message: 'Açıklama giriniz',
                        },
                        {
                          min: 100,
                          message: 'Açıklama en az 100 karakter olmalıdır',
                        },
                      ]}
                    >
                      <TextArea
                        placeholder="Açıklama giriniz"
                        showCount
                        rows={4}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </div>
          )}
        </Form>

        <div className="gx-d-flex">
          <Button
            className="gx-text-uppercase"
            onClick={() => setModalVisible(false)}
            disabled={busy}
            block
          >
            <FormattedMessage id="appModule.no" />
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            className="gx-text-uppercase"
            form="articleOrderDateChangeForm"
            loading={busy}
            block
          >
            <FormattedMessage id="appModule.yes" />
          </Button>
        </div>
      </Modal>
    </>
  );
};
