import { useState } from 'react';

import { Button } from 'antd';
import SavingContract from 'components/Modal/SavingContract';

const SavingContractAction = ({ articleLength, authors, status, offerID, articleLengthCustom }) => {
  const [savingContract, setSavingContract] = useState(false);
  return (
    <>
      <Button
        type="default"
        block
        className="modalBtnStyle"
        onClick={() => setSavingContract(true)}
      >
        TASARRUF SÖZLEŞMESİ
      </Button>
      <SavingContract
        open={savingContract}
        onCancel={() => setSavingContract(false)}
        offerStatus={status}
        authors={authors}
        offerID={offerID}
        destroyOnClose
        articleLength={articleLength}
        articleLengthCustom={articleLengthCustom}
      />
    </>
  );
};

export default SavingContractAction;
