import { useState } from 'react';

import { Button, Card } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useTimeLine } from 'useSWR';
import { useWindowSize } from 'hooks';
import { CircularProgress, CustomScrollbars } from 'components';
import { BranchesOutlined, TableOutlined } from '@ant-design/icons';

import TimeLineComponent from './TimeLineComponent';
import TimeLineTable from './TimeLineTable';

const TimeLine = ({ id }) => {
  const { timeScale, isLoading } = useTimeLine(id);
  const { height } = useWindowSize();
  const [showTable, setShowTable] = useState(true);

  if (isLoading) return <CircularProgress />;

  return (
    <Card
      title={
        <h2>
          <FormattedMessage id="article.detail.timeLine.Title" />
        </h2>
      }
      extra={
        <Button
          className="gx-mb-0"
          onClick={() => setShowTable(!showTable)}
        >
          {showTable ? (
            <>
              <BranchesOutlined /> Grafiksel Görünüm
            </>
          ) : (
            <>
              <TableOutlined /> Tablo Görünümü
            </>
          )}
        </Button>
      }
      className="gx-mx-5"
    >
      <CustomScrollbars
        autoHeight
        autoHeightMin={100}
        autoHeightMax={height - 360}
      >
        {showTable ? <TimeLineTable timeScale={timeScale?.['hydra:member']} /> : <TimeLineComponent timeScale={timeScale?.['hydra:member']} />}
      </CustomScrollbars>
    </Card>
  );
};

export default TimeLine;
