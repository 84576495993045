import { useState, useEffect } from 'react';

import { Collapse, Select, Form, Input } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useCountries } from 'useSWR';

const { Panel } = Collapse;
const { TextArea } = Input;
const { Option } = Select;

export default ({ title, prefix, editable, copyAddress }) => {
  const { formatMessage } = useIntl();
  const form = Form.useFormInstance();
  const { countries, isLoading: loadingCountries } = useCountries();

  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedCity, setSelectedCity] = useState();

  const countryKey = form.getFieldValue(`${prefix}country`);
  const cityKey = form.getFieldValue(`${prefix}city`);

  useEffect(() => {
    const country = countries.find((c) => c['@id'] === countryKey);
    const city = country?.cities?.find((c) => c['@id'] === cityKey || c.name === cityKey);
    setSelectedCountry(country);
    setSelectedCity(city);
  }, [cityKey, countryKey, countries, prefix]);

  useEffect(() => {
    if (copyAddress?.country && copyAddress?.city) {
      const country = countries.find((c) => c['@id'] === copyAddress.country);
      const city = country?.cities?.find((c) => c['@id'] === copyAddress.city || c.name === copyAddress.city);
      setSelectedCountry(country);
      setSelectedCity(city);
    }
  }, [countries, prefix, copyAddress]);

  const handleSelectCountry = (value) => {
    form.setFieldsValue({
      [`${prefix}country`]: value,
      [`${prefix}city`]: '',
      [`${prefix}district`]: '',
    });
    const country = countries.find((c) => c['@id'] === form.getFieldValue(`${prefix}country`));
    setSelectedCountry(country);
  };
  const handleSelectCity = (value) => {
    form.setFieldsValue({
      [`${prefix}city`]: value,
      [`${prefix}district`]: '',
    });
    const country = countries.find((c) => c['@id'] === form.getFieldValue(`${prefix}country`));
    const city = country?.cities?.find((c) => c['@id'] === form.getFieldValue(`${prefix}city`) || c.name === form.getFieldValue(`${prefix}city`));
    setSelectedCity(city);
  };

  return (
    <Collapse
      expandIconPosition="right"
      bordered={false}
      defaultActiveKey={[`${title}-contact`]}
    >
      <Panel
        header={<h4 className="gx-m-0">{title}</h4>}
        key={`${title}-contact`}
        className="gx-mb-3"
      >
        <Form.Item
          name={`${prefix}country`}
          label={<FormattedMessage id="author.info.country" />}
          className="gx-mb-2"
          rules={[
            {
              required: true,
              message: (
                <FormattedMessage
                  id="appModule.requiredFieldMessage"
                  values={{
                    field: formatMessage({ id: 'author.info.country' }),
                  }}
                />
              ),
            },
          ]}
        >
          <Select
            showSearch
            loading={loadingCountries}
            filterOption={(input, option) => option.props.children.toLowerCase().includes(input.toLowerCase())}
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            disabled={!editable}
            onChange={handleSelectCountry}
          >
            {countries?.map((country) => (
              <Option
                key={country['@id']}
                value={country['@id']}
              >
                {country.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name={`${prefix}city`}
          label={<FormattedMessage id="author.info.city" />}
          className="gx-mb-2"
          rules={[
            {
              required: true,
              message: (
                <FormattedMessage
                  id="appModule.requiredFieldMessage"
                  values={{
                    field: formatMessage({ id: 'author.info.city' }),
                  }}
                />
              ),
            },
          ]}
        >
          {selectedCountry?.cities?.length > 0 ? (
            <Select
              showSearch
              loading={loadingCountries}
              filterOption={(input, option) => option.props.children.toLowerCase().includes(input.toLowerCase())}
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              disabled={!editable}
              onChange={handleSelectCity}
            >
              {selectedCountry?.cities?.map((city) => (
                <Option
                  key={city['@id']}
                  value={city['@id']}
                >
                  {city.name}
                </Option>
              ))}
            </Select>
          ) : (
            <Input disabled={!editable} />
          )}
        </Form.Item>

        <Form.Item
          name={`${prefix}district`}
          label={<FormattedMessage id="author.info.district" />}
          className="gx-mb-2"
          rules={[
            {
              required: true,
              message: (
                <FormattedMessage
                  id="appModule.requiredFieldMessage"
                  values={{
                    field: formatMessage({ id: 'author.info.district' }),
                  }}
                />
              ),
            },
          ]}
        >
          {selectedCountry?.cities?.length > 0 ? (
            <Select
              showSearch
              loading={loadingCountries}
              filterOption={(input, option) => option.props.children.toLowerCase().includes(input.toLowerCase())}
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              disabled={!editable}
            >
              {selectedCity?.districts?.map((district) => (
                <Option
                  key={district['@id']}
                  value={district['@id']}
                >
                  {district.name}
                </Option>
              ))}
            </Select>
          ) : (
            <Input disabled={!editable} />
          )}
        </Form.Item>

        <Form.Item
          name={`${prefix}description`}
          label={<FormattedMessage id="author.info.address" />}
          className="gx-mb-2"
        >
          <TextArea
            autoSize={{ minRows: 5, maxRows: 7 }}
            disabled={!editable}
          />
        </Form.Item>
      </Panel>
    </Collapse>
  );
};
