import { Card } from 'antd';
import { FormattedMessage } from 'react-intl';
import { UserOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { contributorWorks } from 'utils';
import { CircularProgress } from 'components';

export default ({ opinions }) => {
  if (!opinions) {
    return <CircularProgress />;
  }

  return (
    <Card
      title={
        <h2 className="gx-h2-lg gx-mb-0">
          <FormattedMessage id="article.opinion.title" />
        </h2>
      }
    >
      {opinions.map((opinion) => (
        <Card
          key={opinion.id}
          className={`opinion-card ${opinion.opinion || 'notAnswered'}`}
        >
          <div className="gx-d-flex gx-justify-content-between gx-align-items-center">
            <p className="gx-font-weight-semi-bold">{opinion.committee.name}</p>
            <p className="opinion-time">
              {opinion.opinionDate ||
              dayjs(opinion.lastOpinionDate).isBefore(Date.now()) ? (
                dayjs(opinion.opinionDate ?? opinion.lastOpinionDate).format(
                  'DD MMMM YYYY - HH:mm'
                )
              ) : (
                <FormattedMessage
                  id="article.opinion.remainingDateTime"
                  values={{
                    time: dayjs(opinion.lastOpinionDate).format(
                      'DD MMMM YYYY - HH:mm'
                    ),
                  }}
                />
              )}
            </p>
          </div>

          <ul className="gx-mb-3 gx-pl-3">
            {opinion.type.map((type) => (
              <li key={type}>{contributorWorks[type]}</li>
            ))}
          </ul>

          {opinion.opinion === 'rejected' && (
            <Card className="opinion-card-inner">
              <div className="gx-d-flex">
                <div className="gx-mr-3">
                  <UserOutlined style={{ fontSize: 18 }} />
                </div>
                <div className="gx-word-break-word">{opinion.description}</div>
              </div>
            </Card>
          )}

          <p
            className={`gx-d-inline-block opinion-button ${
              opinion.opinion || 'notAnswered'
            }`}
          >
            <FormattedMessage
              id={`article.status.${opinion.opinion || 'notAnswered'}`}
            />
          </p>
        </Card>
      ))}
    </Card>
  );
};
