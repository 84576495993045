import { Button, Col, Form, Input, Row } from 'antd';
import { useIntl } from 'react-intl';
import { useUpdateDisposalContactConfigMutation } from 'services';
import { openNotification } from 'utils';
const DisposalContractGeneralSettings = ({ data, handleNextStep }) => {
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const [updateDisposalContactConfig] = useUpdateDisposalContactConfigMutation();

  const onFinish = async (values) => {
    const { error } = await updateDisposalContactConfig({
      id: data.id,
      body: {
        wordPrice: values.wordPrice,
        revisionPrice: values.revisionPrice,
        pricePercent: Number(values.pricePercent),
        boardDecision: values.boardDecision || null,
      },
    });
    if (!error) {
      handleNextStep(1);
    }

    openNotification({
      message: formatMessage({
        id: 'appModule.actionSuccessfully',
      }),
    });
  };
  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onFinish}
      initialValues={{
        ...data,
      }}
      className="gx-mt-5"
    >
      <Row
        style={{
          flexDirection: 'row',
        }}
        gutter={[24, 6]}
      >
        <Col md={8}>
          <Form.Item
            name="wordPrice"
            label="Kelime Ücreti (Telif)"
            rules={[
              {
                required: true,
                validator: (_, value) => {
                  if (!value) {
                    return Promise.reject('Bu alan zorunludur');
                  }
                  if (Number(value) === 0) {
                    return Promise.reject('Kelime Ücreti(Telif) sıfır olamaz');
                  }
                  if (Number(value) < 0) {
                    return Promise.reject('Kelime Ücreti(Telif) negatif değer olamaz');
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
        </Col>
        <Col md={8}>
          <Form.Item
            name="revisionPrice"
            label="Kelime Ücreti (Tashih/Redaksiyon)"
            rules={[
              {
                required: true,
                validator: (_, value) => {
                  if (!value) {
                    return Promise.reject('Bu alan zorunludur');
                  }
                  if (Number(value) === 0) {
                    return Promise.reject('Kelime Ücreti (Tashih/Redaksiyon) ücreti sıfır olamaz');
                  }
                  if (Number(value) < 0) {
                    return Promise.reject('Kelime Ücreti (Tashih/Redaksiyon) ücreti negatif değer olamaz');
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
        </Col>
        <Col md={8}>
          <Form.Item
            name="pricePercent"
            label="Telif Yüzdesi"
            rules={[
              {
                required: true,
                validator: (_, value) => {
                  if (!value) {
                    return Promise.reject('Bu alan zorunludur');
                  }
                  if (Number(value) === 0) {
                    return Promise.reject('Telif Yüzdesi sıfır olamaz');
                  }
                  if (Number(value) < 0) {
                    return Promise.reject('Telif Yüzdesi negatif değer olamaz');
                  }
                  if (Number(value) > 100) {
                    return Promise.reject('Telif Yüzdesi %100 den büyük olamaz');
                  }
                  if (Number(value) % 1 !== 0) {
                    return Promise.reject('Telif Yüzdesi tam sayı olmalıdır');
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input addonAfter="%" />
          </Form.Item>
        </Col>
        <Col md={24}>
          <Form.Item
            name="boardDecision"
            label="Yönetim Kurulu Kararı"
            rules={[
              {
                min: 100,
                message: 'en az 100 karakter olabilir',
              },
              {
                max: 1000,
                message: 'en fazla 1000 karakter olabilir',
              },
            ]}
            normalize={(value) => (value.trim() === '' ? undefined : value)}
          >
            <Input.TextArea rows={5} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Button
            block
            className="gx-mt-3"
            htmlType="submit"
            type="primary"
          >
            Kaydet
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default DisposalContractGeneralSettings;
