import React, { useState } from 'react';

import { Col, Row } from 'antd';
import { mutate } from 'swr';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';
import { FETCH_RESULTS, sendOffers } from 'fetcher';
import { useAlertLeavingPage } from 'hooks';
import { CircularProgress, Form, Title } from 'components';
import { checkFilesSize, openNotification } from 'utils';
import { useUserInfo } from 'providers/UserInfoProvider';
import { fetchError } from 'slices/commonSlice';

import ArticleDetection from '../../module/Form/ArticleDetection';

const NewDetectionForm = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { isLoading } = useUserInfo();

  const valuesFromLocalStorage = JSON.parse(sessionStorage.getItem('maddeTespitFormu'));
  //Formdan ayılırken alert veren hook
  useAlertLeavingPage();

  const [activeTab, setActiveTab] = useState('1');
  const [buttonLoading, setButtonLoading] = useState(false);
  const [fileList, setFileList] = useState([]);

  const onFinish = async (values) => {
    setButtonLoading(true);
    const committeeID = values.ownerCommittee.split('/')[3];
    try {
      checkFilesSize(fileList);
      const result = await sendOffers(
        {
          ...values,
          ...(Array.isArray(values.oldArticleWorkType)
            ? { oldArticleWorkType: values.oldArticleWorkType }
            : { oldArticleWorkType: values.rewriteandUpdate === 'Yeniden Yazım' ? [0] : [] }),
          ...(Array.isArray(values.authors) && { authors: values.authors }),
          oldTitle: values?.oldTitle?.value ?? null,
          oldArticles: values.oldArticles?.map((item) => item.value) ?? [],
        },
        fileList
      );

      // Check if article created
      if (result.status !== FETCH_RESULTS.CREATED) {
        throw result.status;
      }

      sessionStorage.removeItem('maddeTespitFormu');
      openNotification({
        message: formatMessage({ id: 'appModule.actionSuccessfully' }),
      });
      await mutate(`/article_offers?&committee=${committeeID}&isDraft=true&itemsPerPage=20&order[]=null`);
      navigate(`/madde-teklifleri/${committeeID}/taslak-maddeler?&page=1`);
    } catch (error) {
      dispatch(
        fetchError(
          formatMessage(
            {
              id: 'article.form.fetchError',
            },
            { error: `${error}` }
          )
        )
      );
      setButtonLoading(false);
    } finally {
      setButtonLoading(false);
      setFileList([]);
    }
  };

  const saveFieldsLocalStorage = (changedFields, allFields) => {
    const touchedAllFields = allFields
      .filter((field) => {
        if ((field?.name.includes('committeeContributions') && typeof field.value?.[0] !== 'object') || field.name?.length > 1) {
          return false;
        }
        return field;
      })
      ?.map((data) => {
        return {
          name: data.name[0],
          value: data.value ?? null,
        };
      });

    const reducedField = touchedAllFields.reduce(
      (
        obj,
        item // eslint-disable-next-line
      ) => ((obj[item.name] = item.value), obj),
      {}
    );
    sessionStorage.setItem('maddeTespitFormu', JSON.stringify(reducedField));
  };

  if (isLoading) return <CircularProgress />;

  const handleActiveTab = (key) => {
    setActiveTab(key);
  };

  const firstTabErrorFields = [
    'ownerCommittee',
    'title',
    'articleType',
    'articleGroup',
    'articleLength',
    'oldTitle',
    'oldArticleWorkType',
    'articleSupervisor',
  ];

  const handleScrollErrorField = ({ errorFields }) => {
    if (errorFields.length > 0) {
      const errorField = errorFields[0].name[0];
      if (firstTabErrorFields.includes(errorField)) {
        console.log('triggered');
        setActiveTab('1');
      } else {
        setActiveTab('2');
      }
    }
  };

  return (
    <>
      <Title>{formatMessage({ id: 'article.form.detectionFormTitle' })}</Title>
      <h1 className="gx-h1-lg">
        <FormattedMessage id="article.form.detectionFormTitle" />
      </h1>
      <Row>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={20}
          xxl={20}
        >
          <Form
            form={form}
            onFinish={onFinish}
            layout={'vertical'}
            onFieldsChange={saveFieldsLocalStorage}
            initialValues={valuesFromLocalStorage}
            onFinishFailed={handleScrollErrorField}
            scrollToFirstError
          >
            <ArticleDetection
              activeTab={activeTab}
              handleActiveTab={handleActiveTab}
              fileList={fileList}
              setFileList={setFileList}
              buttonLoading={buttonLoading}
            />
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default NewDetectionForm;
