import { Dropdown } from 'antd';
import { SettingOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { logout } from 'fetcher';
import { useUserInfo } from 'providers/UserInfoProvider';

const UserInfo = () => {
  const navigate = useNavigate();
  const { user } = useUserInfo();

  const signOut = async () => {
    await logout();
    navigate('/giris');
  };

  const items = [
    {
      key: 'kullanici-bilgileri',
      icon: <SettingOutlined />,
      label: <Link to="/kullanici-bilgileri">Kullanıcı Ayarları</Link>,
    },
    {
      key: 'logout',
      icon: <LogoutOutlined />,
      label: 'Çıkış Yap',
      onClick: signOut,
    },
  ];

  return (
    <Dropdown
      placement="bottomRight"
      menu={{ items }}
      trigger="click"
    >
      <span className="gx-avatar-name gx-text-center">
        <UserOutlined style={{ marginRight: 10 }} />
        {user?.fullName ? user?.fullName : ''}
        <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
      </span>
    </Dropdown>
  );
};

export default UserInfo;
