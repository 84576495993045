import { Input as AntInput } from 'antd';
import { useKeyboard } from 'hooks';

import { Search } from './KeyboardFormSearch';
import { TextArea } from './KeyboardFormTextArea';

export const Input = ({ onFocus, ...props }) => {
  const { setInputName, setValue } = useKeyboard();

  const onFocusChange = (event) => {
    const inputName = props.id;

    setInputName(inputName);
    setValue(props.value);
    if (onFocus) {
      onFocus(event);
    }
  };

  return (
    <AntInput
      onFocus={onFocusChange}
      {...props}
    />
  );
};

Input.TextArea = TextArea;
Input.Search = Search;
Input.Password = AntInput.Password;
Input.Group = AntInput.Group;
