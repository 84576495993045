import { useEffect, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

/**
 * Dönüşüm ihtiyacı olan keyler ve dönüşümü uygulayacak method
 */
const convert = {
  page: Number,
  pageSize: Number,
};

export const useFilters = (defaultFilters) => {
  const [searchParams, setSearchParams] = useSearchParams();

  /**
   * Filtreler defaultFilters ve searchParams objesinden gelen verinin birleşmesi ile oluştuğu için
   * filtre değerleri her zaman string oluyor. Bazı filtrelerin spesifik veri tiplerinde olmaları gerektiği
   * için dönüştürme ihtiyacı olan keyler convert objesi kullanılarak istenen tipe dönüştürülür.
   */
  const paramObject = Object.fromEntries(searchParams.entries());
  const fixedParams = Object.fromEntries(Object.entries(paramObject).map(([k, v]) => [k, Object.keys(convert).includes(k) ? convert[k](v) : v]));

  const [filters, setFilters] = useState({ ...defaultFilters, ...fixedParams });

  const filtered = Object.keys(filters).find((k) => !Object.keys(defaultFilters).includes(k));

  useEffect(() => {
    setSearchParams({ ...searchParams, ...filters });
  }, [filters, searchParams, setSearchParams]);

  return {
    filtered,
    filters,
    setFilters,
    searchParams,
  };
};
