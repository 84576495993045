import { useState } from 'react';

import { Button, Radio, Form, Input } from 'antd';
import { FieldTimeOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { sendCommitteeOpinion } from 'fetcher';
import { openNotification } from 'utils';
import { fetchError } from 'slices/commonSlice';

const { TextArea } = Input;

export default ({ opinion }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const { formatMessage } = useIntl();

  const [loading, setLoading] = useState(false);

  const initialValues = {
    opinion: null,
    description: '',
  };

  const onFinish = async (values) => {
    setLoading(true);

    try {
      await sendCommitteeOpinion(opinion.id, values);

      openNotification({
        message: formatMessage({ id: 'appModule.actionSuccessfully' }),
      });

      navigate('/madde-teklifleri/tum-maddeler');
    } catch (error) {
      dispatch(fetchError(formatMessage({ id: 'article.form.fetchError' }, { error: `${error}` })));
    }

    setLoading(false);
  };

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <Form.Item shouldUpdate>
        {({ getFieldValue }) => (
          <>
            <Form.Item name="opinion">
              <Radio.Group className="contrib-request-form">
                <Radio.Button
                  value="approved"
                  className={`gx-mr-3 ${getFieldValue('opinion') === 'approved' ? 'success-filled' : 'success'}`}
                >
                  <FormattedMessage id="appModule.accept" />
                </Radio.Button>

                <Radio.Button
                  value="rejected"
                  className={`${getFieldValue('opinion') === 'rejected' ? 'danger-filled' : 'danger'}`}
                >
                  <FormattedMessage id="appModule.reject" />
                </Radio.Button>
              </Radio.Group>
            </Form.Item>

            {getFieldValue('opinion') === 'rejected' && (
              <Form.Item
                name="description"
                label={<FormattedMessage id="article.decision.reasonTitle" />}
                rules={[
                  {
                    required: true,
                    message: formatMessage(
                      { id: 'appModule.requiredFieldMessage' },
                      {
                        field: formatMessage({
                          id: 'article.decision.reasonTitle',
                        }),
                      }
                    ),
                  },
                  {
                    min: 100,
                    message: formatMessage({
                      id: 'article.reasonLengthMessage',
                    }),
                  },
                ]}
              >
                <TextArea
                  className="gx-mb-4"
                  placeholder={formatMessage({
                    id: 'article.decision.reasonPlaceholder',
                  })}
                  rows={5}
                  showCount
                />
              </Form.Item>
            )}

            <div className="gx-d-flex gx-justify-content-between gx-align-items-center gx-mt-2">
              <div className="gx-d-flex gx-align-items-center">
                <FieldTimeOutlined style={{ fontSize: 20 }} />
                <span className="gx-ml-1">
                  <FormattedMessage
                    id="article.contribution.remaining"
                    values={{
                      time: dayjs(opinion.lastOpinionDate).format('DD MMMM YYYY HH:mm'),
                    }}
                  />
                </span>
              </div>

              <Button
                type="primary"
                className="gx-mb-0 gx-px-4"
                htmlType="submit"
                disabled={!getFieldValue('opinion') || (getFieldValue('opinion') === 'rejected' && !getFieldValue('description'))}
                loading={loading}
              >
                <FormattedMessage id="form.send" />
              </Button>
            </div>
          </>
        )}
      </Form.Item>
    </Form>
  );
};
