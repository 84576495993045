import CommiteeDecisionForm from './CommitteeDecisionForm';

const CommiteeRejectDecision = ({ step, offerID, status }) => {
  return (
    <CommiteeDecisionForm
      offerID={offerID}
      step={step}
      status={status}
    />
  );
};

export default CommiteeRejectDecision;
