/* eslint-disable react/no-deprecated */
import React from 'react';

import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';

import App from './App';

Sentry.init({
  dsn: 'https://bb22f6d97cb3c40e4bb150db26675552@sentry.90pixel.net/28',
  tracesSampleRate: 1,
  debug: false,
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
});

ReactDOM.render(<App />, document.getElementById('root'));
