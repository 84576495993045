import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, redirectToLogin: window.location.pathname === '/giris' };
  }

  static getDerivedStateFromError(error) {
    console.log(error);
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError && this.state.redirectToLogin) {
      return this.props.fallback;
    }

    return this.props.children;
  }
}
export default ErrorBoundary;
