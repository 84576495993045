import { Modal, Table, Tooltip } from 'antd';
import { CheckCircleOutlined, ClockCircleOutlined } from '@ant-design/icons';

const column = [
  {
    key: 'id',
    title: 'Heyet Adı',
    dataIndex: ['committee', 'name'],
  },
  {
    key: 'id',
    title: 'Karar Durumu',
    align: 'center',
    dataIndex: 'isDecided',
    render: (isDecided) => (
      <Tooltip title={isDecided ? 'Karar Verildi' : 'Karar Bekliyor'}>
        {isDecided ? <CheckCircleOutlined style={{ color: 'green' }} /> : <ClockCircleOutlined style={{ color: 'orange' }} />}
      </Tooltip>
    ),
  },
];

const DecisionAskedModal = ({ data, ...other }) => {
  return (
    <Modal
      {...other}
      bodyStyle={{ paddingTop: '3rem' }}
      footer={null}
    >
      <Table
        columns={column}
        dataSource={data}
        pagination={false}
        rowKey="id"
      />
    </Modal>
  );
};

export default DecisionAskedModal;
