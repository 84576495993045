import { useState } from 'react';

import { Card, Col, Collapse, Divider, Row } from 'antd';
import { FormattedMessage } from 'react-intl';
import StartProcessStepActions from 'components/ArticleOrder/status/StartProcessStepActions.jsx';
import SendItemSteps from 'components/ArticleOrder/status/SendItemSteps';
import { useGetArticleOfferProcessQuery, useGetAskedCommitteesQuery, useGetCurrentUserQuery, useGetDecidedCommitteesQuery } from 'services';
import { CircularProgress } from 'components/CircularProgress';
import { useUserHasPermission } from 'hooks';
import StatusBar from 'components/StatusBar';
import { article_detection_decisions, article_order_status } from 'constant/static';
import RevertAnswerItem from 'components/ArticleOffer/offer/ArticleStatus/RevertAnswerItem';
import dayjs from 'dayjs';
import ReorderProcessCancel from 'components/ReorderProcess/ReorderProcessCancel';
import ReorderCommitteeActions from 'components/ReorderProcess/ReorderCommitteeActions';

import { OrderDetailGrid } from './OrderDetailGrid';
import { OrderDetailDescriptions } from './OrderDetailDescriptions';
import { OrderDetailAddendum } from './OrderDetailAddendum';
import { OrderAgreement } from './OrderAgreement';
import { ArticleOrderStatus } from './ArticleOrderStatus';
import OrderInterviewHistory from './OrderInterviewHistory';
import { AuthorDecision, StartOrderProcess } from './status';
import ArticleOrderMedia from './ArticleOrderMedia';
import SendToCommitteeActions from './status/SendToCommitteeActions';
import CommiteeDecisionActions from './status/CommiteeDecisionActions';
import { ArticleInterviewNote } from './ArticleInterviewNote/ArticleInterviewNote';
import GiveCommitteeOpinionAction from './status/GiveCommitteeOpinionAction/GiveCommitteeOpinionAction';
import ArticleOrderAdditionalNote from './ArticleOrderAdditionalNote';
import SavingContractAction from './SavingContractAction/SavingContract';
import ActivityHistory from './ActivityHistory';
import ComitteeComments from './ComitteeComments';
import ArticleVersionChanger from './ArticleVersionChanger/ArticleVersionChanger';

const { Panel } = Collapse;

export const ArticleOrderDetail = ({ offer }) => {
  const { data: current_user } = useGetCurrentUserQuery();
  const { handlePermission, handleUserIsOnCommittee, authorHasPermission } = useUserHasPermission();
  const { data: processTrack, isLoading: processTrackLoading } = useGetArticleOfferProcessQuery(offer.id);
  const { data, isLoading, isError } = useGetDecidedCommitteesQuery(offer && offer.id, {
    skip: (offer && !offer.id) || !offer?.ihControlStatus,
  });
  const {
    data: askedCommitees,
    isLoading: askedCommiteesLoading,
    isError: askedCommiteesError,
  } = useGetAskedCommitteesQuery(offer && offer.id, {
    skip: (offer && !offer.id) || !offer?.ihControlStatus,
  });

  const decidedCommittee = data?.decisions.filter((item) => item.isDecided === true);
  // TODO: need refactoring
  const [modalProps, setModalProps] = useState({
    visible: false,
    data: null,
  });

  const isUserOnCommittee = handleUserIsOnCommittee(offer && offer.ownerCommittee.id);

  const handleModalProps = (visible, data) => {
    setModalProps({ visible, data });
  };

  //ih goruslerinin gozukmesi icin gerekli olan izinler
  const isCommitteeDesionShown = () => {
    const hasIhControlStatus = offer?.ihControlStatus;
    const hasControlDescription = offer?.ihControlDescription || offer?.ihControlMediaOfferDescription;
    const isAuthorizedUser =
      current_user.isOrderUnit || current_user.isAdmin || current_user.isDAH || current_user.isAYK || handlePermission(offer?.ownerCommittee?.id);

    return hasIhControlStatus && hasControlDescription && isAuthorizedUser;
  };

  // iptal asamasindakl siparis birimi iptal gerekcelerini gorme izni
  const canShowOrderUnitCancelReason = () => {
    return current_user.isAdmin || current_user.isOrderUnit || isUserOnCommittee;
  };

  // siparis birimi iptal asamasinda sorumlu ilim heyetinin verdigi karar statusu
  const handleStatusName = () => {
    const status = offer && offer.ownerCommitteeStatus;
    switch (status) {
      case article_detection_decisions.red:
        return 'Ret';
      case article_detection_decisions.yeniden_telif:
        return 'Yeniden Telif';
      case article_detection_decisions.yeniden_siparis:
        return 'Yeniden Sipariş';
      default:
        break;
    }
  };

  /**
   * madde versiyonları arası navigasyon işlemi burada kontrol ediliyor
   */

  const authorNote = processTrack?.filter((item) => ['approved', 'rejected'].includes(item.opinion) && item.opinionDescription !== null);

  /**
   * Sistemdeki yazarın madde yazarı olup olmadığı kontrolü
   */
  const authorPermission = authorHasPermission(offer && offer.authors);
  if (isLoading || askedCommiteesLoading) return <CircularProgress />;

  /**
   * Görüş istenen heyetlerin id leri
   */
  const decidedCommitteeID = data?.decisions.map((item) => {
    return item.committee.id;
  });

  const isUserDecide = handlePermission(decidedCommitteeID);
  const isAuthorDecideAndOnNextStep = processTrack?.some(
    (item) => ['rejected', 'notAnswered'].includes(item.opinion) && item.author.user.id === current_user.id
  );

  const isItemRejectedOrNotAnswered = processTrack?.some((item) => ['rejected', 'notAnswered'].includes(item.opinion));

  // Madde iade durumunu burada kontrol ediliyor
  const isItemRevert = offer.status === article_order_status['Yazar-Gündemde'] && offer.ihControlStatus;
  return (
    <>
      <ArticleVersionChanger offer={offer} />
      {(authorPermission || current_user?.isOrderUnit || current_user?.isAdmin) &&
        [article_order_status['Yazar-Gündemde'], article_order_status['Yazar-Onay'], article_order_status['SB-Gündemde']].includes(offer?.status) &&
        !processTrackLoading && (
          <ArticleOrderStatus
            processData={processTrack}
            offer={offer}
          />
        )}
      {/**
       * Yazarın Maddeyi Onaylarken/Reddederken ilettiği not
       */}
      {authorNote?.map((item) => (
        <Card
          key={item.id}
          className={`${item.author.user.id === current_user.id || current_user.isOrderUnit || current_user.isAdmin ? 'gx-mb-3' : 'gx-display-none '}`}
        >
          <Collapse className="commitment-agreement">
            <Panel
              key={item.id}
              header={<h2>{`Yazarın ${item.opinion === 'approved' ? 'onay' : 'reddetme'} notu (${item.author.user.fullName})`}</h2>}
              extra={<div className="gx-d-flex gx-align-items-center">{dayjs(item.opinionDate).format('DD.MM.YYYY HH:mm')}</div>}
            >
              {item.opinionDescription}
            </Panel>
          </Collapse>
        </Card>
      ))}
      {(current_user?.isAuthor || current_user?.isOrderUnit || current_user?.isAdmin) &&
        [article_order_status['Yazar-Onay'], article_order_status['Yazar-Gündemde']].includes(offer?.status) && (
          <ArticleOrderAdditionalNote id={offer && offer.id} />
        )}

      {/* Madde Sipariş birimine geçince statü 19 oluyor sonraki bütün süreçlerde gözükmesi durumu için bu tarz bir kontrol yapıldı*/}
      {(offer?.status >= article_order_status['IH - Taslak'] ||
        [article_order_status.Onaylandı, article_order_status.Reddedildi].includes(offer && offer.status)) &&
        (current_user?.isOrderUnit || current_user?.isAdmin) && (
          <Card className="gx-p-3">
            <OrderInterviewHistory offerID={offer && offer.id} />
          </Card>
        )}

      {/* Madde Sipariş birimine geçince statü 19 oluyor  sonraki bütün süreçlerde gözükmesi durumu için bu tarz bir kontrol yapıldı*/}
      {offer?.status >= article_order_status['IH - Taslak'] && (current_user.isOrderUnit || current_user.isAdmin) && (
        <ArticleInterviewNote id={offer && offer['@id']} />
      )}

      <h2 className="gx-h2-lg">
        <FormattedMessage id="article.steps.ArticleOffer" />
      </h2>
      <Card className="gx-p-3">
        <StatusBar status={offer && offer.status} />
        <OrderDetailGrid offer={offer} />
        <OrderDetailDescriptions offer={offer} />
        <OrderDetailAddendum offer={offer} />
        {(current_user.isOrderUnit || current_user.isAdmin || current_user.isComittee) &&
          (offer.status >= article_order_status['SB-Madde Kontrol'] || article_order_status.Onaylandı || article_order_status.Reddedildi) && (
            <ActivityHistory id={offer && offer.id} />
          )}
        {offer?.revertReasons?.committee_control?.length > 0 && (
          <Card>
            <Collapse className="commitment-agreement decision">
              <Panel header={<h4>{offer.ihControlStatus === 3 ? 'İade' : 'Ret'} Gerekçesi</h4>}>
                <ul>
                  {offer.revertReasons.committee_control.map((item) => (
                    <RevertAnswerItem
                      key={item.id}
                      title={item.revertReason.title}
                      answer={item.answer}
                    />
                  ))}
                </ul>
              </Panel>
            </Collapse>
          </Card>
        )}
        {/* iptal asamasindaki maddenin siparis birimi tarafindan iptal edilme gerekceleri */}
        {offer?.revertReasons?.ou?.length > 0 && article_order_status.Iptal && canShowOrderUnitCancelReason() && (
          <Card>
            <Collapse className="commitment-agreement decision">
              <Panel header="Sipariş birimi iptal Gerekçesi">
                <ul>
                  {offer.revertReasons.ou.map((item) => (
                    <RevertAnswerItem
                      key={item.id}
                      title={item.revertReason.title}
                      answer={item.answer}
                    />
                  ))}
                </ul>
              </Panel>
            </Collapse>
          </Card>
        )}
        {/* iptal asamasindayken sorumlu ilim heyetinin verdigi karar  */}
        {offer && offer.ownerCommitteeDescription && canShowOrderUnitCancelReason() && (
          <Card>
            <Collapse>
              <Panel header={`Sorumlu ilim heyeti ${handleStatusName()} kararı açıklaması`}>{offer.ownerCommitteeDescription}</Panel>
            </Collapse>
          </Card>
        )}
        {/**
         * Yazarın gönderdiği içerikler
         */}

        {(current_user.isOrderUnit ||
          current_user.isAdmin ||
          authorPermission ||
          current_user.isDAH ||
          current_user.isAYK ||
          handlePermission(offer && offer.ownerCommittee.id)) &&
          ([
            article_order_status.Onaylandı,
            article_order_status.Reddedildi,
            article_order_status['SB-Madde Kontrol'],
            article_order_status['IH-Madde Kontrol'],
            article_order_status['IH-Madde Kontrol Görüş İstedi'],
            article_order_status['IH-Madde Kontrol Yeniden Telif'],
          ].includes(offer && offer.status) ||
            offer.status > article_order_status['Telif Sonrasi'] ||
            isItemRevert) && (
            <ArticleOrderMedia
              isContentSend={true}
              isEncylopediaUnitControl={offer.status === article_order_status['Iptal']}
              modalProps={modalProps}
              handleModalProps={handleModalProps}
              offerID={offer.id}
            />
          )}
        <OrderAgreement offer={offer} />
        {/**
         * Heyet Görüşleri
         */}
        {(current_user.isOrderUnit || current_user.isAdmin || current_user.isDAH || current_user.isAYK || isUserDecide || isUserOnCommittee) &&
          decidedCommittee?.length > 0 && (
            <>
              <Divider />
              <ComitteeComments data={decidedCommittee} />
            </>
          )}
        {isCommitteeDesionShown() && (
          <>
            <h4>İlim Heyeti Açıklamaları</h4>
            <Collapse className="gx-mb-5">
              {offer?.ihControlMediaOfferDescription && (
                <Panel
                  header="Heyet Medya Kontrol Açıklaması"
                  key="ihControlMediaOfferDescription"
                >
                  {offer.ihControlMediaOfferDescription}
                </Panel>
              )}
              {offer.ihControlDescription && (
                <Panel
                  header="Heyet Kontrol Açıklaması"
                  key="ihControl"
                >
                  {offer.ihControlDescription}
                </Panel>
              )}
            </Collapse>
          </>
        )}
        {[article_order_status['Yazar-Onay'], article_order_status['Yazar-Gündemde']].includes(offer?.status) &&
          (current_user.isOrderUnit || current_user.isAdmin) && (
            <StartProcessStepActions
              title={offer && offer.title}
              authors={offer && offer.authors}
              id={offer && offer.id}
              orderDate={offer && offer.ouDate?.orderDate}
              deliveryDate={offer && offer.ouDate?.deliveryDate}
            />
          )}
        {(current_user?.isAdmin || current_user?.isOrderUnit) && offer?.status === article_order_status['SB-Gündemde'] && (
          <Row
            className="gx-mt-4 gx-mb-0"
            gutter={24}
          >
            {isItemRejectedOrNotAnswered && (
              <Col
                md={12}
                sm={24}
              >
                <ReorderProcessCancel
                  committee="ou"
                  offerID={offer && offer.id}
                />
              </Col>
            )}
            <Col
              md={isItemRejectedOrNotAnswered ? 12 : 24}
              sm={24}
            >
              <StartOrderProcess offer={offer} />
            </Col>
          </Row>
        )}
        {authorPermission && offer?.status === article_order_status['Yazar-Onay'] && !isAuthorDecideAndOnNextStep && <AuthorDecision offer={offer} />}
        {authorPermission && offer?.status === article_order_status['Yazar-Gündemde'] && (
          <SendItemSteps
            offerId={offer?.id}
            title={offer?.title}
            isAuthorPage={true}
          />
        )}
        {[
          article_order_status['Telif Sonrasi'],
          article_order_status['IH-Madde Kontrol Yeniden Telif'],
          article_order_status.Onaylandı,
          article_order_status.Reddedildi,
        ].includes(offer && offer.status) &&
          (current_user.isAdmin || current_user.isOrderUnit) && (
            <SavingContractAction
              status={offer && offer.status}
              articleLength={offer && offer.articleLength}
              offerID={offer && offer.id}
              authors={offer && offer.authors}
              articleLengthCustom={offer && offer.articleLengthCustom}
            />
          )}
        {(current_user.isAdmin || current_user.isOrderUnit) && offer?.status === 23 && (
          <SendToCommitteeActions
            ihControlStatus={offer && offer.ihControlStatus}
            offerId={offer && offer.id}
            step={offer && offer.step}
          />
        )}
        {article_order_status['Iptal'] === offer?.status && handlePermission(offer && offer.ownerCommittee.id) && (
          <ReorderCommitteeActions offerID={offer && offer.id} />
        )}
        {(handlePermission(offer && offer.ownerCommittee.id) || current_user.isAdmin) && offer?.status === 24 && (
          <CommiteeDecisionActions
            offerId={offer && offer.id}
            commiteeID={offer && offer.ownerCommittee && offer.ownerCommittee.id}
            step={offer && offer.step}
          />
        )}
        {offer?.status === article_order_status['IH-Madde Kontrol Görüş İstedi'] &&
        (!isError || !askedCommiteesError) &&
        isUserDecide &&
        askedCommitees?.decisions?.length > 1 ? (
          <GiveCommitteeOpinionAction committes={askedCommitees && askedCommitees.decisions} />
        ) : (
          offer?.status === article_order_status['IH-Madde Kontrol Görüş İstedi'] &&
          (!isError || !askedCommiteesError) &&
          isUserDecide &&
          askedCommitees?.decisions?.length === 1 &&
          !askedCommitees?.decisions?.[0]?.isDecided && <GiveCommitteeOpinionAction committes={askedCommitees && askedCommitees.decisions} />
        )}
      </Card>
    </>
  );
};
