import { Card, Col, Row } from 'antd';
import { DashboardOutlined, FileWordOutlined, HourglassOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import { useGetStiplatedTimeQuery } from 'services';

export const ArticleOrderSummary = ({ offer }) => {
  const { data: times } = useGetStiplatedTimeQuery();

  const stipulatedTime = times?.find((time) => time?.lengthKey === offer?.articleLength);

  return (
    <Row className="gx-my-3">
      <Col span={8}>
        <Card className="gx-m-0">
          <div className="gx-d-flex gx-justify-content-center gx-align-items-center">
            <FileWordOutlined className="gx-fs-xl gx-mr-2" />
            <FormattedMessage id="article.detail.articleLength" />
            <span>: {offer && offer.articleLengthCustom ? offer.articleLengthCustom : stipulatedTime?.lengthDisplay}</span>
          </div>
        </Card>
      </Col>
      <Col span={8}>
        <Card className="gx-m-0">
          <div className="gx-d-flex gx-justify-content-center gx-align-items-center">
            <DashboardOutlined className="gx-fs-xl gx-mr-2" />
            <FormattedMessage
              id="article.order.stipulatedTime"
              values={{ time: offer && offer.ouDate?.daysToDelivery + ' Gün' }}
            />
          </div>
        </Card>
      </Col>
      <Col span={8}>
        <Card className="gx-m-0">
          <div className="gx-d-flex gx-justify-content-center gx-align-items-center">
            <HourglassOutlined className="gx-fs-xl gx-mr-2" />
            <FormattedMessage id="article.order.deliveryDate" />
            <span>: {dayjs(offer && offer.ouDate?.deliveryDate).format('DD/MM/YYYY')}</span>
          </div>
        </Card>
      </Col>
    </Row>
  );
};
