import { useRef, useState } from 'react';

import { Button, Table, Row, Col, Tag, Input } from 'antd';
import { Link } from 'react-router-dom';
import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useIntl, FormattedMessage } from 'react-intl';
import { useAuthors, useCommittees } from 'useSWR';
import { useWindowSize } from 'hooks';
import { ROLES_DEF } from 'utils';
import { Title } from 'components';
import { useUserInfo } from 'providers/UserInfoProvider';

const { Search } = Input;

const NextPage = ({ filters }) => {
  const { page, pageSize } = filters;
  const { data: authors } = useAuthors({ page: page + 1, pageSize });
  return (
    <span
      className="gx-d-none"
      data-authors={authors}
    />
  );
};
const AuthorList = () => {
  const { formatMessage } = useIntl();

  const debounce = useRef();
  const [filters, setFilters] = useState({
    page: 1,
    pageSize: 20,
  });

  const { height } = useWindowSize();
  const { data: authors, isLoading } = useAuthors(filters);
  const { isAdmin, isManager } = useUserInfo();
  const { committees, isLoading: committeesLoading } = useCommittees('all');

  const pagination = {
    defaultCurrent: 1,
    defaultPageSize: 20,
    current: filters.page,
    pageSize: filters.pageSize,
    total: authors?.['hydra:totalItems'] ?? 1,
    position: ['bottomRight'],
    responsive: true,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    showTotal: (total) => `${formatMessage({ id: 'table.totalItem' }, { type: formatMessage({ id: 'author' }) })} ${total}`,
  };

  const columns = [
    {
      title: '#',
      width: 100,
      render: (_i, _r, index) => <span>{index + 1 + (filters.page - 1) * filters.pageSize}</span>,
    },
    {
      title: <FormattedMessage id="appModule.name" />,
      dataIndex: ['user', 'firstName'],
      width: 160,
    },
    {
      title: <FormattedMessage id="appModule.surname" />,
      dataIndex: ['user', 'lastName'],
      width: 160,
    },
    {
      title: <FormattedMessage id="appModule.email" />,
      dataIndex: ['user', 'email'],
      width: 250,
    },
    {
      title: <FormattedMessage id="appModule.role" />,
      dataIndex: ['user', 'roles'],
      width: 250,
      render: (dataIndex) =>
        dataIndex
          ?.filter((r) => r !== 'ROLE_USER')
          .map((item, idx) => (
            <div key={idx}>
              <Tag className="ant-tag-primary">{ROLES_DEF[item]}</Tag>
            </div>
          )),
      filters: Object.keys(ROLES_DEF).map((role) => ({
        text: ROLES_DEF[role],
        value: role,
      })),
    },
    {
      title: <FormattedMessage id="appModule.committees" />,
      dataIndex: ['user', 'memberships'],
      align: 'center',
      width: 150,
      render: (dataIndex) =>
        dataIndex && (
          <span title={`${dataIndex}`}>
            {dataIndex?.length === 0
              ? '-'
              : dataIndex.map((committeeName, i) => (
                  <Tag
                    title={committeeName.committee.name}
                    className="ant-tag-primary gx-mx-0 gx-my-1"
                    key={i}
                  >
                    {committeeName.committee.shortName}
                  </Tag>
                ))}
          </span>
        ),
      filters: committees?.map((committee) => ({
        text: committee.name,
        value: committee.shortName,
      })),
    },
    {
      title: <FormattedMessage id="appModule.situation" />,
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 150,
      render: (approved) => (
        <Tag
          className="gx-mx-0 gx-my-1"
          color={approved ? 'green' : 'red'}
        >
          <FormattedMessage id={approved ? 'appModule.approved' : 'appModule.unapproved'} />
        </Tag>
      ),
      filters: [
        {
          text: <FormattedMessage id="appModule.approved" />,
          value: 1,
        },
        {
          text: <FormattedMessage id="appModule.unapproved" />,
          value: 0,
        },
      ],
      filterMultiple: false,
    },
    {
      title: <FormattedMessage id="appModule.action" />,
      key: 'action',
      align: 'center',
      width: 70,
      render: (a) => (
        <Link to={`/yazar-islemleri/yazar-duzenle/${a.id}`}>
          <EditOutlined title="Düzenle" />
        </Link>
      ),
    },
  ];

  const searchName = (e) => {
    clearTimeout(debounce.current);

    debounce.current = setTimeout(() => {
      setFilters({ ...filters, page: 1, search: e.target.value });
    }, 500);
  };

  const handleTableChange = (tablePagination, tableFilters) => {
    const { current, pageSize } = tablePagination;
    const entries = Object.entries(tableFilters).map(([k, v]) => [k.replace('user.', ''), v]);
    const filterEntries = Object.fromEntries(entries);

    setFilters({
      ...filters,
      ...filterEntries,
      page: pageSize === filters.pageSize ? current : 1,
      pageSize,
    });
  };

  return (
    <>
      <Title>{formatMessage({ id: 'author.allAuthors' })}</Title>

      <Row
        justify="space-between"
        align="middle"
        className="gx-px-3"
      >
        <Col>
          <h1>
            <FormattedMessage id="author.allAuthors" />
          </h1>
        </Col>

        {(isAdmin || isManager) && (
          <Col>
            <Link to="/yazar-islemleri/yazar-ekle">
              <Button
                type="primary"
                icon={<PlusCircleOutlined />}
              >
                {formatMessage({ id: 'author.addAuthor' }, { to: 'Yazar Listesine' })}
              </Button>
            </Link>
          </Col>
        )}
      </Row>

      <Row>
        <Col
          xs={24}
          sm={14}
          md={11}
          lg={8}
        >
          <Search
            name="authorName"
            loading={isLoading}
            onChange={searchName}
            placeholder={formatMessage({ id: 'author.searchPlaceholder' })}
            enterButton
          />
        </Col>
      </Row>

      <Table
        bordered
        columns={columns}
        dataSource={authors?.['hydra:member']}
        rowKey={(record) => record['@id']}
        loading={isLoading || committeesLoading}
        scroll={{ x: 990, y: height - 410 }}
        pagination={pagination}
        onChange={handleTableChange}
      />
      <NextPage filters={filters} />
    </>
  );
};

export default AuthorList;
