import { useGetCurrentUserQuery } from 'services';

export const useUserHasPermission = () => {
  const { data: current_user } = useGetCurrentUserQuery();

  const handlePermission = (value) => {
    // Array.isArray kontrolü yapılarak gelen değer array ise value.includes(item.committee.id) ile kontrol yapılır bu hook genel yetki kontrolü de yaptığı için arary olarak gelmeme durumunda da doğru çalışması sağlandı.
    return Boolean(
      (current_user &&
        current_user.memberships
          .filter((item) => (Array.isArray(value) ? value.includes(item.committee.id) : item.committee.id === value))
          .find((item) => item.type === 0 || item.type === 1)) ||
        current_user.isAdmin
    );
  };
  const handleUserIsOnCommittee = (value) => {
    return Boolean((current_user && current_user.memberships.find((item) => item.committee.id === value)) || current_user.isAdmin);
  };

  const authorHasPermission = (authors) => {
    return current_user?.author && Boolean(authors.some((author) => author?.author?.id === current_user?.author.id));
  };

  return { handlePermission, handleUserIsOnCommittee, authorHasPermission };
};
