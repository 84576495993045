import apiInstance from './api';
const orderRevertReasonApi = apiInstance.injectEndpoints({
  endpoints: (build) => ({
    getAllOrderRevertReason: build.query({
      query: () => 'ou_revert_reasons',
      transformResponse: (response) => response['hydra:member'],
      providesTags: ['OrderRevertReasons'],
    }),
  }),
  overrideExisting: true,
});

export default orderRevertReasonApi;
