import { useState } from 'react';

import { Table, Button, Row, Col } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import { useWindowSize } from 'hooks';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { exportTable, ROLES_DEF } from 'utils';
import { CircularProgress } from 'components';
import { Title } from 'components';
import { useGetActivitiesQuery } from 'services';

const columns = [
  {
    title: <FormattedMessage id="logs.logId" />,
    dataIndex: 'id',
    width: 150,
    sorter: (a, b) => a.id - b.id,
  },
  {
    title: <FormattedMessage id="logs.logType" />,
    dataIndex: 'type',
    width: 150,
    sorter: (a, b) => a.type.localeCompare(b.type),
  },
  {
    title: <FormattedMessage id="logs.entityId" />,
    dataIndex: 'entityId',
    width: 150,
    sorter: (a, b) => a.entityId - b.entityId,
  },
  {
    title: <FormattedMessage id="logs.entityName" />,
    dataIndex: 'entityName',
    width: 150,
    sorter: (a, b) => a.entityName.localeCompare(b.entityName),
  },
  {
    title: <FormattedMessage id="logs.entityValue" />,
    dataIndex: 'entityValue',
    width: 150,
    sorter: (a, b) => a.type.localeCompare(b.type),
  },
  {
    title: <FormattedMessage id="logs.createdAt" />,
    dataIndex: 'createdAt',
    width: 130,
    sorter: (a, b) => new Date(a.createdAt) < new Date(b.createdAt),
    render: (createdAt) => dayjs(createdAt).format('DD/MM/YYYY - HH:mm'),
  },
  {
    title: <FormattedMessage id="logs.userId" />,
    dataIndex: ['user', 'id'],
    width: 150,
    sorter: (a, b) => a.user.id - b.user.id,
  },
  {
    title: <FormattedMessage id="logs.userFullName" />,
    dataIndex: ['user', 'fullName'],
    width: 150,
    sorter: (a, b) => a.user.fullName.localeCompare(b.user.fullName),
    render: (fullName) => {
      if (!fullName) return <FormattedMessage id="appModule.systemUser" />;
      return fullName;
    },
  },
  {
    title: <FormattedMessage id="logs.userRole" />,
    dataIndex: ['user', 'roles'],
    width: 150,
    render: (roles) => {
      if (!roles) return <FormattedMessage id="appModule.system" />;
      return roles?.map((role, index) => <div key={index}>{ROLES_DEF[role]}</div>);
    },
  },
];
const downloadUrl = '/activities/export?';
const Logs = () => {
  const { formatMessage } = useIntl();
  const { height } = useWindowSize();
  const navigate = useNavigate();

  const [pageOptions, setPageOptions] = useState({
    page: 1,
    pageSize: 20,
  });

  const { data, isLoading, isFetching, isSuccess } = useGetActivitiesQuery({
    page: pageOptions.page,
    pageSize: pageOptions.pageSize,
  });

  const pagination = {
    defaultCurrent: 1,
    defaultPageSize: 20,
    current: pageOptions.page,
    pageSize: pageOptions.pageSize,
    total: data?.['hydra:totalItems'] ?? 1,
    showTotal: (total) => `${formatMessage({ id: 'table.totalItem' }, { type: 'Log Kaydı' })} ${total}`,
    position: ['bottomRight'],
    responsive: true,
    pageSizeOptions: [10, 20, 50, 100],
    showSizeChanger: true,
    onChange: (page, pageSize) => {
      setPageOptions({ page, pageSize });
    },
    onShowSizeChange: (_, pageSize) => {
      setPageOptions({ page: 1, pageSize });
    },
  };

  const [wait, setWait] = useState(false);
  const handleExportTable = async () => {
    setWait(true);

    await exportTable(downloadUrl, 'Tüm Loglar');
    setWait(false);
  };

  if (isLoading) return <CircularProgress />;

  return (
    <>
      <Title>{formatMessage({ id: 'logs.allLogs' })}</Title>

      <Row className="gx-justify-content-between gx-mx-1">
        <Col>
          <h1>
            <FormattedMessage id="logs.allLogs" />
          </h1>
        </Col>
        <Col>
          <Button
            loading={wait}
            disabled={data['hydra:member']?.length === 0}
            onClick={handleExportTable}
            icon={<CloudDownloadOutlined />}
          >
            <FormattedMessage id="app.export" />
          </Button>
        </Col>
      </Row>
      <Table
        bordered
        loading={isFetching || !isSuccess}
        columns={columns}
        dataSource={data?.['hydra:member']}
        pagination={pagination}
        rowKey={(record) => record.id}
        rowClassName={() => 'clickable'}
        onRow={(record) => {
          return {
            onClick: () => {
              if (record.type.includes('entity')) {
                navigate(`/madde-detay/${record.entityId}`);
              } else if (record.type.includes('user')) {
                navigate(`/uye-islemleri/uye-duzenle/${record.entityId}`);
              } else if (record.type.includes('committee')) {
                navigate(`/heyetler/heyet-detay/${record.entityId}`);
              }
            },
          };
        }}
        scroll={{ x: 990, y: height - 350 }}
      />
    </>
  );
};

export default Logs;
