import React, { memo, useEffect } from 'react';

import { ConfigProvider } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { SWRConfig } from 'swr';
import ReactGA from 'react-ga4';
import AppLocale from 'lngProvider';
import trTR from 'antd/lib/locale/tr_TR';
import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
} from 'constant/themeSettings';
import { useHash } from 'useSWR';
import { axios } from 'fetcher';
import { onLayoutTypeChange, onNavStyleChange, setThemeType } from 'slices/settingSlice';
import ErrorBoundary from 'ErrorBoundry';
import FallBack from 'components/Fallback/FallBack';
import { VirtualKeyboard } from 'components';

import { AppRoutes } from './AppRoutes';

ReactGA.initialize(process.env.REACT_APP_GA_ID);

const keyboardShownPath = ['/yeni-madde-teklifi/madde-tespit-formu', '/madde-teklifleri', '/maddelerim'];

export const AppContainer = memo(() => {
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();

  const locale = useSelector(({ settings }) => settings.locale);
  const navStyle = useSelector(({ settings }) => settings.navStyle);
  const layoutType = useSelector(({ settings }) => settings.layoutType);

  useHash();

  useEffect(() => {
    const params = new URLSearchParams(search);

    if (params.has('theme')) {
      dispatch(setThemeType(params.get('theme')));
    }

    if (params.has('nav-style')) {
      dispatch(onNavStyleChange(params.get('nav-style')));
    }

    if (params.has('layout-type')) {
      dispatch(onLayoutTypeChange(params.get('layout-type')));
    }

    setLayoutType(layoutType);
    setNavStyle(navStyle);
  }, [dispatch, layoutType, pathname, search, navStyle]);

  const setLayoutType = (layoutType) => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('full-layout');
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove('full-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('boxed-layout');
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('full-layout');
      document.body.classList.add('framed-layout');
    }
  };

  const setNavStyle = (navStyle) => {
    if (
      navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
      navStyle === NAV_STYLE_DARK_HORIZONTAL ||
      navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
      navStyle === NAV_STYLE_ABOVE_HEADER ||
      navStyle === NAV_STYLE_BELOW_HEADER
    ) {
      document.body.classList.add('full-scroll');
      document.body.classList.add('horizontal-layout');
    } else {
      document.body.classList.remove('full-scroll');
      document.body.classList.remove('horizontal-layout');
    }
  };

  const swrFetcher = async (url) => {
    const swrDefaultUrls = [
      '/users?',
      '/article_offers/?page=',
      '/resources?',
      '/article_offers?',
      '/author_articles?',
      '/article_offer_versions?',
      '/activities?',
      '/authors',
      '/media?',
    ];

    const res = await axios.get(url);
    const isDefaultUrl = swrDefaultUrls.some((e) => url.includes(e));

    if (isDefaultUrl) {
      return res.data;
    }

    if (res.data['hydra:member']) {
      return res.data['hydra:member'];
    }

    return res.data;
  };
  const currentAppLocale = AppLocale[locale.locale];

  return (
    <ErrorBoundary fallback={<FallBack />}>
      <SWRConfig value={{ fetcher: swrFetcher }}>
        <ConfigProvider locale={trTR}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
            defaultLocale="tr"
          >
            <AppRoutes />
            {keyboardShownPath.some((path) => pathname.includes(path)) && <VirtualKeyboard />}
          </IntlProvider>
        </ConfigProvider>
      </SWRConfig>
    </ErrorBoundary>
  );
});
