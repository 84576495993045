import ContentUpload from 'module/ContentUpload/ContentUpload';

import { useEffect } from 'react';

import { Form } from 'antd';
import { Input } from 'components';
import { useIntl } from 'react-intl';
import { useGetArticleContentQuery } from 'services';

const { TextArea } = Input;

const CommitteeOpinionExtras = ({ offerID }) => {
  const form = Form.useFormInstance();
  const { formatMessage } = useIntl();
  const { data } = useGetArticleContentQuery(
    {
      offerID,
    },
    {
      skip: !offerID,
    }
  );
  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        itemFile: data.contents.find((item) => item.type === 0),
        idCard: data.contents.find((item) => item.type === 1),
        uvkaFile: data.contents.find((item) => item.type === 3),
      });
    }
  }, [data, form]);

  return (
    <>
      <ContentUpload id={offerID} />
      <Form.Item
        name="mediaOfferDescription"
        className="gx-mb-4"
        rules={[
          {
            min: 50,
            message: formatMessage({
              id: 'article.reasonLengthMessageShort',
            }),
          },
        ]}
        normalize={(value) => (value.trim() === '' ? undefined : value)}
      >
        <TextArea
          rows={8}
          placeholder="Medya teklifi ile ilgili görüş belirtiniz"
          showCount
        />
      </Form.Item>
    </>
  );
};

export default CommitteeOpinionExtras;
