import React from 'react';

import { Radio } from 'antd';
import { useGetArticleGroupsQuery } from 'services';

const ArticleGroupRadioGroup = ({ editable = true, disabled, value, onChange }) => {
  const { data, isLoading } = useGetArticleGroupsQuery();
  return (
    <Radio.Group
      buttonStyle="solid"
      disabled={!editable || isLoading || disabled}
      onChange={onChange}
      value={value}
    >
      {!isLoading &&
        data?.map((item) => (
          <Radio.Button
            key={item.id}
            value={item['@id']}
          >
            {item.name}
          </Radio.Button>
        ))}
    </Radio.Group>
  );
};

export default ArticleGroupRadioGroup;
