import apiInstance from './api';
const stiplatedTimeApi = apiInstance.injectEndpoints({
  endpoints: (build) => ({
    getStiplatedTime: build.query({
      query: () => '/stipulated_time',
      providesTags: ['StiplatedTimes'],
    }),
  }),
  overrideExisting: true,
});

export default stiplatedTimeApi;
