import apiInstance from './api';

const articleApi = apiInstance.injectEndpoints({
  endpoints: (build) => ({
    getArticleGroups: build.query({
      query: () => '/article_groups',
      transformResponse: (response) => response['hydra:member'],
      providesTags: ['ArticleGroups'],
    }),
    getArticleTypes: build.query({
      query: () => '/article_types',
      transformResponse: (response) => response['hydra:member'],
      providesTags: ['ArticleTypes'],
    }),
  }),
  overrideExisting: true,
});
export default articleApi;
