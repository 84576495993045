import { useState } from 'react';

import { Button, Col, Form, Input, Row } from 'antd';
import { article_detection_decisions } from 'constant/static';
import { useSendDecisionMutation } from 'services';
import { useDispatch } from 'react-redux';
import { fetchError } from 'slices/commonSlice';
import { useIntl } from 'react-intl';
import { openNotification } from 'utils';

const { TextArea } = Input;

const ReorderCommitteeActions = ({ offerID }) => {
  const [form] = Form.useForm();
  const [committeeDecision, setCommitteeDecision] = useState(null);
  const [sendDecision] = useSendDecisionMutation();

  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const onFinish = async (values) => {
    const body = {
      status: committeeDecision,
      ...values,
    };

    const { error } = await sendDecision({ id: offerID, committee: 'owner_committee', body });
    if (error) {
      return dispatch(
        fetchError(
          formatMessage(
            {
              id: 'article.form.fetchError',
            },
            { error: error.status }
          )
        )
      );
    }
    openNotification({
      message: formatMessage({
        id: 'appModule.actionSuccessfully',
      }),
    });
  };

  return (
    <>
      <Row gutter={24}>
        <Col
          xs={24}
          md={8}
        >
          <Button
            block
            onClick={() => setCommitteeDecision(article_detection_decisions.yeniden_telif)}
          >
            YENİDEN TELİF
          </Button>
        </Col>
        <Col
          xs={24}
          md={8}
        >
          <Button
            block
            onClick={() => setCommitteeDecision(article_detection_decisions.yeniden_siparis)}
          >
            YENİDEN SİPARİŞ
          </Button>
        </Col>
        <Col
          xs={24}
          md={8}
        >
          <Button
            block
            onClick={() => setCommitteeDecision(article_detection_decisions.red)}
          >
            RET
          </Button>
        </Col>
      </Row>
      <>
        {committeeDecision === article_detection_decisions.yeniden_siparis && (
          <div className="gx-text-center gx-mt-3 gx-mb-3">Madde yazara yeniden gönderilecektir.</div>
        )}
        {committeeDecision === article_detection_decisions.red && (
          <div className="gx-text-center gx-mt-3 gx-mb-3">
            Madde daha sonradan gündeme gelmek üzere askıya alınacaktır. Onaylıyorsanız gönder butonuna basabilirsiniz.
          </div>
        )}
        {committeeDecision && (
          <>
            <Form
              layout="vertical"
              form={form}
              onFinish={onFinish}
            >
              <Form.Item
                label="Açıklama"
                rules={[
                  {
                    required: true,
                    message: 'Bu alan zorunludur',
                  },
                  {
                    min: 100,
                    message: 'Açıklama en az 100 karakter olmalıdır',
                  },
                ]}
                name="description"
              >
                <TextArea
                  placeholder={committeeDecision === article_detection_decisions.yeniden_siparis ? 'Not' : 'Gerekçe'}
                  rows={7}
                  showCount
                />
              </Form.Item>
              <Button
                block
                htmlType="submit"
                className="gx-mt-4"
              >
                Gönder
              </Button>
            </Form>
            <Button
              className="gx-mt-2"
              block
              type="primary"
              onClick={() => setCommitteeDecision(null)}
            >
              Vazgeç
            </Button>
          </>
        )}
      </>
    </>
  );
};

export default ReorderCommitteeActions;
