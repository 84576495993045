import { Descriptions, Card } from 'antd';
import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import { useWindowSize } from 'hooks';
import { MISSION } from 'utils';

const UserInfos = ({ offer }) => {
  const { width } = useWindowSize();

  const noCommittee = () => offer.owner.memberships.length !== 0;

  return (
    <Card
      className="gx-mb-2"
      title={
        <h2
          style={{
            marginTop: 2,
          }}
          className={'gx-h2-lg'}
        >
          <FormattedMessage id="article.detail.UserInfoTitle" />
        </h2>
      }
    >
      <Descriptions
        layout="horizontal"
        bordered
        column={24}
        className="owner-user"
      >
        <Descriptions.Item
          label={<FormattedMessage id="article.detail.offerSource" />}
          span={24}
        >
          {offer.offerReason === null ? (
            <FormattedMessage id="article.detail.AYKCommittee" />
          ) : (
            <FormattedMessage id="article.detail.AYKMember" />
          )}
        </Descriptions.Item>
        {offer.offerReason === null && (
          <Descriptions.Item
            label={
              <FormattedMessage id="article.detail.CommitteeWhoMadeOffer" />
            }
            span={24}
          >
            {offer?.ownerCommittee?.name}
          </Descriptions.Item>
        )}
        <Descriptions.Item
          label={
            offer.offerReason === null ? (
              <FormattedMessage id="article.detail.PersonWhoMadeOffer" />
            ) : (
              <>
                <FormattedMessage id="appModule.name" />{' '}
                <FormattedMessage id="appModule.surname" />
              </>
            )
          }
          span={width > 657 ? 24 : 24}
        >
          {offer?.owner.fullName}
        </Descriptions.Item>
        {noCommittee && (
          <Descriptions.Item
            label={
              <FormattedMessage id="article.detail.PersonWhoMadeOfferMisson" />
            }
            className={'tiny-bar'}
            span={width > 657 ? 12 : 24}
          >
            {offer?.owner.memberships.map((item, idx) => (
              <div key={idx} title={item.committee.name}>
                {MISSION[item.type]}
              </div>
            ))}
          </Descriptions.Item>
        )}
        {noCommittee && offer?.offerReason !== null && (
          <Descriptions.Item
            label={<FormattedMessage id="article.detail.PersonCommittee" />}
            span={width > 657 ? 12 : 24}
            className={'tiny-bar'}
          >
            {offer?.owner?.memberships?.map((item, idx) => (
              <div key={idx} title={item?.committee?.name}>
                {item?.committee?.name}
              </div>
            ))}
          </Descriptions.Item>
        )}
        <Descriptions.Item
          label={<FormattedMessage id="article.detail.OfferTime" />}
          span={24}
        >
          {dayjs(new Date(offer.createdAt)).format('DD/MM/YYYY - HH:mm')}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export default UserInfos;
