import React, { useState } from 'react';

import { Col, Row } from 'antd';
import useSWR from 'swr';
import { CircularProgress } from 'components';

import UserInfos from './UserInfosSection';
import OfferInfosSection from './OfferInfosSection';

const ArticleInfos = ({ offer, offerOldArticles, situation }) => {
  const [versionUrl, setversionUrl] = useState();
  const { data: offerVersions, error: offerError } = useSWR(offer?.id ? `/article_offers/${offer.id}/versions?step=1` : '');

  const last = offerVersions?.[0]?.['@id']?.replaceAll('/api', '');

  const { data, error } = useSWR(versionUrl && versionUrl !== 'current' && !offer.isDraft ? versionUrl : last);

  if (!offerError && !offerVersions) return <CircularProgress />;

  return (
    <Row>
      <Col xs={24}>
        <UserInfos offer={offer} />
        <OfferInfosSection
          offer={offer.isDraft || versionUrl === 'current' ? offer : data}
          currentOfferStep={offer?.step}
          formNumber={offer?.formNumber}
          situation={situation}
          offerOldArticles={offerOldArticles}
          setversionUrl={setversionUrl}
          offerVersions={offerVersions}
          isValidating={versionUrl && !error && !data}
          isDraft={offer?.isDraft}
          isView={offer?.isView}
        />
      </Col>
    </Row>
  );
};

export default ArticleInfos;
