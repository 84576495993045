import { Button } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { useWindowSize } from 'hooks';

export const DownButton = ({ title, section }) => {
  const { width } = useWindowSize();

  const goId = () => {
    document.getElementById(section).scrollIntoView({ behavior: 'smooth' });
  };

  if (width > 1599) return null;

  return (
    <Button onClick={() => goId()} className="down-button">
      {title}
      <FormattedMessage id="downButtonText" />
      <CaretDownOutlined />
    </Button>
  );
};
