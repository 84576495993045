import useSWR from 'swr';

const useCommitteeOpinions = (id) => {
  const { data, error } = useSWR(`/committee_opinions/${id}`);

  return {
    opinions: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export default useCommitteeOpinions;
