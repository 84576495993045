import { Button, Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import { useIntl, FormattedMessage } from 'react-intl';
import { Title } from 'components';
import AuthorInfo from 'components/AuthorDetail/AuthorInfo';

const AddAuthor = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Title>{formatMessage({ id: 'author.addAuthor' })}</Title>

      <Row justify="space-between" align="middle" className="gx-px-3">
        <Col>
          <h1>
            <FormattedMessage id="author.addAuthor" />
          </h1>
        </Col>

        <Col>
          <Link to="/yazar-islemleri/yazar-listesi">
            <Button icon={<UserOutlined />}>
              {formatMessage({ id: 'app.backTo' }, { to: 'Yazar Listesine' })}
            </Button>
          </Link>
        </Col>
      </Row>

      <AuthorInfo />
    </>
  );
};

export default AddAuthor;
