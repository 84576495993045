import { Steps } from 'antd';
import { FileDoneOutlined, FileSyncOutlined, SolutionOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

const ArticleProcess = ({ process, offerStep, setProcess }) => {
  const steps = ['detection', 'order', 'afterCopyright', 'publish'];

  function stepStatus(step) {
    if (step === 0 && offerStep === 0) {
      return { name: 'process', icon: <FileSyncOutlined /> };
    }
    if (step + 2 < offerStep) {
      return { name: 'finished', icon: <FileDoneOutlined /> };
    } else if (step === 0 && offerStep === 1) {
      return { name: 'process', icon: <FileSyncOutlined /> };
    } else if (step + 2 > offerStep) {
      return { name: 'wait', icon: <SolutionOutlined /> };
    }

    return { name: 'process', icon: <FileSyncOutlined /> };
  }

  const items = steps.map((step, idx) => ({
    key: step,
    status: stepStatus(idx).name,
    title: (
      <span className={classNames({ 'gx-text-underline': process - 1 === idx })}>
        <FormattedMessage id={`article.process.${step}`} />
      </span>
    ),
    icon: stepStatus(idx).icon,
    className: classNames({
      'completed-step': stepStatus(idx).name === 'finished',
      clickable: idx + 1 <= offerStep,
    }),
    onClick: () => idx + 1 <= offerStep && setProcess(idx + 1),
  }));

  return (
    <Steps
      className="gx-mt-3 gx-mb-5"
      labelPlacement="vertical"
      items={items}
      responsive
    />
  );
};
export default ArticleProcess;
