import { useEffect } from 'react';

import { message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from 'components';
import { hideMessage } from 'slices/commonSlice';

export const InfoView = () => {
  const dispatch = useDispatch();

  const error = useSelector(({ common }) => common.error);
  const loading = useSelector(({ common }) => common.loading);
  const displayMessage = useSelector(({ common }) => common.message);

  useEffect(() => {
    setTimeout(() => {
      dispatch(hideMessage());
    }, 4000);
  }, [error, dispatch, displayMessage]);

  return (
    <>
      {loading && (
        <div className="gx-loader-view gx-loader-position">
          <CircularProgress />
        </div>
      )}

      {error &&
        message.error(
          <span
            id="message-id"
            style={{ lineHeight: 1.6 }}
            dangerouslySetInnerHTML={{ __html: error }}
          />
        )}

      {displayMessage && message.info(<span id="message-id">{displayMessage}</span>)}
    </>
  );
};
