import React, { useCallback, useState, useEffect } from 'react';

import { Button, Card, Input } from 'antd';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { editStaticPage } from 'fetcher';
import { openNotification } from 'utils';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { fetchError } from 'slices/commonSlice';

export const EditorComponent = ({ content = '', name, title = '', isLoading }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [htmlContent, setHtmlContent] = useState(EditorState.createEmpty());
  const [loading, setLoading] = useState(false);
  const [pageTitle, setPageTitle] = useState('');

  const handleContent = (value) => {
    setHtmlContent(value);
  };

  const savePage = async () => {
    setLoading(true);
    const data = {
      content: draftToHtml(convertToRaw(htmlContent.getCurrentContent())),
      title: pageTitle,
    };

    try {
      await editStaticPage(data, name);
      openNotification({
        message: formatMessage({
          id: 'appModule.actionSuccessfully',
        }),
      });
      setLoading(false);
    } catch (error) {
      dispatch(
        fetchError(
          formatMessage(
            {
              id: 'article.form.fetchError',
            },
            { error: `${error}` }
          )
        )
      );
      setLoading(false);
    }
  };

  const initialValueMaker = useCallback(() => {
    const html = content;
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      setHtmlContent(editorState);
    }
  }, [content]);

  useEffect(() => {
    initialValueMaker();
  }, [initialValueMaker]);

  useEffect(() => {}, [title]);

  const uploadImageCallBack = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader(); // eslint-disable-line no-undef
      reader.onload = (e) => resolve({ data: { link: e.target.result } });
      reader.onerror = (e) => reject(e);
      reader.readAsDataURL(file);
    });
  };

  const handlePageTitle = useCallback((e) => {
    setPageTitle(e.target.value);
  }, []);

  return (
    <Card>
      {!isLoading && (
        <Input
          placeholder="Sayfa Başlığı"
          className="gx-mb-3"
          defaultValue={title}
          onChange={(e) => handlePageTitle(e)}
          allowClear
        />
      )}

      <Editor
        toolbar={{
          inline: { bold: { className: 'gx-font-weight-semi-bold' } },
          image: {
            uploadCallback: uploadImageCallBack,
            alt: { present: true, mandatory: false },
            previewImage: true,
          },
        }}
        onEditorStateChange={(e) => handleContent(e)}
        editorState={htmlContent}
      />
      <Button
        type="primary"
        className="gx-my-2 gx-d-flex gx-justify-content-end"
        loading={loading}
        onClick={() => savePage()}
      >
        Kaydet
      </Button>
    </Card>
  );
};
