import apiInstance from './api';

const authorArticleApi = apiInstance.injectEndpoints({
  endpoints: (builder) => ({
    getAuthorArticles: builder.query({
      query: (params) => ({
        url: '/author_articles',
        method: 'GET',
        params,
      }),
      providesTags: ['AuthorArticles'],
    }),
  }),
  overrideExisting: true,
});

export default authorArticleApi;
