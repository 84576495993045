import { Collapse } from 'antd';

const { Panel } = Collapse;

const ComitteeComments = ({ data }) => {
  return (
    <>
      <h2>Heyet Görüş{data.length > 1 ? 'leri' : 'ü'}</h2>
      <Collapse className="gx-mb-5">
        {data.map((item) => {
          return (
            <Panel
              key={item.committee.id}
              header={item.committee.name}
            >
              {item?.description}
            </Panel>
          );
        })}
      </Collapse>
    </>
  );
};

export default ComitteeComments;
