import React from 'react';

import { Table, Tag } from 'antd';
import dayjs from 'dayjs';

/**
 * bu değer metadata içerisindeki step parametresne göre form adını değiştirmek için kullanılır
 */
const formName = {
  1: 'Tespit Formu',
  3: 'Sipariş ve Telif süreci ',
};

const committeeNames = {
  dah: 'Tematik Ansiklopediler Eşgüdüm Kurulu',
  ayk: 'Ansiklopedi Yayın Kurulu',
};

const renderEntityDecision = (metadata) => {
  if (metadata.committeeStatusCommittee) {
    return (
      <>
        Sorumlu ilim heyeti:{' '}
        <span
          style={{
            color: '#ad6800',
          }}
        >
          {metadata.committeeStatusCommittee[1]}
        </span>
      </>
    );
  }
  if (metadata.dahStatus) {
    return `${committeeNames.dah}`;
  }
  if (metadata.aykStatus) {
    return `${committeeNames.ayk}`;
  }
  if (metadata.media) {
    return 'Medya dosyası yüklendi';
  }
  if (metadata.author) {
    return 'Yazar bilgileri güncellendi';
  }
};

const renderEntityAdd = (metadata) => {
  if (Object.keys(metadata).includes('committee')) {
    return (
      <>
        <span
          style={{
            color: '#ad6800',
          }}
        >
          {metadata.committee}
        </span>{' '}
        adına
      </>
    );
  }
};

const handleRenderCommitee = (text, record) => {
  switch (text) {
    case 'entity_add':
      return renderEntityAdd(record.metadata);
    case 'entity_decision':
      return renderEntityDecision(record.metadata);
    case 'entity_edit':
      return renderEntityDecision(record.metadata);
    default:
      <></>;
  }
};

const renderFullName = (text, metadata) => {
  return !metadata.version ? text : ' - ';
};

const handleTagColor = (status) => {
  switch (status) {
    case 'Kabul':
      return 'success';
    case 'Gündemde':
      return 'processing';
    case 'İade':
      return 'warning';
    default:
      <></>;
  }
};

const renderStatus = (metadata) => {
  if (metadata.committeeStatus && metadata.committeeStatusCommittee) {
    return <Tag color={handleTagColor(metadata.committeeStatus[1])}>{metadata.committeeStatus[1]}</Tag>;
  }
  if (metadata.dahStatus) {
    return <Tag color={handleTagColor(metadata.dahStatus[1])}>{metadata.dahStatus[1]} </Tag>;
  }
  if (metadata.aykStatus) {
    return <Tag color={handleTagColor(metadata.aykStatus[1])}>{metadata.aykStatus[1]} </Tag>;
  }
  if (Object.keys(metadata).includes('committee')) {
    return `${formName[1]} dolduruldu`;
  }
  if (metadata.version) {
    return (
      <>
        Madde {formName[metadata.step]}
        <span
          style={{
            color: '#ad6800',
          }}
        >
          v{metadata.version[1]}
        </span>{' '}
        versiyonu oluşturuldu
      </>
    );
  }
  if (metadata.media) {
    return metadata.media.filter((item) => item !== null).map((item) => <>{item.originalFilename}</>);
  }
  if (metadata.author) {
    return metadata.author.filter((item) => item !== null).map((item) => <Tag key={item.user.id}>{item.user.fullName}</Tag>);
  }
};

const notShowenFields = ['opinionDate', 'articleOffer', 'requested'];

const TimeLineTable = ({ timeScale }) => {
  const stepper = timeScale.map((item) => {
    const metaKeys = Object.keys(item.metadata);
    if (metaKeys.includes('formNumber')) return null;

    if (metaKeys.includes('answers')) {
      const answers = item.metadata.answers.filter((item) => item.answer[1]).map((item) => [item.input, item.answer]);
      item.metadata = {
        ...item.metadata,
        ...Object.fromEntries(answers),
      };
      delete item.metadata.answers;
    }

    notShowenFields.forEach((field) => {
      delete item.metadata[field];
    });

    // geri çekme hareketinde status görüntülenmemeli
    if (item.metadata.requester) {
      delete item.metadata.status;
    }

    if (item.type === 'entity_remove' && item.metadata.committee) {
      item.metadata.committee = [item.metadata.committee, null];
    }
    return item;
  });
  const columns = [
    {
      title: '#',
      render: (_, record, index) => stepper.length - index,
    },
    {
      title: 'Tarih',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => dayjs(text).format('DD/MM/YYYY - HH:mm'),
    },

    {
      title: 'Kullanıcı',
      dataIndex: ['user', 'fullName'],
      key: 'user',
      render: (text, record) => renderFullName(text, record.metadata),
    },
    {
      title: 'Heyet',
      dataIndex: 'type',
      key: 'type',
      render: (text, record) => handleRenderCommitee(text, record),
    },
    {
      title: 'Durum',
      key: 'status',
      render: (_, record) => renderStatus(record.metadata),
    },
  ];

  return (
    <Table
      rowKey={(record) => record.id}
      columns={columns}
      dataSource={stepper}
      pagination={false}
    />
  );
};

export default TimeLineTable;
