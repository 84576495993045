import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';

import { AutoComplete, Row, Col, Form, Select, Button, Divider } from 'antd';
import { FilterFilled, CloseOutlined } from '@ant-design/icons';
import { useIntl, FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import { DatePicker } from 'components';
import { STEP_DEF } from 'utils';
import { useGetCommitteesQuery, useLazyGetAuthorsQuery } from 'services';
import { aykId, higherCommittee } from 'constant/static';
import SearchBox from 'components/SearchBox';

import { DetailedFilter } from './DetailedFilter';

const { Option } = Select;
const { RangePicker } = DatePicker;

export const FilterModal = forwardRef(({ isLoading, handleSearchOwner, handleCancel, tableType, setUrlFilters, filterModals, ownerOptions }, ref) => {
  const intl = useIntl();
  const { data: committees } = useGetCommitteesQuery();
  const [getAuthors] = useLazyGetAuthorsQuery();
  const [form] = Form.useForm();
  const [detailedVisible, setDetailedVisible] = useState(false);

  const clearFilters = () => {
    form.resetFields();

    setUrlFilters((prevState) => ({
      ...prevState,
      page: 1,
      filterModalURL: '',
      filterStatusURL: '',
    }));

    setDetailedVisible(false);

    // Clear filter modal values
    localStorage.removeItem('filterModalValues');
  };

  const owner = Form.useWatch('owner', form);
  handleSearchOwner(owner);

  const onFinish = (values) => {
    localStorage.setItem('filterModalValues', JSON.stringify(values));

    const { dateRange } = values;

    const datePicker = dateRange
      ? dateRange.map((date, i) => `&createdAt[${i === 0 ? 'after' : 'before'}]=${dayjs(date).format('YYYY-MM-DD')} ${i === 0 ? '00:00' : '23:59'}`).join('')
      : '';

    let filterArray;
    let filterString = '';
    const dateRangeIndexOf = Object.keys(values).indexOf('dateRange');

    values.oldArticles = values.oldArticles?.map((oa) => `&oldArticles[]=${oa.value}`);

    let oldArticleWorkTypeURL = '';
    if (values.oldArticleWorkType?.map) {
      values.oldArticleWorkType = values.oldArticleWorkType?.map((oavt) => `&oldArticleWorkType[]=${oavt}`);
    } else if (values.oldArticleWorkType) {
      oldArticleWorkTypeURL = `&oldArticleWorkType[]=${values.oldArticleWorkType}`;
    } else if (values.rewriteandUpdate === 'Yeniden Yazım') {
      oldArticleWorkTypeURL = '&oldArticleWorkType[]=0';
    }

    filterArray = Object.values(values)
      ?.filter((item, i) => item !== undefined && Array.isArray(item) && item && i !== dateRangeIndexOf)
      ?.map((filter) => filter.reduce((acc, item) => acc + item, ''));

    if (filterArray.length > 0) {
      filterString = filterArray?.reduce((acc, item) => acc + item, '');
      if (filterArray.length >= 1) {
        filterString = filterString?.replaceAll(',', '');
      }
    }
    const owner = form.getFieldValue('owner') ? `&owner=${values.owner}` : '';
    const contentAuthor = form.getFieldValue('author') ? `&authors.author.id=${values.author}` : '';
    const step = form.getFieldValue('step') ? `&targetOffer.step=${values.step}` : '';

    const detectReason = form.getFieldValue('detectReason') ? `&detectReason=${values.detectReason}` : '';

    const status = form.getFieldValue('status') ? values.status : '';
    const ihStatus = form.getFieldValue('ihStatus') ? values.ihStatus : '';
    const aykStatus = form.getFieldValue('aykStatus') ? values.aykStatus : '';
    const dahStatus = form.getFieldValue('dahStatus') ? values.dahStatus : '';

    const dahHistory = form.getFieldValue('dahIncludeHistory') ? '&historyDahStatus=3' : '';
    const aykHistory = form.getFieldValue('aykIncludeHistory') ? '&historyAykStatus=3' : '';

    const oldTitle = values.oldTitle?.value || '';

    const articleGroup = form.getFieldValue('articleGroup') ? values.articleGroup : '';
    const articleType = form.getFieldValue('articleType') ? values.articleType : '';
    const oldTitleType = form.getFieldValue('atifTam') ? `&oldTitleType=${values.atifTam}` : '';

    const firstEdition = form.getFieldValue('birinciEdisyondaYerAldi') ? `&exists[oldTitleType]=${values.birinciEdisyondaYerAldi === 'Evet'}` : '';

    const firstEditionTerm = form.getFieldValue('yakinIfadeZikrediliyorMu') ? `&jsonNullFilter[oldArticles]=${values.yakinIfadeZikrediliyorMu === 'Evet'}` : '';

    const filterStatusURL = status + ihStatus + aykStatus + dahStatus + dahHistory + aykHistory + step;
    const filterModalURL =
      filterString +
      datePicker +
      owner +
      contentAuthor +
      detectReason +
      articleGroup +
      articleType +
      oldTitleType +
      oldTitle +
      oldArticleWorkTypeURL +
      firstEdition +
      firstEditionTerm;

    setUrlFilters((prevState) => ({
      ...prevState,
      page: 1,
      filterStatusURL,
      filterModalURL,
    }));
    handleCancel();
  };

  useImperativeHandle(ref, () => ({
    clearFilters,
  }));

  useEffect(() => {
    const propsToRemove = ['otherCommittees', 'owner', 'ownerCommittee', 'committeeContributions', 'dateRange', 'step'];
    const filters = Object.entries(filterModals).reduce((arr, [k, v]) => {
      if (!propsToRemove.includes(k)) {
        arr.push(v);
      }
      return arr;
    }, []);
    const isDetailed = filters.some((v) => {
      if (Array.isArray(v)) {
        return v.length > 0;
      }
      return !!v;
    });
    if (isDetailed && !detailedVisible) {
      setDetailedVisible(isDetailed);
    }
  }, [filterModals, detailedVisible]);

  return (
    <Form
      style={{ flexDirection: 'row !important' }}
      labelAlign="right"
      layout={'vertical'}
      onFinish={onFinish}
      form={form}
      initialValues={filterModals}
    >
      <Row
        style={{ flexDirection: 'row' }}
        justify="start"
      >
        {!tableType?.includes('ownerCommittee') && (
          <Col
            xs={24}
            sm={12}
            className="gx-mt-2"
          >
            <Form.Item
              label={<FormattedMessage id="article.detail.ownerCommittee" />}
              name="ownerCommittee"
            >
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                mode="multiple"
                placeholder={intl.formatMessage({
                  id: 'committees.Title',
                })}
              >
                {committees
                  ?.filter((item) => !higherCommittee.includes(item.id))
                  .map((item) => (
                    <Option
                      key={item['@id']}
                      value={`&ownerCommittee.id[]=${item.id}`}
                    >
                      <FormattedMessage
                        id="committees.detail.Title"
                        values={{ committee: item.name }}
                      />
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        )}
        <Col
          xs={24}
          sm={12}
          className="gx-mt-2"
        >
          <Form.Item
            labelAlign="right"
            label={<FormattedMessage id="article.filter.owner" />}
            name="owner"
          >
            <AutoComplete
              options={
                owner &&
                owner.trim() !== '' &&
                ownerOptions?.map((item) => ({
                  id: item.id,
                  value: item.fullName,
                }))
              }
              placeholder={intl.formatMessage({ id: 'article.filter.owner' })}
            />
          </Form.Item>
        </Col>

        {!tableType?.includes('committeeContributions') && (
          <Col
            xs={24}
            sm={12}
            className="gx-mt-2"
          >
            <Form.Item
              label={<FormattedMessage id="article.filter.askedCommittee" />}
              name={'committeeContributions'}
            >
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                mode="multiple"
                placeholder={intl.formatMessage({
                  id: 'committees.Title',
                })}
              >
                {committees
                  ?.filter((item) => item.id !== aykId)
                  .map((item) => (
                    <Option
                      key={item['@id']}
                      value={`&committeeContributions.committee[]=${item.id}`}
                    >
                      <FormattedMessage
                        id="committees.detail.Title"
                        values={{ committee: item.name }}
                      />
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        )}

        <Col
          xs={24}
          sm={12}
          className="gx-mt-2"
        >
          <Form.Item
            label={<FormattedMessage id="article.filter.dateRange" />}
            name={'dateRange'}
          >
            <RangePicker
              locale={dayjs.locale('tr')}
              format="DD-MM-YYYY"
              className="gx-w-100"
            />
          </Form.Item>
        </Col>

        <Col
          xs={24}
          sm={12}
          className="gx-mt-2"
        >
          <Form.Item
            label={<FormattedMessage id="article.filter.generalProcess" />}
            name="step"
          >
            <Select
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              placeholder={<FormattedMessage id="article.filter.generalProcess" />}
              allowClear
            >
              {Object.entries(STEP_DEF).map(([key, value]) => (
                <Option
                  key={key.toString()}
                  value={key.toString()}
                >
                  <FormattedMessage id={`article.${value}.title`} />
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col
          xs={24}
          sm={12}
          className="gx-mt-2"
        >
          <Form.Item
            labelAlign="right"
            label={<FormattedMessage id="article.filter.author" />}
            name="author"
          >
            <SearchBox
              fetcher={getAuthors}
              placeholder={intl.formatMessage({ id: 'article.filter.author' })}
            />
          </Form.Item>
        </Col>

        {detailedVisible && <DetailedFilter form={form} />}

        <Divider className="gx-mt-4" />

        <div className="gx-mt-3 gx-d-flex ant-btn-block">
          <Form.Item shouldUpdate>
            {() => (
              <Button
                className="gx-mb-2"
                type="secondary"
                loading={isLoading}
                onClick={() => setDetailedVisible(!detailedVisible)}
                icon={detailedVisible ? <CloseOutlined /> : <FilterFilled />}
              >
                <FormattedMessage id={detailedVisible ? 'article.filter.close' : 'article.filter.detail'} />
              </Button>
            )}
          </Form.Item>

          <div className="gx-ml-auto gx-d-flex">
            <Form.Item shouldUpdate>
              <Button
                className="gx-mb-2"
                type="link"
                htmlType="submit"
                onClick={clearFilters}
                loading={isLoading}
              >
                <FormattedMessage id="article.filter.clear" />
              </Button>
            </Form.Item>

            <Form.Item shouldUpdate>
              {() => (
                <Button
                  className="gx-mb-2"
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  // onClick={handleCancel}
                  disabled={!form.isFieldsTouched()}
                >
                  <FormattedMessage id="article.filter" />
                </Button>
              )}
            </Form.Item>
          </div>
        </div>
      </Row>
    </Form>
  );
});
