import { Table, Input } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import { PlusCircleOutlined } from '@ant-design/icons';
import { MISSION } from 'utils';

const { Search } = Input;

const UserListForCommittee = ({ userAddToCommittee, users, isLoading, currentPageOptions, setCurrentPageOptions }) => {
  const intl = useIntl();
  const pagination = {
    defaultCurrent: 1,
    defaultPageSize: 50,
    total: users?.['hydra:totalItems'] ?? 1,
    showTotal: (total) => `${intl.formatMessage({ id: 'table.totalItem' }, { type: 'Üye' })} ${total}`,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    position: ['topRight', 'bottomRight'],
    responsive: true,
    size: 'default',
    onChange: (page, pageSize) => {
      setCurrentPageOptions({ ...currentPageOptions, page, pageSize });
    },
  };

  const searchName = (e) => {
    setCurrentPageOptions({
      ...currentPageOptions,
      page: 1,
      fullName: e.target.value,
    });
  };

  const columnAllMembers = [
    {
      title: <FormattedMessage id="appModule.name" />,
      dataIndex: 'firstName',
      width: 150,
    },
    {
      title: <FormattedMessage id="appModule.surname" />,
      dataIndex: 'lastName',
      width: 150,
    },

    {
      title: <FormattedMessage id="committees.userList.beenCommittiees" />,
      width: 200,
      render: (dataIndex) =>
        dataIndex?.memberships?.map((item) => (
          <div
            key={item?.committee?.id}
            title={MISSION[item?.type]}
          >
            {item?.committee?.name}
          </div>
        )),
    },
    {
      title: <FormattedMessage id="committees.userList.addToCommittee" />,
      align: 'center',
      width: 150,
      render: (user) => (
        <div
          onClick={() => userAddToCommittee(user['@id'], 2)}
          style={{ cursor: 'pointer' }}
        >
          <PlusCircleOutlined />
        </div>
      ),
    },
  ];

  return (
    <>
      <Search
        name="committeeMember"
        onChange={searchName}
        placeholder={intl.formatMessage({
          id: 'committees.userList.searchPlaceHolder',
        })}
        enterButton
      />

      <Table
        bordered
        columns={columnAllMembers}
        dataSource={users?.['hydra:member']}
        rowKey={(record) => record.id}
        loading={isLoading}
        scroll={{ x: 990, y: 525 }}
        pagination={pagination}
      />
    </>
  );
};

export default UserListForCommittee;
