import AfterCopyrightProcessContent from 'module/AfterCopyright/AfterCopyrightProcessContent';

const PublishManagement = ({ offer }) => {
  return (
    <AfterCopyrightProcessContent
      offer={offer}
      type={1}
      disabledStatus={39}
      committee="pub"
    />
  );
};

export default PublishManagement;
