import { Form, Input } from 'antd';

const { TextArea } = Input;

const EncylopediaManagementUnitReview = () => {
  return (
    <div className="gx-mt-5 gx-mb-5">
      <div className="gx-text-center gx-mb-4">
        <div className="gx-mt-4 gx-mb-2">Gönder butonuna bastığınızda madde incelenmek üzere listelenecek.</div>
      </div>
      <Form.Item name="description">
        <TextArea
          rows={7}
          placeholder="Notunuz varsa girebilirsiniz"
          showCount
        />
      </Form.Item>
    </div>
  );
};

export default EncylopediaManagementUnitReview;
