import { Table, Radio, Button, Popconfirm } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { fileDownload } from 'fetcher';
import { downloadBlob, getFileExtensionIcon, getReadableFileSize, openNotification } from 'utils';
import { useFilters, useWindowSize } from 'hooks';
import { MediaUploader, Title } from 'components';
import { useDeleteMediaMutation, useGetMediasQuery } from 'services';
import { useDispatch } from 'react-redux';
import { fetchError } from 'slices/commonSlice';

const mediaTypes = [
  {
    key: 'guide',
    accept: ['.pdf'],
  },
  {
    key: 'docs',
    accept: ['application/pdf', '.doc', '.docx', '.xls', '.xlsx', '.txt'],
  },
  {
    key: 'contract',
    accept: ['application/pdf'],
  },
  {
    key: 'photo',
    accept: ['photo/*'],
  },
  {
    key: 'video',
    accept: ['video/*'],
  },
];

export default function FileManagment() {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { filters, setFilters } = useFilters({
    page: 1,
    pageSize: 20,
    mediaType: 'guide',
  });

  const { height } = useWindowSize();
  const { data: medias, isLoading: mediasLoading } = useGetMediasQuery(filters);
  const [deleteMediaMutation] = useDeleteMediaMutation();

  const deleteMedia = async (id) => {
    const { error } = await deleteMediaMutation(id);
    if (error) {
      return dispatch(
        fetchError(
          formatMessage(
            {
              id: 'article.form.fetchError',
            },
            { error: error.status }
          )
        )
      );
    }
    openNotification({
      message: formatMessage({
        id: 'appModule.actionSuccessfully',
      }),
    });
  };

  const mediaType = mediaTypes.find((type) => type.key === filters.mediaType);

  const pagination = {
    defaultCurrent: 1,
    defaultPageSize: 20,
    current: filters.page,
    pageSize: filters.pageSize,
    total: medias?.['hydra:totalItems'] ?? 1,
    position: ['bottomRight'],
    responsive: true,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    showTotal: (total) => `${formatMessage({ id: 'table.totalItem' }, { type: formatMessage({ id: 'file' }) })} ${total}`,
  };

  const handleTableChange = (tablePagination) => {
    const { current, pageSize } = tablePagination;

    setFilters({
      ...filters,
      page: pageSize === filters.pageSize ? current : 1,
      pageSize,
    });
  };

  async function download(url, name) {
    const file = await fileDownload(url);
    downloadBlob(file.data, name);
  }

  const columns = [
    {
      title: <FormattedMessage id="file.managment.name" />,
      dataIndex: 'name',
      key: 'name',
      render: (dataIndex) => <span>{dataIndex}</span>,
    },
    {
      title: <FormattedMessage id="file.managment.size" />,
      dataIndex: 'fileSize',
      key: 'fileSize',
      render: (_, file) => <span>{getReadableFileSize(file)}</span>,
    },
    {
      title: <FormattedMessage id="file.managment.types" />,
      dataIndex: 'fileExtension',
      key: 'fileExtension',
      render: (ext, file) => (
        <div className="gx-d-flex gx-align-items-center">
          <span className=" gx-fs-xl">{getFileExtensionIcon(file)}</span>
          <span className="gx-ml-2 gx-mt-1">{ext.toUpperCase()}</span>
        </div>
      ),
    },
    {
      title: <FormattedMessage id="file.managment.action" />,
      dataIndex: 'fileFullUrl',
      render: (dataIndex, data) => (
        <div className="gx-d-flex">
          <Button
            type="link"
            style={{ marginRight: 0, marginBottom: 0 }}
            onClick={() => download(dataIndex, data?.name)}
          >
            <FormattedMessage id="appModule.download" />
          </Button>
          <Popconfirm
            title="Seçili dosyayı silmek istediğinize emin misiniz?"
            onConfirm={() => deleteMedia(data.id)}
            okText={formatMessage({ id: 'appModule.yes' })}
            cancelText={formatMessage({ id: 'appModule.no' })}
          >
            <Button
              style={{ marginRight: 0, marginBottom: 0 }}
              type="link"
            >
              <FormattedMessage id="appModule.delete" />
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Title>{formatMessage({ id: 'file.managment' })}</Title>

      <h1>
        <FormattedMessage id="file.managment.title" />
      </h1>

      <Radio.Group
        onChange={(type) => setFilters({ ...filters, mediaType: type?.target.value })}
        defaultValue={filters?.mediaType}
      >
        {mediaTypes.map((type, i) => (
          <Radio.Button
            key={i}
            value={type.key}
          >
            <FormattedMessage id={`file.type.${type.key}`} />
          </Radio.Button>
        ))}
      </Radio.Group>

      <MediaUploader mediaType={mediaType} />

      <Table
        bordered
        columns={columns}
        dataSource={medias?.['hydra:member']}
        rowKey={(record) => record['@id']}
        loading={mediasLoading}
        pagination={pagination}
        onChange={handleTableChange}
        scroll={{ x: 990, y: height - 550 }}
      />
    </div>
  );
}
