import React, { useEffect } from 'react';

import { Modal, Form, Button } from 'antd';
import { CommitteeMemberSelect } from 'components/Select';
import { useChangeSupervisorMutation } from 'services';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { fetchError } from 'slices/commonSlice';

const ArticleSupervisorEditModal = ({ id, initialData, offerID, ...other }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [changeSupervisor] = useChangeSupervisorMutation();

  useEffect(() => {
    if (!other.open) form.resetFields();
    if (initialData && other.open) form.setFieldsValue({ articleSupervisor: initialData });
  }, [form, other.open, initialData]);

  const onSubmit = async (values) => {
    const { error } = await changeSupervisor({ id: offerID, body: values });
    if (!error) {
      other.onCancel();
      form.resetFields();
    }
    if (error) {
      return dispatch(
        fetchError(
          formatMessage(
            {
              id: 'article.form.fetchError',
            },
            { error: error.status }
          )
        )
      );
    }
  };

  return (
    <Modal
      destroyOnClose
      footer={null}
      height={400}
      {...other}
    >
      <h2 className="gx-mb-4">Madde sorumlusunu değiştirin</h2>
      <Form
        form={form}
        onFinish={onSubmit}
      >
        <Form.Item name="articleSupervisor">
          <CommitteeMemberSelect committeeID={id} />
        </Form.Item>
        <Button
          className="gx-mt-3"
          block
          htmlType="submit"
        >
          Kaydet
        </Button>
      </Form>
    </Modal>
  );
};

export default ArticleSupervisorEditModal;
