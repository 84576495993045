import apiInstance from './api';

const committeeRevertReasonApi = apiInstance.injectEndpoints({
  endpoints: (build) => ({
    getCommitteeRevertReasons: build.query({
      query: (params) => ({
        url: '/revert_reasons',
        method: 'GET',
        params,
      }),
      transformResponse: (response) => response['hydra:member'],
      providesTags: ['CommitteeRevertReasons'],
    }),
  }),
  overrideExisting: true,
});

export default committeeRevertReasonApi;
