import ContentUpload from 'module/ContentUpload/ContentUpload';

import React, { useEffect, useMemo } from 'react';

import { DownloadOutlined, FileOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Input, Row, Switch } from 'antd';
import { useGetArticleContentQuery, useMakeAssigmentDecisionMutation } from 'services';
import { downloadBlob, openNotification } from 'utils';
import { fileDownload } from 'fetcher';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { fetchError } from 'slices/commonSlice';

const { TextArea } = Input;

const handleBlobDownload = async (fileUrl, fileName) => {
  const { data } = await fileDownload(fileUrl);
  downloadBlob(data, fileName);
};
const contentFile = (fileName, fileUrl) => {
  return (
    <Card>
      <div className="gx-d-flex gx-align-items-center gx-justify-content-between">
        <div className="gx-d-flex gx-fit-text gx-align-items-center">
          <FileOutlined />
          <div className="gx-ml-2">{fileName}</div>
        </div>
        <Button
          type="link"
          icon={<DownloadOutlined className="gx-fs-xl" />}
          onClick={() => handleBlobDownload(fileUrl, fileName)}
        />
      </div>
    </Card>
  );
};

const MediaField = ({
  offerID,
  description,
  euControlDescription,
  pubControlDescription,
  redactionNote,
  isProcessContinue = false,
  isMediaEditable = true,
}) => {
  const [form] = Form.useForm();
  const switchValue = Form.useWatch('switch', form);
  console.log(description);
  const { data } = useGetArticleContentQuery({
    offerID,
  });
  const itemFile = useMemo(() => {
    return data?.contents.find((item) => item.type === 0);
  }, [data]);
  const idCard = useMemo(() => {
    return data?.contents.find((item) => item.type === 1);
  }, [data]);
  const uvkaFile = useMemo(() => {
    return data?.contents.find((item) => item.type === 3);
  }, [data]);

  const [makeAssigmentDecision] = useMakeAssigmentDecisionMutation();

  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        itemFile: data.contents.find((item) => item.type === 0),
        idCard: data.contents.find((item) => item.type === 1),
        uvkaFile: data.contents.find((item) => item.type === 3),
        description: description,
      });
    }
  }, [data, description, form]);

  const handleSubmit = async (values) => {
    const body = {
      opinion: 'approved',
      ...(values.description && { description: values.description }),
    };

    const { error } = await makeAssigmentDecision({
      offerID,
      data: body,
    });

    if (error) {
      return dispatch(
        fetchError(
          formatMessage(
            {
              id: 'article.form.fetchError',
            },
            { error: error.status }
          )
        )
      );
    }
    openNotification({
      message: formatMessage({
        id: 'appModule.actionSuccessfully',
      }),
    });
    form.resetFields();
  };

  return (
    <>
      {euControlDescription && (
        <>
          <h3>Redaksiyon Aşaması Genel Notu</h3>
          <TextArea
            className="gx-mb-5"
            value={euControlDescription}
            disabled
          />
        </>
      )}
      {pubControlDescription && (
        <>
          <h3>Redaksiyon Aşaması Genel Notu</h3>
          <TextArea
            className="gx-mb-5"
            value={pubControlDescription}
            disabled
          />
        </>
      )}
      <div className="gx-d-flex gx-align-items-center gx-justify-content-between">
        <h3>Madde Dosyası</h3>
        {/* <Button
          icon={<FieldTimeOutlined />}
          type="text"
        >
          Düzenleme Arşivi
        </Button> */}
      </div>
      <Card className="gx-mt-3">
        {itemFile && (
          <Col>
            <h4>Madde Dosyası</h4> {contentFile(itemFile.media[0].name, itemFile.media[0].fileFullUrl)}
          </Col>
        )}

        {idCard && (
          <>
            <h4>Kimlik Kartı</h4>
            {contentFile(idCard.media[0].name, idCard.media[0].fileFullUrl)}
          </>
        )}
        {uvkaFile && (
          <>
            <h4>ÜVKA Dosyası</h4>
            {contentFile(uvkaFile.media[0].name, uvkaFile.media[0].fileFullUrl)}
          </>
        )}

        {redactionNote && (
          <>
            <h3>Redaksiyon Kararı ile Gelen Notlar</h3>
            <TextArea
              className="gx-mb-5"
              value={redactionNote}
              disabled
            />
          </>
        )}

        {description && (
          <>
            <h3>Açıklama</h3>
            <TextArea
              className="gx-mb-5"
              value={description}
              disabled
            />
          </>
        )}

        {isMediaEditable && isProcessContinue && (
          <Form
            id="mediaForm"
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
          >
            <Form.Item
              className="gx-mt-5 gx-mb-5"
              label="Açıklama"
              name="description"
            >
              <Input.TextArea
                placeholder="İletmek istediğiniz not varsa yazabilirsiniz."
                rows={5}
                showCount
              />
            </Form.Item>
            <Card>
              <div className="gx-d-flex gx-justify-content-between">
                Madde dosyalarında herhangi bir değişiklik yok.
                <Form.Item
                  name="switch"
                  valuePropName="checked"
                  normalize={(value) => (!value ? undefined : value)}
                >
                  <Switch />
                </Form.Item>
              </div>
            </Card>
            {switchValue && (
              <div className="gx-mt-5">
                <ContentUpload
                  id={offerID}
                  isIdCardRequired={true}
                />
              </div>
            )}
            {isProcessContinue && (
              <Row
                className="gx-mt-5"
                gutter={24}
                style={{
                  flexDirection: 'row',
                }}
              >
                {/* <Col
                  sm={24}
                  md={12}
                >
                  <Button block>Vazgeç</Button>
                </Col> */}
                <Col sm={24}>
                  <Button
                    form="mediaForm"
                    block
                    htmlType="submit"
                  >
                    Gönder
                  </Button>
                </Col>
              </Row>
            )}
          </Form>
        )}
      </Card>
    </>
  );
};

export default MediaField;
