import { useState } from 'react';

import { Collapse, Form, Card, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { editAuthor } from 'fetcher';
import { openNotification } from 'utils';
import { fetchError } from 'slices/commonSlice';

import EducationField from './EducationField';

const { Panel } = Collapse;

export default ({ author, editable }) => {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const onRemove = async (remove, field) => {
    remove(field);
    await save(false);
  };

  const save = async (showNotification = true) => {
    setLoading(true);
    try {
      // sadece eğitim tipi seçilmiş bilgileri filtrele
      const authorEducation = form
        .getFieldValue('authorEducation')
        .filter((e) => e.graduationYear && e.institution)
        .map((edu) => ({
          id: edu['@id'],
          branch: edu.branch,
          graduationYear: edu.graduationYear,
          institute: edu.institute,
          institution: edu.institution,
          thesisTitle: edu.thesisTitle,
          type: edu.type,
        }));

      await editAuthor(author.id, {
        authorEducation,
        user: { id: author.user['@id'] },
      });

      if (showNotification) {
        openNotification({
          message: formatMessage({ id: 'appModule.actionSuccessfully' }),
        });
      }
    } catch (error) {
      dispatch(
        fetchError(
          formatMessage(
            {
              id: 'article.form.fetchError',
            },
            { error: `${error}` }
          )
        )
      );
    }
    setLoading(false);
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={save}
      initialValues={{ authorEducation: author?.authorEducation }}
    >
      <Form.List name="authorEducation">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, ...field }) => (
              <Collapse
                expandIconPosition="right"
                bordered={false}
                key={key}
                defaultActiveKey="info"
              >
                <Panel
                  header={
                    <h4 className="gx-m-0">
                      <FormattedMessage id="author.education.title" />
                    </h4>
                  }
                  key="info"
                  className="gx-mb-3"
                >
                  <EducationField
                    editable={editable}
                    remove={() => onRemove(remove, field.name)}
                    {...field}
                  />

                  <div className="gx-flex-row gx-justify-content-end">
                    <Button
                      className="gx-mt-2 gx-mb-0"
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                    >
                      <FormattedMessage id="appModule.save" />
                    </Button>
                  </div>
                </Panel>
              </Collapse>
            ))}

            <Card className="gx-mb-3">
              <Button
                type="dashed"
                onClick={() => add()}
                icon={<PlusOutlined />}
                disabled={!editable}
                className="gx-m-0"
                block
              >
                {' '}
                <FormattedMessage id="author.education.new" />
              </Button>
            </Card>
          </>
        )}
      </Form.List>
    </Form>
  );
};
