import React from 'react';

import { Alert } from 'antd';
import { OFFER_STATUS } from 'utils';
import { CheckCircleFilled, ClockCircleTwoTone, CloseCircleFilled, LoginOutlined } from '@ant-design/icons';

const results = {
  success: {
    type: 'success',
    icon: <CheckCircleFilled />,
  },
  error: {
    type: 'error',
    icon: <CloseCircleFilled />,
  },
  info: {
    type: 'info',
    icon: <LoginOutlined />,
  },
  infoTwoTone: {
    type: 'info',
    icon: <ClockCircleTwoTone />,
  },
};

const kararType = {
  0: results.info,
  1: results.infoTwoTone,
  5: results.info,
  6: results.infoTwoTone,
  11: results.info,
  12: results.infoTwoTone,
  17: results.success,
  18: results.error,
  19: results.info,
  20: results.info,
  21: results.info,
  22: results.info,
  23: results.info,
};

const StatusBar = ({ className, status }) => {
  return (
    <Alert
      className={className}
      message={OFFER_STATUS[status] ?? '-'}
      type={kararType[status]?.type}
      icon={kararType[status]?.icon}
      showIcon={!!OFFER_STATUS[status]}
      banner
    />
  );
};

export default StatusBar;
