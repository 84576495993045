import React from 'react';

import { FormattedHTMLMessage, useIntl } from 'react-intl';

const EntityAdd = ({ metadata, user }) => {
  const { formatMessage } = useIntl();

  const { version, step, committee, contentFormFilled, lastOpinionDate } =
    metadata;

  const [oldStep, currentStep] = [step - 1, step].map((s) => {
    if (contentFormFilled) {
      return formatMessage({
        id: 'article.process.content',
      });
    }
    return formatMessage({
      id: `article.process.${s > 1 ? 'content' : 'detection'}`,
    });
  });

  const Content = () => {
    if (!contentFormFilled && Array.isArray(version))
      return (
        <FormattedHTMLMessage
          id="article.detail.timeLine.versionCreated"
          values={{
            version: version[1],
            step: currentStep,
          }}
        />
      );

    if (lastOpinionDate)
      return <FormattedHTMLMessage id="article.detail.timeLine.lastDate" />;

    if (committee) {
      if (step > 1 && !contentFormFilled) {
        return (
          <FormattedHTMLMessage
            id="article.detail.timeLine.offerChangedStep"
            values={{
              form: oldStep,
              process: currentStep,
              user: user.fullName,
              version,
            }}
          />
        );
      } else {
        return (
          <FormattedHTMLMessage
            id="article.detail.timeLine.WhoGiveOfferWithCommittee"
            values={{
              type: currentStep,
              user: user.fullName,
              committee: committee,
            }}
          />
        );
      }
    }

    return (
      <FormattedHTMLMessage
        id="article.detail.timeLine.WhoGiveOffer"
        values={{
          type: currentStep,
          user: user.fullName,
        }}
      />
    );
  };

  return (
    <h5>
      <Content />
    </h5>
  );
};

export default EntityAdd;
