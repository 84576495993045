import React from 'react';

import { Select } from 'antd';
import { useGetCommitteeQuery } from 'services';

const CommitteeMemberSelect = ({ committeeID: id, editable = true, disabled, value, onChange }) => {
  const { data, isLoading } = useGetCommitteeQuery(id, { skip: !id });

  return (
    <Select
      value={value}
      disabled={isLoading || !editable || disabled}
      onChange={onChange}
      getPopupContainer={(triggerNode) => triggerNode.parentElement}
      placeholder="İlim Heyeti Üyesi Seçin"
      options={
        !isLoading &&
        data?.members?.map(({ user }) => ({
          label: user.fullName,
          value: user['@id'],
        }))
      }
    />
  );
};

export default CommitteeMemberSelect;
