import { useState } from 'react';

import { Button, Popconfirm } from 'antd';
import { MinusOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

export const RemoveButton = ({
  className,
  onClick,
  disabled,
  icon,
  size = 'small',
  type = 'dashed',
  placement = 'topLeft',
  title = <FormattedMessage id="appModule.removeTitle" />,
  hideIcon = false,
  hideButtonText = false,
  hideConfirm = false,
  danger = false,
}) => {
  const [visible, setVisible] = useState(false);

  const Content = () => (
    <Button
      size={size}
      type={type}
      disabled={disabled}
      icon={hideIcon ? null : icon || <MinusOutlined className={hideButtonText ? 'gx-mx-1' : 'gx-mr-2'} />}
      className={`remove-button ${className}`}
      onClick={hideConfirm ? onClick : () => setVisible(true)}
      danger={danger}
    >
      {!hideButtonText && <FormattedMessage id="appModule.remove" />}
    </Button>
  );

  if (hideConfirm) return <Content />;

  return (
    <Popconfirm
      open={visible}
      title={title}
      placement={placement}
      onConfirm={onClick}
      onCancel={() => setVisible(false)}
      okText={<FormattedMessage id="appModule.yes" />}
      cancelText={<FormattedMessage id="appModule.no" />}
    >
      <Content />
    </Popconfirm>
  );
};
