import React from 'react';

import { Button } from 'antd';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import AdditionalTime from './AdditionalTime';
// import AuthorWantAdditionalTime from './AuthorWantAdditionalTime';
/**
 * Madde Gönder Butonu
 */
const SendItemSteps = ({ offerId, title, isAuthorPage }) => {
  const { formatMessage } = useIntl();

  return (
    <div className="modalBtnGrp">
      {isAuthorPage ? (
        // <AuthorWantAdditionalTime />
        <></>
      ) : (
        <AdditionalTime title={title} />
      )}

      <Button
        block
        className="modalBtnStyle"
      >
        <Link to={`/yeni-icerik-ekle/${offerId}`}>{formatMessage({ id: 'article.status.sendItem' })}</Link>
      </Button>
    </div>
  );
};

export default SendItemSteps;
