import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { getCookies } from 'fetcher';
import { Loading } from 'components';
import { useGetCurrentUserQuery } from 'services';

export const AppRedirect = () => {
  const navigate = useNavigate();
  const { isFetching } = useGetCurrentUserQuery();

  useEffect(() => {
    const { token: tokenCookie, refreshToken: rtCookie } = getCookies();
    if (!isFetching) {
      if (tokenCookie && rtCookie) {
        navigate('/anasayfa');
      } else {
        navigate('/giris');
      }
    }
  }, [navigate, isFetching]);

  return <Loading />;
};
