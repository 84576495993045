import { Steps } from 'antd';
import { CheckOutlined, CloseOutlined, FormOutlined, SolutionOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { heyetKarariMetin } from 'utils';

const { Step } = Steps;

const STEP_SECTION = {
  null: 'wait-step',
  0: 'waiting-step',
  1: 'process-step',
  2: 'accept-step',
  3: 'revent-step',
  4: 'change-committee-step',
  5: 'decline-step',
  6: 'accept-step',
  7: 'process-step',
  8: 'pullback-step',
  9: 'wait-step',
  17: 'accept-step',
  18: 'decline-step',
};

const ArticleSteps = ({ step, status, committeeStatus, dahStatus, aykStatus, process }) => {
  return (
    <Steps
      className="gx-my-4 "
      labelPlacement="vertical"
    >
      {process < 2 && (
        <Step
          status="finish"
          title={<FormattedMessage id="article.steps.ArticleOffer" />}
          icon={<FormOutlined />}
          className="completed-step"
        />
      )}
      <Step
        className={STEP_SECTION[step > process ? 2 : committeeStatus ?? 1]}
        status={
          step > process ? 'finish' : [0, 1, 2, 3, 4, 19].includes(status) ? 'process' : committeeStatus <= 1 || committeeStatus === null ? 'wait' : 'finish'
        }
        title={
          <div title={committeeStatus !== null ? heyetKarariMetin[committeeStatus] : heyetKarariMetin[0]}>
            <FormattedMessage id="committee" />
          </div>
        }
        icon={<SolutionOutlined title={committeeStatus !== null ? heyetKarariMetin[committeeStatus] : heyetKarariMetin[0]} />}
      />
      <Step
        className={STEP_SECTION[step > process ? 2 : dahStatus]}
        status={
          step > process ? 'finish' : [5, 6, 7, 8, 9].includes(status) ? 'process' : dahStatus <= 1 || dahStatus === null || dahStatus === 4 ? 'wait' : 'finish'
        }
        title={
          <div title={heyetKarariMetin[dahStatus]}>
            <FormattedMessage id="taek" />
          </div>
        }
        icon={<SolutionOutlined title={heyetKarariMetin[dahStatus]} />}
      />
      <Step
        className={STEP_SECTION[step > process ? 2 : aykStatus]}
        status={step > process ? 'finish' : [11, 12, 13, 14, 15].includes(status) ? 'process' : aykStatus <= 1 || aykStatus === null ? 'wait' : 'finish'}
        title={
          <div title={heyetKarariMetin[aykStatus]}>
            <FormattedMessage id="aykShort" />
          </div>
        }
        icon={<SolutionOutlined title={heyetKarariMetin[aykStatus]} />}
      />
      <Step
        className={step > process ? STEP_SECTION[2] : [17, 18].includes(status) && STEP_SECTION[status]}
        status={step > process || status === 17 ? 'finish' : 'wait'}
        title={status === 18 ? <FormattedMessage id="appModule.rejected" /> : <FormattedMessage id="appModule.approve" />}
        icon={status === 18 ? <CloseOutlined title={heyetKarariMetin[aykStatus]} /> : <CheckOutlined />}
      />
    </Steps>
  );
};

export default ArticleSteps;
