import { Button, Card } from 'antd';

const ArticleOfferVersionItem = ({ label, btnText, onClick }) => {
  return (
    <Card>
      <div className="gx-d-flex gx-align-items-center gx-justify-content-between gx-w-100">
        <span>{label}</span>
        <Button
          style={{
            marginBottom: 0,
          }}
          onClick={onClick}
        >
          {btnText}
        </Button>
      </div>
    </Card>
  );
};

export default ArticleOfferVersionItem;
