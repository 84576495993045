import React, { useState } from 'react';

import {
  CaretDownOutlined,
  CaretUpOutlined,
  SwapOutlined,
} from '@ant-design/icons';

const orderStrings = {
  asc: 'desc',
  desc: null,
  null: 'asc',
};

export const Order = ({ type, title, setOrder, order }) => {
  const [orderType, setorderType] = useState({ type: type, order: null });

  const handleClick = () => {
    setOrder({ type: type, order: orderStrings[orderType.order] });
    setorderType({ type: type, order: orderStrings[orderType.order] });
  };

  return (
    <div
      style={{
        cursor: 'pointer',
        padding: '26px 9px',
        margin: '-26px -9px',
        background:
          order.type === type && orderType.order !== null && '#efefef',
      }}
      className="gx-text-center gx-d-flex gx-justify-content-between gx-align-items-center order-hover"
      onClick={() => handleClick()}
    >
      <div>{title}</div>
      <div>
        {order.type === type && orderType.order !== null ? (
          <span className="gx-m-1" style={{ color: '#3DA4E6' }}>
            {' '}
            {orderType.order === 'asc' ? (
              <CaretDownOutlined />
            ) : (
              <CaretUpOutlined />
            )}{' '}
          </span>
        ) : (
          <SwapOutlined
            className="gx-m-1"
            style={{ transform: 'rotate(90deg)' }}
          />
        )}
      </div>
    </div>
  );
};
