import { Card } from 'antd';

const AssigmentNotes = ({ descriptions }) => {
  return (
    <div>
      <h3>Notlar</h3>
      <Card className="gx-p-3">
        {descriptions?.map((item) => (
          <Card
            key={item.id}
            className="gx-mb-3"
          >
            <div>{item.field.roleText}</div>
            <div>{item.description}</div>
          </Card>
        ))}
      </Card>
    </div>
  );
};

export default AssigmentNotes;
