import React, { useState } from 'react';

import { Button, Popover, Row, Col, Checkbox, Divider, Form, Radio, Space } from 'antd';
import { FilterFilled } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

const filterTypes = {
  typeOfResources: '&types.id[]=',
  typeOfAccess: '&accessType=',
};

const Filter = ({ title, setFilters, options, filters, filterType }) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const onFinish = ({ filter }) => {
    if (Array.isArray(filter)) {
      const reducedFilter = filter?.reduce((i, a) => i + a, '');
      if (filters?.includes(filterTypes?.typeOfAccess)) {
        setFilters(filters + reducedFilter);
      } else {
        setFilters(reducedFilter);
      }
    } else {
      if (filters.includes(filterTypes?.typeOfResources)) {
        setFilters(filters + filter);
      } else {
        setFilters(filter);
      }
    }
  };

  const handleVisible = (visible) => {
    setVisible(visible);
  };

  const content = (
    <Form
      form={form}
      style={{
        width: 200,
      }}
      className="gx-p-2"
      onFinish={onFinish}
    >
      <Form.Item name="filter">
        {filterType === 'typeOfResources' ? (
          <Checkbox.Group
            name="filter"
            style={{ overflow: 'auto', maxHeight: 250, width: '100%' }}
          >
            <Row className="filter-row">
              {options?.map((type) => (
                <Col
                  key={type.id}
                  span="24"
                  className="colon-hover"
                >
                  <Checkbox
                    className="gx-w-100"
                    value={`${filterTypes[filterType]}${type.id || type.value}`}
                  >
                    {type.name}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        ) : (
          <Radio.Group>
            <Space direction="vertical">
              {options.map((type) => (
                <Radio
                  key={type.value}
                  value={`${filterTypes[filterType]}${type.value}`}
                >
                  {type.name}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        )}
      </Form.Item>
      <Divider />
      <Col className="gx-d-flex gx-justify-content-between">
        <Button
          className="gx-mb-2"
          type="link"
          htmlType="submit"
          onClick={() => {
            const removedFilters = Array.isArray(form.getFieldValue('filter'))
              ? form.getFieldValue('filter')?.reduce((i, a) => i + a, '')
              : form.getFieldValue('filter');
            setVisible(!visible);
            setFilters(filters.replaceAll(removedFilters, ''));
            form.resetFields();
          }}
          disabled={!form.getFieldValue('filter')}
        >
          <FormattedMessage id="article.filter.clear" />
        </Button>
        <Button
          className="gx-mb-2"
          type="primary"
          htmlType="submit"
          onClick={() => setVisible(!visible)}
        >
          <FormattedMessage id="article.filter" />
        </Button>
      </Col>
    </Form>
  );

  return (
    <div className="ant-table-filter-column">
      <span className="ant-table-filter-column-title">{title}</span>
      <Popover
        content={content}
        placement="bottom"
        trigger="click"
        open={visible}
        onOpenChange={handleVisible}
      >
        <span className="ant-table-filter-trigger-container">
          <span className="ant-dropdown-trigger ant-table-filter-trigger">
            <FilterFilled
              style={{
                color: filters?.indexOf(filterTypes[filterType]) > -1 && '#3DA4E6',
              }}
            />
          </span>
        </span>
      </Popover>
    </div>
  );
};

export default Filter;
