import apiInstance from './api';
const guideApi = apiInstance.injectEndpoints({
  endpoints: (build) => ({
    getGuides: build.query({
      query: () => '/guides',
      transformResponse: (response) => response['hydra:member'],
      providesTags: ['Guides'],
    }),
  }),
  overrideExisting: true,
});
export default guideApi;
