import useSWR from 'swr';

const useArticleTypes = () => {
  const { data, error } = useSWR('/article_types', {
    revalidateOnFocus: false,
  });
  return {
    articleTypes: data,
    isLoading: !error && !data,
    isError: error,
  };
};
export default useArticleTypes;
