import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { higherCommittee } from 'constant/static';
import useSWR from 'swr';

const initState = {
  user: null,
  isLoading: false,
  isError: false,
  isManager: false,
  canOffer: false,
  isResourceManager: false,
  isAdmin: false,
  isSuperAdmin: false,
  memberships: [],
  canWhichCommitteeOffer: [],
  isDAH: false,
  isAYK: false,
  isEncyclopedia: false,
  isE2User: false,
  isOrderUnit: false,
  isAuthor: false,
};

const userInfoContext = createContext(initState);

const UserInfoProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState({});

  const { data, error } = useSWR('/current_user', {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateOnMount: true,
  });

  useEffect(() => {
    if (data) {
      setUserInfo({
        isSuperAdmin: data.roles.includes('ROLE_SUPER_ADMIN'),
        isAdmin: data.roles.includes('ROLE_SUPER_ADMIN') || data.roles.includes('ROLE_ADMIN'),
        isResourceManager: data.roles.includes('ROLE_ADMIN') || data.roles.includes('ROLE_SUPER_ADMIN') || data.roles.includes('ROLE_ERESOURCE'),

        isDAH: data.roles.includes('ROLE_DAH'),
        isAYK: data.roles.includes('ROLE_AYK'),
        isEncyclopedia: data.roles.includes('ROLE_ENCYCLOPEDIA'),
        isE2User: data.roles.includes('ROLE_USER'),
        isOrderUnit: data.roles.includes('ROLE_ORDER_UNIT'),
        isAuthor: data.roles.includes('ROLE_AUTHOR'),

        isManager:
          data.roles.includes('ROLE_ADMIN') || data.roles.includes('ROLE_SUPER_ADMIN') || data.memberships.some((item) => item.type === 1 || item.type === 0),
        canOffer:
          data.roles.includes('ROLE_ADMIN') ||
          data.roles.includes('ROLE_SUPER_ADMIN') ||
          data.roles.includes('ROLE_ENCYCLOPEDIA') ||
          data.memberships.some((item) => !higherCommittee.includes(item.committee.id) && (item.type === 1 || item.type === 0)),
        canWhichCommitteeOffer: data.memberships.filter((item) => !higherCommittee.includes(item.committee.id) && (item.type === 1 || item.type === 0)),

        memberships: data.memberships,
      });
    }
  }, [data]);

  const value = useMemo(() => {
    return {
      user: data,
      isLoading: !error && !data,
      isError: error,
      ...userInfo,
    };
  }, [data, error, userInfo]);

  return <userInfoContext.Provider value={value}>{children}</userInfoContext.Provider>;
};

export default UserInfoProvider;

export const useUserInfo = () => {
  return useContext(userInfoContext);
};
