import React, { useEffect, useState } from 'react';

import { Menu, Tooltip } from 'antd';
import {
  CopyOutlined,
  FileTextOutlined,
  FolderAddOutlined,
  FormOutlined,
  MenuOutlined,
  PlusCircleOutlined,
  ReadOutlined,
  TableOutlined,
  UserAddOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { CircularProgress, CustomScrollbars } from 'components';
import { useGetCommitteesQuery, useGetCurrentUserQuery } from 'services';
import { higherCommittee } from 'constant/static';

import SidebarLogo from './SidebarLogo';

const rootSubmenuKeys = [
  'yeni-madde-teklifi',
  'madde-teklifleri',
  'maddelerim',
  'uye-islemleri',
  'yazar-islemleri',
  'heyetler',
  'e-kaynaklar',
  'loglar',
  'logout',
];

const subKeys = ['teklif-edilen-maddeler', 'taslak-maddeler', 'sorumlu-olunan-maddeler', 'gorüs-istenen-maddeler', 'heyetten-cikan-maddeler', 'heyet-detay'];

const SidebarContent = () => {
  const { data: committees, isLoading: committeesLoading } = useGetCommitteesQuery();

  const { data: current_user, isLoading } = useGetCurrentUserQuery();
  const {
    isAdmin,
    isResourceManager,
    isEncyclopedia,
    isManager,
    isSuperAdmin,
    pubEmployee,
    euEmployee,
    isAuthor,
    isComittee,
    isOrderUnit,
    canOffer,
    afterCopyrightRoles,
    memberships,
  } = current_user;

  let location = useLocation();

  const { pathname } = location;

  const selectedKeys = pathname.substr(1);
  let defaultOpenKeys = selectedKeys.split('/');

  const [openKeys, setOpenKeys] = useState();
  const [openKeysSub, setOpenKeysSub] = useState();

  useEffect(() => {
    const selectedKeys = location.pathname.substr(1);
    const openKeys = selectedKeys.split('/');
    setOpenKeys(openKeys);
  }, [location]);

  if (isLoading || committeesLoading) return <CircularProgress />;

  let subRootSubMenuKeys = [];

  const onOpenChange = (keys, type) => {
    const keysObj = {
      openedKeys: type === 'root' ? openKeys : openKeysSub,
      menuArray: type === 'root' ? rootSubmenuKeys : subRootSubMenuKeys,
    };
    const handleKeys = (keys) => {
      if (type === 'rootSingle') {
        setOpenKeys(keys);
        setOpenKeysSub([]);
      }
      if (type === 'subSingle') {
        setOpenKeysSub(keys);
        setOpenKeys(['madde-teklifleri']);
      }
      if (type === 'root') {
        setOpenKeys(keys);
      }
      if (type === 'sub') {
        setOpenKeysSub(keys);
      }
    };

    if (['root', 'sub'].includes(type)) {
      const latestOpenKey = keys.find((key) => !keysObj.openedKeys?.includes(key));

      if (!keysObj.menuArray.includes(latestOpenKey)) {
        handleKeys(keys);
      } else {
        handleKeys(latestOpenKey ? [latestOpenKey] : []);
      }
    } else {
      handleKeys(keys);
    }
  };

  const setDefaultOpenKeys = () => {
    let isSub = false;
    subKeys.forEach((key) => {
      if (defaultOpenKeys.includes(key)) {
        isSub = true;
      }
    });

    if (isSub) {
      defaultOpenKeys = [defaultOpenKeys[0], `/${defaultOpenKeys[0]}/${defaultOpenKeys[1]}`, `${defaultOpenKeys[1]}/${defaultOpenKeys[2]}`];
    }
  };
  if (current_user) {
    if (isAdmin || (isEncyclopedia && committees)) {
      subRootSubMenuKeys = committees.map((item) => '' + item.id);
      subRootSubMenuKeys = [...subRootSubMenuKeys, 'tum-teklifler'];
    }
    if (!isAdmin && memberships) {
      subRootSubMenuKeys = memberships?.map((item) => item.committee.id);
    }
  }
  setDefaultOpenKeys();

  /**
   * Eğer kullanıcı admin ise tüm komiteleri görebilir, değilse sadece kendi komitelerini görebilir.
   */
  const userCommitees = isAdmin ? committees : memberships;
  /**
   * eğer kulanıcı admin ise dönen end pointte direk veriye erişebilir ancak değilse içinde bulunduğu komiteler memberships içinden geleceği için key.commitee şeklinde erişilebiliyor bu sebeble bu fonksiyon oluşturuldu.
   */
  const handleUserCommiteesKeys = (item) => (isAdmin ? item : item.committee);

  const menuItems = [
    current_user && (isAdmin || isResourceManager || isEncyclopedia)
      ? {
          key: 'e-kaynaklar',
          icon: <CopyOutlined className="iconPositions" />,
          label: (
            <Link to="/e-kaynaklar">
              <FormattedMessage id="resources.Title" />
            </Link>
          ),
        }
      : [],
    current_user && (isAdmin || isResourceManager || isEncyclopedia)
      ? {
          key: 'sabit-sayfalar',
          icon: <CopyOutlined className="iconPositions" />,
          label: <Link to="/sabit-sayfalar">Sabit Sayfalar</Link>,
        }
      : [],
    canOffer || isManager
      ? {
          key: 'yeni-madde-teklifi',
          icon: <PlusCircleOutlined className="gx-d-inline-block iconPositions" />,
          label: (
            <Tooltip
              title={
                !canOffer &&
                'Bu alan şu anda sadece ilim heyeti başkan ve sekreterleri tarafından "Madde Tespit Formu" ile madde teklifi yapmak için kullanılmaktadır.'
              }
              placement="rightBottom"
              key="yeni-madde-teklifi"
            >
              <FormattedMessage id="sidebar.newOffer" />
            </Tooltip>
          ),
          children:
            canOffer || isManager
              ? [
                  {
                    key: 'madde-tespit-formu',
                    icon: <FormOutlined className="iconPositions" />,
                    label: (
                      <Link to="/yeni-madde-teklifi/madde-tespit-formu">
                        <FormattedMessage id="article.form.detectionFormTitle" />
                      </Link>
                    ),
                  },
                ]
              : [],
        }
      : [],

    isComittee || isManager || isOrderUnit || isAdmin
      ? {
          key: 'madde-teklifleri',
          icon: <TableOutlined className="gx-d-inline-block iconPositions" />,
          label: <FormattedMessage id="sidebar.articlerOffers" />,
          children: [
            {
              key: 'tum-maddeler',
              icon: <MenuOutlined className="iconPositions" />,
              label: (
                <Link to="/madde-teklifleri/tum-maddeler">
                  <FormattedMessage id="sidebar.allOffers" />
                </Link>
              ),
            },
            ...(isAdmin || isManager || memberships.length > 0
              ? userCommitees?.map((item) => ({
                  key: handleUserCommiteesKeys(item).id,
                  label: higherCommittee.includes(handleUserCommiteesKeys(item).id) ? (
                    <Link to={`/madde-teklifleri/${handleUserCommiteesKeys(item).id}`}>
                      <div>{handleUserCommiteesKeys(item).shortName}</div>
                    </Link>
                  ) : (
                    <div>{handleUserCommiteesKeys(item).name}</div>
                  ),
                  className: 'committeess',
                  children: !higherCommittee.includes(handleUserCommiteesKeys(item).id) && [
                    {
                      key: `${handleUserCommiteesKeys(item).id}/taslak-maddeler`,
                      className: 'subtitle',
                      label: (
                        <Link
                          to={`/madde-teklifleri/${handleUserCommiteesKeys(item).id}/taslak-maddeler`}
                          pros={handleUserCommiteesKeys(item).name}
                        >
                          <FormattedMessage id="sidebar.draftArticles" />
                        </Link>
                      ),
                    },
                    {
                      className: 'subtitle',
                      key: `${handleUserCommiteesKeys(item).id}/sorumlu-olunan-maddeler`,
                      label: (
                        <Link to={`/madde-teklifleri/${handleUserCommiteesKeys(item).id}/sorumlu-olunan-maddeler`}>
                          <FormattedMessage id="sidebar.ownerArticles" />
                        </Link>
                      ),
                    },
                    {
                      className: 'subtitle',
                      key: `${handleUserCommiteesKeys(item).id}/gorüs-istenen-maddeler`,
                      label: (
                        <Link to={`/madde-teklifleri/${handleUserCommiteesKeys(item).id}/gorus-istenen-maddeler`}>
                          <FormattedMessage id="sidebar.askDecisionArticles" />
                        </Link>
                      ),
                    },
                    {
                      className: 'subtitle',
                      key: `${handleUserCommiteesKeys(item).id}/heyetten-cikan-maddeler`,
                      label: (
                        <Link to={`/madde-teklifleri/${handleUserCommiteesKeys(item).id}/heyetten-cikan-maddeler`}>
                          <FormattedMessage id="sidebar.removedFromCommitteeArticles" />
                        </Link>
                      ),
                    },
                  ],
                }))
              : []),
          ],
        }
      : afterCopyrightRoles
      ? {
          key: 'madde-teklifleri',
          icon: <TableOutlined className="gx-d-inline-block iconPositions" />,
          label: <FormattedMessage id="sidebar.articlerOffers" />,
          children: [
            {
              key: 'tum-maddeler',
              icon: <MenuOutlined className="iconPositions" />,
              label: (
                <Link to="/madde-teklifleri/tum-maddeler/telif-sonrasi">
                  <FormattedMessage id="sidebar.allOffers" />
                </Link>
              ),
            },
            euEmployee
              ? {
                  key: 'ansiklopedi-birimi',
                  label: 'Ansiklopedi Birimi',
                  className: 'committeess',
                  children: [
                    {
                      key: 'bana-atananlar',
                      label: <Link to={`/madde-teklifleri/ansiklopedi-birimi/bana-atananlar`}>Bana Atananlar</Link>,
                    },
                    {
                      key: 'kontrol-edilecekler',
                      label: <Link to={`/madde-teklifleri/ansiklopedi-birimi/kontrol-edilecekler`}>Kontrol Edilecekler</Link>,
                    },
                  ],
                }
              : [],
            pubEmployee
              ? {
                  key: 'yayin-mudurlugu',
                  label: 'Yayın Müdürlüğü',
                  className: 'committeess',
                  children: [
                    {
                      key: 'bana-atananlar',
                      label: <Link to={`/madde-teklifleri/yayin-mudurlugu/bana-atananlar`}>Bana Atananlar</Link>,
                    },
                    {
                      key: 'kontrol-edilecekler',
                      label: <Link to={`/madde-teklifleri/yayin-mudurlugu/kontrol-edilecekler`}>Kontrol Edilecekler</Link>,
                    },
                  ],
                }
              : [],
          ].flat(),
        }
      : [],
    isAuthor
      ? {
          key: 'maddelerim',
          icon: <ReadOutlined className="iconPositions" />,
          label: (
            <Link to="/maddelerim">
              <FormattedMessage id="sidebar.authorArticleOffers" />
            </Link>
          ),
        }
      : [],
    isAdmin || isEncyclopedia
      ? {
          key: 'uye-islemleri',
          icon: <UserOutlined className="gx-d-inline-block iconPositions" />,
          label: 'Üye İşlemleri',
          children: [
            {
              key: 'uye-ekle',
              icon: <UserAddOutlined className="iconPositions" />,
              label: (
                <Link to="/uye-islemleri/uye-ekle">
                  <FormattedMessage id="userAdd.userAdd" />
                </Link>
              ),
            },
            {
              key: 'uye-listesi',
              icon: <UserSwitchOutlined className="iconPositions" />,
              label: (
                <Link to="/uye-islemleri/uye-listesi">
                  <FormattedMessage id="userList.title" />
                </Link>
              ),
            },
          ],
        }
      : [],
    isAdmin || isManager || isOrderUnit
      ? {
          key: 'yazar-islemleri',
          icon: <UserOutlined className="gx-d-inline-block iconPositions" />,
          label: <FormattedMessage id="author.actions" />,
          children: [
            isAdmin || isManager
              ? {
                  key: 'yazar-ekle',
                  icon: <UserAddOutlined className="iconPositions" />,
                  label: (
                    <Link to="/yazar-islemleri/yazar-ekle">
                      <FormattedMessage id="author.addAuthor" />
                    </Link>
                  ),
                }
              : null,
            {
              key: 'yazar-listesi',
              icon: <UserSwitchOutlined className="iconPositions" />,
              label: (
                <Link to="/yazar-islemleri/yazar-listesi">
                  <FormattedMessage id="author.allAuthors" />
                </Link>
              ),
            },
          ],
        }
      : [],
    {
      key: 'heyetler',
      icon: <UserOutlined className="gx-d-inline-block iconPositions" />,
      label: <FormattedMessage id="sidebar.committeeList" />,
      children: [
        ...(committees &&
          committees
            .filter((item) => higherCommittee.includes(item.id))
            .map((commitee) => ({
              key: `/heyetler/heyet-detay/${commitee.id}`,
              label: <Link to={`/heyetler/heyet-detay/${commitee.id}`}>{commitee.shortName}</Link>,
            }))),
        {
          key: 'ilim-heyetleri',
          icon: <MenuOutlined className="iconPositions" />,
          label: (
            <Link to="/heyetler/ilim-heyetleri">
              <FormattedMessage id="committees.Title" />
            </Link>
          ),
        },
      ],
    },
    isSuperAdmin
      ? {
          key: 'loglar',
          label: 'Loglar',
          icon: <FileTextOutlined className="gx-d-inline-block iconPositions" />,
          children: [
            {
              key: 'tum-loglar',
              label: (
                <Link to="/loglar/tum-loglar">
                  <FormattedMessage id="logs.allLogs" />
                </Link>
              ),
            },
          ],
        }
      : [],
    isAdmin
      ? {
          key: 'dosya-yonetimi',
          icon: <FolderAddOutlined className="gx-d-inline-block iconPositions" />,
          label: (
            <Link to="/dosya-yonetimi">
              <FormattedMessage id="file.managment" />
            </Link>
          ),
        }
      : [],
  ];

  return (
    <>
      <div className="gx-d-flex gx-flex-column h-100">
        <SidebarLogo />
        <div className="gx-sidebar-content">
          <div className="gx-d-flex gx-flex-column gx-flex-1">
            <CustomScrollbars className="gx-layout-sider-scrollbar-sidebar">
              <Menu
                className="gx-d-flex gx-flex-column gx-h-100 gx-py-3 sidebarItemPositions"
                theme="dark"
                mode="inline"
                onClick={(e) => {
                  setOpenKeys(e.key);
                }}
                onOpenChange={(e) => onOpenChange(e, 'root')}
                onSelect={({ keyPath }) => {
                  onOpenChange(keyPath, 'rootSingle');
                }}
                defaultOpenKeys={openKeys}
                defaultSelectedKeys={defaultOpenKeys}
                selectedKeys={defaultOpenKeys}
                openKeys={openKeys}
                items={menuItems.flat()}
              />
            </CustomScrollbars>
          </div>
        </div>
      </div>
    </>
  );
};

export default SidebarContent;
