import { useEffect } from 'react';

import { Form as AntForm } from 'antd';
import { useKeyboard } from 'hooks';

import { FormItem } from './KeyboardFormItem';
import { FormList } from './KeyboardFormList';

/**
 * Sadece ana Form u buradan almamız yeterli olacaktır Form.Item ve Form.List i buradan almak zorunda değiliz.
 */
export const Form = ({ children, form, onFieldsChange, ...props }) => {
  const { inputName, setInputName, value, setValue } = useKeyboard();

  useEffect(() => {
    if (!form?.setFieldsValue || !inputName) return;

    const [field, index, key] = inputName.split('.');

    if (index && key) {
      const clone = form.getFieldValue(field);

      if (clone) {
        const item = clone[index];
        clone[index] = { ...item, [key]: value };

        form.setFieldsValue({
          [field]: clone,
        });
      }
    } else {
      form.setFieldsValue({
        [field]: value,
      });
    }
  }, [form, inputName, value]);

  const onChange = (field, all) => {
    if (field?.length === 0) return;

    const [{ name, value }] = field;

    const fieldName = name.join('.');

    if (!Array.isArray(value)) {
      setInputName(fieldName);
      setValue(value);
    }

    if (onFieldsChange) {
      onFieldsChange(field, all);
    }
  };

  return (
    <AntForm
      {...props}
      {...(form.getFieldValue ? { form, onFieldsChange: onChange } : {})}
    >
      {children}
    </AntForm>
  );
};

Form.Item = FormItem;
Form.List = FormList;
Form.useForm = AntForm.useForm;
Form.useFormInstance = AntForm.useFormInstance;
Form.useWatch = AntForm.useWatch;
