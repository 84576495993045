import React, { useEffect, useState } from 'react';

import { Collapse, Button, Divider, Form, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { editAuthor } from 'fetcher';
import { openNotification } from 'utils';
import { CircularProgress, RemoveButton } from 'components';
import { fetchError } from 'slices/commonSlice';
import { useGetAuthorWorksQuery } from 'services';

const { Panel } = Collapse;
const { TextArea } = Input;

export default ({ author, editable }) => {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { data: works, isLoading: worksLoading } = useGetAuthorWorksQuery({
    pagination: false,
    author: author.id,
  });

  const [loading, setLoading] = useState(false);
  const [activePanels, setActivePanels] = useState([]);

  useEffect(() => {
    if (!works || !author) return;

    // authorWorks arrayini obje entrylerine donustur
    const authorWorks = author.authorWorks.reduce((acc, work) => {
      const workInfo = works.find((w) => w['@id'] === work.work['@id']);
      const { title, description } = work;

      if (!acc[workInfo['@id']]) {
        acc[workInfo['@id']] = [];
      }

      acc[workInfo['@id']].push({ title, description });
      return acc;
    }, {});

    setActivePanels(Object.keys(authorWorks));
    form.setFieldsValue(authorWorks);
  }, [works, author, form]);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const { id } = author;

      // tüm work içeriklerini tek arrayde topla
      const authorWorks = Object.entries(values)
        .reduce((acc, [wid, work]) => {
          const works = work?.map((w) => ({
            ...w,
            work: wid,
          }));
          return acc.concat(works);
        }, [])
        .filter((w) => !!w);

      await editAuthor(id, { authorWorks, user: { id: author.user['@id'] } });
      openNotification({
        message: formatMessage({ id: 'appModule.actionSuccessfully' }),
      });
    } catch (error) {
      dispatch(
        fetchError(
          formatMessage(
            {
              id: 'article.form.fetchError',
            },
            { error: `${error}` }
          )
        )
      );
    }
    setLoading(false);
  };

  if (worksLoading) return <CircularProgress />;

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onFinish}
    >
      {works?.map((work) => (
        <Collapse
          key={work.id}
          expandIconPosition="right"
          bordered={false}
          activeKey={activePanels}
          onChange={(key) => setActivePanels(key)}
        >
          <Panel
            key={work['@id']}
            header={<h4 className="gx-m-0">{work.name}</h4>}
            className="gx-mb-3"
          >
            <Form.List name={work['@id']}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name }) => (
                    <React.Fragment key={key}>
                      <div className="gx-flex-row gx-justify-content-between ant-form-item-label">
                        <label className="ant-form-item-required gx-mb-0">
                          <FormattedMessage id="author.work.workTitle" />
                        </label>

                        <RemoveButton
                          disabled={!editable}
                          onClick={() => remove(name)}
                        />
                      </div>

                      <Form.Item
                        className="gx-mb-2"
                        name={[name, 'title']}
                        rules={[
                          {
                            required: true,
                            message: formatMessage(
                              { id: 'appModule.requiredFieldMessage' },
                              {
                                field: formatMessage({
                                  id: 'author.work.workTitle',
                                }),
                              }
                            ),
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        name={[name, 'description']}
                        label={<FormattedMessage id="author.work.workDescription" />}
                      >
                        <TextArea rows={3} />
                      </Form.Item>

                      <Divider className="gx-my-3" />
                    </React.Fragment>
                  ))}

                  <Button
                    type="dashed"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                    disabled={!editable}
                    className="gx-m-0"
                    block
                  >
                    {' '}
                    <FormattedMessage id="appModule.addNew" />
                  </Button>

                  <Form.Item shouldUpdate>
                    {({ getFieldValue }) => (
                      <div className="gx-flex-row gx-justify-content-end">
                        <Button
                          className="gx-mb-0 gx-mt-3"
                          type="primary"
                          htmlType="submit"
                          loading={loading}
                          disabled={!editable || !getFieldValue(work['@id'])}
                        >
                          <FormattedMessage id="appModule.save" />
                        </Button>
                      </div>
                    )}
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Panel>
        </Collapse>
      ))}
    </Form>
  );
};
