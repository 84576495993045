import apiInstance from './api';

const roleApi = apiInstance.injectEndpoints({
  endpoints: (builder) => ({
    getRoles: builder.query({
      query: () => '/roles',
      providesTags: ['Roles'],
    }),
  }),
  overrideExisting: true,
});

export default roleApi;
