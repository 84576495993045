import { useState, useEffect, useCallback } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { passwordResetValidateToken } from 'fetcher';
import { fetchError, fetchStart, fetchSuccess } from 'slices/commonSlice';
import { useIntl } from 'react-intl';

export const useResetPasswordValidateToken = ({ token = null, hasToken }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const [isValidate, setIsValidate] = useState(true);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);

  const checkToken = useCallback(
    async (token) => {
      setLoading(true);
      dispatch(fetchStart());
      const data = { token };
      try {
        const res = await passwordResetValidateToken(data);
        dispatch(fetchSuccess());
        setLoading(false);
        setIsValidate(true);
        setUser(res.user);
      } catch (error) {
        dispatch(
          fetchError(
            formatMessage({
              id: 'app.userAuth.PassResetTokenTimeoutError',
            })
          )
        );

        setIsValidate(false);
        setLoading(false);
        setTimeout(() => {
          navigate('/');
        }, 4000);
      }
    },
    [dispatch, formatMessage, navigate]
  );

  useEffect(() => {
    if (hasToken) {
      checkToken(token);
    }
  }, [token, checkToken, hasToken]);

  return { isValidate, user, loading };
};
