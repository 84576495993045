import React from 'react';

import { Card, Collapse } from 'antd';
import dayjs from 'dayjs';
import { useGetArticleOfferProcessQuery } from 'services';

const { Panel } = Collapse;

const ArticleOrderAdditionalNote = ({ id }) => {
  const { data: processTrack } = useGetArticleOfferProcessQuery(id);

  const pendingStatus = processTrack && processTrack.filter((item) => item.type === 2 && item.additionalNote !== null);
  return (
    <Card
      style={{
        display: pendingStatus && pendingStatus.length > 0 ? 'block' : 'none',
      }}
    >
      <h3 className="gx-mb-2">Sipariş Birimi Notu</h3>
      <Collapse>
        {pendingStatus &&
          pendingStatus.map((item) => (
            <Panel
              key={item.id}
              className="gx-fit-text"
              header={`${dayjs(item.startDate).format('DD-MM-YYYY HH:mm')} tarihli not`}
            >
              {item.additionalNote}
            </Panel>
          ))}
      </Collapse>
    </Card>
  );
};

export default ArticleOrderAdditionalNote;
