import { Form, Select } from 'antd';
import Refund from 'components/ArticleOffer/offer/ArticleStatus/Refund';

const options = [
  // {
  //   value: 'owner_committee',
  //   label: 'Sorumlu İlim Heyeti',
  // },
  {
    value: 'eu_control',
    label: 'Ansiklopedi Birimi',
  },
  {
    value: 'pub_control',
    label: 'Yayın Müdürlüğü',
  },
];

const EncylopeiaManagementUnitRevert = ({ step, status, commitee }) => {
  const form = Form.useFormInstance();

  const selectedCommittee = Form.useWatch('to', form);

  return (
    <>
      <Form.Item
        className="gx-mb-5"
        rules={[
          {
            required: true,
            message: 'Bu Alan Zorunludur',
          },
        ]}
        name="to"
      >
        <Select
          placeholder="İade etmek istediğiniz birimi seçin"
          options={options}
        />
      </Form.Item>
      {selectedCommittee && (
        <Refund
          toCommittee={selectedCommittee}
          step={step}
          status={status}
          committee={commitee}
        />
      )}
    </>
  );
};

export default EncylopeiaManagementUnitRevert;
