import { Button, Form, Input } from 'antd';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useGiveDecisionForEncylopediaUnitMutation } from 'services';
import { fetchError } from 'slices/commonSlice';
import { openNotification } from 'utils';

const { TextArea } = Input;

const GiveOpinionForEncylopediaUnit = ({ offerID, handleOpinion }) => {
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const [giveDecisionForEncylopediaUnitMutation] = useGiveDecisionForEncylopediaUnitMutation();
  const dispatch = useDispatch();
  const onFinish = async (values) => {
    const data = {
      status: 2,
      ...values,
    };
    const { error } = await giveDecisionForEncylopediaUnitMutation({ data, offerID });
    if (error) {
      return dispatch(
        fetchError(
          formatMessage(
            {
              id: 'article.form.fetchError',
            },
            { error: error.status }
          )
        )
      );
    }
    openNotification({
      message: formatMessage({
        id: 'appModule.actionSuccessfully',
      }),
    });
    form.resetFields();
    handleOpinion(false);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
    >
      <Form.Item
        rules={[
          {
            required: true,
            message: 'Notunuzu Giriniz',
          },
          {
            min: 100,
            message: 'Notunuz 100 karakterden az olamaz',
          },
        ]}
        normalize={(value) => (value.trim() === '' ? undefined : value)}
        name="description"
      >
        <TextArea
          placeholder="Notunuzu Giriniz"
          showCount
          rows={4}
        />
      </Form.Item>
      <Button
        block
        className="gx-mt-3"
        htmlType="submit"
      >
        Gönder
      </Button>
    </Form>
  );
};

export default GiveOpinionForEncylopediaUnit;
