import React from 'react';

import { Card, Col, Form, Row, Select } from 'antd';
import { Input } from 'components';
import { ArticleGroupRadioGroup } from 'components/RadioGroup';
import { FormattedMessage, useIntl } from 'react-intl';
import { useUserInfo } from 'providers/UserInfoProvider';
import { ArticleTypeSelect } from 'components/Select';
import { useGetCommitteesByCategoryQuery } from 'services';

const { TextArea } = Input;

const DetectionInfo = ({ isDetailPage }) => {
  const { formatMessage } = useIntl();
  const { isAdmin, canWhichCommitteeOffer } = useUserInfo();
  const { data: committees, isLoading: loadingCommittees } = useGetCommitteesByCategoryQuery({
    head: false,
  });
  const form = Form.useFormInstance();

  // bu form üzerindeki articleType alanının değişimini izlemek için kullanılıyor
  const articleType = Form.useWatch('articleType', form);

  return (
    <>
      <Card
        style={{ marginBottom: 12, border: 0 }}
        hoverable
      >
        <Form.Item
          name="ownerCommittee"
          label={<FormattedMessage id="article.detail.ownerCommittee" />}
          required
          initialValue={!isAdmin ? (canWhichCommitteeOffer?.length === 1 ? canWhichCommitteeOffer[0]?.committee?.['@id'] : null) : null}
          rules={[
            {
              required: true,
              message: formatMessage({
                id: 'appModule.requiredMessage',
              }),
            },
          ]}
        >
          {isAdmin || isDetailPage ? (
            <Select
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              placeholder={formatMessage({
                id: 'committees.Title',
              })}
              options={
                !loadingCommittees &&
                committees?.['hydra:member'].map((item) => ({
                  key: item['@id'],
                  value: item['@id'],
                  label: item.name,
                }))
              }
            />
          ) : (
            <Select
              placeholder={formatMessage({
                id: 'committees.Title',
              })}
              name={'ownerCommittee'}
              disabled={canWhichCommitteeOffer?.length === 1}
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              options={canWhichCommitteeOffer?.map((item) => ({
                key: item.committee.id,
                value: item.committee['@id'],
                label: item.committee.name,
              }))}
            />
          )}
        </Form.Item>
      </Card>
      <Card
        style={{ marginBottom: 12, border: 0 }}
        hoverable
      >
        <Form.Item
          name={'title'}
          label={<FormattedMessage id="article.form.OfferedArticleTitle" />}
          labelfor={'title'}
          rules={[
            {
              required: true,
              message: formatMessage({
                id: 'appModule.requiredMessage',
              }),
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Card>
      <Row
        gutter={24}
        style={{
          flexDirection: 'row',
        }}
      >
        <Col
          sm={24}
          md={12}
          className="gx-w-100"
        >
          <Card
            bordered={false}
            hoverable
          >
            <Form.Item
              name="articleGroup"
              labelfor={'articleGroup'}
              label={<FormattedMessage id="article.detail.articleGroup" />}
              rules={[
                {
                  required: true,
                  message: formatMessage({
                    id: 'appModule.requiredMessage',
                  }),
                },
              ]}
            >
              <ArticleGroupRadioGroup />
            </Form.Item>
          </Card>
        </Col>
        <Col
          sm={24}
          md={12}
          className="gx-w-100"
        >
          <Card
            bordered={false}
            hoverable
          >
            <Form.Item
              name="articleType"
              labelfor={'articleType'}
              label={<FormattedMessage id="article.detail.articleType" />}
              rules={[
                {
                  required: true,
                  message: formatMessage({
                    id: 'appModule.requiredMessage',
                  }),
                },
              ]}
            >
              <ArticleTypeSelect />
            </Form.Item>
            <Form.Item dependencies={['articleType']}>
              {() => {
                if (articleType === 'Diğer') {
                  return (
                    <Form.Item
                      name="articleTypeOther"
                      className="gx-mt-2"
                      rules={[
                        {
                          required: true,
                          message: formatMessage({
                            id: 'appModule.requiredMessage',
                          }),
                        },
                        {
                          min: 100,
                          message: formatMessage({
                            id: 'article.reasonLengthMessage',
                          }),
                        },
                      ]}
                    >
                      <TextArea
                        name="articleTypeOther"
                        showCount
                        placeholder={formatMessage({
                          id: 'article.form.OtherArticleTypePlaceholder',
                        })}
                      />
                    </Form.Item>
                  );
                }
              }}
            </Form.Item>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default DetectionInfo;
