import AfterCopyrightContentControl from 'module/AfterCopyright/AfterCopyrightContentControl';

const EncyclopediaManagementUnit = ({ offer }) => {
  return (
    <AfterCopyrightContentControl
      offer={offer}
      committee="ayk_control"
    />
  );
};
export default EncyclopediaManagementUnit;
