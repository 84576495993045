import React from 'react';

const TimePoint = ({ count }) => {
  return (
    <div className="timeline-point">
      <span style={{ color: '#000' }}> {count}</span>
    </div>
  );
};

export default TimePoint;
