import React, { useState } from 'react';

import { Table, Tag, Dropdown, Input } from 'antd';
import { DownOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { useIntl, FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import { useWindowSize } from 'hooks';
import { ROLE } from 'utils';
import { useUserInfo } from 'providers/UserInfoProvider';

const { Search } = Input;

const CommitteeMembers = ({ userlist, giveAuthorityToUser, userRemoveFromCommittee, tableLoading, edit }) => {
  const intl = useIntl();
  const [searcResult, setSearcResult] = useState();

  const { isAdmin } = useUserInfo();
  const { height } = useWindowSize();

  const config = {
    pagination: false,
    scroll: { x: 990, y: height - 300 },
  };

  const searchName = (e) => {
    setSearcResult(userlist.filter((item) => item.user.fullName.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase())));
  };
  const menuItems = (user) => {
    const items = [
      user.type !== 0
        ? {
            key: '1',
            label: <FormattedMessage id="committees.head" />,
            onClick: () => giveAuthorityToUser(user.id, user.user['@id'], 0),
          }
        : [],
      user.type !== 1
        ? {
            key: '2',
            label: <FormattedMessage id="committees.secretary" />,
            onClick: () => giveAuthorityToUser(user.id, user.user['@id'], 1),
          }
        : [],
      user.type !== 3
        ? {
            key: '3',
            label: <FormattedMessage id="committees.spacialist" />,
            onClick: () => giveAuthorityToUser(user.id, user.user['@id'], 3),
          }
        : [],

      user.type !== 2
        ? {
            key: '4',
            label: <FormattedMessage id="committees.member" />,
            onClick: () => giveAuthorityToUser(user.id, user.user['@id'], 2),
          }
        : [],
    ];
    return items.flat();
  };

  let columnMembers = [
    {
      title: '#',
      width: 100,
      render: (_i, _r, index) => <span>{index + 1}</span>,
    },
    {
      title: <FormattedMessage id="appModule.name" />,
      sorter: (a, b) => a?.user.firstName?.localeCompare(b?.user.firstName),
      sortDirections: ['descend', 'ascend'],
      width: 150,
      render: (item) => <div>{item?.user?.firstName}</div>,
    },
    {
      title: <FormattedMessage id="appModule.surname" />,
      sorter: (a, b) => a?.user?.lastName?.localeCompare(b?.user?.lastName),
      sortDirections: ['descend', 'ascend'],
      width: 150,
      render: (item) => <div>{item?.user?.lastName}</div>,
    },
    {
      title: <FormattedMessage id="appModule.email" />,
      width: 250,
      render: (item) => <div>{item?.user?.email}</div>,
    },
    {
      title: <FormattedMessage id="committees.userList.userMission" />,
      align: 'center',
      width: 150,
      render: (item) => (
        <Dropdown
          disabled={!edit}
          menu={{
            items: menuItems(item),
          }}
          trigger={['click']}
        >
          <div
            style={{ cursor: edit ? 'pointer' : '' }}
            className="gx-d-flex gx-align-items-center gx-justify-content-center"
            title="Görevi değiştir"
          >
            <Tag
              style={{ borderRadius: 2, marginBottom: 0 }}
              color={ROLE[item?.type]?.color}
            >
              {ROLE[item?.type]?.title}
            </Tag>
            {edit && <DownOutlined />}
          </div>
        </Dropdown>
      ),
    },
  ];

  if (isAdmin) {
    columnMembers = [
      ...columnMembers,
      {
        title: <FormattedMessage id="committees.userList.userAddedDate" />,
        dataIndex: 'createdAt',
        align: 'center',
        width: 150,
        render: (dataIndex) => dayjs(dataIndex).format('DD/MM/YYYY - HH:mm'),
      },
    ];
  }

  if (edit) {
    columnMembers = [
      ...columnMembers,
      {
        title: <FormattedMessage id="committees.userList.removeFromCommittee" />,
        align: 'center',
        width: 150,
        render: (user) => (
          <div>
            <MinusCircleOutlined
              onClick={() => userRemoveFromCommittee(user.id)}
              style={{ cursor: 'pointer' }}
            />
          </div>
        ),
      },
    ];
  }

  return (
    <>
      <Search
        name="memberName"
        onChange={searchName}
        placeholder={intl.formatMessage({
          id: 'committees.userList.searchPlaceHolder',
        })}
        enterButton
      />

      <Table
        id="userList"
        bordered
        columns={columnMembers}
        dataSource={searcResult ? searcResult : userlist}
        rowKey={(record) => record.user['@id']}
        {...config}
        loading={tableLoading}
      />
    </>
  );
};

export default CommitteeMembers;
