import useSWR from 'swr';

const useLanguages = () => {
  const { data, error } = useSWR(`/languages`);

  return {
    languages: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export default useLanguages;
