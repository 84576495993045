import React from 'react';

import { Card, Modal, Popconfirm, Table } from 'antd';
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';

const UploadedMediaModal = ({ data, open, onClose, downloadBlob, handleDeleteFormMedia, isContentSend }) => {
  const column = [
    {
      title: 'Madde Adı',
      dataIndex: 'name',
      key: 'id',
      render: (text, record) => (
        <div className="gx-d-flex gx-align-items-center ">
          {isContentSend && (
            <DownloadOutlined
              style={{
                cursor: 'pointer',
              }}
              onClick={() => downloadBlob(record.fileFullUrl, text)}
            />
          )}
          <span className="gx-ml-3">{text}</span>
        </div>
      ),
    },
  ];

  const actions = [
    {
      key: 'actions',
      render: (record) => {
        return (
          <Popconfirm
            placement="leftBottom"
            title="Silmek İstediğinizden Emin misiniz?"
            okText="Evet"
            onConfirm={() => handleDeleteFormMedia(record.id)}
          >
            <DeleteOutlined />
          </Popconfirm>
        );
      },
    },
  ];

  const tableColumn = [...column, ...(!isContentSend ? actions : [])];

  return (
    <Modal
      title="Dosya Yönetimi"
      open={open}
      onCancel={onClose}
      footer={null}
      destroyOnClose
    >
      <Card>
        <Table
          dataSource={data}
          columns={tableColumn}
          pagination={data.length > 10}
        />
      </Card>
    </Modal>
  );
};

export default UploadedMediaModal;
