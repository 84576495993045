import useSWR from 'swr';

const useCountries = () => {
  const params = new URLSearchParams({ pagination: false });
  const { data, error } = useSWR(`/countries?${params.toString()}`);

  return {
    countries: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export default useCountries;
