import React from 'react';

import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Title, InfoView } from 'components';

export const NotFound = ({ text = '' }) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Title>404</Title>

      <Result
        status="404"
        title="404"
        subTitle={formatMessage({ id: 'notFoundTitle' }) + text}
        extra={
          <Link to={`/anasayfa`}>
            <Button type="primary">Ana Sayfa</Button>
            <InfoView />
          </Link>
        }
      />
    </>
  );
};
