import axios, { baseURL } from './customAxios';

export const easyPost = async (path, data) =>
  await axios.post(`${baseURL}${path}`, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const easyPostToken = async (path, data) => {
  var config = {
    method: 'post',
    url: `${baseURL}${path}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };
  const response = await axios(config);
  return response;
};

export const easyPutToken = async (path, data) => {
  var config = {
    method: 'put',
    url: `${baseURL}${path}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };
  const response = await axios(config);
  return response;
};

export const easyGetToken = async (path) => {
  try {
    const response = await axios.get(path);
    const data = response;
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const fileDownload = async (path) => {
  try {
    const response = await axios.get(path, {
      responseType: 'blob',
    });
    const data = response;
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const fileUpload = async (path, formData) => {
  try {
    const response = await axios.post(path, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const easyDeleteToken = async (path) => {
  try {
    const response = await axios.delete(path);
    const data = response;
    return data;
  } catch (error) {
    console.log(error);
  }
};
