import antdTr from 'antd/lib/locale-provider/tr_TR';
import dayjs from 'dayjs';

import trMessages from '../locales/tr_TR.json';
import 'dayjs/locale/tr';

dayjs.locale('tr');

const TrLang = {
  messages: {
    ...trMessages,
  },
  antd: antdTr,
  locale: 'tr',
};

export default TrLang;
