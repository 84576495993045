import Refund from 'components/ArticleOffer/offer/ArticleStatus/Refund';

const EncylopediaManagementUnitReject = ({ status, step, commitee }) => {
  return (
    <Refund
      status={status}
      step={step}
      committee={commitee}
    />
  );
};

export default EncylopediaManagementUnitReject;
