import useSWR from 'swr';

const useAllOffers = ({ pagination = true, page = 1, pageSize = 20, ...filters }) => {
  const params = new URLSearchParams({
    pagination,
    page,
    itemsPerPage: pageSize,
  });

  Object.entries(filters).forEach(([key, value]) => params.append(key, value));

  const { data, error, isValidating } = useSWR(`/article_offers?${params.toString()}`);

  return {
    offers: data,
    isLoading: !error && !data,
    fethcing: isValidating,
    isError: error,
  };
};

export default useAllOffers;
