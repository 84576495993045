import useSWR from 'swr';

const useCommitteePullback = (id, committee) => {
  const { data, error } = useSWR(
    `article_offers/pullback/${id}/${committee}/info`
  );

  return {
    pullback: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export default useCommitteePullback;
