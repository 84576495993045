import ArticleDetection from 'module/Form/ArticleDetection';

import { useCallback, useEffect, useState } from 'react';

import { Button, Card, Dropdown, Form } from 'antd';
import { DownOutlined, EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { useUserInfo } from 'providers/UserInfoProvider';
import { OFFER_STATUS } from 'utils';

const OfferInfosSection = ({ offer, situation, offerVersions, currentOfferStep, setversionUrl, isValidating, formNumber, isDraft, isView }) => {
  const { formatMessage } = useIntl();
  const { memberships, isLoading, isAdmin, isManager } = useUserInfo();
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm();
  const editableOfferStatuses = [0, 1, 9, 19];
  const [activeTab, setActiveTab] = useState('1');

  const handleInitialValue = useCallback(() => {
    if (offer) {
      form.setFieldsValue({
        formNumber: formNumber,
        ownerCommittee: offer.ownerCommittee['@id'] ?? null,
        maddeDurumu: OFFER_STATUS[offer.status] ?? null,
        title: offer.title ?? null,
        articleGroup: offer.articleGroup['@id'] ?? null,
        articleType: offer.articleTypeOther !== null ? 'Diğer' : offer.articleType?.['@id'],
        birinciEdisyondaYerAldi: offer.oldTitle !== null ? 'Evet' : 'Hayır' ?? null,
        atifTam: offer.oldTitle?.type ? (offer.oldTitle.type === 'TAM' ? 'Tam' : 'Atıf') : null,
        authors: offer.authors?.map((item) => {
          return {
            isCommitteeMember: item.author?.user?.memberships?.length > 0 ? 'yes' : 'no',
            authorSelection: item.author['@id'] ? 'list' : 'new',
            author: item.author['@id'] ?? null,
            reason: item.reason ?? null,
          };
        }),
        oldArticleWorkType:
          offer.oldArticleWorkType?.includes(4) || offer.oldArticleWorkType?.includes(5) ? offer.oldArticleWorkType[0] : offer.oldArticleWorkType,
        rewriteandUpdate: offer.oldArticleWorkType?.some((item) => item !== 0) ? 'Güncelleme' : 'Yeniden Yazım',
        oldTitle: {
          key: offer.oldTitle?.id,
          value: offer.oldTitle?.id,
          label: `${offer.oldTitle?.title} (${offer.oldTitle?.slug})`,
        },
        articleLength: offer.articleLength,
        articleLengthCustom: offer?.articleLengthCustom,
        articleLengthDay: offer.articleLengthDay,
        switch: Boolean(offer.articleLengthDay),
        articleLengthReason: offer.articleLengthReason,
        articleSupervisor: offer.articleSupervisor['@id'] ?? null,
        articleSupervisorOther: offer.articleSupervisorOther,
        yakinIfadeZikrediliyorMu: offer.oldArticles?.length > 0 ? 'Evet' : 'Hayır',
        oldArticles: offer.oldArticles?.map((item) => {
          return {
            key: item.id,
            value: item.id,
            label: `${item.title} (${item.slug})`,
          };
        }),
        detectReason: offer.detectReason,
        articleTypeOther: offer.articleTypeOther,
        contentDescription: offer.contentDescription,
      });
    }
  }, [offer, formNumber, form]);

  useEffect(() => {
    if (offer?.media?.length > 0) {
      const files = offer.media.map(({ media }) => ({
        uid: media.id,
        name: media?.originalFilename,
        size: media?.filesize,
        status: 'done',
        relativePath: media?.relativePath,
      }));
      setFileList(files);
    }
  }, [offer]);

  useEffect(() => {
    handleInitialValue();
  }, [handleInitialValue]);
  const getVersion = () => {
    if (!offerVersions) {
      return 1;
    }
    const version = offerVersions[0]?.version;
    return version ? version + 1 : 1;
  };

  const dropDownMenuItems = [
    currentOfferStep <= 1 && {
      key: 'newest',
      onClick: () => {
        setversionUrl('current');
      },
      label: formatMessage({ id: 'article.draft' }, { version: getVersion() }),
    },
    ...offerVersions.map((version) => ({
      key: 'v' + version.version,
      label: `v${version.version}`,
      onClick: () => {
        setversionUrl(version['@id'].replaceAll('/api', ''));
      },
    })),
  ];

  const isEditable =
    situation === 'committee' &&
    offer?.step < 2 &&
    editableOfferStatuses.includes(offer?.status) &&
    isManager &&
    memberships?.some((item) => {
      if (offer?.ownerCommittee) return item?.committee?.id === offer?.ownerCommittee?.id;
      if (offer?.otherCommittees) return item?.committee?.id === offer?.otherCommittees[0]?.id;
      return false;
    });

  return (
    <Card
      loading={isValidating || isLoading}
      title={
        <div className="gx-d-flex gx-justify-content-between gx-align-items-center">
          <h2 className="gx-h2-lg gx-mb-0">
            <FormattedMessage id="article.detail.info.title" />
          </h2>

          {isView && (
            <div>
              {!isDraft ? (
                <>
                  <Dropdown
                    trigger={['click']}
                    menu={{
                      items: dropDownMenuItems,
                    }}
                    placement="bottomRight"
                    overlayStyle={{ height: 'auto', overflow: 'auto', maxHeight: 300 }}
                  >
                    <Button className="gx-mb-0">
                      {offer?.version ? 'v' + offer?.version : formatMessage({ id: 'article.draft' }, { version: getVersion() })}
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                </>
              ) : (
                <Button
                  danger
                  className="gx-mb-0"
                  style={{ cursor: 'default' }}
                >
                  <FormattedMessage id="draft" />
                </Button>
              )}

              {!offer?.version && (isAdmin || isEditable) && (
                <Link to={`/madde-tespit-formu/duzenle/${offer?.id}`}>
                  <Button
                    type="primary"
                    className="gx-mb-0 "
                    icon={<EditOutlined />}
                    title={formatMessage({
                      id: 'article.detail.EditArticle',
                    })}
                  />
                </Link>
              )}
            </div>
          )}
        </div>
      }
    >
      <Form
        disabled={true}
        layout="vertical"
        form={form}
      >
        <ArticleDetection
          isMediaPreview={true}
          isDetailPage={true}
          activeTab={activeTab}
          handleActiveTab={setActiveTab}
          setFileList={setFileList}
          fileList={fileList}
        />
      </Form>
    </Card>
  );
};

export default OfferInfosSection;
