import { useEffect } from 'react';

import { Button, Row, Col, Tabs } from 'antd';
import { useParams, Link } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import { useIntl, FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import { Title } from 'components';
import { CircularProgress } from 'components';
import AuthorInfo from 'components/AuthorDetail/AuthorInfo';
import AuthorEducation from 'components/AuthorDetail/AuthorEducation';
import AuthorWork from 'components/AuthorDetail/AuthorWork';
import AuthorOtherInfo from 'components/AuthorDetail/AuthorOtherInfo';
import { useGetAuthorQuery, useGetCurrentUserQuery } from 'services';

const { TabPane } = Tabs;

const tabList = [
  {
    title: 'author.writer',
    render: (props) => <AuthorInfo {...props} />,
  },
  {
    title: 'author.education',
    render: (props) => <AuthorEducation {...props} />,
  },
  {
    title: 'author.work',
    render: (props) => <AuthorWork {...props} />,
  },
  {
    title: 'author.otherInfo',
    render: (props) => <AuthorOtherInfo {...props} />,
  },
];

const EditAuthor = () => {
  const { formatMessage } = useIntl();
  const { id } = useParams();
  const { data: current_user } = useGetCurrentUserQuery();
  const { isAdmin, isManager, isOrderUnit } = current_user;
  const { data: author, isLoading: loading } = useGetAuthorQuery(id);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loading) return <CircularProgress />;

  return (
    <>
      <Title>{formatMessage({ id: 'author.editAuthor' })}</Title>

      <Row
        justify="space-between"
        align="middle"
        className="gx-px-3"
      >
        <Col>
          <h1>
            <FormattedMessage id="author.editAuthor" /> - {author?.user?.fullName}
          </h1>
        </Col>

        <Col>
          <Link to="/yazar-islemleri/yazar-listesi">
            <Button icon={<UserOutlined />}>{formatMessage({ id: 'app.backTo' }, { to: 'Yazar Listesine' })}</Button>
          </Link>
        </Col>
      </Row>

      {author && (
        <div className="gx-w-100 gx-text-right gx-mb-2">
          <small>
            <FormattedMessage
              id="appModule.createdAt"
              values={{
                time: dayjs(author.createdAt).format('DD/MM/YYYY - HH:mm'),
                name: author?.createdBy?.fullName,
              }}
            />
            <br />
            <FormattedMessage
              id="appModule.updatedAt"
              values={{
                time: dayjs(author.updatedAt).format('DD/MM/YYYY - HH:mm'),
                name: author?.updatedBy?.fullName,
              }}
            />
          </small>
        </div>
      )}

      <Row>
        <Col span={24}>
          <Tabs
            type="card"
            tabBarGutter={12}
            animated
            centered
          >
            {tabList.map((tab, i) => (
              <TabPane
                tab={<FormattedMessage id={tab.title} />}
                key={i}
              >
                {tab.render({ author, editable: isAdmin || isManager || isOrderUnit })}
              </TabPane>
            ))}
          </Tabs>
        </Col>
      </Row>
    </>
  );
};

export default EditAuthor;
