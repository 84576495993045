import React from 'react';

import Modal from 'antd/lib/modal/Modal';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { useIntl } from 'react-intl';
import { useGiveCommitteeOpinionMutation } from 'services';
import { useDispatch } from 'react-redux';
import { fetchError } from 'slices/commonSlice';
import { useNavigate } from 'react-router-dom';
import { taekId } from 'constant/static';
const { TextArea } = Input;

const GiveCommitteOpinionModal = ({ committes, ...other }) => {
  const { formatMessage } = useIntl();
  const [giveCommitteeOpinion] = useGiveCommitteeOpinionMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleFinish = async (values) => {
    const { error } = await giveCommitteeOpinion({
      committeeID: values.committeeID,
      body: {
        description: values.description,
      },
    });

    if (error) return dispatch(fetchError(formatMessage({ id: 'article.form.fetchError' }, { error: error.status })));

    navigate('/madde-teklifleri/tum-maddeler');
  };


  // TAEK dışındaki heyetlerin karar verip vermediğini kontrol eder
  const disabledTAEK = committes?.find((item) => item.isDecided === false && item.committee.id !== taekId);

  return (
    <Modal
      width="50%"
      footer={null}
      {...other}
    >
      <h3 className="gx-text-center gx-mb-4">
        {formatMessage({
          id: 'article.committeeDecisionGiveOpinion',
        })}
      </h3>
      <Form
        id="giveCommitteOpinionForm"
        onFinish={handleFinish}
        layout="vertical"
        initialValues={{
          committeeID: committes?.length === 1 ? committes?.[0]?.id : undefined,
        }}
      >
        <Form.Item
          hidden={committes?.length === 1}
          label="Komite"
          name="committeeID"
          rules={[
            {
              required: true,
              message: 'Komite seçiniz',
            },
          ]}
          className="gx-mb-5"
        >
          <Select
            placeholder="Komite seçiniz"
            options={committes?.map((item) => ({
              // eğer görüş istenen heyetler içinde taek var ise taek görüşünü en son bildirebilir.
              // Bunun için taek in olduğu seçenek ile birlikte diğer bütün heyetlerin karar vermiş olup olmadığı belirlenmelidir.
              // disableTAEK ve item.committee.id === taekId  kontrlü TAEK seçeniğini doğru bir şekilde referanslaıp bütün heyetlerin karar verip vermediğini kontrol ediyor ve
              // TAEK in en son karar vermesi gerekir şartını doğru şekilde uygulamamaya olanak sağlıyor.
              disabled: item.isDecided || (item.committee.id === taekId && disabledTAEK),
              key: item?.['@id'],
              value: item.id,
              label: item.committee.name,
            }))}
          />
        </Form.Item>
        <Form.Item
          name="description"
          label="Lütfen görüşünüzü aşağıdaki alana yazınız"
          rules={[
            {
              required: true,
              message: 'Görüş bildiriniz',
            },
          ]}
        >
          <TextArea
            rows={6}
            placeholder="Görüş bildir"
          />
        </Form.Item>
      </Form>
      <Row
        gutter={24}
        className="gx-mt-5"
      >
        <Col span={12}>
          <Button
            form="giveCommitteOpinionForm"
            htmlType="submit"
            block
          >
            {formatMessage({
              id: 'form.send',
            })}
          </Button>
        </Col>
        <Col span={12}>
          <Button
            block
            onClick={() => other.onCancel()}
          >
            {formatMessage({
              id: 'appModule.cancel',
            })}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default GiveCommitteOpinionModal;
