import { DeleteOutlined, FileWordOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useDeleteArticleContentMediaMutation } from 'services';
import { fetchError } from 'slices/commonSlice';
import { openNotification } from 'utils';

const UploadedFile = ({ content, id, offerID, style, fontStyle, extra = false }) => {
  const [deleteArticleContentMedia] = useDeleteArticleContentMediaMutation();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  // bağlanmış olan bir maddeyi silmek için kullanılır
  const handleDelete = async () => {
    const { error } = await deleteArticleContentMedia({
      offerID,
      mediaID: id,
    });
    if (error) {
      return dispatch(
        fetchError(
          formatMessage(
            {
              id: 'article.form.fetchError',
            },
            { error: error.status }
          )
        )
      );
    }
    openNotification({
      message: formatMessage({
        id: 'appModule.actionSuccessfully',
      }),
    });
  };

  return (
    <div
      style={{
        ...style,
        fontSize: 24,
        padding: '0 16px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        backgroundColor: '#fafafa',
      }}
    >
      <div className="gx-d-flex gx-pt-3 gx-pb-3 gx-align-items-center gx-fit-text ">
        <FileWordOutlined />
        <span
          className="gx-ml-4"
          style={{ ...fontStyle, fontSize: 16 }}
        >
          {content}
        </span>
      </div>
      {extra ? (
        extra
      ) : (
        <Popconfirm
          placement="leftBottom"
          title="Silmek İstediğinizden Emin misiniz?"
          okText="Evet"
          onConfirm={handleDelete}
        >
          <DeleteOutlined />
        </Popconfirm>
      )}
    </div>
  );
};

export default UploadedFile;
