import React from 'react';

import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Title } from 'components';

export const ErrorPage = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Title>404</Title>

      <Result
        status="500"
        title="Hata"
        subTitle={formatMessage({ id: 'errorPageTitle' })}
        extra={
          <Link to={`/anasayfa`}>
            <Button type="primary">Ana Sayfa</Button>
          </Link>
        }
      />
    </>
  );
};
