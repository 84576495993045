/**
 * bu parser fonksiyonu hazır gerekçelerin formda doğru şekilde gösterilmesi için kullanılır
 * örnek dönen obje: {
 *  "revertReason": null || "string",
 * }
 *  */
export const refundObjectParser = (data) => {
  Object.keys(data).forEach((key) => (key.includes('checkbox') || ['accept', 'other', 'status', 'to'].includes(key)) && delete data[key]);
  return Object.keys(data).forEach((key) => {
    if (data[key] === undefined) {
      data[key] = null;
    }
  });
};
