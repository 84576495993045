import { Form, Select } from 'antd';
import { FormattedMessage } from 'react-intl';

const { Option } = Select;

export default ({ name, title, editable }) => {
  return (
    <Form.Item name={name} label={title}>
      <Select
        disabled={!editable}
        getPopupContainer={(triggerNode) => triggerNode.parentElement}
        defaultValue={1}
      >
        <Option value={1}>
          <FormattedMessage id="author.languages.beginner" />
        </Option>
        <Option value={2}>
          <FormattedMessage id="author.languages.intermediate" />
        </Option>
        <Option value={3}>
          <FormattedMessage id="author.languages.advanced" />
        </Option>
      </Select>
    </Form.Item>
  );
};
