import apiInstance from './api';

const articleOfferApi = apiInstance.injectEndpoints({
  endpoints: (build) => ({
    getArticleOfferProcess: build.query({
      query: (id) => `/article_offers/${id}/ou_process_tracks`,
      transformResponse: (response) => response['hydra:member'],
      providesTags: ['ArticleOffersProcess'],
    }),
    getOffers: build.query({
      query: () => '/article_offers',
      providesTags: ['ArticleOffers'],
    }),
    getOffer: build.query({
      query: (id) => `/article_offers/${id}`,
      providesTags: ['ArticleOfferDetail'],
    }),
    sendDecision: build.mutation({
      query: ({ id, committee, body }) => ({
        url: `/article_offers/decision/${committee}/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['ArticleOffers', 'ArticleOffersProcess', 'ArticleOfferDetail', 'AuthorArticles'],
    }),
    getArticleChains: build.query({
      query: (id) => `/article_offers/${id}/chains`,
      providesTags: ['ArticleChains'],
    }),
    sendArticleProcess: build.mutation({
      query: ({ id, body }) => ({
        url: `/article_offers/${id}/process/send_article/author`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['ArticleOffers', 'ArticleOffersProcess', 'ArticleOfferDetail', 'AuthorArticles'],
    }),
    sendCommitteeDecision: build.mutation({
      query: ({ id, body }) => ({
        url: `/article_offers/${id}/process/send_article/committee`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['ArticleOffers', 'ArticleOffersProcess', 'ArticleOfferDetail', 'AuthorArticles'],
    }),
    changeDeadline: build.mutation({
      query: ({ id, body }) => ({
        url: `/article_offers/deadlines/${id}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ArticleOffers', 'ArticleOffersProcess', 'ArticleOfferDetail', 'AuthorArticles'],
    }),
    changeSupervisor: build.mutation({
      query: ({ id, body }) => ({
        url: `article_offers/${id}/supervisor`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['ArticleOffers', 'ArticleOffersProcess', 'ArticleOfferDetail', 'AuthorArticles'],
    }),
    getArticleOfferGuides: build.query({
      query: (id) => `article_offers/${id}/guides`,
      providesTags: ['ArticleOfferGuides'],
      transformResponse: (response) => response['hydra:member'],
    }),
    downloadArticleOfferContentTemplate: build.query({
      query: ({ id, type }) => ({
        url: `article_offers/${id}/template/${type}`,
        responseType: 'blob',
        responseHandler: (response) => response.blob(),
      }),
      transformResponse: (response, meta) => {
        const fileName = meta.response.headers.get('content-disposition');
        return {
          data: response,
          fileName: fileName.split('filename=')[1],
        };
      },
      providesTags: ['ArticleOfferContentTemplate'],
    }),
  }),
  overrideExisting: true,
});

export default articleOfferApi;
