import { article_order_status } from 'constant/static';
import { useGetArticleChainsQuery, useGetCurrentUserQuery } from 'services';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from 'components/CircularProgress';

import ArticleOfferVersionItem from './ArticleOfferVersionItem';

const ArticleVersionChanger = ({ offer }) => {
  const navigate = useNavigate();
  const { data: current_user } = useGetCurrentUserQuery();

  const {isAdmin, isOrderUnit, isComittee, isDAH, isAYK , isAuthor, memberships} = current_user;

  const { data: articleChains, isLoading } = useGetArticleChainsQuery(offer && offer.id);

  const isUserJustAuthor = isAuthor && memberships.length === 0;
  const handleNavigate = (id) => {
    navigate(`/madde-detay/${id}`);
  };

if (isLoading) return <CircularProgress />;


  return (
    <>
      {(!isUserJustAuthor || isAdmin || isOrderUnit ||isComittee || isDAH || isAYK) && (
        <>
          {articleChains && articleChains.parent && (
            <ArticleOfferVersionItem
              label="Bu Maddenin Önceki Versiyonu:"
              btnText="Önceki Versiyon"
              onClick={() => handleNavigate(articleChains.parent)}
            />
          )}
          {[article_order_status['IH-Madde Kontrol Yeniden Telif'], article_order_status['IH - Yeniden Yazım']].includes(offer && offer.status) &&
            articleChains.child && (
              <ArticleOfferVersionItem
                label="Bu Maddenin Sonraki Versiyonu:"
                btnText="Sonraki Versiyon"
                onClick={() => handleNavigate(articleChains.child)}
              />
            )}
          {[article_order_status['IH-Madde Kontrol Yeniden Telif'], article_order_status['IH - Yeniden Yazım']].includes(offer && offer.status) &&
            articleChains &&
            articleChains.child !== articleChains.latestChild && (
              <ArticleOfferVersionItem
                label="Maddenin Güncel Versiyonu:"
                btnText="Son Versiyon"
                onClick={() => handleNavigate(articleChains.latestChild)}
              />
            )}
        </>
      )}
    </>
  );
};

export default ArticleVersionChanger;
