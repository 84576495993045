import React, { useState } from 'react';

import { Card, Button, Radio, Row, Col, Checkbox, Switch } from 'antd';
import { addResource, editResource } from 'fetcher';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import format from 'dayjs';
import { mutate } from 'swr';
import { useSources, useSourcesTypes } from 'useSWR';
import { openNotification } from 'utils';
import { CircularProgress, DatePicker, Title, Form, Input } from 'components';

const { TextArea } = Input;

const ResourceManagement = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [form] = Form.useForm();
  const { types } = useSourcesTypes();

  const [loading, setLoading] = useState(false);
  const { formatMessage } = useIntl();

  const { sources: data, isLoading } = useSources({
    type: 'single',
    id: id ? id : '',
  });

  const dateConfig = data?.lastUpdatedAt
    ? {
        initialValue: format(data.lastUpdatedAt),
      }
    : null;

  const onFinish = async (values) => {
    setLoading(true);

    const res = await (id ? resourceEdit : resourceAdd)(values);

    if (res) {
      openNotification({ message: 'İşlem Başarılı.' });
      mutate(`/resources?title=&itemsPerPage=50&page=1`);
      mutate(`/resources/${id}`);
      setTimeout(() => {
        navigate('/e-kaynaklar');
        setLoading(false);
      }, 1500);
    }
  };

  const resourceAdd = async (data) => {
    const res = await addResource(data);
    return res;
  };

  const resourceEdit = async (data) => {
    const res = await editResource(id, data);
    return res;
  };

  if (id && isLoading) return <CircularProgress />;

  return (
    <>
      <Title>{formatMessage({ id: 'resources.resourceEdit' })}</Title>
      <div className="gx-d-flex gx-justify-content-between gx-align-items-center">
        <h1>
          <FormattedMessage id="resources.resourceEdit" />
        </h1>
        <Link to="/e-kaynaklar">
          <Button>
            <FormattedMessage id="resources.backToSources" />
          </Button>
        </Link>
      </div>
      <Card loading={data ? false : true}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          requiredMark="optional"
        >
          <Form.Item
            label="Kaynak Başlığı"
            name="title"
            required
            initialValue={data?.title}
          >
            <Input placeholder="Başlık" />
          </Form.Item>
          <Form.Item
            className="gx-my-2"
            name="link"
            label="Link"
            required
            tooltip="Bu alana kaynağın linki tam olarak girilmelidir."
            initialValue={data?.link}
            rules={[{ type: 'url' }]}
          >
            <Input placeholder="Örn: https://islamansiklopedisi.org.tr/" />
          </Form.Item>
          <Form.Item
            className="gx-my-2"
            name="description"
            label="Açıklama"
            initialValue={data?.description}
          >
            <TextArea
              rows={4}
              placeholder="Kaynak hakkında açıklama girebilirsiniz"
            />
          </Form.Item>
          <Form.Item
            className="gx-my-2"
            label="Erişim Türü"
            required
            name="accessType"
            initialValue={data?.accessType}
          >
            <Radio.Group buttonStyle="solid">
              <Radio.Button value={1}>Açık Erişim</Radio.Button>
              <Radio.Button value={0}>Abonelik</Radio.Button>
              <Radio.Button value={2}>Aboneliği Planlanan</Radio.Button>
              <Radio.Button value={3}>Deneme Erişimi</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            className="gx-my-2"
            name="types"
            label="Kaynak Türü"
            required
            initialValue={data?.types?.map((type) => type['@id'])}
          >
            <Checkbox.Group>
              <Row style={{ flexDirection: 'row' }}>
                {types?.map((type) => (
                  <Col
                    span={6}
                    key={type.id}
                  >
                    <Checkbox
                      key={type.id}
                      value={type['@id']}
                      style={{ lineHeight: '32px' }}
                    >
                      {type.name}
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </Form.Item>
          <Form.Item
            className="gx-my-2"
            name="lastUpdatedAt"
            label="Son Güncelleme Tarihi"
            {...dateConfig}
          >
            <DatePicker format="DD-MM-YYYY" />
          </Form.Item>

          <Form.Item
            className="gx-my-2"
            label="Yayın Durumu"
            required
            name="isPublished"
            tooltip="Bu alana kaynağı diğer kullanıcılara gösterip, gizleyebilirsiniz."
            initialValue={data?.isPublished}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <div>
            {' '}
            <Button
              htmlType="submit"
              className="gx-mt-4"
              type="primary"
              loading={loading}
            >
              Kaydet
            </Button>{' '}
          </div>
        </Form>
      </Card>
    </>
  );
};

export default ResourceManagement;
