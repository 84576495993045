import { useState } from 'react';

import { Card, Tag } from 'antd';
import { OrderDetailGrid } from 'components/ArticleOrder';
import ArticleOrderMedia from 'components/ArticleOrder/ArticleOrderMedia';

const Publisment = ({ offer }) => {
  const [modalProps, setModalProps] = useState({
    visible: false,
    data: null,
  });

  const handleModalProps = (visible, data) => {
    setModalProps({ visible, data });
  };

  return (
    <>
      <Tag
        className="gx-p-3"
        color="green"
      >
        {offer && offer.title} maddesinin aşağıda ana başlıklarıyla listelenen süreçleri tamamlanmış, basılı ve elektronik olarak yayına hazır hale gelmiştir.
        Madde ve madde ile ilişkili belgelerin son versiyonları aşağıdadır.
      </Tag>
      <Card>
        <OrderDetailGrid offer={offer} />
      </Card>
      <Card>
        <ArticleOrderMedia
          isContentSend
          modalProps={modalProps}
          handleModalProps={handleModalProps}
          offerID={offer.id}
        />
      </Card>
    </>
  );
};

export default Publisment;
