import { FormattedMessage } from 'react-intl';
import { Card } from 'antd';
import dayjs from 'dayjs';
import { contributorWorks } from 'utils';

import OpinionForm from './OpinionForm';

export default ({ offer, opinions }) => {
  const { ownerCommittee } = offer;

  return (
    <Card
      title={
        <h2 className="gx-h2-lg gx-mb-0">
          <FormattedMessage id="article.contribution.title" />
        </h2>
      }
    >
      {opinions.map((opinion) => (
        <Card
          key={opinion.id}
          title={
            <div className="gx-d-flex gx-justify-content-between gx-mx-3">
              <small>
                <FormattedMessage
                  id="article.contribution.requestTitle"
                  values={{ committee: ownerCommittee.name }}
                />
              </small>
              {opinion?.startOpinionDate !== null && <small>{dayjs(opinion?.startOpinionDate).format(`DD MMMM YYYY - HH:mm`)}</small>}
            </div>
          }
          className="gx-my-2"
        >
          <p className="gx-font-weight-semi-bold">{opinion.committee.name}</p>

          <ul className="gx-mb-3">
            {opinion.type.map((type) => (
              <li key={type}>{contributorWorks[type]}</li>
            ))}
          </ul>

          <OpinionForm opinion={opinion} />
        </Card>
      ))}
    </Card>
  );
};
