import { useState } from 'react';

import { Card } from 'antd';
import { OrderDetailGrid } from 'components/ArticleOrder';
import ArticleOrderMedia from 'components/ArticleOrder/ArticleOrderMedia';
import StatusBar from 'components/StatusBar';
import RevertAnswerItem from 'components/ArticleOffer/offer/ArticleStatus/RevertAnswerItem';

const RejectedContentItem = ({ offer }) => {
  const [modalProps, setModalProps] = useState({
    visible: false,
    data: null,
  });

  const handleModalProps = (visible, data) => {
    setModalProps({ visible, data });
  };

  return (
    <Card>
      <StatusBar status={offer.status} />
      <Card>
        <OrderDetailGrid offer={offer} />
      </Card>
      <Card>
        <ArticleOrderMedia
          isContentSend
          modalProps={modalProps}
          handleModalProps={handleModalProps}
          offerID={offer.id}
        />
      </Card>
      {offer?.revertReasons?.eu_control?.length > 0 && (
        <Card>
          <h3>Ansiklopedi biriminden aşağıdaki bilgiler doğrultusunda görüş istendi.</h3>
          <Card>
            {offer?.revertReasons?.eu_control?.map((item, index) => (
              <RevertAnswerItem
                key={index}
                title={item.revertReason.title}
                answer={item.answer}
              />
            ))}
          </Card>
        </Card>
      )}

      {offer?.ownerCommitteeOpinion && (
        <Card>
          <h3>Heyet Görüşü</h3>
          <span>{offer.ownerCommitteeOpinion}</span>
        </Card>
      )}
      {offer?.revertReasons?.ayk_control?.length > 0 && (
        <Card>
          <h3>Ansiklopedi Yonetim Kurulu aşağıdaki nedenlerle maddeyi reddetti.</h3>
          <Card>
            {offer?.revertReasons?.ayk_control?.map((item, index) => (
              <RevertAnswerItem
                key={index}
                title={item.revertReason.title}
                answer={item.answer}
              />
            ))}
          </Card>
        </Card>
      )}
    </Card>
  );
};

export default RejectedContentItem;
