import React, { useCallback, useEffect, useMemo, useState } from 'react';

import SweetAlert from 'react-bootstrap-sweetalert';
import { Button, Card, Checkbox, Descriptions, Select, Tooltip, Form, Input } from 'antd';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { formType, heyetKarariMetin, openNotification, refundObjectParser } from 'utils';
import { CircularProgress } from 'components';
import { fetchError, setShouldScroll } from 'slices/commonSlice';
import { MakeDecision } from 'components/RadioGroup';
import { useGetCommitteesByCategoryQuery, useGetCurrentUserQuery, useSendDecisionMutation } from 'services';
import { aykId, taekId } from 'constant/static';

import { PullbackStatus } from '../ArticlePullback';

import Refund from './Refund';
import RevertAnswerItem from './RevertAnswerItem';

const { TextArea } = Input;
const { Option } = Select;

// 3 -> İade 5 -> İptal 6 -> Havale
const decisionStatus = [3, 5, 6];

// Madde tekliflerine geri dönmek için navigate'a gönderilen değer
let redirectParams = '';

const ArticleStatus = ({ offer, situation, hasActivePullback }) => {
  const {
    id,
    dahStatus,
    statusChangeCommittee,
    dahDescription,
    committeeStatus,
    committeeContributions,
    statusDescription,
    ownerCommittee,
    aykStatus,
    aykDescription,
    otherCommittees,
    dahStatusChangeCommittee,
    dahStatusIsConditional,
    dahRevertReasonAnswers,
    aykRevertReasonAnswers,
    committeeRevertReasonAnswers,
    isOffer,
    isDraft,
    isView,
    step,
  } = offer;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();

  const offerTableURL = useSelector(({ common }) => common.offerTableURL);
  const offerTablePage = useSelector(({ common }) => common.offerTablePage);
  const offerTablePageSize = useSelector(({ common }) => common.offerTablePageSize);
  const filterModalURL = useSelector(({ common }) => common.filterModalURL);

  const { data: committees, isLoading: committeesLoading } = useGetCommitteesByCategoryQuery({
    'category.id': 1,
  });
  const [sendDecision] = useSendDecisionMutation();
  const { data: current_user, isLoading } = useGetCurrentUserQuery();
  const [alert, setAlert] = useState(false);
  const [commiteeAlert, setCommiteeAlert] = useState(false);
  const [editDecision, setEditDecision] = useState(false);
  const [loading, setLoading] = useState(false);
  // madde tekliflerine geri dönülüp dönülmemesi durumu
  const [shouldRedirect, setRedirect] = useState(false);

  const commiteeId = ownerCommittee !== null ? ownerCommittee.id : otherCommittees.length !== 0 ? otherCommittees[0].id : { id: -100 };

  const formData = {
    ayk: {
      id: aykId,
      status: aykStatus,
      description: aykDescription,
      revertAnswers: aykRevertReasonAnswers,
    },
    dah: {
      id: taekId,
      status: dahStatus,
      description: dahDescription,
      revertAnswers: dahRevertReasonAnswers,
    },
    committee: {
      id: commiteeId,
      status: committeeStatus,
      description: statusDescription,
      revertAnswers: committeeRevertReasonAnswers,
    },
  };

  const isMember = current_user?.memberships.some((item) => item.committee.id === formData[situation].id && [0, 1].includes(item.type));

  const canPullback = useMemo(() => {
    return (
      current_user &&
      (current_user?.isAdmin || isMember) &&
      ((situation === 'committee' && [0, 8].includes(formData.dah.status)) || (situation === 'dah' && [0, 8].includes(formData.ayk.status)))
    );
    //eslint-disable-next-line
  }, [current_user, isMember, situation]);

  const editable = useMemo(
    () => {
      if (current_user && current_user.isAdmin) return true;

      const editableStatuses = [0, 1, 7, 8, situation === 'committee' ? 4 : -1];
      return isMember && editableStatuses.includes(formData[situation].status);
    }, // eslint-disable-next-line
    [current_user, isMember, situation]
  );

  const onCommitteeChange = (value) => {
    const isContributor = committeeContributions.some((c) => c.committee.id === value);
    setCommiteeAlert(isContributor);
  };

  /**
   * async olan onFinish fonksiyonu ile navigate() çağrıldığında oluşabilen memory leak
   * ve sayfa yanıt vermiyor hatasının çözümü için useEffect ve useCallback kullanılarak alternatif
   * bir çözüm yapısı kuruldu.
   */
  const removeRedirect = useCallback(() => {
    setRedirect(false);
  }, [setRedirect]);

  useEffect(() => {
    if (shouldRedirect) {
      navigate(redirectParams);
      removeRedirect();
    }
  }, [shouldRedirect, navigate, removeRedirect]);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      let data;
      if (decisionStatus.includes(values.status)) {
        let answers = { ...values };
        refundObjectParser(answers);
        data = {
          status: values.status,
          description: values.other ?? values.description,
          answers,
          ...(situation === 'ayk' && { to: values.to }),
        };
      } else {
        data = {
          status: values.status,
          description: values.other ?? (values.description || null),

          ...(values.statusChangeCommittee && {
            statusChangeCommittee: values.statusChangeCommittee ?? null,
          }),
        };
      }

      const { error } = await sendDecision({
        id,
        committee: situation,
        body: data,
      });

      if (error) {
        return dispatch(
          fetchError(
            error.data['hydra:description']
              ? error.data['hydra:description']
              : formatMessage(
                  {
                    id: 'article.form.fetchError',
                  },
                  { error: error.status }
                )
          )
        );
      }
      openNotification({
        message: formatMessage({ id: 'appModule.actionSuccessfully' }),
      });

      form.resetFields();
      setLoading(false);
      dispatch(setShouldScroll(true));

      const params = new URLSearchParams({
        page: offerTablePage,
        pageSize: offerTablePageSize,
      });
      redirectParams = `${offerTableURL}?&${params.toString()}${filterModalURL}`;
      setRedirect(true);
    } catch (error) {
      dispatch(
        fetchError(
          formatMessage(
            {
              id: 'article.form.fetchError',
            },
            { error: `${error}` }
          )
        )
      );
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) return <CircularProgress />;

  return (
    <>
      <Card
        style={{
          marginBottom: 31,
          top: 0,
          minHeight: 'auto',
          overflow: 'hidden',
        }}
        title={
          <div className="gx-d-flex gx-justify-content-between gx-align-items-center">
            <h2 className="gx-mb-0">{formType[situation]}</h2>

            {step < 2 && (
              <div className="gx-d-flex gx-justify-content-end gx-align-items-center">
                {canPullback && (
                  <PullbackStatus
                    id={id}
                    committee={situation}
                  />
                )}

                {editable && (
                  <Tooltip
                    placement="left"
                    title={hasActivePullback && <FormattedMessage id="article.status.pullback.decideTooltip" />}
                  >
                    <span>
                      <Button
                        type="primary"
                        className="gx-mb-0"
                        disabled={hasActivePullback || !isView}
                        onClick={() => {
                          setEditDecision(!editDecision);
                        }}
                      >
                        <FormattedMessage id="article.status.giveDecision" />
                      </Button>
                    </span>
                  </Tooltip>
                )}
              </div>
            )}
          </div>
        }
      >
        {editable && editDecision ? (
          <Form
            form={form}
            labelAlign="right"
            onFinish={onFinish}
            layout="vertical"
          >
            <Form.Item
              name="status"
              labelfor={'status'}
              label="Heyet Kararı"
              rules={[
                {
                  required: true,
                  message: <FormattedMessage id="appModule.requiredMessage" />,
                },
              ]}
            >
              <MakeDecision
                formData={formData}
                situation={situation}
                isDraft={isDraft}
              />
            </Form.Item>

            <Form.Item dependencies={['status']}>
              {({ getFieldValue }) =>
                decisionStatus.includes(getFieldValue('status')) &&
                ['committee', 'dah', 'ayk'].includes(situation) && (
                  <Refund
                    committee={situation}
                    step={step}
                    status={getFieldValue('status')}
                  />
                )
              }
            </Form.Item>

            {situation === 'committee' && (
              <SweetAlert
                warning
                cancelBtnText={<FormattedMessage id="appModule.cancel" />}
                showCancel
                closeAnim
                confirmBtnText={
                  <Link
                    className="gx-m-0 gx-p-2 gx-text-white"
                    to={`/madde-tespit-formu/duzenle/${id}`}
                  >
                    <FormattedMessage id="article.detail.EditArticle" />
                  </Link>
                }
                title={<FormattedMessage id="appModule.danger" />}
                show={alert}
                onCancel={() => {
                  setAlert(false);
                  form.resetFields();
                }}
                confirmBtnStyle={{ zIndex: -1, padding: 0, border: 'none' }}
              >
                <div
                  style={{ lineHeight: '150%' }}
                  className="gx-mt-2 gx-font-weight-normal"
                >
                  <FormattedMessage id="article.status.CommitteOfferAccepInfoMessage" />
                </div>
              </SweetAlert>
            )}

            <Form.Item dependencies={['status']}>
              {({ getFieldValue }) =>
                getFieldValue('status') === 4 && (
                  <Form.Item
                    label={<FormattedMessage id="article.committeeDecisionChangeCommittee" />}
                    labelfor={'statusChangeCommittee'}
                    name={'statusChangeCommittee'}
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="appModule.requiredMessage" />,
                      },
                    ]}
                  >
                    <Select
                      getPopupContainer={(triggerNode) => triggerNode.parentElement}
                      placeholder="Heyetler"
                      style={{ width: '100%' }}
                      onChange={onCommitteeChange}
                    >
                      {committeesLoading &&
                        committees?.['hydra:member']?.map((item) => (
                          <Option
                            key={item?.id}
                            value={item?.id}
                            disabled={ownerCommittee?.id === item?.id}
                          >
                            {item.name} Heyeti
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                )
              }
            </Form.Item>

            {situation === 'dah' && (
              <SweetAlert
                warning
                closeAnim
                confirmBtnText={<FormattedMessage id="appModule.okey" />}
                title={<FormattedMessage id="appModule.danger" />}
                show={alert}
                onConfirm={() => setAlert(false)}
              >
                <div className="gx-mt-2">
                  <FormattedHTMLMessage id="article.status.committeAcceptAlertMessage" />
                </div>
              </SweetAlert>
            )}

            <Form.Item dependencies={['statusChangeCommittee']}>
              {({ getFieldValue }) =>
                situation === 'dah' &&
                getFieldValue('statusChangeCommittee') && (
                  <SweetAlert
                    warning
                    closeAnim
                    confirmBtnText={<FormattedMessage id="appModule.okey" />}
                    title={<FormattedMessage id="appModule.danger" />}
                    show={commiteeAlert}
                    onConfirm={() => setCommiteeAlert(false)}
                  >
                    <div className="gx-mt-2">
                      <FormattedHTMLMessage
                        id="article.status.committeChangeAlertMessage"
                        values={{
                          committee: committees.find((c) => c.id === getFieldValue('statusChangeCommittee')).name,
                        }}
                      />
                    </div>
                  </SweetAlert>
                )
              }
            </Form.Item>

            <Form.Item dependencies={['status']}>
              {({ getFieldValue }) =>
                ((['dah', 'ayk', 'committee'].includes(situation) && [1, 2, 4].includes(getFieldValue('status'))) ||
                  ((situation === 'committee' && getFieldValue('status')) === 2 && !isOffer)) && (
                  <Form.Item
                    label={<FormattedMessage id="article.status.committeReason" />}
                    colon={false}
                    labelfor="description"
                    name="description"
                    rules={[
                      {
                        required: getFieldValue('status') === 6,
                        message: <FormattedMessage id={situation === 'dah' ? 'article.status.committeAcceptErrorMessage' : 'appModule.requiredMessage'} />,
                      },
                      {
                        min: 100,
                        message: <FormattedMessage id="article.reasonLengthMessage" />,
                      },
                    ]}
                  >
                    <TextArea
                      rows={5}
                      placeholder={formatMessage({
                        id:
                          situation === 'committee' && getFieldValue('status') === 2
                            ? 'article.status.DecisionReasonPlaceHolderForCommittee'
                            : 'article.status.DecisionReasonPlaceHolder',
                      })}
                      showCount
                    />
                  </Form.Item>
                )
              }
            </Form.Item>

            <Form.Item
              name="accept"
              valuePropName="checked"
            >
              <div className="gx-text-right gx-mt-5">
                <Checkbox>
                  <FormattedMessage id="article.status.approveDecision" />
                </Checkbox>
                <h6 style={{ color: 'red' }}>
                  <FormattedMessage id="article.status.approveDecisionCantChange" />
                </h6>
              </div>
            </Form.Item>

            <Form.Item dependencies={['accept']}>
              {({ getFieldValue }) => (
                <Form.Item>
                  <Button
                    style={{ width: 100, float: 'right' }}
                    type="primary"
                    disabled={!getFieldValue('accept')}
                    htmlType="submit"
                    loading={loading}
                  >
                    <FormattedMessage id="appModule.send" />
                  </Button>
                </Form.Item>
              )}
            </Form.Item>
          </Form>
        ) : (
          <Descriptions
            layout="horizontal"
            bordered
            column={24}
            className="decision"
          >
            <>
              <Descriptions.Item
                span={24}
                label={<FormattedMessage id="article.status.committeeDecision" />}
              >
                <div className="gx-text-uppercase">
                  {situation === 'dah' && dahStatusIsConditional ? (
                    <FormattedMessage id="article.committeeDecisionAcceptAnnotated" />
                  ) : (
                    heyetKarariMetin[step > 1 ? 2 : formData[situation]?.status]
                  )}
                </div>
              </Descriptions.Item>
              {situation === 'committee' && [1, 2].includes(formData.dah.status) && (
                <Descriptions.Item
                  label={<FormattedMessage id="taekDesicion" />}
                  span={24}
                >
                  {formData.dah.description}
                </Descriptions.Item>
              )}
              {situation === 'committee' && formData.dah.status === 3 && (
                <Descriptions.Item
                  label={<FormattedMessage id={'article.status.TAEKreventReason'} />}
                  span={24}
                >
                  {Array.isArray(formData.dah.revertAnswers) &&
                    formData.dah.revertAnswers.map((item) => (
                      <RevertAnswerItem
                        key={item.id}
                        title={item.revertReason.title}
                        answer={item.answer}
                      />
                    ))}
                  {formData.dah.description && (
                    <li className="gx-mb-1">
                      <div className="gx-font-weight-semi-bold gx-mb-1">
                        <FormattedMessage id="article.detail.articleTypeOther" />
                      </div>
                      <q>{formData.dah.description}</q>
                    </li>
                  )}
                </Descriptions.Item>
              )}
              {situation === 'committee' && (formData.ayk.status === 3 || formData.ayk.status === 5) && (
                <Descriptions.Item
                  label={<FormattedMessage id={formData.ayk.status === 5 ? 'article.status.AYKcancelReason' : 'article.status.AYKreventReason'} />}
                  span={24}
                >
                  {Array.isArray(formData.ayk.revertAnswers) &&
                    formData.ayk.revertAnswers.map((item) => (
                      <RevertAnswerItem
                        key={item.id}
                        title={item.revertReason.title}
                        answer={item.answer}
                      />
                    ))}
                  {formData.ayk.description && (
                    <li className="gx-mb-1">
                      <div className="gx-font-weight-semi-bold gx-mb-1">
                        <FormattedMessage id="article.detail.articleTypeOther" />
                      </div>
                      <q>{formData.ayk.description}</q>
                    </li>
                  )}
                </Descriptions.Item>
              )}
              {situation === 'ayk' && formData.committee.status === 6 && (
                <Descriptions.Item
                  label={<FormattedMessage id={'article.status.CommiteeTransferReason'} />}
                  span={24}
                >
                  {Array.isArray(formData.committee.revertAnswers) &&
                    formData.committee.revertAnswers.map((item) => (
                      <RevertAnswerItem
                        key={item.id}
                        title={item.revertReason.title}
                        answer={item.answer}
                      />
                    ))}
                  {formData.ayk.description && (
                    <li className="gx-mb-1">
                      <div className="gx-font-weight-semi-bold gx-mb-1">
                        <FormattedMessage id="article.detail.articleTypeOther" />
                      </div>
                      <q>{formData.ayk.description}</q>
                    </li>
                  )}
                </Descriptions.Item>
              )}
            </>

            {isView && step < 2 && formData[situation].revertAnswers && formData[situation].status === 3 && (
              <Descriptions.Item
                label={<FormattedMessage id="article.status.reventReasons" />}
                span={24}
              >
                <ul className="gx-m-2 gx-p-0">
                  {formData[situation]?.revertAnswers?.map((item) => (
                    <RevertAnswerItem
                      key={item.id}
                      title={item.revertReason.title}
                      answer={item.answer}
                    />
                  ))}
                  {(dahStatus === 3 || aykStatus === 3) && formData[situation]?.description && (
                    <li className="gx-mb-1">
                      <div className="gx-font-weight-semi-bold gx-mb-1">
                        <FormattedMessage id="article.detail.articleTypeOther" />
                      </div>
                      <q>{formData[situation]?.description}</q>
                    </li>
                  )}
                </ul>
              </Descriptions.Item>
            )}

            {step === 1 && (formData[situation] === 'committee' || formData[situation].status !== 3) && formData[situation]?.description && (
              <Descriptions.Item
                label="Heyet Gerekçesi"
                span={24}
              >
                {formData[situation]?.description}
              </Descriptions.Item>
            )}

            {(statusChangeCommittee?.id || dahStatusChangeCommittee?.id) && (
              <Descriptions.Item
                label={<FormattedMessage id="article.status.committeReason" />}
                span={24}
              >
                {statusChangeCommittee?.name || dahStatusChangeCommittee?.name} İlim Heyeti
              </Descriptions.Item>
            )}
          </Descriptions>
        )}
      </Card>
    </>
  );
};
export default ArticleStatus;
