import { Button } from 'antd';
import ComitteeRewriteDecision from 'components/ArticleOrder/status/ComitteeRewriteDecision';
import RevertCommitteeDecision from 'components/ArticleOrder/status/RevertCommitteeDecision';
import CommiteeRejectDecision from 'components/ArticleOrder/status/CommiteeRejectDecision';
import { useNavigate, useParams } from 'react-router-dom';
import { committeeDecisions } from 'utils';

import ApprovedCommiteeDecision from './ApprovedCommiteeDecision';



const handleRenderRoute = (status, offerID, step) => {
  switch (status) {
    case committeeDecisions.approved:
      return (
        <ApprovedCommiteeDecision
          offerID={offerID}
          step={step}
          status={committeeDecisions.approved}
        />
      );
    case committeeDecisions.revert:
      return (
        <RevertCommitteeDecision
          offerID={offerID}
          step={step}
          status={committeeDecisions.revert}
        />
      );
    case committeeDecisions.rewrite:
      return (
        <ComitteeRewriteDecision
          offerID={offerID}
          step={step}
          status={committeeDecisions.rewrite}
        />
      );
    case committeeDecisions.rejected:
      return (
        <CommiteeRejectDecision
          offerID={offerID}
          step={step}
          status={committeeDecisions.rejected}
        />
      );
    default:
      <></>;
  }
};

const GiveDecisionView = () => {
  const { offerID, step, status } = useParams();
  const navigate = useNavigate();

  return (
    <>
      <Button onClick={() => navigate(`/madde-detay/${offerID}`)}>Geri</Button>
      {handleRenderRoute(Number(status), Number(offerID), Number(step))}
    </>
  );
};

export default GiveDecisionView;
