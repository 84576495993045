import useSWR from 'swr';
import { getFilteredURL } from 'utils';

const useOfferVersions = ({ pagination = false, page = 1, pageSize = 20, id, ...filters }) => {
  const url = getFilteredURL(`/article_offers/${id}/versions`, {
    pagination,
    page,
    pageSize,
    ...filters,
  });

  const { data, error, mutate } = useSWR(url, { revalidateOnFocus: false });

  return {
    versions: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useOfferVersions;
