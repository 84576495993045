import { useState } from 'react';

import { Button, Modal, Tooltip } from 'antd';
import { InfoCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useCommitteePullback } from 'useSWR';
import { sendPullbackRequest } from 'fetcher';
import { openNotification } from 'utils';
import { Form, Input } from 'components';
import RemainingTime from 'components/ArticleOffer/RemainingTime';
import { fetchError } from 'slices/commonSlice';
import { useGetCurrentUserQuery } from 'services';

const { TextArea } = Input;

export function PullbackStatus({ id, committee }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { pullback, isError, isLoading } = useCommitteePullback(id, committee);
  const { data: current_user } = useGetCurrentUserQuery();
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const getButtonContent = () => {
    const prefix = 'article.status.pullback';

    // Gösterilecek metin tam olarak bu sırayla kontrol edildikten sonra belirleniyor
    if (pullback?.isWithdrawn) {
      return prefix + '.withdrawn';
    } else if (pullback?.isPullbackRequested) {
      return prefix + '.sentRequest';
    } else if (pullback?.hasExpired) {
      return prefix + '.sendRequest';
    }

    return prefix;
  };

  const onFinish = async (values) => {
    setLoading(true);

    try {
      await sendPullbackRequest(id, committee, values);

      openNotification({
        message: formatMessage({ id: 'appModule.actionSuccessfully' }),
      });

      navigate('/madde-teklifleri/tum-maddeler');
    } catch (error) {
      dispatch(fetchError(formatMessage({ id: 'article.form.fetchError' }, { error: `${error}` })));
    }

    setLoading(false);
  };

  if (isError || isLoading) return null;

  return (
    <>
      {current_user.isAdmin && (
        <Tooltip
          placement="left"
          title={
            !pullback?.hasExpired && (
              <RemainingTime
                id="article.status.pullbackTimestamp"
                start={Date.now()}
                end={pullback?.expiresAt}
                uppercaseDate
              />
            )
          }
        >
          <span>
            <Button
              className="pullback-button gx-mb-0 gx-mr-3"
              disabled={pullback?.isPullbackRequested || pullback?.isWithdrawn}
              onClick={() => setShowConfirm(true)}
            >
              <ReloadOutlined className="gx-flip-h" /> <FormattedMessage id={getButtonContent()} />
            </Button>
          </span>
        </Tooltip>
      )}

      <Modal
        width={450}
        footer={null}
        closable={false}
        maskClosable={false}
        open={showConfirm}
        onCancel={() => setShowConfirm(false)}
        centered
      >
        <div className="gx-d-flex gx-justify-content-center gx-align-items-center gx-flex-column">
          <InfoCircleOutlined
            className="gx-text-warning"
            style={{ fontSize: 70 }}
          />

          <h3 className="gx-my-4 gx-font-weight-medium">
            <FormattedMessage id="appModule.danger" />
          </h3>

          <p className="gx-text-center">
            <FormattedHTMLMessage
              id={
                pullback?.hasExpired && !pullback?.isPullbackRequested
                  ? 'article.status.pullback.confirmRequestModalContent'
                  : 'article.status.pullback.confirmModalContent'
              }
              values={{
                committee: formatMessage({
                  id: `from${committee === 'dah' ? 'AYK' : 'DAH'}`,
                }),
                button: formatMessage({
                  id: pullback?.hasExpired ? 'form.send' : 'appModule.okey',
                }),
              }}
            />
          </p>

          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            className="gx-d-flex gx-flex-column gx-align-items-center gx-w-100 gx-px-4"
          >
            {pullback?.hasExpired && (
              <>
                <Form.Item
                  name="description"
                  className="gx-w-100 gx-mt-2 gx-mb-4"
                  label={<FormattedHTMLMessage id="article.status.pullback.requestDescription" />}
                  rules={[
                    {
                      required: true,
                      message: (
                        <FormattedMessage
                          id="appModule.requiredFieldMessage"
                          values={{
                            field: <FormattedMessage id="appModule.reason" />,
                          }}
                        />
                      ),
                    },
                    {
                      min: 100,
                      message: formatMessage({
                        id: 'article.reasonLengthMessage',
                      }),
                    },
                  ]}
                >
                  <TextArea
                    showCount
                    className="gx-resize-none"
                    rows={5}
                    required
                  />
                </Form.Item>
              </>
            )}

            <div className="gx-d-flex gx-justify-content-center gx-mt-3">
              <Button
                type="primary"
                className="gx-px-5 gx-text-uppercase"
                loading={loading}
                htmlType="submit"
              >
                <FormattedMessage id={pullback?.hasExpired ? 'form.send' : 'appModule.okey'} />
              </Button>
              <Button
                type="danger"
                className="gx-px-5  gx-text-uppercase"
                disabled={loading}
                onClick={() => setShowConfirm(false)}
              >
                <FormattedMessage id="appModule.cancel" />
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
}
