import React, { useMemo } from 'react';

import { Select } from 'antd';
import { useAuthors } from 'useSWR';

const { Option } = Select;

const AuthorSelect = ({ editable = true, value, disabled, isCommitteeMember, onChange }) => {
  const { data, isLoading } = useAuthors({
    pagination: false,
  });
  const authorSelectList = useMemo(() => {
    if (!data || !Array.isArray(data['hydra:member'])) return;

    return data['hydra:member'].reduce(
      (acc, author) => {
        if (author?.user?.memberships.length > 0) {
          acc.committee.push(author);
        } else {
          acc.authors.push(author);
        }
        return acc;
      },
      { committee: [], authors: [] }
    );
  }, [data]);
  return (
    <Select
      value={value}
      showSearch
      loading={isLoading}
      filterOption={(input, option) => {
        return option.children.join('').toLocaleLowerCase().includes(input.toLocaleLowerCase());
      }}
      onChange={onChange}
      getPopupContainer={(triggerNode) => triggerNode.parentElement}
      disabled={isLoading || !editable || disabled}
    >
      {authorSelectList?.[isCommitteeMember].map((author) => (
        <Option
          key={author['@id']}
          value={author['@id']}
        >
          {author?.user?.firstName} {author?.user?.lastName} ({author?.user?.email})
        </Option>
      ))}
    </Select>
  );
};

export default AuthorSelect;
