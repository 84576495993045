export function getFilteredURL(baseURL, { pagination = true, page = 1, pageSize = 20, ...filters }) {
  const params = new URLSearchParams({
    pagination,
    page,
    itemsPerPage: pageSize,
  });

  Object.entries(filters).forEach(([key, value]) => params.append(key, value));

  return `${baseURL}?${params.toString()}`;
}
