import React, { useEffect, useState } from 'react';

import { Button, Form, Select } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { chunkUploadMedia, openNotification, showErrorMessage } from 'utils';
import { CircularProgress, CustomUpload } from 'components';
import { apiInstance, useGetArticleGroupsQuery, useGetArticleTypesQuery, useGetGuidesQuery } from 'services';
import { useDispatch } from 'react-redux';

export const MediaUploader = ({ mediaType }) => {
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);

  const { data: guides, isLoading: guidesLoading } = useGetGuidesQuery();
  const { data, isLoading } = useGetArticleTypesQuery();
  const { data: articleGroup, isLoading: articleGroupLoading } = useGetArticleGroupsQuery();

  useEffect(() => {
    if (mediaType) {
      form.setFieldsValue({
        type: mediaType?.key,
      });
    }
  }, [form, mediaType]);

  const getGuide = (type) => {
    const guide = guides?.find((g) => g?.articleType?.id === type?.id);
    return `${type.name} ${guide?.media ? `- ${guide?.media?.name}` : ''}`;
  };

  async function upload(values) {
    setUploading(true);

    const { articleType, articleGroup } = values;

    const data = {
      ...(articleType && { articleType }),
      ...(articleGroup === 2 && { articleGroup }),
    };

    try {
      const fileSizeSum = fileList.reduce((acc, file) => acc + file.size, 0);

      // throw if sum of file sizes are larger than 25 MB
      if (fileSizeSum > 25 * 1024 * 1024) {
        throw formatMessage({
          id: 'appModule.fileSizeExceeded',
        });
      }

      await chunkUploadMedia(mediaType.key, fileList, {
        ...data,
      });
      dispatch(apiInstance.util.invalidateTags(['Medias', 'Guides']));

      setFileList([]);
      openNotification({
        message: formatMessage({ id: 'appModule.actionSuccessfully' }),
      });
    } catch (error) {
      showErrorMessage(formatMessage({ id: 'article.form.fetchError' }, { error: `${error}` }));
    }

    setUploading(false);
  }

  if (isLoading || guidesLoading) return <CircularProgress />;

  return (
    <Form
      form={form}
      layout="vertical"
      className="gx-mb-3"
      onFinish={upload}
      initialValues={{
        type: mediaType?.key,
        articleGroup: 1,
      }}
    >
      <Form.Item
        className="gx-mb-3"
        shouldUpdate
      >
        {({ getFieldValue }) =>
          getFieldValue('type') === 'guide' && (
            <>
              <Form.Item
                label={<FormattedMessage id="article.detail.articleGroup" />}
                className="gx-w-50 gx-mb-3 gx-mt-3"
                name="articleGroup"
              >
                <Select
                  loading={articleGroupLoading}
                  options={articleGroup?.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                />
              </Form.Item>
              {/* 2 id si sözlük maddesini temsil etmektedir sözlük maddesinde henüz madde tipi seçimi olmadığından o seçenekte bu alan gizlenmektedir */}
              {getFieldValue('articleGroup') !== 2 && (
                <Form.Item
                  label={<FormattedMessage id="article.detail.articleType" />}
                  name="articleType"
                >
                  <Select
                    allowClear
                    loading={isLoading}
                    placeholder={<FormattedMessage id="article.detail.articleType" />}
                  >
                    {data?.map((type) => (
                      <Select.Option
                        key={type.id}
                        value={type?.id}
                      >
                        {getGuide(type)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </>
          )
        }
      </Form.Item>

      <CustomUpload
        accept={mediaType.accept}
        fileList={fileList}
        setFileList={setFileList}
        disabled={uploading}
      />

      <div className="gx-d-flex gx-justify-content-end">
        <Button
          className="gx-mt-3"
          onClick={() => setFileList([])}
          disabled={fileList.length === 0 || uploading}
        >
          <FormattedMessage id="article.filter.clear" />
        </Button>

        <Button
          className="gx-mt-3"
          type="primary"
          htmlType="submit"
          disabled={fileList.length === 0 || uploading}
          loading={uploading}
        >
          <FormattedMessage id="appModule.upload" />
        </Button>
      </div>
    </Form>
  );
};
