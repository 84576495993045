import ContentUpload from 'module/ContentUpload/ContentUpload';

import React, { useEffect, useState } from 'react';

import { Button, Card, Form, Input, message } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { idCardRequired } from 'utils';
import {
  useGetArticleContentQuery,
  useGetCurrentUserQuery,
  useGetOfferQuery,
  useSendArticleContentMutation,
  useSendArticleProcessMutation,
  useUpdateArticleContentMutation,
} from 'services';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchError } from 'slices/commonSlice';

import AddMediaContent from './AddMediaContent';

const AddNewArticleView = () => {
  const { id } = useParams();
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [editTrigger, setEditTrigger] = useState({
    trigger: false,
    mediaID: null,
  });
  const { data: current_user } = useGetCurrentUserQuery();
  const { data: offer } = useGetOfferQuery(id);
  const articleType = offer && offer.articleType;
  const [sendArticleContent] = useSendArticleContentMutation();
  const [sendArticleProcess] = useSendArticleProcessMutation();
  const [updateArticleContent] = useUpdateArticleContentMutation();
  const { data } = useGetArticleContentQuery(
    {
      offerID: id,
    },
    {
      skip: !current_user,
    }
  );

  const itemFile = Form.useWatch('itemFile', form);
  const idCard = Form.useWatch('idCard', form);
  const uvkaFile = Form.useWatch('uvkaFile', form);

  // edit işlemi yapılması istendiğinde ilgili verileri alt component ten alan fonksiyon
  const handleEditStateTrigger = (bool, mediaID) => {
    setEditTrigger({
      trigger: bool,
      mediaID: mediaID,
    });
  };

  const metaDataObj = data && data.contents.find((item) => item.type === 2);

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        itemFile: data.contents.find((item) => item.type === 0),
        idCard: data.contents.find((item) => item.type === 1),
        uvkaFile: data.contents.find((item) => item.type === 3),
      });
    }
  }, [data, metaDataObj, form]);

  const isIdCardRequired = idCardRequired.includes(articleType && articleType.id);

  const onSave = async (values) => {
    const { metadata } = values;
    const metaDataObj = metadata.reduce((acc, item) => ({ ...acc, ...item }), {});
    // gidecek request te metada içerisinde media verisi olmamalı bu yüzden ayrı bir değişkene atıp silme işlemi yapıldı
    const { media, ...rest } = metaDataObj;
    delete rest.media;
    const reqBody = {
      type: 2,
      metadata: rest,
      ...(media && media.length > 0 && { media: media.map((item) => item.mediaID) }),
    };

    const { error } = editTrigger.trigger
      ? await updateArticleContent({
          mediaID: editTrigger.mediaID,
          body: reqBody,
        })
      : await sendArticleContent({
          offerID: id,
          body: reqBody,
        });

    if (!error) {
      message.success('Kaydedildi');
    } else {
      dispatch(fetchError(formatMessage({ id: 'article.form.fetchError' }, { error: error.status })));
    }
    setEditTrigger({
      trigger: false,
      mediaID: null,
    });
  };
  // madde gönderme işlemi medya kaydetten tamamen farklı bir işlem
  const handleSendArticle = async (values) => {
    const { error } = await sendArticleProcess({
      id,
      body: {
        opinion: 'approved',
        authorDescription: values.authorDescription,
      },
    });

    if (!error) {
      navigate('/maddelerim');
    } else {
      message.error('Makale gönderilirken bir hata oluştu');
    }
  };

  return (
    <>
      <div className="gx-d-flex gx-justify-content-end">
        <Button
          onClick={() => navigate(-1)}
          type="default"
          style={{
            fontSize: 18,
          }}
        >
          <ArrowLeftOutlined />
          {formatMessage({
            id: 'goBack',
          })}
        </Button>
      </div>
      <Form
        id="add-new-article-form"
        layout="vertical"
        onFinish={handleSendArticle}
        form={form}
      >
        <ContentUpload
          id={id}
          articleTypeID={articleType && articleType.id}
        />
        <Card>
          <AddMediaContent
            handleEditStateTrigger={handleEditStateTrigger}
            onSave={onSave}
            offerID={id}
          />
        </Card>
        <Form.Item
          className="gx-mb-4"
          label="Açıklamalar ve Notlar"
          name="authorDescription"
          rules={[
            {
              min: 50,
              message: 'Açıklama en az 50 karakter olmalıdır',
            },
          ]}
          normalize={(value) => (value.trim() === '' ? undefined : value)}
        >
          <Input.TextArea
            placeholder="Madde açıklaması giriniz"
            rows={5}
            showCount
          />
        </Form.Item>
        <Form.Item>
          <Button
            block
            disabled={!itemFile || !uvkaFile || isIdCardRequired ? !idCard : false}
            htmlType="submit"
            type="primary"
          >
            {formatMessage({
              id: 'article.status.sendItem',
            })}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default AddNewArticleView;
