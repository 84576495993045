import apiInstance from './api';

const commiteesApi = apiInstance.injectEndpoints({
  endpoints: (build) => ({
    getCommittee: build.query({
      query: (id) => `/committees/${id}`,
      providesTags: ['Committee'],
    }),
    getCommittees: build.query({
      query: () => '/committees',
      transformResponse: (response) => response['hydra:member'],
      providesTags: ['Committees'],
    }),
    getCommitteesByCategory: build.query({
      query: (params) => ({
        url: `/committees`,
        method: 'GET',
        params,
      }),
      providesTags: ['CommitteesByCategory'],
    }),
    getAllCommitteeCategories: build.query({
      query: () => '/committee_categories',
      transformResponse: (response) => response['hydra:member'],
      providesTags: ['CommitteeCategories'],
    }),
    createCommittee: build.mutation({
      query: (data) => ({
        url: '/committees',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['CommitteesByCategory', 'Committees'],
    }),
    editCommitee: build.mutation({
      query: ({ id, data }) => ({
        url: `/committees/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['CommitteesByCategory', 'Committee'],
    }),
    removeFromCommitee: build.mutation({
      query: (id) => ({
        url: `/committee_members/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CommitteesByCategory', 'Committee', 'UserList'],
    }),
    addUserToCommittee: build.mutation({
      query: (data) => ({
        url: '/committee_members',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['CommitteesByCategory', 'Committee', 'UserList'],
    }),
  }),
  overrideExisting: true,
});
export default commiteesApi;
