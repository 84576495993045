import dayjs from 'dayjs';
import { exportFilteredTable } from 'fetcher';

import { downloadBlob } from './downloadBlob';

export async function exportTable(tableUrl, fileNamePrefix) {
  const fileName = `${fileNamePrefix}_${dayjs().format('YYYYMMDDHHmmss')}`;
  const data = await exportFilteredTable(tableUrl);
  downloadBlob(data, `${fileName}.xlsx`);
}
