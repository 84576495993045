import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  error: '',
  loading: false,
  message: '',
  navCollapsed: true,
  width: window.innerWidth,
  initURL: '/maddeler',
  offerID: 1,
  offerTableURL: '/madde-teklifleri/tum-maddeler',
  offerTablePage: 1,
  offerTablePageSize: 20,
  offerShouldTableScroll: false,
  filterModalURL: '',
  tableOrder: { type: null, order: null },
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    fetchStart: (state) => {
      state.loading = true;
    },
    fetchSuccess: (state) => {
      state.loading = false;
      state.error = '';
    },
    fetchError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    locationChange: (state, action) => {
      state.pathname = action.payload.location.pathname;
      state.navCollapsed = false;
    },
    windowWidth: (state, action) => {
      state.width = action.payload;
    },
    toggleCollapsedNav: (state, action) => {
      state.navCollapsed = action.payload;
    },
    showMessage: (state, action) => {
      state.error = '';
      state.message = action.payload;
      state.loading = false;
    },
    hideMessage: (state) => {
      state.loading = false;
      state.error = '';
      state.message = '';
    },
    setOfferTable: (state, action) => {
      state.offerID = action.payload.offerID;
      state.offerTableURL = action.payload.offerTableURL;
      state.offerTablePage = action.payload.offerTablePage;
      state.offerTablePageSize = action.payload.offerTablePageSize;
      state.filterModalURL = action.payload.filterModalURL;
    },
    setShouldScroll: (state, action) => {
      state.offerShouldTableScroll = action.payload;
    },
    setTableOrder: (state, action) => {
      state.tableOrder = action.payload;
    },
  },
});

export const {
  fetchStart,
  fetchSuccess,
  fetchError,
  showMessage,
  hideMessage,
  setOfferTable,
  setShouldScroll,
  setTableOrder,
  windowWidth,
  toggleCollapsedNav,
} = commonSlice.actions;

export default commonSlice.reducer;
