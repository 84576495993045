import { useState } from 'react';

import { Button, Card, Form, Input } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { editAuthor } from 'fetcher';
import { openNotification } from 'utils';
import { fetchError } from 'slices/commonSlice';

const { TextArea } = Input;

export default ({ author, editable }) => {
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      await editAuthor(author.id, {
        ...values,
        user: { id: author.user['@id'] },
      });
      openNotification({
        message: formatMessage({ id: 'appModule.actionSuccessfully' }),
      });
    } catch (error) {
      dispatch(
        fetchError(
          formatMessage(
            {
              id: 'article.form.fetchError',
            },
            { error: `${error}` }
          )
        )
      );
    }
    setLoading(false);
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      initialValues={{ otherInfo: author?.otherInfo }}
    >
      <Card title={<FormattedMessage id="author.otherInfo.title" />}>
        <Form.Item name="otherInfo">
          <TextArea
            className="gx-mb-3"
            rows={10}
            showCount
            disabled={!editable}
          />
        </Form.Item>

        <div className="gx-flex-row gx-justify-content-end">
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            className="gx-m-0 gx-mt-3"
            disabled={!editable}
          >
            <FormattedMessage id="appModule.save" />
          </Button>
        </div>
      </Card>
    </Form>
  );
};
