import { useState } from 'react';

import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';

import ArticleOrderDateEdit from '../ArticleOrderDateEdit/ArticleOrderDateEdit';

const EditArticleOrderDate = ({ id, title, orderDate, deliveryDate, authors }) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <Button
        onClick={() => setOpenModal(true)}
        className="gx-mb-0 gx-text-uppercase modalBtnStyle"
        block
      >
        <FormattedMessage id="edit.article.order.time" />
      </Button>

      <ArticleOrderDateEdit
        id={id}
        open={openModal}
        title={title}
        deliveryDate={deliveryDate}
        orderDate={orderDate}
        authors={authors}
        onCancel={() => setOpenModal(false)}
      />
    </>
  );
};

export default EditArticleOrderDate;
