export const downloadBlob = async (blob, fileName) => {
  if (!(blob instanceof Blob) || blob.constructor.name !== 'Blob') {
    return;
  }

  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;

  document.body.append(link);

  link.click();
  link.remove();
  setTimeout(() => window.URL.revokeObjectURL(link.href), 10000);
};
