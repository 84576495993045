import { useEffect, useMemo } from 'react';

import { useNavigate } from 'react-router-dom';

export const usePushFilteredUrl = (urlFilters, subMenus) => {
  const navigate = useNavigate();
  const { page, title, filterModalURL, filterStatusURL, pageSize } = urlFilters;

  const computedURL = useMemo(() => {
    let search = '';
    search = search + `&page=${page}&pageSize=${pageSize}`;

    if (title !== '') {
      search = search + `&title=${title}`;
    }
    if (filterModalURL.length) {
      search = search + filterModalURL;
    }
    if (filterStatusURL.length) {
      search = search + filterStatusURL;
    }
    return { search };
  }, [page, title, filterModalURL, filterStatusURL, pageSize]);

  useEffect(() => {
    navigate(
      {
        pathname: subMenus || 'tum-maddeler',
        search: computedURL.search,
      },
      { replace: false }
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [computedURL.search, subMenus]);

  return { newRoute: computedURL.search };
};
