import CommitteeOpinionExtras from 'module/Form/CommitteeOpinionExtras';

import React from 'react';

import { Button, Checkbox, Col, Form, Row, Tooltip } from 'antd';
import { useGetCommitteesQuery, useSendCommitteeControlDecisionMutation } from 'services';
import { CircularProgress } from 'components/CircularProgress';
import { higherCommittee, taekId } from 'constant/static';
import { useDispatch } from 'react-redux';
import { fetchError } from 'slices/commonSlice';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

const AskCommiteeDecisionView = () => {
  const [form] = Form.useForm();
  const { offerID, authorID, comitteeID, status } = useParams();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const [sendCommitteeControlDecision] = useSendCommitteeControlDecisionMutation();
  const dispatch = useDispatch();
  const { data, isLoading } = useGetCommitteesQuery();

  const handleFinish = async (values) => {
    const newValues = {
      status: Number(status),
      ...(values.taekCommittee
        ? {
            committees: [...values.committees, ...values.taekCommittee],
          }
        : {
            committees: values.committees,
          }),
    };

    const { error } = await sendCommitteeControlDecision({ id: Number(offerID), body: newValues });

    if (error) return dispatch(fetchError(formatMessage({ id: 'article.form.fetchError' }, { error: error.status })));

    navigate('/madde-teklifleri/tum-maddeler');
  };

  if (isLoading) return <CircularProgress />;
  return (
    <>
      <Button onClick={() => navigate(`/madde-detay/${offerID}`)}>Geri</Button>
      <Form
        form={form}
        onFinish={handleFinish}
        layout="vertical"
      >
        <CommitteeOpinionExtras
          authorID={authorID}
          offerID={offerID}
        />
        <Row
          style={{
            flexDirection: 'row',
          }}
          gutter={24}
          className=" gx-pt-3 gx-pb-5"
        >
          <Col
            xs={24}
            sm={24}
            md={12}
          >
            <Form.Item
              name="committees"
              label="İlim Heyeti"
              rules={[{ required: true, message: 'İlim Heyeti seçiniz' }]}
            >
              <Checkbox.Group>
                {data?.map(
                  (item) =>
                    !higherCommittee.includes(item.id) && (
                      <Col
                        key={item.id}
                        span={24}
                      >
                        <Tooltip title={item.id === Number(comitteeID) ? 'Sorumlu ilim heyeti' : ''}>
                          <Checkbox
                            disabled={item.id === Number(comitteeID)}
                            key={item.id}
                            value={item.id}
                          >
                            {item.name}
                          </Checkbox>
                        </Tooltip>
                      </Col>
                    )
                )}
              </Checkbox.Group>
            </Form.Item>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
          >
            <Form.Item
              name="taekCommittee"
              label="TAEK"
              normalize={(value) => (value ? value : null)}
            >
              <Checkbox.Group>
                <Checkbox value={taekId}>Kuruldan Görüş İste</Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </Col>
        </Row>
        <Button
          block
          htmlType="submit"
        >
          Görüş İste
        </Button>
      </Form>
    </>
  );
};

export default AskCommiteeDecisionView;
