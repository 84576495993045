import React from 'react';

import { Card } from 'antd';
import { Link } from 'react-router-dom';

export const TokenExpired = () => {
  return (
    <div className="token-expired-wrapper">
      <Card className="gx-p-2 token-expired-card">
        <div className="gx-p-2">
          <Link to={'/'}>
            <img
              src="https://cdn.islamansiklopedisi.org.tr/assets/w/images/layouts/dia-yatay-logo.svg"
              alt="İSAM"
              width="100%"
            />
          </Link>
        </div>

        <h4 className="gx-mx-auto gx-w-75 gx-text-center gx-font-weight-semi-bold gx-p-2 gx-m-4 gx-text-grey token-expired-title">
          TDV İSLAM ANSİKLOPEDİSİ <br /> 2. EDİSYON YÖNETİM SİSTEMİ
        </h4>

        <h4 className="gx-text-center">Tıklanılan linkin kullanım süresi dolmuştur. Lütfen işlemleri baştan tekrarlayınız.</h4>

        <div className="gx-text-light gx-fs-sm gx-mt-2 gx-text-center ">
          <Link to="/parola-sifirlama">Parolamı Unuttum</Link>
        </div>
      </Card>
    </div>
  );
};

export default TokenExpired;
