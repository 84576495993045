import React from 'react';

import { Select } from 'antd';
import { useIntl } from 'react-intl';
import { useGetArticleTypesQuery } from 'services';

const ArticleTypeSelect = ({ editable = true, disabled, value, onChange }) => {
  const { formatMessage } = useIntl();
  const { data, isLoading } = useGetArticleTypesQuery();

  return (
    <Select
      name="maddeTuru"
      disabled={isLoading || !editable || disabled}
      onChange={onChange}
      value={value}
      options={
        !isLoading && [
          ...data?.map((item) => ({
            label: item.name,
            value: item['@id'],
          })),
          {
            label: formatMessage({ id: 'article.detail.articleTypeOther' }),
            value: 'Diğer',
          },
        ]
      }
    />
  );
};

export default ArticleTypeSelect;
