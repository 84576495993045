import { useMemo, useRef, useState, useEffect } from 'react';

import { FormattedMessage } from 'react-intl';
import { Spin, Select } from 'antd';
import { debounce } from 'lodash';
import { researchGet } from 'fetcher';
import { openNotification } from 'utils';

function DebounceSelect({ fetchOptions, debounceTimeout = 800, ...props }) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);

  useEffect(() => {
    fetchOptions().then((newOptions) => {
      setOptions(newOptions);
    });
  }, [fetchOptions]);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        setOptions(newOptions);
        setFetching(false);
      });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
      id="researchInput"
    />
  );
}

async function fetchUserList(searchValue) {
  if (searchValue)
    return researchGet(searchValue)
      .then((res) => res['hydra:member'])
      .then((body) =>
        body.map((user) => ({
          label: `${user.name}`,
          value: user['@id'],
        }))
      );
}

const AuthorResearch = ({ form, author }) => {
  const [value, setValue] = useState([]);
  const [searchValue, setSearchValue] = useState([]);

  const clearInput = () => {
    setSearchValue('');
  };

  const addItem = (e) => {
    if (e.key === ',' && e.target.value.length) {
      setValue([
        ...value,
        {
          value: e.target.value,
          label: e.target.value,
        },
      ]);
      openNotification({
        message: `${e.target.value} listeye eklendi`,
      });
      clearInput();
    }
  };

  useEffect(() => {
    form?.setFieldsValue({ authorResearchSubjects: value });
    if (value === undefined) setValue([]);
  }, [form, value]);

  useEffect(() => {
    let authorResearchSubjectsArray = author?.authorResearchSubjects.map(
      (item) => {
        return {
          value: item.researchSubject['@id'],
          label: item.researchSubject.name,
          key: item.researchSubject['@id'],
          id: item['@id'],
        };
      }
    );
    setValue(authorResearchSubjectsArray);
  }, [author]);

  return (
    <DebounceSelect
      mode="multiple"
      value={value}
      placeholder={<FormattedMessage id="author.info.researchPlaceholder" />}
      fetchOptions={() => fetchUserList(searchValue)}
      onChange={(newValue) => {
        setValue(newValue);
      }}
      tokenSeparators={[',']}
      style={{
        width: '100%',
      }}
      onKeyDown={(e) => addItem(e)}
      searchValue={searchValue}
      onSearch={setSearchValue}
    />
  );
};

export default AuthorResearch;
