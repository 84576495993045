import { useRef, useState } from 'react';

import { Button, Table, Row, Col, Tag, Input } from 'antd';
import { EditOutlined, EyeOutlined, PlusCircleOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import Cookies from 'js-cookie';
import { useCommittees, useUsers } from 'useSWR';
import { useWindowSize } from 'hooks';
import { exportTable, MISSION, ROLES_DEF } from 'utils';
import { Title } from 'components';
import { apiInstance } from 'services';
import { useDispatch } from 'react-redux';
import UserAddDrawer from 'components/Drawer/User/UserAddDrawer';
import EditUserDrawer from 'components/Drawer/User/EditUserDrawer';

const { Search } = Input;

const UserList = () => {
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openEditDrawer, setOpenEditDrawer] = useState({
    visible: false,
    id: null,
  });
  const { formatMessage } = useIntl();
  const { height } = useWindowSize();

  const dispatch = useDispatch();

  const debounce = useRef();
  const [wait, setWait] = useState(false);
  const [filters, setFilters] = useState({
    page: 1,
    pageSize: 20,
  });

  const { users, isLoading, mutate, url: downloadURL } = useUsers(filters);
  const { committees, isLoading: committeesLoading } = useCommittees('all');

  const pagination = {
    current: filters.page,
    pageSize: filters.pageSize,
    total: users?.['hydra:totalItems'] ?? 1,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    position: ['bottomRight'],
    responsive: true,
    size: 'default',
    showTotal: (total) => `${formatMessage({ id: 'table.totalItem' }, { type: 'Üye' })} ${total}`,
  };

  const columns = [
    {
      title: '#',
      width: '6%',
      render: (_i, _r, index) => <span>{index + 1 + (filters.page - 1) * filters.pageSize}</span>,
    },
    {
      title: <FormattedMessage id="appModule.email" />,
      dataIndex: 'email',
      width: '19.2%',
    },
    {
      title: <FormattedMessage id="appModule.name" />,
      dataIndex: 'firstName',
      width: '15%',
    },
    {
      title: <FormattedMessage id="appModule.surname" />,
      dataIndex: 'lastName',
      width: '15%',
    },
    {
      title: <FormattedMessage id="appModule.role" />,
      dataIndex: 'roles',
      width: '16.8%',
      render: (roles) =>
        Array.isArray(roles) &&
        roles.map((role, i) => (
          <span
            key={i}
            className="gx-d-flex"
          >
            <Tag className="ant-tag-primary gx-mx-0 gx-my-1">{ROLES_DEF[role]}</Tag>
          </span>
        )),
      filters: Object.keys(ROLES_DEF).map((role) => ({
        text: ROLES_DEF[role],
        value: role,
      })),
    },
    {
      title: <FormattedMessage id="appModule.committees" />,
      dataIndex: 'memberships',
      align: 'center',
      width: '8%',
      render: (memberships) =>
        memberships.map((membership, i) => (
          <span
            key={i}
            className="gx-d-block"
          >
            <Tag
              className="ant-tag-primary gx-mx-0 gx-my-1"
              title={`${membership.committee.name} ${MISSION[membership.type]}`}
            >
              {membership.committee.shortName}
            </Tag>
          </span>
        )),
      filters: committees?.map((committee) => ({
        text: committee.name,
        value: committee.shortName,
      })),
    },
    {
      title: <FormattedMessage id="appModule.situation" />,
      dataIndex: 'isActive',
      align: 'center',
      width: '8%',
      render: (isActive) => (
        <Tag
          className="gx-mx-0 gx-my-1"
          color={isActive ? 'green' : 'red'}
        >
          <FormattedMessage id={isActive ? 'appModule.active' : 'appModule.deactivated'} />
        </Tag>
      ),
      filters: [
        {
          text: <FormattedMessage id="appModule.active" />,
          value: 1,
        },
        {
          text: <FormattedMessage id="appModule.deactivated" />,
          value: 0,
        },
      ],
      filterMultiple: false,
    },
    {
      title: 'Kullanıcının Profilinden Gör',
      key: 'action',
      align: 'center',
      width: '8%',
      render: (a) => (
        <div
          className="viewFromUserProfile"
          onClick={() => {
            Cookies.set('xSwitchUser', a.email, {
              expires: 1,
              domain: process.env.REACT_APP_COKKIE_URL,
              path: '/',
            });
            dispatch(apiInstance.util.invalidateTags(['CurrentUser']));
            navigate('/');
          }}
        >
          <EyeOutlined title="Kullanıcının Profilinden Gör" />
        </div>
      ),
    },
    {
      title: <FormattedMessage id="appModule.action" />,
      key: 'action',
      align: 'center',
      width: '6%',
      render: (a) => (
        <EditOutlined
          title="Düzenle"
          onClick={() =>
            setOpenEditDrawer({
              visible: true,
              id: a.id,
            })
          }
        />
      ),
    },
  ];

  const searchName = (e) => {
    clearTimeout(debounce.current);

    debounce.current = setTimeout(() => {
      setFilters({ ...filters, page: 1, search: e.target.value });
    }, 500);
  };

  const handleTableChange = (tablePagination, tableFilters) => {
    const { current, pageSize } = tablePagination;
    setFilters({
      ...filters,
      ...tableFilters,
      page: pageSize === filters.pageSize ? current : 1,
      pageSize,
    });
  };

  const handleExportTable = async () => {
    setWait(true);

    let url = downloadURL;
    url = url.replaceAll('/users', '/users/export?');
    await exportTable(url, 'Üye Listesi');
    setWait(false);
  };

  return (
    <>
      <Title>{formatMessage({ id: 'userList.title' })}</Title>

      <div className="ant-row-flex gx-justify-content-between gx-align-items-center">
        <h1>
          {' '}
          <FormattedMessage id="userList.title" />
        </h1>

        <div className="gx-d-flex gx-d-flex-row">
          <Button
            loading={wait}
            disabled={!(users?.['hydra:totalItems'] > 0)}
            onClick={handleExportTable}
            icon={<CloudDownloadOutlined />}
          >
            <FormattedMessage id="app.export" />
          </Button>

          <Button
            style={{ float: 'right' }}
            type="primary"
            onClick={() => setOpenDrawer(true)}
            icon={<PlusCircleOutlined />}
          >
            <FormattedMessage id="userList.addNewUser" />
          </Button>
        </div>
      </div>

      <Row>
        <Col
          xs={24}
          sm={16}
          md={13}
          lg={11}
        >
          <Search
            name="userName"
            className="gx-mt-3"
            placeholder={formatMessage({ id: 'userList.searchPlaceholder' })}
            loading={isLoading}
            onChange={searchName}
            enterButton
          />
        </Col>
      </Row>

      <Table
        bordered
        columns={columns}
        dataSource={users?.['hydra:member']}
        rowKey={(record) => record.id}
        loading={isLoading || committeesLoading}
        scroll={{ x: 990, y: height - 425 }}
        pagination={pagination}
        onChange={handleTableChange}
      />
      <UserAddDrawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        mutateUser={mutate}
      />
      <EditUserDrawer
        open={openEditDrawer.visible}
        id={openEditDrawer.id}
        onClose={() =>
          setOpenEditDrawer({
            visible: false,
            id: null,
          })
        }
        mutateUser={mutate}
      />
    </>
  );
};

export default UserList;
