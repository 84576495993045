export const KeyboardIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="20"
      height="20"
      className={`anticon ${className}`}
    >
      <path
        fill="none"
        d="M0 0h24v24H0z"
      />
      <path
        fill="#fff"
        d="M4 5v14h16V5H4zM3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm3 4h2v2H6V7zm0 4h2v2H6v-2zm0 4h12v2H6v-2zm5-4h2v2h-2v-2zm0-4h2v2h-2V7zm5 0h2v2h-2V7zm0 4h2v2h-2v-2z"
      />
    </svg>
  );
};
