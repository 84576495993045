import { useEffect, useState } from 'react';

import { Button, Card, Col, Modal, Row, Form, Input } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import { sendArticleOrderMeet } from 'fetcher';
import { openNotification, showErrorMessage } from 'utils';
import draftToHtml from 'draftjs-to-html';

export const OrderMailModal = ({ offer, author, open, close }) => {
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();

  const [busy, setBusy] = useState(false);
  const [mailContent, setMailContent] = useState(EditorState.createEmpty());

  useEffect(() => {
    const html = formatMessage(
      { id: 'article.order.sendMailModalTemplate' },
      {
        title: offer?.title,
        link: process.env.REACT_APP_PUBLIC_BASE_URL,
      }
    );

    const contentBlock = htmlToDraft(html);

    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      setMailContent(editorState);
    }
  }, [offer, formatMessage]);

  async function submit(values) {
    setBusy(true);

    const { subject } = values;
    const content = draftToHtml(convertToRaw(mailContent.getCurrentContent()));

    const data = {
      articleOfferVersion: offer?.['@id'],
      type: 2,
      subject,
      content,
    };

    try {
      await sendArticleOrderMeet(data);

      openNotification({
        message: formatMessage({ id: 'appModule.actionSuccessfully' }),
      });

      close();
    } catch (error) {
      showErrorMessage(error);
    }

    setBusy(false);
  }

  return (
    <Modal
      footer={null}
      width="40%"
      open={open}
      onCancel={close}
      maskClosable={false}
      className="gx-px-4"
      centered
      destroyOnClose
    >
      <h1 className="gx-text-center">{offer?.title}</h1>
      <h2 className="gx-text-center gx-mb-4">
        <FormattedMessage id="appModule.sendMail" />
      </h2>

      <Row>
        <Col span={12}>
          <Card className="gx-mb-3">
            <p className="gx-my-1">{author?.user?.fullName}</p>
          </Card>
        </Col>

        <Col span={12}>
          <Card className="gx-mb-3">
            <p className="gx-my-1">{author?.user?.email}</p>
          </Card>
        </Col>
      </Row>

      <Form
        form={form}
        onFinish={submit}
        layout="vertical"
      >
        <Form.Item
          name="subject"
          label="Konu"
          className="gx-mb-3"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Editor
          toolbar={{ options: ['inline', 'link', 'remove', 'history'] }}
          onEditorStateChange={(value) => setMailContent(value)}
          editorState={mailContent}
        />

        <div className="gx-d-flex gx-mt-4">
          <Button
            onClick={close}
            block
          >
            <FormattedMessage id="appModule.cancel" />
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            loading={busy}
            block
          >
            <FormattedMessage id="appModule.send" />
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
