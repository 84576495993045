import React, { useState } from 'react';

import { Row, Col, Table, Button, Popover } from 'antd';
import { PlusCircleOutlined, FormOutlined, DeleteOutlined, CheckCircleOutlined, CloseCircleOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import ReactReadMoreReadLess from 'react-read-more-read-less';
import dayjs from 'dayjs';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { mutate } from 'swr';
import { useSources, useSourcesTypes } from 'useSWR';
import { removeResource } from 'fetcher';
import { exportTable } from 'utils';
import { useWindowSize } from 'hooks';
import { CircularProgress, Input, Title } from 'components';
import Filter from 'components/Resources/Filter';
import { useUserInfo } from 'providers/UserInfoProvider';

const { Search } = Input;

const NextPage = ({ currentPageOptions }) => {
  const { page, pageSize } = currentPageOptions;
  const { sources: data } = useSources({
    type: 'all',
    title: '',
    page: page + 1,
    pageSize,
  });
  return (
    <span
      className="gx-d-none"
      data-users={data}
    />
  );
};

const Resources = () => {
  const { formatMessage } = useIntl();
  const { isAdmin, isLoading: userDatasLoading, isResourceManager } = useUserInfo();
  const [title, setTitle] = useState('');
  const [currentPageOptions, setCurrentPageOptions] = useState({
    page: 1,
    pageSize: 20,
  });

  const [filters, setFilters] = useState('');
  const {
    sources: data,
    isLoading,
    url: downloadURL,
  } = useSources({
    type: 'all',
    title,
    page: currentPageOptions.page,
    pageSize: currentPageOptions.pageSize,
    sourceType: filters,
  });

  const [loading, setLoading] = useState(false);
  const { types } = useSourcesTypes();

  const { width: screenWidth } = useWindowSize();

  const pagination = {
    defaultCurrent: 1,
    defaultPageSize: 20,
    total: data?.['hydra:totalItems'] ?? 1,
    showTotal: (total) =>
      `${formatMessage(
        {
          id: 'table.totalItem',
        },
        { type: 'Kaynak' }
      )} ${total}`,
    position: ['bottomRight'],
    responsive: true,
    size: 'default',
    onChange: (page, pageSize) => {
      setCurrentPageOptions({ page, pageSize });
    },
    pageSizeOptions: [10, 20],
    showSizeChanger: true,
    onShowSizeChange: (current, size) => {
      setCurrentPageOptions({ page: 1, pageSize: size });
    },
  };

  const searchName = (e) => {
    setCurrentPageOptions({ ...currentPageOptions, page: 1 });
    setTitle(e.target.value.toLocaleLowerCase());
  };

  const deleteSource = async (id) => {
    setLoading(true);
    const res = await removeResource(id);
    if (res === '') {
      mutate(`/resources?title=${title}&itemsPerPage=${currentPageOptions.pageSize}&page=${currentPageOptions.page}${filters}`);

      setLoading(false);
    }
  };

  let columns = [
    {
      title: <FormattedMessage id="resources.sourceName" />,
      dataIndex: 'title',
      width: 150,
      fixed: screenWidth > 575 ? 'left' : null,
      render: (dataIndex, record) => (
        <div className="gx-text-capitalize">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={record.link}
          >
            {dataIndex}
          </a>
        </div>
      ),
    },
    {
      title: <FormattedMessage id="resources.description" />,
      dataIndex: 'description',
      width: 180,
      render: (dataIndex) =>
        dataIndex ? (
          <ReactReadMoreReadLess
            charLimit={90}
            readMoreText={formatMessage({ id: 'resources.showMore' })}
            readLessText={formatMessage({ id: 'resources.showLess' })}
            readMoreClassName="read-more-less--more"
            readLessClassName="read-more-less--less"
          >
            {dataIndex}
          </ReactReadMoreReadLess>
        ) : (
          <div style={{ height: 60 }} />
        ),
    },
    {
      title: (
        <Filter
          title={<FormattedMessage id="resources.accessType" />}
          options={[
            {
              value: 0,
              name: <FormattedMessage id="resources.accessType0" />,
            },
            {
              value: 1,
              name: <FormattedMessage id="resources.accessType1" />,
            },
            {
              value: 2,
              name: <FormattedMessage id="resources.accessType2" />,
            },
            {
              value: 3,
              name: <FormattedMessage id="resources.accessType3" />,
            },
          ]}
          setFilters={setFilters}
          filters={filters}
          filterType={'typeOfAccess'}
        />
      ),
      dataIndex: 'accessType',
      width: 180,
      render: (dataIndex) => <FormattedMessage id={`resources.accessType${dataIndex}`} />,
    },
    {
      title: (
        <Filter
          title={<FormattedMessage id="resources.sourceType" />}
          options={types}
          setFilters={setFilters}
          filters={filters}
          filterType={'typeOfResources'}
        />
      ),
      width: 150,
      dataIndex: 'types',
      render: (dataIndex) => dataIndex.map((item) => <div key={item['@id']}>{item.name}</div>),
    },
  ];

  if ((isResourceManager || isAdmin) && columns.length === 4) {
    columns = [
      ...columns,
      {
        title: <FormattedMessage id="resources.lastUpdatedAt" />,
        dataIndex: 'lastUpdatedAt',
        width: 120,
        align: 'center',
        render: (updatedAt) => dayjs(updatedAt).format('DD/MM/YYYY'),
      },
      {
        title: <FormattedMessage id="resources.isPublished" />,
        dataIndex: 'isPublished',
        width: 90,
        align: 'center',
        render: (dataIndex) => (
          <div>{dataIndex ? <CheckCircleOutlined style={{ color: '#73d13d' }} /> : <CloseCircleOutlined style={{ color: '#ff4d4f' }} />}</div>
        ),
      },
      {
        title: <FormattedMessage id="appModule.action" />,
        width: 90,
        render: (record) => (
          <div
            style={{
              fontSize: 16,
              display: 'flex',
              width: 50,
              justifyContent: 'space-between',
            }}
          >
            <Link to={`/e-kaynak/duzenle/${record.id}`}>
              <FormOutlined
                title="Düzenle"
                className="clickable"
              />
            </Link>
            <Popover
              placement="left"
              content={
                <div className="gx-m-1 gx-d-flex gx-flex-column">
                  <Button
                    onClick={() => deleteSource(record.id)}
                    className="gx-mt-2 gx-mx-auto gx-w-50"
                    danger
                  >
                    <FormattedMessage id="resources.delete" />
                  </Button>
                </div>
              }
              trigger="click"
              title={
                <h3 className="gx-m-1 ">
                  <FormattedMessage id="resources.deleteWillDelete" />
                </h3>
              }
            >
              <DeleteOutlined
                title="Sil"
                className="clickable"
                style={{ color: '#a8071a' }}
              />
            </Popover>
          </div>
        ),
      },
    ];
  }
  const [wait, setWait] = useState(false);

  const handleExportTable = async () => {
    setWait(true);
    let url = downloadURL;
    url = url.replaceAll('/resources', '/resources/export?');
    await exportTable(url, 'E-Kaynaklar');
    setWait(false);
  };

  if (userDatasLoading) return <CircularProgress />;
  return (
    <Row>
      <Title>{formatMessage({ id: 'resources.Title' })}</Title>
      <Col span={24}>
        <div className="ant-row-flex gx-justify-content-between gx-align-items-center gx-mb-2">
          <h1>
            <FormattedMessage id="resources.Title" />
          </h1>
          <div className="gx-d-flex gx-d-flex-row">
            <Button
              loading={wait}
              className="gx-mb-0"
              disabled={!(data?.['hydra:totalItems'] > 0)}
              onClick={handleExportTable}
              icon={<CloudDownloadOutlined />}
            >
              <FormattedMessage id="app.export" />
            </Button>
            {isAdmin && (
              <Link to="/e-kaynak/ekle">
                <Button
                  type="primary"
                  className="gx-mb-0"
                  icon={<PlusCircleOutlined />}
                >
                  <FormattedMessage id="resources.newSource" />
                </Button>
              </Link>
            )}
          </div>
        </div>
      </Col>

      <Col
        xs={18}
        md={11}
      >
        <Search
          className="gx-mt-33"
          name="resource-title"
          placeholder={formatMessage({ id: 'resources.searchSourcePlaceHolder' })}
          onChange={searchName}
          enterButton
        />
      </Col>

      <Col span={24}>
        <Table
          bordered
          columns={columns}
          rowKey={(record) => record.id}
          dataSource={data?.['hydra:member']}
          pagination={pagination}
          loading={loading || isLoading}
          scroll={{ x: 990, y: 435 }}
        />
      </Col>
      <NextPage currentPageOptions={currentPageOptions} />
    </Row>
  );
};

export default Resources;
