import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { AppContainer } from 'containers/App';
import UserInfoProvider from 'providers/UserInfoProvider';
import 'assets/vendors/style';
import './styles/wieldy.less';
import store from 'store';


export default () => {
  return (
    <Provider store={store}>
        <UserInfoProvider>
          <BrowserRouter>
            <AppContainer />
          </BrowserRouter>
        </UserInfoProvider>
    </Provider>
  );
};
