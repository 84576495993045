import React from 'react';

import { articleLength } from 'utils';
import { Radio } from 'antd';

const ArticleLengthRadioGroup = ({ isDictionary, isEncyclopedia, disabled, value, onChange }) => {
  return (
    <Radio.Group
      buttonStyle="solid"
      onChange={onChange}
      value={value}
    >
      <Radio.Button
        className="gx-mb-0"
        value={0}
        disabled={isDictionary || disabled}
      >
        {articleLength[0]}
      </Radio.Button>

      {articleLength.slice(1).map((item, idx) => (
        <Radio.Button
          className="gx-mb-0"
          value={idx + 1}
          key={idx}
          disabled={isEncyclopedia || disabled}
        >
          {item}
        </Radio.Button>
      ))}
    </Radio.Group>
  );
};

export default ArticleLengthRadioGroup;
